import {  Alert, Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Trash01 ,Plus } from '../../../assets';
import { useContext } from 'react';
import { StockCountCreateContext, StockCountCreateValues } from '../contexts/StockCountCreateContext';

const StockCountCreateMain=() => {
  const { t,
    hasCategories,
    isSmartCount,
    deleteCategory,
    handleClickOpen,
    deleteCategoryRow,
    categoryData } = useContext(StockCountCreateContext) as StockCountCreateValues;

  return (
    <Box sx={{ border:'1px solid' }}>
      <Grid container
        sx={{ pt: '12px', pb: '6px', px: '8px' }}>
        <Grid item 
          xs={6}>
          <Typography variant="textMD"
            sx={{ fontWeight:'bold' }}>{t('categories')}</Typography>
        </Grid>
        <Grid item 
          xs={6}
          sx={{ display:'flex', justifyContent:'flex-end',alignItems:'center' }}>
          {hasCategories && (
            <Button
              size='lg'
              sx={{ mr: 4 }}
              variant='bad'
              onClick={deleteCategory}
            ><Trash01 />{t('deleteAll')}
            </Button>
          )}
          <Button
            size='lg'
            variant='primary'
            onClick={handleClickOpen }><Plus/>{t('add')}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ pt:'10px' }}>
        {hasCategories ? (
          <TableContainer sx={{ overflowy:'auto' }}>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell >{t('category')}</TableCell>
                  <TableCell 
                    style={{ display:'flex', justifyContent:'flex-end' }} >{t('remove')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData?.map((row) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell >
                      {row.primaryCategoryName}
                    </TableCell>
                    <TableCell style ={{ display:'flex', justifyContent:'flex-end' }} ><Trash01 style={{ color:'red' }}
                      onClick={() => deleteCategoryRow(row.id ?? 0)}/></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) :(
          <Alert severity='info'
            variant= {isSmartCount ? 'filled': 'outlined'}
            sx={{ mb: '12px', mx: '8px' }}>
            {isSmartCount ?
              t('smartCountMessage')
              :
              t('noCategories')}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default StockCountCreateMain;