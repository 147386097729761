import {  Alert, Box, Button, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { Trash01 ,Plus } from '../../../assets';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';

const ScheduleCreateItems=() => {
  const { t,
    isLoading,
    handleQtyChange,
    handleValueChange,
    handleAddBulkItemClick,
    handleAddItemsClick,
    deleteItemRow,
    handleCategoryChange,
    currencySymbol,
    itemsData,
    hasRightToEdit } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const hasItems = itemsData.length > 0;

  if(isLoading){
    return <></>;
  } 

  return (
    <Box sx={{ border:'2px solid', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        sx={{ pt: '12px', pb: '6px', px: '8px', borderRadius:'4px' }}>
        <Grid item 
          xs={3}>
          <Typography variant="textMD"
            sx={{ textAlign:'left',fontWeight:'bold' }}>{t('items')}</Typography>
        </Grid>
        <Grid item 
          xs={9} 
          sx={{ display:'flex',justifyContent:'flex-end',alignItems:'center' }}>
          {hasItems && (
            <Button size='lg'
              sx={{ mr: 3 }}
              onClick={() => deleteItemRow()}
              disabled = {!hasRightToEdit}
              variant='bad'
              startIcon={<Trash01/>}>{t('deleteAll')}
            </Button>
          )}
          <Button size='lg'
            sx={{ mr: 3 }}
            variant='primary'
            disabled = {!hasRightToEdit}
            onClick={handleAddBulkItemClick}
            startIcon={<Plus/>}>
            {t('addBulks')}
          </Button>
          <Button size='lg'
            variant='primary'
            onClick={handleAddItemsClick}
            disabled = {!hasRightToEdit}
            startIcon={<Plus/>}>
            {t('addItem')}
          </Button>
        </Grid>
      </Grid>
      <Box sx= {{ pt:'10px' }}>
        {hasItems ? (
          <TableContainer sx={{ overflowy:'auto' }}>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell >{t('skuNumber')}</TableCell>
                  <TableCell >{t('description')}</TableCell>
                  <TableCell >{t('category')}</TableCell>
                  <TableCell >{t('approvalQtyValue') + ' ' + currencySymbol}</TableCell>
                  <TableCell >{t('qtyTolerance')}</TableCell>
                  <TableCell >{t('remove')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsData?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.itemNumber}
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>  
                    <TableCell>
                      <TextField value={row.category ?? ''}
                        disabled={!hasRightToEdit} 
                        onChange={(e) => handleCategoryChange(row.id ?? 0, e.target.value)}></TextField>
                    </TableCell>  
                    <TableCell>
                      <TextField InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      disabled={!hasRightToEdit} 
                      type='number'
                      value={row.tolerancePercentage?.toString() ?? '0'}
                      onChange={(e) => handleQtyChange(row.itemNumber ?? '', e.target.value)}></TextField>  
                    </TableCell>  
                    <TableCell>
                      <TextField InputProps={{
                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      }}
                      type='number'
                      disabled={!hasRightToEdit} 
                      value={row.toleranceValue?.toString() ?? '0'}
                      onChange={(e) => handleValueChange(row.itemNumber ?? '', e.target.value)}></TextField>  
                    </TableCell>
                    <TableCell> 
                      <IconButton
                        disabled={!hasRightToEdit} 
                        onClick={() => deleteItemRow(row.itemNumber ?? '')} >
                        <Trash01 style={{ color:!hasRightToEdit ? 'action' : 'red' }} />
                      </IconButton> 
                    </TableCell>
                  </TableRow> 
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) :(
          <Alert severity='info'
            sx={{ mb: '12px', mx: '8px' }}>
            {t('noItems')}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleCreateItems;