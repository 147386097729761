import PurchaseOrdersDetailsTable from '../components/PurchaseOrderDetailsTable';
import { Box } from '@mui/material';
import { theme } from '../../../theme';
import PurchaseOrdersDetailsFilters from '../components/PurchaseOrderDetailsFilters';
import { useContext } from 'react';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';

const PurchaseOrdersDetailsMainArea = () => {
  const { isLoading } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  return !isLoading ? <Box sx={{
    border: '1px solid',
    borderColor: theme.palette.custom.gray[300],
  }} >
    <PurchaseOrdersDetailsFilters/>
    <PurchaseOrdersDetailsTable/>
  </Box>
    :
    <></>; 
};

export default PurchaseOrdersDetailsMainArea;