import { Alert, Snackbar } from '@mui/material';

interface MessagesSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

const MessagesSnackbar: React.FC<MessagesSnackbarProps> = ({ open, onClose, message, severity }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      autoHideDuration={3000}
    >
      <Alert onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessagesSnackbar;