import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CreateTransactionsChangeCostConfirmDialog = () => {
  const { closeCostChangeModal, isCostChangeModalOpen, t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={isCostChangeModalOpen}
    >
      <DialogTitle sx={{ pr: '48px' }}>
        {t('confirmPrice.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeCostChangeModal}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pb: 0 }}>
        <Typography variant='textMD'
          sx={{ mb: 8 }}>
          {t('confirmPrice.message')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='primary'
          size="lg"
          onClick={() => { closeCostChangeModal(); }}>
          {t('confirmPrice.continue')}
        </Button>
      </DialogActions >
    </Dialog>
  );
};

