import { Button, Grid, SvgIcon, TextField } from '@mui/material';
import ManageUsersTable from './ManageUsersTable';
import { User } from '../../../app/services/api/generated';
import { useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { Plus, SearchSm } from '../../../assets';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import CreateEditModal from './UserEditCreateModal';
import { theme } from '../../../theme';
import { UserPutPostRequest } from '../types/UserPutPostRequest';
import useLogError from '../../../hooks/useLogError';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';

const MainBox = (props: { users: User[], loadData: () => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { createUser } = useNswagClient();
  const { t } = useTranslation('manageUsers');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [transformedData, setTransformedData] = useState<User[]>(props.users);
  const [page, setPage] = useState(0);
  const [showCreateSuccessMessage, setShowCreateSuccessMessage] = useState<boolean>(false);
  const [showCreateErrorMessage, setShowCreateErrorMessage] = useState<boolean>(false);

  const { logError }= useLogError();

  const handleCreate = (body: UserPutPostRequest) => {
    createUser(body)
      .then(() => {
        setShowCreateSuccessMessage(true); 
        setTimeout(() => props.loadData(), 2000);
      })
      .catch((error) => {
        logError(error);
        setShowCreateErrorMessage(true); 
      });
  };

  const closeProcessingAlert = () => {
    setShowCreateSuccessMessage(false);
  };

  const closeErrorAlert = () => {
    setShowCreateErrorMessage(false);
  };

  const handleSearch = () => {
    setPage(0);
    const filteredData = props.users.filter(x => 
      x.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      x.persona?.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      x.franchiseName?.toLowerCase().includes(searchTerm.toLowerCase()));
    setTransformedData(filteredData);
  };

  const handleClear = () => {
    setPage(0);
    setSearchTerm('');
    props.loadData();
  };
  
  return <><Grid item
    xs={12}
    container
    justifyContent="flex-end">
    <Button variant="primary"
      size="lg"
      startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
      sx={{ ml: { sm: 2 } }}
      onClick={() => setShowCreateModal(true)}>
      {t('createNew')}
    </Button>
  </Grid><Grid item
    container
    mt={5}
    xs={12}
    sx={{
      padding: '20px 20px 20px 20px',
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
    }}>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <TextField
        fullWidth
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        size='small'
        label={t('search')} />
    </Grid>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <Button fullWidth
        variant="secondary"
        size="lg"
        onClick={() => handleClear()}
      >
        {t('clearFilters')}
      </Button>
    </Grid>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <Button fullWidth
        variant="primary"
        size="lg"
        onClick={() => handleSearch()}
        startIcon={<SearchSm />}
      >
        {t('search')}
      </Button>
    </Grid>
    <ManageUsersTable list={transformedData}
      setPage={setPage}
      page={page}
      loadData={props.loadData}></ManageUsersTable>
  </Grid><CreateEditModal openModal={showCreateModal}
    onConfirm={(requestBody: UserPutPostRequest) => handleCreate(requestBody)}
    user={undefined}
    onCancel={() => setShowCreateModal(false)}
  ></CreateEditModal>
  <MessagesSnackbar
    open={showCreateSuccessMessage}
    onClose={closeProcessingAlert}
    message={t('successCreateMessage')}
    severity="success"
    duration={null}
  />
  <MessagesSnackbar
    open={showCreateErrorMessage}
    onClose={closeErrorAlert}
    message={t('errors.creatingUser')}
    severity="error"
    duration={null}
  /></>;
};

const ManageUsers = (props: { users: User[], loadData: () => void, isLoading: boolean }) => {
  return props.isLoading ? <LoadingWheel></LoadingWheel> : <MainBox users={props.users}
    loadData={props.loadData} ></MainBox>;
};

export default ManageUsers;