import useLogError from '../../../hooks/useLogError';
import { ItemFeedChange } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const usePendingChanges = () => {
  const { t } = useTranslation('recipeUpdates');
  const { getRecipeChanges, applyChanges, disregardChanges } = useNswagClient();
  const { logError } = useLogError();

  const [data,  setData] = useState<ItemFeedChange[]>([]);
  const [page,  setPage] = useState(0);
  const [maxPages,  setMaxPages] = useState<number>(0);
  const [isLoading, setIsLoading]= useState(false);
  const [isProcessing, setIsProcessing]= useState(false);
  const [isFinishedProcessing, setIsFinishedProcessing]= useState(false);
  const [isErrorProcessing, setIsErrorProcessing]= useState(false);
  const [errorMessage, setErrorMessage]= useState('');

  const [isChangesDialogOpen, setIsChangesDialogOpen] = useState(false);
  const [isDisregardDialogOpen, setIsDisregardDialogOpen] = useState(false);
  const [viewId, setViewId] = useState(-1);
  const [searchTerm,  setSearchTerm] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  
  const { selectedStore } = useContext(UserContext); 

  const selectedStoreNumber = selectedStore?.storeNumber ?? '-1';

  const handleViewButtonClick = (id: number) => () => {
    setViewId(id);
    setIsChangesDialogOpen(true);
  };

  const closeDialog = () => {
    setIsChangesDialogOpen(false);
  };

  const handleDisregardButtonClick = (id: number) => () => {
    setViewId(id);
    setIsDisregardDialogOpen(true);
  };

  const closeDisregardDialog = () => {
    setIsDisregardDialogOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchButtonClick = () => {
    setPage(0);
    loadData(searchTerm, 0);
  };

  const loadData = (passedSearch?: string, passedPage?: number) => {
    const searchTermToUse = passedSearch ?? searchTerm;
    const pageToUse = passedPage ?? page;
    setIsLoading(true);
    getRecipeChanges(searchTermToUse, selectedStoreNumber, pageToUse * 10, 10, true, false, false)
      .then(
        (result) => {
          setData(result.data ?? []);
          const count = result.totalCount ?? 0;
          const maxPages = Math.ceil(count / 10);
          setMaxPages(maxPages);
        },
      )
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleClearButtonClick = () => {
    setSearchTerm('');
    setPage(0);
    loadData('', 0);
    setSelectedRows([]);
  };

  const handleRowSelection = (itemId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(itemId)) {
        return prevSelectedRows.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedRows, itemId];
      }
    });
  };

  const handleHeaderSelection = () => {
    if (selectedRows.length == 10){
      setSelectedRows([]);
    }
    else{
      setSelectedRows(data.map((row) => row.itemFeedChangeId ?? -1));
    }
  };

  const handleDisregardSelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await disregardChanges(selectedRows)
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        setSelectedRows([]);
        setPage(0);
        loadData(searchTerm, 0);
      });
  };

  const handleApplySelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await applyChanges(selectedRows)
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        setSelectedRows([]);
        setPage(0);
        loadData(searchTerm, 0);
      });
  };

  const dismissAlerts = () => {
    setIsErrorProcessing(false);
    setIsFinishedProcessing(false);
  };

  const changePage = (pageNumber: number) => {
    setPage(pageNumber);
    setSelectedRows([]);
  };

  return {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData: loadData,
    handleClearButtonClick,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  };
};