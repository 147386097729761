import React, { createContext, useMemo } from 'react';
import { useCreateTransaction } from '../hooks/useCreateTransactions';
import { ReasonRecord } from '../../../types/ReasonRecord';
import { TypesRecord } from '../../../types/TypesRecord';
import { SelectChangeEvent } from '@mui/material';
import { Item, ProductTransactionSearch } from '../../../../../app/services/api/generated';
import { ItemCost, ItemPossibleUOMs, ItemReason, ItemUoms, ItemQuantity } from '../interfaces/itemValueTypes';
import { TFunction } from 'i18next';

export interface CreateTransactionsValues {
  hasPermissionToChangePrice: boolean,
  onCancel: () => void,
  quantityIsValid: (id: number | undefined) => boolean,
  uomIsValid: (id: number | undefined) => boolean,
  isFormInvalid: () => boolean,
  transactionTypesToShow: () => TypesRecord[],
  handleTypeChange: (event: SelectChangeEvent) => void,
  handleSearchTermChange: (value: string) => void,
  handleItemSelect: (newValue: string) => Promise<void>,
  handleNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleCostChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleQuantityChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleReasonChange: (id: number) => (event: SelectChangeEvent) => void,
  handleUomChange: (id: number) => (event: SelectChangeEvent) => void,
  handleRecentActivity: (event: React.ChangeEvent<HTMLInputElement>) => void,
  deleteButtonHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void,
  dropDownItems: string[],
  reasonsToShow: () => ReasonRecord[],
  handleSubmit: () => void,
  openModal: boolean,
  hasRecentActivity: boolean | undefined,
  transactionType: TypesRecord | undefined,
  createDate: Date,
  handleCreateDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  value: string | null,
  searchTerm: string,
  itemsToCreate: Item[],
  itemUoms: ItemUoms[],
  itemPossibleUOMs: ItemPossibleUOMs[],
  itemQuantities: ItemQuantity[],
  itemCosts: ItemCost[],
  itemReasons: ItemReason[],
  totalReceiptValue: number,
  note: string,
  isItemsLoading: boolean,
  isProcessingSubmit: boolean,
  isSubmittedSuccess: boolean,
  isDisableActions: boolean,
  isError: boolean,
  errorDismissButtonHandler: () => void,
  successDismissButtonHandler: () => void,
  handleDuplicateDialogClose: () => void,
  isDuplicateModalOpen: boolean,
  createTransactions: () => void,
  duplicateTransactions: ProductTransactionSearch[],
  closeTransactionTypeChangeModal: () => void,
  changeType: () => void,
  isTypeChangeModalOpen: boolean,
  handleCostFeildSelected: () => void,
  handleCostFeildUnselected: () => void,
  closeCostChangeModal: () => void,
  isCostChangeModalOpen: boolean,
  currencyCode: string | undefined,
  hasNewChanges: boolean,
  t: TFunction<'createTransaction', undefined>,
  createErrorMessage: string,
}

export const CreateTransactionsContext = createContext<null | CreateTransactionsValues>(null);

interface ProviderProps {
  children: React.ReactNode;
  openModal: boolean;
  onCancel: () => void;
}

export const CreateTransactionProvider = ({ children, openModal, onCancel }: ProviderProps) => {
  const {
    hasPermissionToChangePrice,
    quantityIsValid,
    uomIsValid,
    isFormInvalid,
    transactionTypesToShow,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleNoteChange,
    handleCostChange,
    handleQuantityChange,
    handleReasonChange,
    handleUomChange,
    handleRecentActivity,
    deleteButtonHandler,
    dropDownItems,
    reasonsToShow,
    handleSubmit,
    transactionType,
    createDate,
    handleCreateDateChange,
    value,
    searchTerm,
    hasRecentActivity,
    itemsToCreate,
    itemUoms,
    itemPossibleUOMs,
    itemQuantities,
    itemCosts,
    itemReasons,
    totalReceiptValue,
    note,
    isItemsLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    handleDuplicateDialogClose,
    isDuplicateModalOpen,
    createTransactions,
    duplicateTransactions,
    closeTransactionTypeChangeModal,
    changeType,
    isTypeChangeModalOpen,
    handleCostFeildSelected,
    handleCostFeildUnselected,
    closeCostChangeModal,
    isCostChangeModalOpen,
    currencyCode,
    hasNewChanges,
    t,
    createErrorMessage,
  } = useCreateTransaction();

  const passedValue = {
    hasPermissionToChangePrice,
    onCancel,
    quantityIsValid,
    uomIsValid,
    isFormInvalid,
    transactionTypesToShow,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleNoteChange,
    handleCostChange,
    handleQuantityChange,
    handleReasonChange,
    handleUomChange,
    handleRecentActivity,
    deleteButtonHandler,
    dropDownItems,
    reasonsToShow,
    handleSubmit,
    openModal,
    transactionType,
    createDate,
    handleCreateDateChange,
    value,
    searchTerm,
    hasRecentActivity,
    itemsToCreate,
    itemUoms,
    itemPossibleUOMs,
    itemQuantities,
    itemCosts,
    itemReasons,
    totalReceiptValue,
    note,
    isItemsLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    handleDuplicateDialogClose,
    isDuplicateModalOpen,
    createTransactions,
    duplicateTransactions,
    closeTransactionTypeChangeModal,
    changeType,
    isTypeChangeModalOpen,
    handleCostFeildSelected,
    handleCostFeildUnselected,
    closeCostChangeModal,
    isCostChangeModalOpen,
    currencyCode,
    hasNewChanges,
    t,
    createErrorMessage,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <CreateTransactionsContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </CreateTransactionsContext.Provider>
  );
};