import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { ItemCompare } from '../../../../../app/services/api/generated';
import { theme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';

export const ChangesTable = (props: {data: ItemCompare}) => {
  const { t } = useTranslation('recipeUpdates');
  
  const bodyStyle = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
  };

  const bodyStyleWarning = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
    backgroundColor: theme.palette.warning[200],
  };

  const current = props.data.itemCurrently;
  const after = props.data.itemAfterUpdate;

  const dataToDisplay = [
    { display: t('discription'), current: current?.description, after: after?.description },
    { display: t('imageUrl'), current: current?.imageUrl, after: after?.imageUrl },
    { display: t('imageUrlLarge'), current: current?.imageUrlLarge, after: after?.imageUrlLarge },   
    { display: t('primaryCategory'), current: current?.primaryCategory, after: after?.primaryCategory },   
    { display: t('familyGroupName'), current: current?.familyGroupName, after: after?.familyGroupName },   
    { display: t('status'), current: current?.status, after: after?.status },   
    { display: t('uom'), current: current?.uom, after: after?.uom },   
    { display: t('weightNet'), current: current?.weightNet, after: after?.weightNet },   
    { display: t('isBom'), current: current?.isBom, after: after?.isBom },   
    { display: t('isPosEnabled'), current: current?.isPosEnabled, after: after?.isPosEnabled },   
    { display: t('isSaleItem'), current: current?.isSaleItem, after: after?.isSaleItem },   
    { display: t('isStockItem'), current: current?.isStockItem, after: after?.isStockItem },   
  ];

  const dataToDisplay2 = dataToDisplay.map((row) => {
    return { display: row.display, current: row.current, after: row.after, isDifferent: String(row.current ?? '') != String(row.after ?? '') };
  });

  return (
    <TableContainer component={'div'}>
      <Table
        size="small"
        aria-label="a table"
        sx={{
          borderTop: '1px solid',
          borderColor: theme.palette.custom.gray[200],
        }}
      >
        <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
          <TableRow>
            <TableCell>
            </TableCell>
            <TableCell
              sx={{ fontWeight: 'bold' }}
              align="left"
            >
              {t('current')}
            </TableCell>
            <TableCell
              sx={{ fontWeight: 'bold' }}
              align="left"
            >
              {t('after')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToDisplay2.map((row) => (
            <TableRow
              key={row.display}
              sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={bodyStyle}
              >
                {row.display}:
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={bodyStyle}
              >
                {String(row.current ?? '')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={row.isDifferent ? bodyStyleWarning : bodyStyle}
              >
                {String(row.after ?? '')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
