import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useContext } from 'react';
import { invoiceItemStatus } from '../interfaces/InvoiceStatus';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';

const InvoiceDetailsTable = () => {
  const { t, sorteditems, isCreditNote, handleNoteButtonClick, handleAcceptButtonClick, handleEditNoteButtonClick, rowSxPropToUse, canEdit, order, orderBy, handleSort } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;
  
  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel active={orderBy === 'itemNumber'}
              sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('itemNumber')}>
              {t('itemNumber')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'itemDescription'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('itemDescription')}>
              {t('itemDescription')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'itemCategory'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('itemCategory')}>                
              {t('itemCategory')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'quantity'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('quantity')}>
              {t('invoiceQuantity')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'pricePerUnit'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('pricePerUnit')}>
              {t('invoicePricePerItem')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'goodsValue'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('goodsValue')}>
              {t('invoicedGoodsValue')}
            </TableSortLabel>
          </TableCell>
          {!isCreditNote && <TableCell>
            <TableSortLabel active={orderBy === 'receivedQuantity'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('receivedQuantity')}>
              {t('receivedQuantity')}
            </TableSortLabel>
          </TableCell>}
          {!isCreditNote && <TableCell>
            <TableSortLabel active={orderBy === 'receivedPricePerUnit'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('receivedPricePerUnit')}>
              {t('receivedPricePerUnit')}
            </TableSortLabel>
          </TableCell>}
          {!isCreditNote && <TableCell>
            <TableSortLabel active={orderBy === 'receivedGoodsValue'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('receivedGoodsValue')}>
              {t('receivedGoodsValue')}
            </TableSortLabel>
          </TableCell>}
          <TableCell>
            <TableSortLabel active={orderBy === 'creditQuantity'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('creditQuantity')}>
              {t('creditQuantity')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'creditPricePerUnit'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('creditPricePerUnit')}>
              {t('creditPerUnit')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel active={orderBy === 'creditGoodsValue'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('creditGoodsValue')}>
              {t('creditGoodsValue')}
            </TableSortLabel>
          </TableCell>
          {!isCreditNote && <TableCell>
            <TableSortLabel active={orderBy === 'status'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('status')}>
              {t('status')}
            </TableSortLabel>
          </TableCell>}
          {!isCreditNote && <TableCell>
            <TableSortLabel active={orderBy === 'note'}
              hideSortIcon
              direction={order}
              onClick={() => handleSort('note')}>
              {t('note')}
            </TableSortLabel>
          </TableCell>}
          <TableCell>
          
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {sorteditems?.map((item) => 
          <TableRow key={item.id}
            sx={ rowSxPropToUse(item.status ?? '') }>
            <TableCell>
              {item.itemNumber}
            </TableCell>
            <TableCell>
              {item.itemDescription}
            </TableCell>
            <TableCell>
              {item.itemCategory}
            </TableCell>
            <TableCell>
              {item.quantity}
            </TableCell>
            <TableCell>
              {item.pricePerUnit}
            </TableCell>
            <TableCell>
              {item.goodsValue}
            </TableCell>
            {!isCreditNote && <TableCell>
              {item.receivedQuantity}
            </TableCell>}
            {!isCreditNote && <TableCell>
              {item.receivedPricePerUnit}
            </TableCell>}
            {!isCreditNote && <TableCell>
              {item.receivedGoodsValue}
            </TableCell>}
            <TableCell>
              {item.creditQuantity}
            </TableCell>
            <TableCell>
              {item.creditPricePerUnit}
            </TableCell>
            <TableCell>
              {item.creditGoodsValue}
            </TableCell>
            {!isCreditNote && <TableCell>
              {item.status}
            </TableCell>}
            {!isCreditNote && <TableCell align='center'>
              {<Tooltip title={item.note}>
                {canEdit && item.status !== invoiceItemStatus.Matched && item.status !== invoiceItemStatus.Accepted ?
                  <IconButton onClick={handleEditNoteButtonClick(item.note ?? '', item.id ?? -1)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  :
                  item.note ? <IconButton onClick={handleNoteButtonClick(item.note ?? '', item.id ?? -1)}>
                    <InfoIcon />
                  </IconButton> : <></>}
              </Tooltip>}
            </TableCell>}
            {!isCreditNote && <TableCell>
              <Button size='lg'
                disabled={item.status === invoiceItemStatus.Matched 
                          || item.status === invoiceItemStatus.Accepted}
                onClick={handleAcceptButtonClick(item.id ?? -1)}>
                {t('accept')}
              </Button>
            </TableCell>}
          </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
};

export default InvoiceDetailsTable;