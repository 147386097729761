import {  Button, Typography,  Dialog,  DialogContent, DialogActions, DialogTitle, IconButton, Grid } from '@mui/material';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const PreviewModal=() => {
  const { t,
    previewModal,
    previewData,
    handleClose,
  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  return (
    <Dialog open={previewModal}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {t('previewDates')} 
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {previewData?.map((row, index) => (
          <Grid container
            spacing={5}
            key={index}
            flexWrap={'wrap'}
            sx={{ p:'10px' }}
          >
            <Grid item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Typography> {t('generateAt')} {row.generateAt?.substring(0, 10) } {t('reportFrom')} {row.reportFrom?.substring(0, 10) } {t('to')} {row.reportTo?.substring(0, 10) } </Typography> 
            </Grid>  
          </Grid>
        ))}
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;