import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import logo from '../../assets/images/LogoOrderly.jpg';
import logomark from '../../assets/images/Logomark.jpg';
import ImageCarousel from './components/ImageCarousel';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const LoginPage = () => {
  const { t } = useTranslation('authentication');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentYear = new Date().getFullYear();
  const [ showForgotPassword, setShowForgotPassword ] = useState(false);
  
  const showLogin = () => {
    setShowForgotPassword(false);
  };

  const showPassword = () => {
    setShowForgotPassword(true);
  };

  return (
    <Grid container
      sx={{ display: 'flex', height: '100vh', width: '100vw', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Grid item
        lg={5}
        md={5}
        xs={12}
        sx={{ padding: isMobile ? '48px' : '32px', display: 'flex', flexDirection: 'column', justifyContent: isMobile ? 'none' : 'space-between' }}
      >
        <Box
          sx={{ display: 'flex', alignContent: 'left' }}>
          {!isMobile && (<img src={logo}
            style={{ width: '135px', height: '35px' }}
            alt='' />)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>{isMobile && (<img src={logomark}
          style={{ width: '40px', height: '40px' }}
          alt='' />)}</Box>
        <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBlock: 'auto' }} >
          {!showForgotPassword ? <LoginForm switchDisplay={showPassword} /> : <ForgotPassword switchDisplay={showLogin} />}
        </Grid>
        <Box
          sx={{ display: isMobile ? 'none' : 'flex', flexDirection: 'row' }}
        >
          <Typography variant="textSM"
            sx={{ paddingRight: '62px' }}>{`${t('copyright')} ${currentYear}`}</Typography>
          <Typography variant="textSM">{t('privacyPolicy')}</Typography>
        </Box>
      </Grid>
      <Grid item
        lg={7}
        md={7}
        sx={{ display: isMobile ? 'none' : 'flex' }} >
        <ImageCarousel />
      </Grid>
    </Grid>
  );
};

export default LoginPage;