import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Trash01 } from '../../../assets';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, onCancel }) => {
  const { t } = useTranslation('manageRoles');

  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <Dialog
      onClose={() => onCancel()}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('deleteRole')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('deleteRoleConfirm')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
        <Button
          variant='bad'
          size="lg"
          onClick={handleConfirm}
          startIcon={<SvgIcon><Trash01 /></SvgIcon>}>
          {t('confirmDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteModal;