import {  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery  } from '@mui/material';
import TransactionTypes from './components/TransactionTypes';
import { Plus, Trash01 } from '../../assets';
import CreateReasonModal from './modals/CreateReason';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../components/shared/useUser';
import { Reason } from '../../app/services/api/generated';
import useNswagClient from '../../hooks/api/useNswagClient';
import { theme } from '../../theme';
import EditReasonModal from './modals/EditReason';
import DeleteReasonModal from './modals/DeleteReason';
import useLogError from '../../hooks/useLogError';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import MessagesSnackbar from './modals/CreateMessageConfirm';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const TransactionsManagement=() => {
  const { selectedStore } = useContext(UserContext);
  const [reasons, setReasons] = useState<Reason[] >([]);
  const { getTransactionReasons } = useNswagClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [hasSaveReason, setHasSaveReason] = useState(false);
  const [createMessage, setCreateMessage] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [reasonId, setReasonId] =  useState<number>(0);
  const[ description, setDescription ] = useState<string>('');
  const[ name, setName ] = useState<string>('');
  const[ actionType, setActionType ] = useState<string>('');
  const { logError } = useLogError();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('manageTransactions');
  const nameOfReason = 'Mark Out';
  
  useEffect(() => {
    setLoading(true);
    getReasons();
  },[hasSaveReason]);
 
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
  };

  const handleCreateSuccess = () => {
    setHasSaveReason(!hasSaveReason);
    setOpen(false);
    setCreateMessage(true);
  };

  const handleEditSuccess = () => {
    setHasSaveReason(!hasSaveReason);
    setOpenEdit(false);
    setEditMessage(true);
  };

  const handleDeleteSuccess = () => {
    setHasSaveReason(!hasSaveReason);
    setOpenDelete(false);
    setDeleteMessage(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
 
  const getReasons = () => {
    const storeNumber = selectedStore?.storeNumber;
    if(storeNumber){
      getTransactionReasons('',storeNumber).then((result) => {
        if (result) {  
          setLoading(false);
          const reasons  = result.data?.reasons;
          if(reasons){
            setReasons(reasons);
            setLoading(false);
          }
        } else {
          logError(result);
        }})
        .catch((error) => {
          logError(error);
        });
    }
  };

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, rowId: number, name :string, description:string,actionType:string) => {
    if ((event.target as HTMLTableRowElement).classList.contains('delete-icon')) {
      return;
    }
    setReasonId(rowId);
    setName(name);
    setDescription(description);
    setActionType(actionType);
    setOpenEdit(true);
  };

  const handleOnDelete = (rowId: number, name:string) => {
    setReasonId(rowId);
    setName(name);
    setOpenDelete(true);
  };

  return (
    <PageArea>
      <Grid container
        spacing={5}>
        <Grid item 
          xs={12} >
          <PageTitle>{t('typesTitle')}</PageTitle>
        </Grid>
        <Grid item
          xs={12}
          sx={{  pt:'10px' }}>
          <TransactionTypes />
        </Grid>
      </Grid>
      <Grid container
        spacing={5}
        sx={{ pt:'20px' }}>
        <Grid item 
          xs={isMobile ? 8 :7} >
          <PageTitle>
            {t('reasonsTitle')}
          </PageTitle>
        </Grid>
        <Grid item
          xs={isMobile ? 4 :5} 
          sx={{ display:'flex', justifyContent:'flex-end' }}>
          <Button
            size='lg'
            sx={{ mr: 3 }}
            variant='primary'
            onClick={handleOpen}
          ><Plus/>{t('add')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          sx={{ pt:'10px' }}>
          <Box sx={{
            border: '1px solid',
            borderColor: theme.palette.custom.gray[200],
            marginTop:'10px',
          }}>
            {loading ? <LoadingWheel />: (
              <TableContainer sx={{ overflowy:'auto' }}
              >
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableBody>
                    {reasons?.map((row:Reason) => (
                      <TableRow
                        hover
                        key={row.id}
                        onClick={(event) => handleRowClick(event, row?.id ?? 0 , row?.name ?? '', row.description ?? '', row.actionType ?? '')}
                      >
                        <TableCell >
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.description}
                        </TableCell>
                        <TableCell onClick={(event) => event.stopPropagation()}>
                          {row.name !== nameOfReason && (
                            <Trash01 
                              color={theme.palette.error[500]}
                              className='delete-icon'
                              onClick={() => handleOnDelete(row.id ?? 0, row.name ?? '')} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> 
            )}
          </Box>
        </Grid>
      </Grid>
      <CreateReasonModal
        open={open}
        onClose={handleClose}
        onConfirm={handleCreateSuccess}
      />
      <EditReasonModal
        open={openEdit}
        onClose={handleClose}
        onConfirm={handleEditSuccess}
        reasonId={reasonId}
        name={name}
        description = {description}
        actionType = {actionType}
      />
      <DeleteReasonModal
        open={openDelete}
        onClose={handleClose}
        onConfirm={handleDeleteSuccess}
        reasonId={reasonId}
        name={name}
      />
      <MessagesSnackbar
        open={createMessage}
        onClose={() => setCreateMessage(false)}
        message={t('createMessage')}
        severity="success"
      />
      <MessagesSnackbar
        open={editMessage}
        onClose={() => setEditMessage(false)}
        message={t('editMessage')}
        severity="success"
      />
      <MessagesSnackbar
        open={deleteMessage}
        onClose={() => setDeleteMessage(false)}
        message={t('deleteMessage')}
        severity="success"
      />
    </PageArea>
  );
};

export default TransactionsManagement;