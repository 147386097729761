import { Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, SvgIcon } from '@mui/material';
import BarcodeScannerContents from './BarcodeScannerContents';
import { Lightbulb05, File06, SwitchHorizontal01 } from '../../../../assets';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Html5Qrcode, CameraCapabilities, CameraDevice } from 'html5-qrcode';
import { BarcodeScannerErrorModal } from './BarcodeScannerErrorModal';
import { client } from '../../../../app/services/api/orderManagementClient';
import { UserContext } from '../../../../components/shared/useUser';
import { StockCheckItem } from '../../../../app/services/api/generated';
import { BarcodeStockItemDetails } from '../BarcodeStockItemDetails';
import MessagesSnackbar from '../../../../components/shared/MessagesSnackbar';
import useLogError from '../../../../hooks/useLogError';
import negativeSound from '../../../../assets/sounds/barcodeNegative.mp3';
import positiveSound from '../../../../assets/sounds/barcodePositive.mp3';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';

export const BarcodeScannerModal = (props: { isScannerOpen: boolean, closeModal: () => void, stockCheckItems: StockCheckItem[], setStockCheckItems: React.Dispatch<React.SetStateAction<StockCheckItem[]>>, stockCountName: string | undefined }) => {
  const [isTorchOn, setIsTorchOn] = useState(false); 
  const [isScanning, setIsScanning] = useState(false); 
  const [resetZoomFactor, setResetZoomFactor] = useState(false);
  const [torchNotSupportedChange, setTorchNotSupportedChange] = useState(false);
  const [isErrorNoBarcodeFromFile, setIsErrorNoBarcodeFromFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiErrorModalOpen, setIsApiErrorModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [decodedText, setDecodedText] = useState('');
  const [stockCheck, setStockCheck] = useState<StockCheckItem | undefined>();
  const [scanner, setScanner] = useState<Html5Qrcode | null>(null);
  const [isItemDetailsModalOpen, setIsItemDetailsModalOpen] = useState(false);
  const [uom, setUom] = useState('');
  const [zoomFactor, setZoomFactor] = useState(1);
  const [zoomNotSupportedAlert, setZoomNotSupportedAlert] = useState(false);
  const [possibleCameras, setPossibleCameras] = useState<CameraDevice[]>();
  const [selectedCamera, setSelectedCamera] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  

  const open = Boolean(anchorEl);

  const { logError } = useLogError();

  const { t } = useTranslation('stockCountScanning');

  const [playPositive] = useSound(positiveSound);
  const [playNegative] = useSound(negativeSound);

  const { selectedStore, user } = useContext(UserContext);

  useEffect(() => {
    Html5Qrcode.getCameras().then((cameras) => {
      setPossibleCameras(cameras);
    }).catch(e => logError(e));
  }, []);

  useEffect(() => {
    startScanner();
  }, [scanner]);

  useEffect(() => {
    if (scanner && isScanning) {
      const cameraCapabilities: CameraCapabilities = scanner.getRunningTrackCameraCapabilities();
      if (!cameraCapabilities.zoomFeature().isSupported()) {
        setZoomNotSupportedAlert(true);
      } else {
        setZoomNotSupportedAlert(false);
      }
      if (!cameraCapabilities.torchFeature().isSupported()) {
        onTorchNotSupportedChange(true);

      }
      else {
        onTorchNotSupportedChange(false);

      }
    }
  }, [scanner, isScanning]);
  
  useEffect(() => {
    if(resetZoomFactor){
      setZoomFactor(1);
    }
  }, [resetZoomFactor]);

  useEffect(() => {
    if (scanner && isScanning) {
      const cameraCapabilities: CameraCapabilities = scanner.getRunningTrackCameraCapabilities();
      if (cameraCapabilities.zoomFeature().isSupported()) {
        cameraCapabilities.zoomFeature().apply(zoomFactor);
      }
      else {
        console.warn('Zoom Feature is not Supported');
      }
    }
  }, [zoomFactor]);

  useEffect(() => {
    if (scanner && isScanning) {
      const cameraCapabilities: CameraCapabilities = scanner.getRunningTrackCameraCapabilities();

      if (cameraCapabilities.torchFeature().isSupported()) {
        cameraCapabilities.torchFeature().apply(isTorchOn);
      }
      else {
        console.warn('Torch Feature is not Supported');
      }
    }
  }, [isTorchOn]);

  useEffect(() => {
    if (!props.isScannerOpen) {
      handleScanStop();
    }
  }, [props.isScannerOpen]);

  const handleTorchToggle = () => {
    setIsTorchOn(prevState => !prevState);
  };

  const handleScanningChange = (isScanning:boolean) => {
    if (isScanning) {
      setResetZoomFactor(false);
    }
    setIsScanning(isScanning);
  };

  const handleTorchOff = () => {
    setIsTorchOn(false);
  };

  const handleCloseModal = () => {
    handleScanStop();
    props.closeModal();
    handleTorchOff();
    setIsScanning(false);
    setResetZoomFactor(true);
  };

  const onTorchNotSupportedChange = (onTorchNotSupportedChange: boolean) => {
    setTorchNotSupportedChange(onTorchNotSupportedChange);
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) return;

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const html5QrCode = new Html5Qrcode('qr-reader');
        try {
          const qrCodeResult = await html5QrCode.scanFile(file);
          handleItemScanned(qrCodeResult);
        } catch (error) {
          setIsErrorNoBarcodeFromFile(true);
          console.error('Error decoding barcode:', error);
        } finally {
          html5QrCode.clear();
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseItemDetailsModal = () => {
    setIsItemDetailsModalOpen(false);
    startScanner();
  };

  const handleCloseApiErrorModal = () => {
    setIsApiErrorModalOpen(false);
    startScanner();
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    startScanner();
  };

  const handleCloseWarningModal = () => {
    setIsWarningModalOpen(false);
    startScanner();
  };

  const handleScanStop = async () => {
    if (scanner?.isScanning) {
      await scanner.stop()
        .then(() => {
          setIsScanning(false);
          handleScanningChange(false);
        });
    }
  };
  
  const startScanner = async () => {
    await handleScanStop();
    if (!scanner) return;
    scanner.start(
      selectedCamera ?? { facingMode: 'environment' },
      {
        qrbox: (viewfinderWidth: number, viewfinderHeight: number) => {return { height: viewfinderHeight * 0.2, width: viewfinderWidth * 0.45 };},
        fps: 24,
      },
      (decodedText) => {
        setDecodedText(decodedText);
        handleItemScanned(decodedText)
          .catch(e => logError(e))
          .finally(() => handleScanStop());
      },
      undefined,
    ).then(() => {
      setIsScanning(true);
      handleScanningChange(true);
    });
  };

  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setZoomFactor(newValue);
    }
  };

  const handleZoomInChange = () => {
    const newZoomLevel = zoomFactor == 7 ? zoomFactor : zoomFactor + 2;
    setZoomFactor(newZoomLevel);
  };

  const handleZoomOutChange = () => {
    const newZoomLevel = zoomFactor == 1 ? zoomFactor : zoomFactor - 2;
    setZoomFactor(newZoomLevel);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwitchCameraClose = (camerId: string) => {
    setAnchorEl(null);
    setSelectedCamera(camerId);
  };

  const handleItemScanned = async (itemNumber: string) => {
    setIsLoading(true);
    client.getItemByBarcode(selectedStore?.storeNumber, itemNumber, props.stockCountName, user?.username )
      .then((result) => {
        if (result.data == null) {
          playNegative();
          setIsErrorModalOpen(true);
        }
        else {
          const itemNumber = result.data.sku;
          const stockCheck = props.stockCheckItems.find(x => x.itemNumber === itemNumber);
          const uom = result.data.barcodeUom;

          if (stockCheck) {
            playPositive();
            setStockCheck(stockCheck);
            setUom(uom);
            setIsItemDetailsModalOpen(true);
          }
          else {
            playNegative();
            setIsWarningModalOpen(true);
          }
        }

      })
      .catch((error => {
        logError(error);
        setIsApiErrorModalOpen(true);
      }))
      .finally(() => { 
        setZoomFactor(1);
        handleTorchOff();
        setIsLoading(false);
      },
      );
  };

  return (
    <Dialog open={props.isScannerOpen}
      fullScreen>
      <DialogContent sx={{ p: '0px', pb: '4px', m: '0px' }}>
        <BarcodeScannerContents
          isLoading={isLoading}
          isScanning={isScanning}
          zoomNotSupportedAlert={zoomNotSupportedAlert}
          handleZoomOutChange={handleZoomOutChange}
          handleZoomInChange={handleZoomInChange}
          zoomFactor={zoomFactor}
          handleZoomChange={handleZoomChange}
          scanner={scanner}
          setScanner={setScanner}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', ml: '10px', mr: '10px', mt: '12px', mb: '10px' }}>
        <label htmlFor={'preview-1'}>
          <Button
            component="span"
            startIcon={<File06 />}
            disabled={isLoading}
            size='md' />
        </label>
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            size='md'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disabled={isLoading}
            startIcon={<SwitchHorizontal01/>}/>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleSwitchCameraClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {possibleCameras?.map((camera) => {return <MenuItem key={camera.id}
              disabled={isLoading}
              onClick={() => handleSwitchCameraClose(camera.id)}
              value={camera.id}>{camera.label}</MenuItem>;})}
          </Menu>
        </div>
        {isScanning && !torchNotSupportedChange &&
        <Button variant={isTorchOn ? 'primary' : 'secondary'}
          onClick={handleTorchToggle}
          size='md'
          startIcon={<SvgIcon style={{ height: '30px', width: '30px' }}>
            <Lightbulb05 />
          </SvgIcon>}
        />
        }
        <Button variant='secondary'
          size='md'
          disabled={isLoading}
          onClick={handleCloseModal}>{t('close')}</Button>
      </DialogActions>
      <div id='qr-reader'></div>
      <BarcodeStockItemDetails stockCheck={stockCheck}
        isOpen={isItemDetailsModalOpen}
        closeModal={handleCloseItemDetailsModal}
        uom={uom}
        setStockCheckItems={props.setStockCheckItems}/>
      <BarcodeScannerErrorModal isOpen={isErrorModalOpen}
        closeModal={handleCloseErrorModal}
        title={t('errorTitle')}
        content={t('errorContent') + decodedText}/>
      <BarcodeScannerErrorModal isOpen={isApiErrorModalOpen}
        closeModal={handleCloseApiErrorModal}
        title={t('apiErrorTitle')}
        content={t('apiErrorContent') + decodedText} />
      <BarcodeScannerErrorModal isOpen={isWarningModalOpen}
        closeModal={handleCloseWarningModal}
        title={t('warningTitle')}
        content={t('warningContent')} />
      <MessagesSnackbar
        open={isErrorNoBarcodeFromFile}
        onClose={() => {setIsErrorNoBarcodeFromFile(false);}}
        message={'No Barcode could be found in the uploaded image. Please try again making sure the barcode takes up most of the image.'}
        severity="warning"
        verticalPosition="bottom"
        horizontalPosition='center'
        duration={null}/>
      <input
        style={{ display: 'none' }}
        accept="image/*"
        id={'preview-1'}
        type="file"
        disabled={isLoading}
        onChange={handleImageUpload}
      />
    </Dialog>
  );
};