import { useState } from 'react';

import Box from '@mui/material/Box';
import { Grid, Paper, SvgIcon, Typography } from '@mui/material';
import CountDropDownBox from './CountDropDownBox';
import { theme } from '../../../theme';
import { Archive } from '../../../assets';
import CountButtonSmall from './CountButton';
import { RAGDetail } from '../types/RAGDetail';

function StockCount(props: {
  readonly title: string;
  readonly redStatusGroup: string;
  readonly orangeStatusGroup: string;
  readonly greenStatusGroup: string;
  readonly redNumber: number | undefined;
  readonly orangeNumber: number | undefined;
  readonly greenNumber: number | undefined;
  readonly ragDetail: RAGDetail | undefined;
}) {
  const [isRedVisible, setIsRedVisible] = useState(false);
  const [isOrangeVisible, setIsOrangeVisible] = useState(false);
  const [isGreenVisible, setIsGreenVisible] = useState(false);

  function handleRedButtonClick() {
    setIsRedVisible(!isRedVisible);
    setIsOrangeVisible(false);
    setIsGreenVisible(false);
  }

  function handleOrangeButtonClick() {
    setIsRedVisible(false);
    setIsOrangeVisible(!isOrangeVisible);
    setIsGreenVisible(false);
  }

  function handleGreenButtonClick() {
    setIsRedVisible(false);
    setIsOrangeVisible(false);
    setIsGreenVisible(!isGreenVisible);
  }

  //flat bottom when a dropdown is below, else round
  function borderRadiusToUse() {
    return isRedVisible || isOrangeVisible || isGreenVisible
      ? '12px 12px 0px 0px'
      : '12px 12px 12px 12px';
  }

  return (
    <Paper elevation={4}
      square={false}
      sx={{ m: '12px', boxShadow: 'none' }}>
      <Paper
        sx={{
          borderRadius: borderRadiusToUse(),
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          border: `1px solid ${theme.palette.custom.gray[200]}`,
        }}
      >
        <Box
          sx={{
            textAlign: 'left',
            pt: '12px',
            pl: '12px',
            color: 'black',
            mb: '2px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
          }}
        >
          <SvgIcon><Archive /></SvgIcon>
          <Typography variant="textLG">{' ' + props.title}</Typography>
        </Box>
        <Box sx={{ p: '12px' }}>
          <Grid
            container
            justifyContent="space-around"
            spacing={5}
          >
            <Grid item
              sx={{ width: { xs: 1, sm: 1 / 3 } }}>
              <CountButtonSmall
                palette={theme.palette.error}
                number={props.redNumber}
                handleClick={handleRedButtonClick}
                isOn={isRedVisible}
                statusGroup={props.redStatusGroup}
              ></CountButtonSmall>
            </Grid>
            <Grid item
              sx={{ width: { xs: 1, sm: 1 / 3 } }}>
              <CountButtonSmall
                palette={theme.palette.warning}
                number={props.orangeNumber}
                handleClick={handleOrangeButtonClick}
                isOn={isOrangeVisible}
                statusGroup={props.orangeStatusGroup}
              ></CountButtonSmall>
            </Grid>
            <Grid item
              sx={{ width: { xs: 1, sm: 1 / 3 } }}>
              <CountButtonSmall
                palette={theme.palette.primary}
                number={props.greenNumber}
                handleClick={handleGreenButtonClick}
                isOn={isGreenVisible}
                statusGroup={props.greenStatusGroup}
              ></CountButtonSmall>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {isRedVisible && (
        <CountDropDownBox
          text={props.redStatusGroup}
          palette={theme.palette.error}
          list={props.ragDetail?.Red}
        />
      )}
      {isOrangeVisible && (
        <CountDropDownBox
          text={props.orangeStatusGroup}
          palette={theme.palette.warning}
          list={props.ragDetail?.Orange}
        />
      )}
      {isGreenVisible && (
        <CountDropDownBox
          text={props.greenStatusGroup}
          palette={theme.palette.primary}
          list={props.ragDetail?.Green}
        />
      )}
    </Paper>
  );
}

export default StockCount;
