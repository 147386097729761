import Typography from '@mui/material/Typography';
import React from 'react';

interface PageTitleProps {
  children: React.ReactNode
}


const PageTitle: React.FC<PageTitleProps> = props => {
  return (
    <Typography 
      variant="displaySM" 
      sx={{ fontWeight: 600 }}> 
      { props.children } 
    </Typography>
  );
};

export default PageTitle;