export interface PaletteColor {
    main: string;
    light: string;
    dark: string;
    contrastText: string;
    950: string;
    900: string;
    800: string;
    700: string;
    600: string;
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    50: string;
    25: string;
  }

const brand = {
  950: '#03311E',
  900: '#022618',
  800: '#053321',
  700: '#074D31',
  600: '#085D3A',
  500: '#00704A',
  400: '#079455',
  300: '#47CD89',
  200: '#75E0A7',
  100: '#A9EFC5',
  50: '#DCFAE6',
  25: '#ECFDF3',
};

const gray = {
  950: '#0C111D',
  900: '#101828',
  800: '#182230',
  700: '#344054',
  600: '#475467',
  500: '#667085',
  400: '#98A2B3',
  300: '#D0D5DD',
  200: '#EAECF0',
  100: '#F2F4F7',
  50: '#F9FAFB',
  25: '#FCFCFD',
};

const primary = {
  main: brand[600],
  light: '#47CD89',
  dark: brand[700],
  ...brand,
  contrastText: '#A9EFC5',
};

const error = {
  950: '#55160C',
  900: '#7A271A',
  800: '#912018',
  700: '#B42318',
  600: '#D92D20',
  500: '#F04438',
  400: '#F97066',
  300: '#FDA29B',
  200: '#FECDCA',
  100: '#FEE4E2',
  50: '#FEF3F2',
  25: '#FFFBFA',
};

const warning = {
  950: '#4E1D09',
  900: '#7A2E0E',
  800: '#93370D',
  700: '#B54708',
  600: '#DC6803',
  500: '#F79009',
  400: '#FDB022',
  300: '#FEC84B',
  200: '#FEDF89',
  100: '#FEF0C7',
  50: '#FFFAEB',
  25: '#FFFCF5',
};

const success = {
  950: '#053321',
  900: '#074D31',
  800: '#085D3A',
  700: '#067647',
  600: '#079455',
  500: '#17B26A',
  400: '#47CD89',
  300: '#75E0A7',
  200: '#ABEFC6',
  100: '#DCFAE6',
  50: '#ECFDF3',
  25: '#F6FEF9',
};

const blue = {
  950: '#102A56',
  900: '#194185',
  800: '#1849A9',
  700: '#175CD3',
  600: '#1570EF',
  500: '#2E90FA',
  400: '#53B1FD',
  300: '#84CAFF',
  200: '#B2DDFF',
  100: '#D1E9FF',
  50: '#EFF8FF',
  25: '#F5FAFF',
};

const darkBlue = {
  950: '#002266',
  900: '#00359E',
  800: '#0040C1',
  700: '#004EEB',
  600: '#155EEF',
  500: '#2970FF',
  400: '#528BFF',
  300: '#84ADFF',
  200: '#B2CCFF',
  100: '#D1E0FF',
  50: '#EFF4FF',
  25: '#F5F8FF',
};

const violet = {
  950: '#2E125E',
  900: '#491C96',
  800: '#5720B7',
  700: '#6927DA',
  600: '#7839EE',
  500: '#875BF7',
  400: '#A48AFB',
  300: '#C3B5FD',
  200: '#DDD6FE',
  100: '#ECE9FE',
  50: '#F5F3FF',
  25: '#FBFAFF',
};

const yellow = {
  950: '#542C0D',
  900: '#713B12',
  800: '#854A0E',
  700: '#A15C07',
  600: '#CA8504',
  500: '#EAAA08',
  400: '#FAC515',
  300: '#FDE272',
  200: '#FEEE95',
  100: '#FEF7C3',
  50: '#FEFBE8',
  25: '#FEFDF0',
};

const secondary = {
  main: yellow[600],
  light: yellow[500],
  dark: yellow[700],
  ...yellow,
};

const black = '#000000';
const white = '#FFFFFF';

export const palette = {
  custom: {
    gray: {
      main: gray[600],
      light: gray[500],
      dark: gray[700],
      contrastText: black,
      ...gray,
    },
    blue: {
      main: blue[600],
      light: blue[500],
      dark: blue[700],
      contrastText: white,
      ...blue,
    },
    darkBlue: {
      main: darkBlue[600],
      light: darkBlue[500],
      dark: darkBlue[700],
      contrastText: black,
      ...darkBlue,
    },
    violet: {
      main: violet[600],
      light: violet[500],
      dark: violet[700],
      contrastText: white,
      ...violet,
    },
  },
  primary,
  secondary,
  error: {
    main: error[600],
    light: error[500],
    dark: error[700],
    contrastText: white,
    ...error,
  },
  warning: {
    main: warning[600],
    light: warning[500],
    dark: warning[700],
    contrastText: white,
    ...warning,
  },
  success: {
    main: success[600],
    light: success[500],
    dark: success[700],
    contrastText: white,
    ...success,
  },
  common: {
    black,
    white,
  },
};