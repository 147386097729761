import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { UserContext } from '../../../components/shared/useUser';
import { Invoice } from '../../../app/services/api/generated';
import { InvoiceStatus } from '../interfaces/InvoiceStatus';

export const useInvoices = () => {
  const { getInvoices, postInvoice } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('invoices');
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false);
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowProcessingAlert, setIsShowProcessingAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('invoiceNumber');
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [note, setNote] = useState('');
  const [acceptId, setAcceptId] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => getInvoicesFromAPI(), []);
  useEffect(() => {}, [invoices]);

  const isAsc = order === 'asc';

  const handleSort = (orderBy: keyof Invoice) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(orderBy);
    getInvoicesFromAPI(order, orderBy);
  };

  const handleInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNumber(event.target.value);
  };

  const handleOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderNumber(event.target.value);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleClearAllClick = () => {
    setPage(0);
    setInvoiceNumber('');
    setOrderNumber('');
    setStartDate('');
    setEndDate('');
    getInvoicesFromAPI(order, orderBy, 0, '', '', '', '' );
  };

  const loadData = () => {
    setPage(0);
    getInvoicesFromAPI(undefined, undefined, 0);
  };

  const getInvoicesFromAPI = (passedOrder?: 'asc' | 'desc', passedOrderBy?: string, passedPage?: number, passedInvoiceNumber?: string, passedOrderNumber?: string, passedStartDate?: string, passedEndDate?: string) => {
    const orderToUse = passedOrder ?? order;
    const orderByToUse = passedOrderBy ?? orderBy;
    const pageToUse = passedPage ?? page;
    const invoiceNumberToUse = passedInvoiceNumber ?? invoiceNumber;
    const orderNumberToUse = passedOrderNumber ?? orderNumber;
    const startDateToUse = passedStartDate ?? startDate;
    const endDateToUse = passedEndDate ?? endDate;

    setIsLoading(true);
    getInvoices(selectedStore?.storeNumber, invoiceNumberToUse, orderNumberToUse, undefined, startDateToUse, endDateToUse, undefined, orderByToUse, orderToUse, (pageToUse) * 10, 10)
      .then((result) => {
        if (result.data){
          setTotalRows(result.data?.invoiceCount ?? -1);
          setInvoices(result.data?.invoices ?? []);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
    getInvoicesFromAPI(order, orderBy, newPage);
  };

  const handleNoteButtonClick = (note: string) => () => {
    setNote(note);
    setOpenNoteModal(!openNoteModal);
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const handleButtonClick = (id: number) => () => {
    setAcceptId(id);
    setIsOpenModal(!isOpenModal);
  };
  
  const closesAcceptModal = () => {
    setIsOpenModal(false);
  };

  const handleAcceptClick = (id: number) => {
    setIsShowProcessingAlert(true);
    postInvoice(id, {
      'accepted': true,
      'note': '',
    })
      .then(() => {
        getInvoicesFromAPI();
        setIsShowSuccessAlert(true);
      })
      .catch((error) => {
        setErrorMessage(error);
        setIsShowErrorAlert(true);
        setIsOpenModal(false);
      })
      .finally(() => {
        setIsShowProcessingAlert(false);
        setIsOpenModal(false);
      });
  };

  const rowSxPropToUse = (status: string) => {
    if (status == InvoiceStatus.Failed){
      return { backgroundColor: theme.palette.error[50] };
    }
    if ( status === InvoiceStatus.Reconciled || status === InvoiceStatus.Accepted){
      return { backgroundColor: theme.palette.success[50] };
    }
    return { };
  };

  return { 
    t, 
    invoices, 
    totalRows, 
    invoiceNumber, 
    handleInvoiceNumberChange, 
    orderNumber, 
    handleOrderNumberChange, 
    startDate, 
    handleStartDateChange, 
    endDate, 
    handleEndDateChange, 
    handleClearAllClick, 
    loadData, 
    isLoading,
    orderBy, 
    order, 
    handleSort, 
    rowSxPropToUse, 
    handleNoteButtonClick, 
    handleAcceptClick, 
    changePage, 
    page, 
    isShowSuccessAlert, 
    setIsShowSuccessAlert, 
    isShowErrorAlert, 
    setIsShowErrorAlert, 
    isShowProcessingAlert, 
    setIsShowProcessingAlert, 
    openNoteModal, 
    handleCloseNoteModal, 
    note,
    errorMessage,
    isOpenModal,
    acceptId,
    closesAcceptModal,
    handleButtonClick,
  };
};