import { Autocomplete, Box, FormControl, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { theme } from '../../theme';
import useNswagClient from '../../hooks/api/useNswagClient';
import { UserContext } from '../../components/shared/useUser';
import { ConversionDto } from '../../app/services/api/generated';
import { UomConversionsTable } from './components/UomConversionsTable';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { useParams } from 'react-router-dom';

export default function UomConversions() {
  const { getConversions } = useNswagClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [uomClass, setUomClass] = useState<string[]>([]);
  const [data, setData] = useState<ConversionDto[]>([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('uomConversions');
  const { itemNumber } = useParams();
  
  useEffect(() => {
    if(itemNumber){
      handleAutomaticSearch();
    }
    else{
      loadData();
    }
  }, []);

  const uom_class = [
    { id: 'case', display: 'Case' },
    { id: 'eu pallet', display: 'EU Pallet' },
    { id: 'liquid', display: 'Liquid' },
    { id: 'pallet', display: 'Pallet' },
    { id: 'quantity', display: 'Quantity' },
    { id: 'uk pallet', display: 'UK Pallet' },
    { id: 'weight', display: 'Weight' },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toUpperCase());
    setPage(0);
    loadData(event.target.value.toUpperCase(), 0);
  };

  const handleAutomaticSearch = () => {
    setSearchTerm(itemNumber!.toString());
    setPage(0);
    loadData(itemNumber!.toString(), 0);
  };
  
  const handleTypeChange = (event: React.SyntheticEvent, value: string[]) => {
    setUomClass(value);
    setPage(0);
    const formattedValue = value.length === 1 ? value[0] : value.join(',');
    loadData(searchTerm, 0, formattedValue);
  };

  const handlePageChange = (passedPage: number) => {
    setPage(passedPage);
    loadData(searchTerm, passedPage);
  };

  const loadData = (search?: string, passedPage?: number, passedClass?: string) => {
    
    const searchToUse = search ?? searchTerm;
    const pageToUse = passedPage ?? page;
    let uomClassToUse = passedClass;
    if (uomClassToUse == undefined){
      if (uomClassToUse != ''){
        const final  =uomClass.length === 1 ? uomClass[0] : uomClass.join(',');
        uomClassToUse = final;
      }
    }

    getConversions(searchToUse, uomClassToUse, pageToUse*10, 10, selectedStore?.storeNumber).then(
      (result) => {
        if (result)
        {
          const total = result.conversionsCount ?? 0;
          setMaxPage(Math.ceil(total / 10));
          setData(result.conversions ?? []);
          setTotalItems(total);
        }
        else{
          setMaxPage(0);
          setData([]);
          setTotalItems(0);
        }     
      },
    );
  };
 
  
  return (
    <PageArea>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <PageTitle>{t('title')}</PageTitle>
        <Box
          sx={{
            display: 'flex',
            p: '2px 8px',
            alignItems: 'center',
            borderRadius: '16px',
            border: '1px solid ',
            borderColor: theme.palette.primary[200],
            background: theme.palette.primary[50],
          }}
        >
          <Typography variant="textXS"
            sx={{ color: theme.palette.primary[700] }}>
            {totalItems} {t('items')}
          </Typography>
        </Box>
      </Typography>
      <Box sx={{
        border: '1px solid',
        borderColor: theme.palette.custom.gray[300],
        py: '0px',
        textAlign: 'left',
      }} >
        <Grid container
          spacing={5}
          sx={{
            borderColor: theme.palette.custom.gray[300],
            py: '12px',
            px: '8px',
          }} >
          <Grid item
            xs={8}>
            <Box>
              <FormControl fullWidth
                size='small'>
                <TextField id="outlined-basic"
                  label={t('search')}
                  size='small'
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange} />
              </FormControl>
            </Box>
          </Grid>
          <Grid item
            xs={4}>
            <Box>
              <FormControl fullWidth
                size='small'>
                <Autocomplete
                  multiple
                  options={uom_class.map(c => c.id)} 
                  getOptionLabel={(option) => uom_class.find(c => c.id === option)?.display ?? ''}
                  value={uomClass}
                  onChange={handleTypeChange}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={t('uomClass')}
                      variant="outlined" />
                  )}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <UomConversionsTable
        page={page}
        maxPage={maxPage}
        handlePageChange={handlePageChange}
        data={data}/>
    </PageArea>
  );
}
