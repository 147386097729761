import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CreateTransactionsChangeTypeConfirmDialog = () => {
  const { closeTransactionTypeChangeModal, changeType, isTypeChangeModalOpen, t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      onClose={closeTransactionTypeChangeModal}
      open={isTypeChangeModalOpen}
    >
      <DialogTitle sx={{ pr: '48px' }}>
        {t('confirmType.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeTransactionTypeChangeModal}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pb: 0 }}>
        <Typography variant='textMD'
          sx={{ mb: 8 }}>
          {t('confirmType.message')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}>
          <Button variant='secondary'
            size="lg"
            onClick={closeTransactionTypeChangeModal}>
            {t('cancel')}
          </Button>
          <Button
            variant='primary'
            size="lg"
            onClick={() => {
              changeType();
              closeTransactionTypeChangeModal();
            }}>
            {t('confirmType.continue')}  
          </Button>
        </Stack>
      </DialogActions >
    </Dialog>
  );
};

