import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CreateTransactionsHeaderProps {
  handleCancel: () => void; 
}
export const CreateTransactionsHeader = ({ handleCancel }: CreateTransactionsHeaderProps) => {
  const { t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <>
      <DialogTitle sx={{ pr: '48px' }}>
        {t('title')}
      </DialogTitle>
      <IconButton
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

