import { Box, Button, Grid, SvgIcon, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { PagedData_1OfWasteHeaders } from '../../app/services/api/generated';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import { Plus } from './../../assets';
import { WasteChecksheetTab } from './enums/WasteChecksheetTab';
import useNswagClient from '../../hooks/api/useNswagClient';
import WasteChecksheetBox from './components/WasteChecksheetBox';
import { WasteChecksheetFilterOptions } from './interfaces/WasteChecksheetFilterOptions';
import { useNavigate } from 'react-router-dom';
import useLogError from '../../hooks/useLogError';
import useCurrencyCodeConfiguration from '../../hooks/configurations/useCurrencyCodeConfiguration';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const WasteChecksheetPage = () => {
  const [wasteChecksheet, setWasteChecksheet] = useState<PagedData_1OfWasteHeaders>({});
  const [activeTab, setActiveTab] = useState<WasteChecksheetTab>(WasteChecksheetTab.ACTIVE);
  const { t } = useTranslation('wasteChecksheet');
  const { hasPermissionTo, selectedStore, isAdmin } = useContext(UserContext);
  const { getWaste } = useNswagClient();
  const navigate = useNavigate();
  const { logError } = useLogError();
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const hasPermissionToCreateNew = hasPermissionTo(['DailyWasteCheckSheetWrite']) || isAdmin();
  const isMobile = useMediaQuery('(max-width:945px)');

  useEffect(() => {
    loadFilterData({     
      type: WasteChecksheetTab.ACTIVE,
      date: '',
      skip: 0,
      take: 10,
    });

    getCurrencyCodeConfiguration();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: WasteChecksheetTab) => {
    loadFilterData({     
      type: newValue,
      date: '',
      skip: 0,
      take: 10,
    });
  };

  const loadFilterData = (options: WasteChecksheetFilterOptions) => {
    const {
      date,
      skip,
      take,
      type,
    } = options;

    const storeNumber = selectedStore?.storeNumber;

    getWaste(storeNumber, date, skip, take,type )
      .then((result) => {
        if (result?.data) {
          setWasteChecksheet(result.data);
          setActiveTab(type ?? WasteChecksheetTab.ACTIVE);
        }

      })
      .catch((error) => {
        logError(error);
      });
  };

  const navigateToDailyWasteCheckSheet = () => {
    const id = 0;
    navigate(`/store/daily-waste-checksheet/${id}`);
  };

  return (
    <PageArea>
      <Box display="flex"
        width="100%"
        height="100%"
        flexDirection="column" >
        <Grid container>
          <Grid item
            xs={12}
            sx={{ textAlign: 'left' }}>
            <PageTitle>{t('title')}</PageTitle>        
          </Grid>
          {hasPermissionToCreateNew && isMobile && <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              variant='primary'
              size='lg'
              sx={{ ml: 4, mb: 4 }}   
              onClick={navigateToDailyWasteCheckSheet}        
              startIcon={<SvgIcon><Plus fill='white' /></SvgIcon>}>{t('createNewBtn')}
            </Button>
          </Grid> }
          <Grid
            item
            sm={8}
            xs={12} >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
            >
              <Tab
                label={t('list.activeSheet')}
                value={WasteChecksheetTab.ACTIVE} />
              <Tab
                label={t('list.archiveSheet')}
                value={WasteChecksheetTab.ARCHIVED} />
            </Tabs>
          </Grid>
          {hasPermissionToCreateNew && !isMobile && <Grid
            item
            xs={4}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              variant='primary'
              size='lg'
              sx={{ ml: 4 }}   
              onClick={navigateToDailyWasteCheckSheet}        
              startIcon={<SvgIcon><Plus fill='white' /></SvgIcon>}>{t('createNewBtn')}
            </Button>
          </Grid> }
          <Grid item
            xs={12}
            sx={{ pt: '20px' }}>
            {wasteChecksheet &&
              <WasteChecksheetBox
                wasteChecksheet={wasteChecksheet}
                loadData={loadFilterData}
                activeTab={activeTab}
                currencyCode={currencyCode}
              />
            }
          </Grid>
        </Grid>
      </Box>
    </PageArea>
  );
};

export default WasteChecksheetPage;