export const stockOnHandData = {
  title: 'Stock On Hand',
  overview: `
        <p>The Stock on Hand page is designed to give users visibility of the available quantity and value of all store products for stock maintenance. It includes the 'base ingredients' used to make up the Recipes, and so their price will determine the Recipe price.</p>
        <p>The Stock on Hand data is driven by Transactions, and so provides users with accurate and up to date information at all times. The application automatically maintains an average cost price for the stock of all items in the system on a store by store basis.</p>
    `,
  keyFeatures: [
    'Item quantities and values are visible for multiple stores (if authorised).',
    'Users can only see data for Stores assigned to their account.',
    'Set a Minimum Quantity against any product for a Store.',
    'Search to find specific products, or description types.',
    'Filter by one or multiple Stock Levels (e.g. "Out of Stock", or "Below Minimum Quantity" etc.)',
    'Filter by one or multiple Categories.',
    'Sort list in order or reverse order by column (e.g. "Price", or "Quantity")',
    'If no cost price is defined, the cost price will default to zero.',
    'The current cost price displays the average stock value, and relates to the UoM for that item.',
    'A full cost price history is available on the item details.',
    'Item Prices will apply whenever they are used in a Recipe, including new and updated Recipes.',
    'Updates to item prices will automatically update the price of any Recipe they are used in.',
  ],
  userGuide: [
    {
      guideTitle: '',
      guideContent: `
            <p>The Stock on Hand page is listed under the Stock section, and on opening it will list all available items, sorted by item number. To change the order in which items are displayed you can either use the Sort function above the list - below the Search box; or alternatively click on any of the column titles where an arrow is displayed (this indicates the column can be sorted). Clicking a second time will reverse the sort order.</p>
            <p>The items a user is able to view will depend on both the User type, and which stores they have access to. Typically a Store Manager will have access to 1 store, and they will only see the items available to that store. If they have more than one store, they will be able to change the store they are currently viewing in order to view items for another store. The list will only display data for one store at a time to this type of user.</p>
            <p>For a Franchise Manager, who will typically have access to several stores, the list will display items for all the stores they have access to - they will not have to changes the store they are currently viewing.</p>
            <p>The Search box allows you to find a specific item or type of item (e.g. milk), and will automatically start filtering the list after you finish typing. As with other pages in the application, it will return both full and partial matches, so you don't have to enter the full name, or know the exact number to find an item.</p>
            <p>Below the search box are several options to filter the list, alongside the sort option previously mentioned. Note that if you have already made a search, the filters will apply to the results. This allows you to filter the search results, or search within a filtered list, however to view all items for a selected filter, you will need to clear the search before/after filtering.</p>
            <p>More than one option in any filter can be selected, and this acts as an 'or' function. In other words any item that belongs to any of the selected filters will be returned, it does not have to match all the selections</p>
            <p>Other features on the Stock on Hand page itself include a paging control to limit the number of results shown at once to 10, and a Create Transaction button above the list, so there is no need to go to the Transactions screen itself if you wanted to make a Stock adjustment (see Transactions section).</p>
            <p>To view further details for any specific item, you can click on the item’s name in the Item Description column. This will link to the Item Details page, which has further information on the item, and includes a full list of the Cost Price history. This will list a new entry for every time a Transaction was made, and the updated price (which may be the same) following that update. Therefore the most recent entry will match the current Cost Price.</p>
            <p>The Item Details page includes the option to edit the minimum quantity for that item, which is a simple process of entering the required value, then saving, and will be updated immediately. The Create Transaction link, as featured on the main page, is also included on this screen for easy access.</p>
        `,
    },
  ],
};
