import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';

interface MessagesSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  duration: number | null;
}

const MessagesSnackbar: React.FC<MessagesSnackbarProps> = ({ open, onClose, message, severity, duration }) => {
  useEffect(()=>{}, [duration]);


  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={duration}
      onClose={(event, reason) => {
        if (reason == 'timeout'){
          onClose();
        }
      }}
    >
      <Alert onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessagesSnackbar;