import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog, DialogTitle, IconButton, DialogContent, TextField,
  Button, Table, TableBody, TableCell, TableHead, TableRow, DialogActions,
  TableContainer, Grid, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { SearchSm } from './../../../assets';
import { UserContext } from '../../../components/shared/useUser';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import CustomPagination from '../../../components/forms/Pagination';
import { client, Order, OrdersFilterOptions, OrderDetails } from '../../../app/services/api/orderManagementClient';
import BackButton from '../actions/BackButton';

interface CopyOrderModalProps {
  currentOrderId: number;
  orderTypeId: number;
  open: boolean;
  onClose: () => void;
  onConfirm: (orderId: number) => void;
}

const CopyOrderModal: React.FC<CopyOrderModalProps> = ({ currentOrderId, orderTypeId, open, onClose, onConfirm }) => {

  const { selectedStore } = useContext(UserContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [orders, setOrders] = useState<Order[]>([]);
  const [activeOrderId, setActiveOrderId] = useState<number | undefined>(undefined);
  const [activeOrderDetails, setActiveOrderDetails] = useState<OrderDetails | undefined>(undefined);

  useEffect(() => {
    if (open) {
      fetchOrders();
    }
  }, [open, selectedStore, page]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await client.getOrders(getFilterOptions());
      const filteredOrders = response.data.items.filter(order => order.id !== currentOrderId);
      setOrders(filteredOrders);
      setTotalPages(response.data.totalPages);
      setPage(response.data.pageNumber);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderSelect = async (orderId: number) => {
    if (orderId !== activeOrderId) {
      const result = await client.getOrder(selectedStore?.storeNumber, orderId);
      if (result.success) {
        setActiveOrderId(orderId);
        setActiveOrderDetails(result.data);
      }
    }
  };

  const handleConfirm = () => {
    if (activeOrderId && activeOrderDetails) {
      onConfirm(activeOrderId);
      onClose();
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchOrders();
    setLoading(false);
  };

  const handleBack = async () => {
    setActiveOrderId(undefined);
    setActiveOrderDetails(undefined);
  };

  const handleClose = async () => {
    onClose();
  };

  const getFilterOptions = () => {
    const filterOptions: OrdersFilterOptions = {
      storeNumber: selectedStore?.storeNumber,
      orderNumber: searchTerm,
      item: '',
      orderType: orderTypeId,
      status: -1,
      deliveryDateFrom: '',
      deliveryDateTo: '',
      pageNumber: page,
      pageSize: pageSize,
      sort: 'OrderNumber',
      direction: 'desc',
    };
    return filterOptions;
  };

  return (
    <Dialog open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        Copy Order
        <IconButton onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingWheel />
        ) : (
          <>
            {!activeOrderId || activeOrderId === 0 ? (
              <>
                <Grid container
                  alignItems={'center'}
                  spacing={5}>
                  <Grid item
                    xs={12}
                    md={8}>
                    <TextField
                      margin="dense"
                      label="Search Order by ID"
                      type="search"
                      fullWidth
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        endAdornment: <SearchIcon />,
                      }}
                    />
                  </Grid>
                  <Grid item
                    xs={12}
                    md={4}>
                    <Button
                      fullWidth
                      variant="primary"
                      size="lg"
                      startIcon={<SearchSm />}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer component={'div'}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Number</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Order Type</TableCell>
                        <TableCell>Delivery Date</TableCell>
                        <TableCell>Total Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map((order) => (
                        <TableRow key={order.id}
                          hover
                          onClick={() => handleOrderSelect(order.id)}
                          style={{ cursor: 'pointer' }}>
                          <TableCell>{order.id}</TableCell>
                          <TableCell>{order.status}</TableCell>
                          <TableCell>{order.orderType}</TableCell>
                          <TableCell>{order.deliveryDate}</TableCell>
                          <TableCell>{order.totalQuantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <CustomPagination
                    page={page}
                    setPage={setPage}
                    maxPages={totalPages}
                    breakpointForChangeDisplay={120000}
                  />
                </TableContainer>
              </>
            ) : (
              <>
                <Grid item
                  xs={12}
                  sx={{ textAlign: 'left', marginTop: 2, marginBottom: 2 }}>
                  <BackButton
                    handleClick={handleBack} 
                    title="Back"
                    isLink={false}
                  />
                </Grid>
                <Alert severity="info"
                  sx={{ marginTop: 2, marginBottom: 2 }}>
                  Note that copying this order will replace any and all current items in order
                </Alert>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>UOM</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!activeOrderId && activeOrderDetails?.items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.category}</TableCell>
                        <TableCell>{item.orderableUom}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}
          size="md"
          color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyOrderModal;
