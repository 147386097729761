import { DialogActions, DialogContent, DialogTitle, TextField, Button, Dialog } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../../contexts/PurchaseOrderDetailsContext';
import { useTranslation } from 'react-i18next';

export function PurchaseOrderDetailsNoteModal() {
  const { note, noteId, isNoteModalOpen, closeModal, handleNotesChange, isEditable } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;
  const { t } = useTranslation('purchaseOrderDetails');

  const [unSavedNote, setUnSavedNote] = useState(note);

  useEffect(() => { setUnSavedNote(note); }, [note, noteId]);

  const handleClose = () => {
    setUnSavedNote(note);
    closeModal();
  };

  const handleSave = () => {
    handleNotesChange(noteId, unSavedNote);
    closeModal();
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnSavedNote(event.target.value);
  };

  return (
    <Dialog onClose={handleClose}
      open={isNoteModalOpen}>
      <DialogTitle>
        {isEditable ? t('noteModal.editNote') : t('noteModal.note')}
      </DialogTitle>
      <DialogContent>
        {isEditable ? <TextField value={unSavedNote}
          onChange={handleNoteChange} />
          :
          unSavedNote}
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleClose}>
          {t('noteModal.close')}
        </Button>
        {isEditable && <Button variant='primary'
          size="lg"
          onClick={handleSave}>
          {t('noteModal.saveNote')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}
