import {  Button, Typography,  Dialog,  DialogContent, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const DeleteTaskType=() => {
  const { t,
    openDeleteModal,
    handleClose,
    confirmDeleteTask, 
  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  return (
    <Dialog open={openDeleteModal}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {t('deleteType')}
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('deleteTypeWarning')}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}>{t('cancel')}</Button>
        <Button variant="bad"
          size='lg'
          onClick={confirmDeleteTask}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTaskType;