import { TableRow, TableCell, SvgIcon, Collapse } from '@mui/material';
import { Minus, Plus } from '../../../../assets';
import { FC, useState } from 'react';
import RecipeIngredients from './RecipeIngredients';
import { FormState, useRecipeDialog } from '../../context/RecipeDialogContext';
import { Recipe } from '../../../../app/services/api/generated';
import RoundAndFormatValue from '../../../../components/shared/RoundAndFormatValue';

type Props = {
    item: Recipe;
    currencyCode?: string;
}

const RecipeItemRow: FC<Props> = ({ item, currencyCode }) => {
  const { setShowRecipeDialog, setFormState, setInitialFormState, mapRecipeToFormState, setIsEditMode } = useRecipeDialog();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onEdit = () => {
    setShowRecipeDialog(true);
    const formattedValue: FormState = mapRecipeToFormState(item);
    setIsEditMode(true);
    setInitialFormState((prevForm) => ({
      ...prevForm,
      ...item,
    }));
    setFormState((prevForm) => ({
      ...prevForm,
      ...formattedValue,
    }));
  };

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <SvgIcon>
            {isExpanded ? <Minus /> : <Plus />}
          </SvgIcon>
          {item.itemNumber}
        </TableCell>
        <TableCell>
          {item.description}
        </TableCell>
        <TableCell align='center'>
          {item.status}
        </TableCell>
        <TableCell align='center'>
          {<RoundAndFormatValue
            value={item.costPriceOnHand ?? 0}
            currencyCode={currencyCode} />}
        </TableCell>
      </TableRow>
              
      <TableRow>
        <TableCell colSpan={4}
          sx={{ p: 0, m: 0, border: 0 }}>
          <Collapse in={isExpanded}>
            <RecipeIngredients
              ingredients={item.recipeComponents ?? []}
              onEdit={onEdit}
              currencyCode={currencyCode} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecipeItemRow;