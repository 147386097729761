import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, ItemPrimaryCategoryOverride3, PrimaryCategoryStockCount } from '../../../app/services/api/generated';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, SvgIcon, TextField, Tooltip } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import 'react-quill/dist/quill.snow.css';
import useLogError from '../../../hooks/useLogError';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { Trash01 } from '../../../assets';

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: Function;
  category: PrimaryCategoryStockCount | undefined;
  onCancel: () => void;
}

const CreateEditModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, category, onCancel }) => {
  const { t } = useTranslation('manageCategories');
  const [request, setRequest] = useState<PrimaryCategoryStockCount | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getStockItems } = useNswagClient();
  const [selectedStore, defaultStoreValue] =  useLocalStorage('selectedStore', null);
  const [items, setItems] = useState<Item[]>([]);
  const { logError } = useLogError();

  const isValid = request?.primaryCategoryName;

  const isCreate = category?.id == undefined;

  useEffect(() => {
    const newRequest = category ? JSON.parse(JSON.stringify(category)) : {};

    if (category?.id == undefined){
      newRequest.includeInReports = true;
      newRequest.smartCount = true;
    }
    
    setRequest(newRequest);
    fetchItems();
  }, [category]);

  const handleCancel = () => {
    onCancel();
    setRequest(undefined);
    if(isCreate){
      setRequest({ includeInReports:true, smartCount:true });
    }
  };

  const fetchItems = () => {
    setIsLoading(true);

    getStockItems(selectedStore?.storeNumber ?? defaultStoreValue)
      .then(
        (result) => {setItems(result.data ?? []);},
      )
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleConfirm = () => {
    const newRequest = {
      ...request,
    };
    onConfirm(newRequest);
    setRequest(undefined);
    onCancel();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const handleCheckChange = (name: keyof PrimaryCategoryStockCount, event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setRequest({ ...request, [name]: checked });
  };

  const formItemStyling = { display: 'flex', flexDirection: 'column', alignItems: 'left'  };

  function handleItemSelect(event: React.ChangeEvent<{}>, newValue: Item | null) {
    if (!newValue) return;
    const newCategoryOverride = {
      itemNumber: newValue.itemNumber,
    } as ItemPrimaryCategoryOverride3;

    const newList = request?.itemPrimaryCategoryOverrides ?? [];
    newList?.push(newCategoryOverride);

    setRequest({ ...request, itemPrimaryCategoryOverrides: newList });
  }

  function removeItem(itemNo: string) {
    const newList = request?.itemPrimaryCategoryOverrides?.filter(x => x.itemNumber != itemNo);
    setRequest({ ...request, itemPrimaryCategoryOverrides: newList });
  }

  const outerBoxStyles = {
    display: 'flex',
    px: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flex: 1,
    marginTop: '20px',
  };

  const scrollableBoxStyles = () => {
    return {
      display: 'flex',
      p: '2px 8px',
      alignItems: 'center',
      width: '100%', overflowY: 'auto', overflowX: 'hidden',
      maxHeight: '300px', flexFlow: 'wrap',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid rgba(0,0,0,.1)',
      },
    };
  };
  
  return isLoading ? <LoadingWheel></LoadingWheel> : (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {isCreate ? t('addPrimaryCategory') : t('editPrimaryCategory')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column', maxWidth: '450px' }}>
        <Grid
          container
          spacing={6}
          alignItems='self-end'
          p={6}
        >
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('primaryCategory')}</Typography>
            <TextField
              fullWidth
              required
              disabled={request?.systemGenerated}
              name='primaryCategoryName'
              placeholder='Name'
              value={request?.primaryCategoryName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'
            >
              {t('items')}
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                options={items}
                onChange={handleItemSelect}
                getOptionDisabled={(option) => request?.itemPrimaryCategoryOverrides ? request.itemPrimaryCategoryOverrides.some(x => x.itemNumber == option.itemNumber) : false}
                getOptionKey={(option) => option.itemNumber ?? 0}
                getOptionLabel={(option) => option.itemNumber + ' - ' + option.description}
                renderInput={(params) => (
                  <TextField {...params}
                    placeholder={t('itemNoOrName')}
                    required/>
                )}
              />
            </FormControl>
          </Grid>
          <Box
            sx={outerBoxStyles}
          >
            <Box
              sx={scrollableBoxStyles()}
            >
              <ul style={{ paddingLeft: 0, width: '100%', marginTop: 0, marginBottom: 0 }}>
                {request?.itemPrimaryCategoryOverrides && request?.itemPrimaryCategoryOverrides?.length > 0 ?
                  request.itemPrimaryCategoryOverrides.map((r) => (
                    <li key={r.itemNumber}
                      style={{ marginBottom: '8px', opacity: '0.7', display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
                      <Typography
                        variant="textSM">
                        {r.itemNumber}
                      </Typography>
                      <Tooltip title='Remove SKU'
                        color='dark'
                        placement='top'>
                        <IconButton
                          size='small'
                          onClick={() => removeItem(r.itemNumber!)}>
                          <SvgIcon color='error'><Trash01></Trash01></SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </li>
                  ))
                  : <Typography 
                    sx={{ opacity: '0.7' }}
                    variant="textSM">
                    {t('noItems')}
                  </Typography>}
              </ul>
            </Box>
          </Box>
          <Grid item
            sx={{ paddingTop: 0 }}
            xs={12}>
            <FormGroup style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={request?.includeInReports == true}
                  onChange={(event) => handleCheckChange('includeInReports', event)} />}
                label={t('includeInReports')}
              />
            </FormGroup>
          </Grid>
          <Grid item
            xs={12}>
            <FormGroup style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={request?.smartCount == true}
                  onChange={(event) => handleCheckChange('smartCount', event)} />}
                label={t('includeInSmartCount')}
              />
            </FormGroup>
          </Grid>
          <Grid item
            xs={12}>
            <FormGroup style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={request?.zeroStockItem == true}
                  onChange={(event) => handleCheckChange('zeroStockItem', event)} />}
                label={t('defaultTo0')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          disabled={!isValid}
          onClick={handleConfirm}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateEditModal;