import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../../contexts/PurchaseOrderDetailsContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const PurchaseOrderDetailCreditRequestCutOffModal = () => {
  const { t } = useTranslation('common');

  const { isCreditRequestCutOffModalOpen, closeCreditRequestCutOffModal, cutOffTime } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  const cutoffTimeFormatted = new Date(cutOffTime);

  const hours = isNaN(cutoffTimeFormatted.getHours()) ? '' : String(cutoffTimeFormatted.getHours()).padStart(2, '0');
  const minutes = isNaN(cutoffTimeFormatted.getMinutes()) ? '' : String(cutoffTimeFormatted.getMinutes()).padStart(2, '0');
  const timeString = hours !== '' ? `${hours}:${minutes}` : '';

  const dateString = cutoffTimeFormatted.toDateString();

  const translatedMessage = t('purchaseOrderReceipt.creditRequestCutoffModal.message', {
    CUT_OFF_TIME: timeString,
    CUT_OFF_DATE: dateString,
  }).replace(/\s?<br>\s?/g, '\n');

  return (
    <Dialog onClose={closeCreditRequestCutOffModal}
      open={isCreditRequestCutOffModalOpen}>
      <DialogTitle>
        {t('purchaseOrderReceipt.creditRequestCutoffModal.title')}
      </DialogTitle>
      <IconButton
        onClick={closeCreditRequestCutOffModal}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography>
          {translatedMessage}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={closeCreditRequestCutOffModal}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};