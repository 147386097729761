import { PurchaseOrderDetailsProvider } from './contexts/PurchaseOrderDetailsContext';
import PurchaseOrdersDetailsTop from './components/PurchaseOrderDetailsTop';
import PurchaseOrdersDetailsPopUps from './components/PurchaseOrderDetailsPopUps';
import PurchaseOrdersDetailsMainArea from './components/PurchaseOrderDetailsMainArea';
import PageArea from '../../components/shared/PageArea';

const PurchaseOrdersDetails = () => {
  return <PurchaseOrderDetailsProvider>
    <PageArea>
      <PurchaseOrdersDetailsTop/>
      <PurchaseOrdersDetailsMainArea/>
    </PageArea>
    <PurchaseOrdersDetailsPopUps/>
  </PurchaseOrderDetailsProvider>; 
};

export default PurchaseOrdersDetails;