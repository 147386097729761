import useNswagClient from '../../../hooks/api/useNswagClient';
import { Item } from '../../../app/services/api/generated';
import { useState } from 'react';

const useStockItemsData = () => {
  const { getStockItems } = useNswagClient();
  const [stockItems, setStockItems] = useState<Item[]>([]);

  const loadStockItems = async (storeNumber: string) => {
    try {
      const res = await getStockItems(storeNumber);
      if (res?.data) {
        setStockItems(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  return {
    stockItems,
    setStockItems,
    loadStockItems,
  };
};

export default useStockItemsData;