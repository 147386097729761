import { Box, Typography, useMediaQuery, Link } from '@mui/material';
import ErrorImage from '../../assets/images/Starbucks 404.png';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';

const ErrorPage = (props: {variant: '403' | '404'}) => {
  const { t } = useTranslation('errorPage');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const imageDivider = isMobile ? 4 : 2;
  
  return (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
    <Box>
      <Typography variant={isMobile ? 'displayXS' : 'displayLG'}>{props.variant == '404'? t('404Title') : t('403Title')}</Typography>
    </Box>
    <img
      src={ErrorImage}
      alt={'error image'}
      loading="lazy"
      height={576/imageDivider}
      width={1012/imageDivider}/>
    <Typography variant={isMobile ? 'textXS' : 'textLG'}>
      {props.variant == '404' ? 
        <>
          {t('404Body')} <Link href="/">{t('homePage')}</Link>
        </>
        :
        <>
          {t('403Body')} <Link href="/">{t('homePage')}</Link>
        </>
      }</Typography>
  </Box>);
};

export default ErrorPage;