import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Alert, SvgIcon } from '@mui/material';
import { X } from '../../../assets';
import { theme } from '../../../theme';


interface DeactivateModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isDeactivationProcessing: boolean;
  errorMessage: string | undefined;
}

const StockTransferDeactivateModal: React.FC<DeactivateModalProps> = ({ open, onConfirm, onCancel, isDeactivationProcessing, errorMessage }) => {
  const { t } = useTranslation('stockTransfersDetails');

  return ( 
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ width: '20px', mr: '5px' }}><X color={theme.palette.error[500]} /></SvgIcon>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>{t('deactivateModal.title')}
        </Typography>      
      </DialogTitle>
      <IconButton
        aria-label="close"
        disabled={isDeactivationProcessing}
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('deactivateModal.text')}
        </Typography>
        {isDeactivationProcessing && <Alert severity="info">
          {t('deactivateModal.processingMessage')}
        </Alert>}
        {!isDeactivationProcessing && errorMessage && <Alert severity="error">
          {errorMessage}
        </Alert>}       
      </DialogContent>
      <DialogActions sx={{ my: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          disabled={isDeactivationProcessing}
          onClick={onCancel}>
          {t('deactivateModal.cancelButton')}
        </Button>
        <Button
          variant='bad'
          size="lg"
          disabled={isDeactivationProcessing}
          onClick={onConfirm}
        >
          {t('deactivateModal.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockTransferDeactivateModal;