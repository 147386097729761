import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button } from '@mui/material';
import { X } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { theme } from '.././../../theme';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSaving: boolean;
}

const SaveModal = ({ open, onClose, onConfirm, isSaving }: Props) => {
  const { t } = useTranslation('wasteChecksheet');

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
          {t('create.saveData') }                  
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 10,
          color: () => theme.palette.custom.gray[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent>
        {
          <Typography>
            {t('create.saveMessage') }                  
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onClose}
          autoFocus
        >
          {t('create.cancel') }                  
       
        </Button>
        <Button variant="primary"
          onClick={onConfirm}
          disabled={isSaving}
          autoFocus
        >
          {t('create.confirm') }                  

        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveModal;