import { Box, useTheme, SvgIcon } from '@mui/material';
import { X } from '../../../../assets';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { useContext } from 'react';

export const Header = () => {
  const theme = useTheme();
  const { isMobile, setOpen } = useContext(DrawerContext) as DrawerValues;

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      my={{ xs: 6, md: 9 }}
      mx={{ xs: 4, md: 6 }}
      alignItems='center'
    >
      <img alt='logo'
        src={'/logo-reverse.svg'}
        height='24px' />

      {isMobile && <Box
        p={4}
        onClick={() => setOpen(false)}
        sx={{
          cursor: 'pointer',
          color: theme.palette.custom.gray[400],
        }}>
        <SvgIcon>
          <X />
        </SvgIcon>
      </Box>}
    </Box >);
};