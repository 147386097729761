import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button, TableCell, TableRow, TableContainer, Table, TableHead, TableBody, Dialog, DialogActions, FormControlLabel, Checkbox, FormGroup, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { PrimaryCategoryStockCount } from '../../../../app/services/api/generated';
import { Check } from  '../../../../assets';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../hooks/useLogError';
import { StockCountCreateContext, StockCountCreateValues } from '../../contexts/StockCountCreateContext';
import CloseIcon from '@mui/icons-material/Close';

const StockCountCreateCategoriesModal=() => {
  const { isCategoriesModalOpen,
    handleClose,
    categoryData,
    handleCategories } = useContext(StockCountCreateContext) as StockCountCreateValues;

  const { selectedStore } = useContext(UserContext);
  const { getCategories } = useNswagClient();
  const [data, setData] = useState<PrimaryCategoryStockCount[] | undefined>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tempCategoryData, setTempCategoryData] = useState<PrimaryCategoryStockCount[] | undefined>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation('createStockCount');
  const { logError } = useLogError();

  const addItemToTempList = (item : PrimaryCategoryStockCount) => {
    setTempCategoryData([...tempCategoryData!, item]);
  };
  
  const handleConfirm = () => {
    handleCategories(tempCategoryData || []);
    setTempCategoryData([]);
  };

  const handleCategoryCheckboxChange = (category: string, isChecked: boolean ) => {
    if (isChecked) {
      const exist = tempCategoryData?.find(row => row.primaryCategoryName === category);
      if (!exist) {
        const selectedCategory = data?.find(row => row.primaryCategoryName === category);
        if (selectedCategory) {
          addItemToTempList(selectedCategory);
        }
      }
    } else {
      setTempCategoryData(prevData => {
        setIsChecked(false);
        const updatedData = prevData?.filter(row => row.primaryCategoryName !== category) || [];
        return updatedData;
      });
    }
  };

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      setTempCategoryData( data ); 
      setIsChanged(true);
      setSelectAll(true);
    } else {
      setTempCategoryData([]);
      setIsChanged(false);
      setSelectAll(false);
    }
  };

  const handleSelectSmartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const smartdata = data?.filter(item => item.smartCount);
    if (checked) {
      setIsChanged(true);
      setTempCategoryData(  selectAll ? data : smartdata) ;
    } else {
      const uncheckedData = data?.filter(item => !smartdata?.includes(item)) || [];
      setTempCategoryData(selectAll ? uncheckedData : []);
      setIsChanged(false);
    }
  };

  useEffect(() => {
    getData();
  }, [ ]);

  const getData = () => {
    const storeNumber = selectedStore?.storeNumber === undefined? '':selectedStore.storeNumber;
    getCategories(storeNumber).then((result) => {
      if (result ) {  
        setData(result.data?.sort((a, b) => a.primaryCategoryName?.localeCompare(b.primaryCategoryName??'') ?? 0));
      } else {
        logError(result);
      }})
      .catch((error) => {
        logError(error);
      });
  };

  return (
    <Dialog open={isCategoriesModalOpen}
      onClose={handleClose}
      maxWidth='md'
      fullWidth>
      <DialogTitle>
        Categories
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ overflowy:'auto', border: '1px solid' }}
          style={{ maxHeight: '400px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell >{t('category')}</TableCell>
                <TableCell >{t('items')}</TableCell>
                <TableCell >{t('smartCount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ display: 'flex', alignItems: 'center' }}  >
                    <Checkbox
                      color="primary"
                      inputProps={{
                        'aria-label': `select category ${row.primaryCategoryName}`,
                      }}
                      checked={isChecked || tempCategoryData?.some((item) => item.primaryCategoryName === row.primaryCategoryName) || categoryData?.some((item) => item.primaryCategoryName === row.primaryCategoryName)}
                      onChange={(e) =>
                        handleCategoryCheckboxChange(
                                row.primaryCategoryName!,
                                e.target.checked,
                        )
                      }/>
                    {row.primaryCategoryName}
                  </TableCell>
                  <TableCell >{row.itemCount}</TableCell>
                  <TableCell >{row.smartCount === true ? <Check /> : row.smartCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FormGroup sx={{ pt:'10px' }}>
          <FormControlLabel
            control={<Checkbox 
              onChange={handleSelectSmartChange}/>}
            checked={isChanged}
            label={isChanged ? 'Remove all smart categories' : 'Add all smart categories'}
          />
          <FormControlLabel 
            control={<Checkbox onChange={handleSelectAllChange}/>}
            label="Select all categories"/>
        </FormGroup>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}> {t('cancel')}</Button>
        <Button
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
    
  );
};

export default StockCountCreateCategoriesModal;