import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import { CreditRequestRag, PosFeedLastReceived, PurchaseOrderRag, RAG, StockCheckRAG } from '../../app/services/api/generated';
import { Box, Grid } from '@mui/material';
import { RAGDetail } from './types/RAGDetail';
import { RAGRecord } from './types/RAGRecord';
import { CreditRequestRAGRecord } from './types/CreditRequestRAGRecord';
import { ConfigurationName } from '../../enums/ConfigurationName';
import { CreditRequestRAGDetail } from './types/CreditRequestRAGDetail';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import TitleArea from './components/TitleArea';
import StockCount from './components/StockCount';
import DailyTasks from './components/DailyTasks';
import ActualTheoryReportsTable from './components/ActualTheoryReportsTable';
import ValueOfCreditReportsTable from './components/ValueOfCreditReportsTable';
import CreditRequestsDetails from './components/CreditRequestsDetails';
import Notifications from './components/Notifications'; 

const DashboardPage = () => {
  const { getStockCheckByRagStatus, getStockCheckRag, getPurchaseOrdersRag, getTransactionsRag, getTransactionsByRagStatus, getPurchaseOrdersByRagStatus, getCreditRequestStatusRag, getCreditRequestStatusDataByRagStatus, getConfiguration } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const { logError } = useLogError();

  const [stockDetail, setStockDetail] = useState<RAGDetail>({});
  const [porDetail, setPorDetail] = useState<RAGDetail>({});
  const [posDetail, setPosDetail] = useState<RAGDetail>({});
  const [creditRequestStatusDetail, setCreditRequestStatusDetail] = useState<CreditRequestRAGDetail>({});

  const [stockCheckRag, setStockCheckRag] = useState<RAG | undefined>(undefined);
  const [posRag, setPosRag] = useState<RAG | undefined>(undefined);
  const [porRag, setPorRag] = useState<RAG | undefined>(undefined);
  const [creditRequestStatusRag, setCreditRequestStatusRag] = useState<RAG | undefined>(undefined);
  const [isCreditRequestEnabled, setIsCreditRequestEnabled] = useState<boolean>(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    getConfiguration(ConfigurationName.CreditRequestsEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((config: any) => {
        if (config.data.value === 'true' && config.data.level.value === 'Franchise') {
          setIsCreditRequestEnabled(true);
        }
      })
      .catch((e) => logError(e));

    getStockCheckRag()
      .then((data) => setStockCheckRag(data.data))
      .catch((e) => logError(e));

    getPurchaseOrdersRag()
      .then((data) => setPorRag(data.data))
      .catch((e) => logError(e));

    getTransactionsRag()
      .then((data) => setPosRag(data.data))
      .catch((e) => logError(e));

    getTransactionsByRagStatus(1)
      .then((data) => {
        const detail = posDetail;
        detail.Red = mapTransactions(data.data);
        setPosDetail(detail);
      })
      .catch((e) => logError(e));

    getTransactionsByRagStatus(2)
      .then((data) => {
        const detail = posDetail;
        detail.Orange = mapTransactions(data.data);
        setPosDetail(detail);
      })
      .catch((e) => logError(e));

    getTransactionsByRagStatus(3)
      .then((data) => {
        const detail = posDetail;
        detail.Green = mapTransactions(data.data);
        setPosDetail(detail);
      })
      .catch((e) => logError(e));

    getPurchaseOrdersByRagStatus(1)
      .then((data) => {
        const detail = porDetail;
        detail.Red = mapPurchaseOrders(data.data);
        setPorDetail(detail);
      })
      .catch((e) => logError(e));

    getPurchaseOrdersByRagStatus(2)
      .then((data) => {
        const detail = porDetail;
        detail.Orange = mapPurchaseOrders(data.data);
        setPorDetail(detail);
      })
      .catch((e) => logError(e));

    getPurchaseOrdersByRagStatus(3)
      .then((data) => {
        const detail = porDetail;
        detail.Green = mapPurchaseOrders(data.data);
        setPorDetail(detail);
      })
      .catch((e) => logError(e));

    getStockCheckByRagStatus(true, false, false)
      .then((data) => {
        const detail = stockDetail;
        detail.Red = mapStockChecks(data.data);
        setStockDetail(detail);
      })
      .catch((e) => logError(e));

    getStockCheckByRagStatus(false, true, false)
      .then((data) => {
        const detail = stockDetail;
        detail.Orange = mapStockChecks(data.data);
        setStockDetail(detail);
      })
      .catch((e) => logError(e));

    getStockCheckByRagStatus(false, false, true)
      .then((data) => {
        const detail = stockDetail;
        detail.Green = mapStockChecks(data.data);
        setStockDetail(detail);
      })
      .catch((e) => logError(e));

    getCreditRequestStatusRag(selectedStore?.storeNumber ?? '')
      .then((data) => setCreditRequestStatusRag(data.data))
      .catch((e) => logError(e));

    getCreditRequestStatusDataByRagStatus(1, selectedStore?.storeNumber ?? '')
      .then((data) => {
        const detail = creditRequestStatusDetail;
        detail.Red = mapCreditRequests(data.data);
        setCreditRequestStatusDetail(detail);
      })
      .catch((e) => logError(e));

    getCreditRequestStatusDataByRagStatus(2, selectedStore?.storeNumber ?? '')
      .then((data) => {
        const detail = creditRequestStatusDetail;
        detail.Orange = mapCreditRequests(data.data);
        setCreditRequestStatusDetail(detail);
      })
      .catch((e) => logError(e));

    getCreditRequestStatusDataByRagStatus(3, selectedStore?.storeNumber ?? '')
      .then((data) => {
        const detail = creditRequestStatusDetail;
        detail.Green = mapCreditRequests(data.data);
        setCreditRequestStatusDetail(detail);
      })
      .catch((e) => logError(e));
  }, []);

  const mapTransactions = (data: PosFeedLastReceived[] | undefined): RAGRecord[] => {
    if (data) {
      const ragDetails = data.map((transaction) => {
        return {
          id: '',
          name: '',
          storeName: transaction.storeName,
          itemDate: transaction.date,
        };
      });
      ragDetails.forEach((rag, index) => rag.id = (index + 1).toString());
      return ragDetails;
    } else {
      return [];
    }
  };

  const mapStockChecks = (data: StockCheckRAG[] | undefined): RAGRecord[] => {
    if (data) {
      return data.map((stockCheck) => {
        return {
          id: stockCheck.id?.toString(),
          name: stockCheck.name,
          storeName: stockCheck.storeName,
          itemDate: stockCheck.plannedCountDate,
        };
      });
    } else {
      return [];
    }
  };

  const mapCreditRequests = (data: CreditRequestRag[] | undefined): CreditRequestRAGRecord[] => {
    if (data) {
      return data.map((creditRequestCheck) => {
        return {
          storeName: creditRequestCheck.storeName?.toString(),
          storeNumber: creditRequestCheck.storeNumber?.toString(),
          orderNumber: creditRequestCheck.orderNumber?.toString(),
          creditedAmount: creditRequestCheck.creditedAmount?.toString(),
        };
      });
    } else {
      return [];
    }
  };

  const mapPurchaseOrders = (data: PurchaseOrderRag[] | undefined): RAGRecord[] => {
    if (data) {
      return data.map((po) => {
        return {
          id: po.orderNo,
          name: '',
          storeName: po.store,
          itemDate: po.dateOfReceipt,
          orderNumber:  po.orderNo,
          orderType: po.type,
        };
      });
    } else {
      return [];
    }
  };

  const doesUserHavePermission = (permission: string): boolean => {
    return user?.roles?.includes(permission) ?? false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
      }}
    >
      <TitleArea storeName={selectedStore?.storeName ?? ''}></TitleArea>
      <Box 
        sx={{ 
          py: 3,
          mb: 5,
          backgroundImage: 'url(/SustainabilityNewsBrief.jpg)',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        }}
      >
        <Box sx={{ maxWidth: { xs: '100%', lg: '100%' }, m: { xs: '30px 12px 18px', lg: '50px' } }}>
          
          <Grid container
            spacing={5}>
            <Grid item
              xs={12}
              lg={6}>
              <DailyTasks storeNumber={selectedStore?.storeNumber} ></DailyTasks> 
            </Grid>
            <Grid item
              xs={12}
              lg={6}>
              <Notifications storeNumber={selectedStore?.storeNumber} />
            </Grid>
          </Grid>
         
        </Box>
      </Box>
      <Box display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
      >
        <Box
          sx={{
            width: {
              xs: 1,
              lg: 1 / 2,
            },
          }}
        >
          <StockCount
            title={t('stockCountTitle')}
            redStatusGroup={t('stockCountRedGroup')}
            orangeStatusGroup={t('stockCountOrangeGroup')}
            greenStatusGroup={t('stockCountGreenGroup')}
            ragDetail={stockDetail}
            greenNumber={stockCheckRag?.green}
            redNumber={stockCheckRag?.red}
            orangeNumber={stockCheckRag?.amber}
          ></StockCount>
          <StockCount
            title={t('posTitle')}
            redStatusGroup={t('posRedGroup')}
            orangeStatusGroup={t('posOrangeGroup')}
            greenStatusGroup={t('posGreenGroup')}
            ragDetail={posDetail}
            greenNumber={posRag?.green}
            redNumber={posRag?.red}
            orangeNumber={posRag?.amber}
          ></StockCount>
          <StockCount
            title={t('porsTitle')}
            redStatusGroup={t('porsRedGroup')}
            orangeStatusGroup={t('porsOrangeGroup')}
            greenStatusGroup={t('porsGreenGroup')}
            ragDetail={porDetail}
            greenNumber={porRag?.green}
            redNumber={porRag?.red}
            orangeNumber={porRag?.amber}
          ></StockCount>
          {
            isCreditRequestEnabled && doesUserHavePermission('purchaseorderwrite') && (
              <CreditRequestsDetails 
                title={t('creditRequestStatusTitle')}
                redStatusGroup={t('creditRequestStatusRedGroup')}
                orangeStatusGroup={t('creditRequestStatusOrangeGroup')}
                greenStatusGroup={t('creditRequestStatussGreenGroup')}
                ragDetail={creditRequestStatusDetail}
                redNumber={creditRequestStatusRag?.red}
                orangeNumber={creditRequestStatusRag?.amber}
                greenNumber={creditRequestStatusRag?.green}
              ></CreditRequestsDetails>
            )
          }
        </Box>
        <Box
          sx={{
            width: {
              xs: 1,
              lg: 1 / 2,
            },
          }}
        >
          <ActualTheoryReportsTable />
          {
            isCreditRequestEnabled && doesUserHavePermission('purchaseorderwrite') &&
              <ValueOfCreditReportsTable />
          }
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPage;
