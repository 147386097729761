import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { FormEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import useNswagClient from './../../../../hooks/api/useNswagClient';
import { District, RequestStockCountsVsUsageReportRequest, Store } from './../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { formatDateForReport, getFormattedDate } from './../../../../utils';
import { UserContext } from './../../../../components/shared/useUser';

type IProps = {
  onClose?: () => void;
}

const StockCountVsUsageReportForm = (props: IProps) => {
  const { selectedStore, user } = useContext(UserContext);
  const [allDistricts, setAllDistricts] = useState<District[]>([]);
  const [selectedDistricts, setSelectedDistricts] = useState<District[]>([]);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const { runStockCountsVsUsageReport, getDistrictsByFranchise, getReportAvailableDates } = useNswagClient();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (selectedStore?.storeNumber) {
      loadDistricts();
    }
  }, [selectedStore]);

  const loadDistricts = () => {
    if (!selectedStore?.franchiseName) return;
    
    getDistrictsByFranchise(selectedStore.franchiseName).then((res) => {
      if (res.data && Array.isArray(res.data)) {
        setAllDistricts(res.data);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const loadAvailableDates = (stores: Store[], districts: District[], selectAllStoresChecked: boolean) => {
    if (stores.length === 0 && districts.length === 0 && !selectAllStoresChecked) {
      setAvailableDates([]);
      return;
    }
    getReportAvailableDates(stores.map(store => store.storeNumber).join(','), districts.map(district => district.id).join(','), selectAllStoresChecked, 28)
      .then((res) => {
        if (res.data) {
          setAvailableDates(res.data.map(date => new Date(date).toISOString()));
        }
      });
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSelectedStores([]);

    setCheckedAllStores(checked);

    loadAvailableDates([], selectedDistricts, checked);
  };

  const handleSelectChange = (event: SyntheticEvent, value: Store[]) => {
    setSelectedStores(value);
    setFromDate('');
    setToDate('');
    loadAvailableDates(value, [], checkedAllStores);

    if (value.length > 0) {
      setSelectedDistricts([]);
    }
  };

  const handleDistrictChange = (event: SyntheticEvent, value: District[]) => {
    setSelectedDistricts(value);
    setFromDate('');
    setToDate('');
    loadAvailableDates(selectedStores, value, checkedAllStores);

    if (value.length > 0) {
      setSelectedStores([]);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsGenerating(true);
    setErrorMessage('');
    setSuccessMessage('');

    const from = new Date(new Date(fromDate).setHours(0, 0, 1, 0));
    const to = new Date(new Date(toDate).setHours(23, 59, 59, 0));
    const selectedAllStores = checkedAllStores || (selectedStores.length > 0 && selectedStores.length == user?.stores?.length);

    const request: RequestStockCountsVsUsageReportRequest = {
      reportOnAllAvailableStores: selectedAllStores,
      stores: selectedAllStores ? undefined : selectedStores.map(x => x.storeNumber as string),
      districts: selectedDistricts.length === 0 ? undefined : selectedDistricts.map(x => x.id as number),
      from: formatDateForReport(from),
      to: formatDateForReport(to),
    };

    runStockCountsVsUsageReport(request).then(() => {
      setSuccessMessage(t('reporting.generic.post-generation-message'));
      setIsRequestSubmitted(true);
    }).catch((error) => {
      if (typeof(error) === 'string') {
        setErrorMessage(error);
      }
      console.log(error);
    }).finally(() => {
      setIsGenerating(false);
    });
  };

  const handleDateChange = (event: SyntheticEvent, value: string | null) => {
    setFromDate(value ?? '');
    setToDate('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container
        spacing={10}
        sx={{ pt: '10px' }}>
        {
          allDistricts.length > 0 && 
            <Grid item
              xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  options={allDistricts}
                  value={selectedDistricts}
                  onChange={handleDistrictChange}
                  getOptionKey={(option) => option.id ?? ''}
                  getOptionLabel={(option) => option.description ?? ''}
                  disabled={selectedStores.length > 0 || checkedAllStores}
                  renderInput={(params) => (
                    <TextField {...params}
                      required={selectedStores.length === 0 && !checkedAllStores && selectedDistricts.length === 0}
                      label={t('reporting.select-districts')}
                      placeholder={t('reporting.select-districts')} />
                  )}
                />
              </FormControl>
            </Grid>
        }
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={user?.stores ?? []}
              value={selectedStores}
              disabled={selectedDistricts.length > 0 || checkedAllStores}
              onChange={handleSelectChange}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedStores.length === 0 && !checkedAllStores && selectedDistricts.length === 0}
                  label={t('reporting.select-stores')}
                  placeholder={t('reporting.select-stores')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox
                checked={checkedAllStores}
                disabled={selectedDistricts.length > 0}
                onChange={handleSelectAllChange} />}
              label={t('reporting.all-stores')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={availableDates.slice(1)} // Exclude most recent date
              value={fromDate || null}
              disabled={selectedStores.length === 0 && !checkedAllStores}
              onChange={handleDateChange}
              getOptionLabel={(option) => option ? getFormattedDate(new Date(option)) : ''}
              renderInput={(params) => (
                <TextField {...params}
                  required
                  label={t('reporting.start-date')}
                  placeholder={t('reporting.start-date')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={availableDates.filter(date => date > fromDate)}
              value={toDate || null}
              disabled={!fromDate}
              onChange={(event, value) => setToDate(value ?? '')}
              getOptionLabel={(option) => option ? getFormattedDate(new Date(option)) : ''}
              getOptionKey={(option) => option}
              renderInput={(params) => (
                <TextField {...params}
                  required
                  label={t('reporting.end-date')}
                  placeholder={t('reporting.end-date')} />
              )}
            />
          </FormControl>
        </Grid>
        {
          errorMessage && 
          <Grid item
            xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        }
        {
          successMessage && 
          <Grid item
            xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        }
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', columnGap: '10px' }}>
          <Button size='lg'
            variant='secondary' 
            onClick={props.onClose}>{t('cancel')}</Button>
          <Button size='lg'
            disabled={isGenerating || isRequestSubmitted}
            type='submit'>{t('reporting.generate')}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StockCountVsUsageReportForm;