import { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemText, Typography,  Alert, Box } from '@mui/material';
import { theme } from '../../../theme';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { Notification2 } from '../../../app/services/api/generated';
import CustomPagination from '../../../components/forms/Pagination'; 
import { useTranslation } from 'react-i18next';
import { client, News } from '../../../app/services/api/orderManagementClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useLogError from '../../../hooks/useLogError';
import NewsDetails from '../modals/NewsDetails';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Notifications = (props: { storeNumber: string | undefined }) => { 
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const { getNotifications } = useNswagClient(); 
  const { logError } = useLogError();
  const [newsData, setNewsData] = useState<News[]>([]); 
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<News | null>(null); 
  const [data, setData] = useState<News[]>([]); 
  const [errorGettingOMNews, setErrorGettingOMNews] = useState<boolean>(false);
  const [errorGettingIMNews, setErrorGettingIMNews] = useState<boolean>(false);
  const rowsPerPage = 3;

  useEffect(() => { 
    fetchData();   
  }, []); 

  useEffect(() => {  
    if (data) {
      const sortedData = [...data].sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
      setNewsData(sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []);
    }

  }, [page,data]); 

  const handleReadMore = (notification: News) => {
    setSelectedItem(notification);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedItem(null);
    setDialogOpen(false);
  };
 
  const fetchData = () => {
    setLoading(true); 
    const omNewsPromise = client.GetNews(props.storeNumber)
      .then((ordersNewsResult) => ordersNewsResult.data)
      .catch((error) => {
        logError('Error fetching news from OM:' + error);
        setErrorGettingOMNews(true);
        return [];
      });

    const imNewsPromise = getNotifications(props.storeNumber ?? '-1')
      .then((notificationResult) => {
        const notifications: Notification2[] = notificationResult.data as Notification2[];
        return notifications.map((notification: Notification2) => ({
          id: notification.notificationId ?? 0,
          title: notification.title ?? '',
          teaser: notification.message ?? '',
          body: '',
          imageId: 0,
          thumbnailUrl: '',
          dataFromOM: false,
          files: null,
          imagesUrl: null,
          startDate: notification.startDate?.toString() ?? '',
        }));
      })
      .catch((error) => {
        logError('Error fetching news from IM:' + error); 
        setErrorGettingIMNews(true);
        return [];
      });

    Promise.all([omNewsPromise, imNewsPromise])
      .then(([omNewsData, imNewsData]) => {
        const mergedData = [...omNewsData, ...imNewsData];
        setData(mergedData);
      })
      .catch((error) => {
        logError('Error fetching data from one or more sources:' + error);
      })
      .finally(() => {
        setLoading(false);
      });
  }; 
 
  const maxPages = () => (data?.length ? Math.ceil(data.length / rowsPerPage) : 1);
  const parseHTMLWithStyles = (htmlString: string) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, 'text/html');
    
    // Find all img elements and apply styles
    parsedHTML.querySelectorAll('img').forEach((img) => {
      img.style.maxWidth = '100%'; 
      img.style.height = 'auto'; 
    });

    return parsedHTML.documentElement.innerHTML;
  };
  const renderCardContent = () => { 
    if (loading) {
      return <LoadingWheel />;
    } 
    if (errorGettingIMNews && errorGettingOMNews){  
      return <Alert severity='error'>{t('notificationErrorFetchingData')}</Alert>; 
    }
    if (newsData?.length > 0) {
      return (
        <List sx={{ bgcolor: theme.palette.common.white , maxHeight:'362px', overflowY:'auto' }}>
          {newsData.map((notification) => (
            <ListItem key={notification.id}
              disableGutters
              secondaryAction={notification.dataFromOM &&
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => handleReadMore(notification)}
                  startIcon={<ArrowForwardIosIcon/>}>
                  <Typography variant="textSM">
                    {t('notificationReadMore')}
                  </Typography>
                </Button>
              }>
              <ListItemText
                sx={{ pr: '85px' }}
                primary={notification.title}
                secondary={ <div dangerouslySetInnerHTML={{ __html: parseHTMLWithStyles(notification.teaser?.toString()) }}></div>}
              />
            </ListItem>
          ))}
        </List>
      );
    }
    return (
      <Typography variant="textSM">
        {t('noAvailableNotifications')}
      </Typography>
    );
  }; 
  return (
    <Box  >
      {renderCardContent()}
      { newsData?.length> 0 && (
        <div style={{ padding: '7px 8px' }}>
          <CustomPagination
            page={page}
            setPage={setPage}
            maxPages={maxPages()}
            breakpointForChangeDisplay={120000}
          ></CustomPagination>
        </div>
      )} 
      {!errorGettingOMNews && errorGettingIMNews && <Alert sx={{ mx: '12px', mb: '12px'  }}
        severity='warning'>{t('notificationErrorIM')}</Alert>}

      {!errorGettingIMNews && errorGettingOMNews && <Alert  sx={{ mx: '12px', mb: '12px'   }}
        severity='warning'>{t('notificationErrorOM')}</Alert>}
      <NewsDetails isDialogOpen={dialogOpen}
        handleCloseDialog={handleCloseDialog}
        selectedNews = {selectedItem} />
    </Box>
    
  );
};
export default Notifications;

 
