import {  Button, Grid } from '@mui/material';
import { Save01 } from '../../../assets';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';

const ScheduleCreateTop=() => {
  const { t,
    isLoading,
    schedules,
    handleLeaveClick,
    submitStock,
    isCreating,
    generateStock,
    name,
    hasRightToEdit } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  return (
    <Grid container>
      <Grid item 
        xs={12}>
        <BackButton
          handleClick={handleLeaveClick} 
          title={t('exit')}
          isLink={false}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item 
          xs={12}>
          <PageTitle>{isCreating ? t('title') : t('editTitle')}</PageTitle>
        </Grid>
        {!isLoading &&
          <Grid item 
            xs={12}
            sx={{ display:'flex', justifyContent:'flex-end', alignContent: 'flex-end' }}>
            {!isCreating && <Button size='lg'
              disabled={!name || (schedules.length === 0) || !hasRightToEdit}
              onClick={generateStock}>
              {t('generateSchedules')}
            </Button>}
            <Button size='lg'
              sx={{ ml: 4 }}
              disabled={!name || (schedules.length === 0) || !hasRightToEdit}
              onClick={submitStock}>
              {isCreating ? <><Save01/>{t('create')}</> : <><Save01/>{t('save')}</>} 
            </Button>
          </Grid>}
      </Grid>
      {isLoading && <Grid item
        xs={12}>
        <LoadingWheel/>
      </Grid>}
    </Grid>
  );
};

export default ScheduleCreateTop;