import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography,  TableCell, TableRow, TableContainer, Table, TableBody, DialogActions,  Box,  FormControlLabel, Checkbox, FormGroup, DialogContent, Dialog, DialogTitle, IconButton } from '@mui/material';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { GetItemsResponse, Item } from '../../../../app/services/api/generated';
import { SearchSm } from  '../../../../assets';
import CustomPagination from '../../../../components/forms/Pagination';
import { theme } from '../../../../theme';
import useLogError from '../../../../hooks/useLogError';
import { ScheduleCreateContext, ScheduleCreateValues } from '../../contexts/ScheduleCreateContext';
import CloseIcon from '@mui/icons-material/Close';

const ScheduleCreateItemsModal=() => {
  const { t,
    isItemsModalOpen,
    itemsData,
    handleClose,
    handleitems } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const { selectedStore } = useContext(UserContext);
  const { getItems } = useNswagClient();
  const { logError } = useLogError();

  const [apiData, setApiData] = useState<GetItemsResponse | undefined>();
  const [tempData, setTempData] = useState<Item[]>([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState<number | undefined>();
  const [isContinueSelecting, setIsContinueSelecting] = useState(false);

  const rowsPerPage = 10;

  useEffect(() => {
    getItemData();
  }, [page]);

  useEffect(() => {
    if(isItemsModalOpen) {
      setSearch('');
      setApiData(undefined);
      setCount(0);
    }
  }, [isItemsModalOpen]);

  const getItemData = () => {
    const skip = rowsPerPage * page;
    const storeNumber = selectedStore?.storeNumber === undefined? '':selectedStore.storeNumber;
    getItems(search, storeNumber, undefined, undefined, true, undefined, undefined, undefined, undefined, '', '', skip, rowsPerPage).then((result) => {
      if (result) {  
        setApiData(result.data);
        setCount(result.data?.itemCount);
      } else {
        logError(result);
      }})
      .catch((error) => {
        logError(error);
      });
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearch = () => {
    setPage(0);
    getItemData();
  };

  const addItemToTempList = (item : Item) => {
    if(!tempData?.some(e => e.id == item.id)) {
      setTempData([...tempData ?? [], item]);
    }
  };
  
  const handleConfirm = () => {
    handleitems(tempData || []);
    !isContinueSelecting && close();
  };
  
  const handleItemChange = (item: number, isChecked: boolean) => {
    if (isChecked) {      
      const selecteditem = apiData?.items?.find(row => row.id === item);
      if (selecteditem) {
        addItemToTempList(selecteditem);
      }
    } else {
      setTempData(prevData => prevData?.filter(row => row.id !== item) || []);
    }
  };

  const close = () => {
    setTempData([]);
    handleClose();
  };

  const toggleCheckbox = () => {
    setIsContinueSelecting(!isContinueSelecting);
  };

  const maxPages = () => count ? Math.ceil(count / rowsPerPage) : 0;

  return (
    <Dialog open={isItemsModalOpen}
      onClose={close}
      maxWidth='md'
      fullWidth>
      <DialogTitle>
        {t('itemsLookup')}
        <IconButton
          onClick={close}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid
            container
            spacing={2}
            display='flex'
            flexWrap={'nowrap'}
            alignItems='self-end'
            sx={{ borderTop:'1px solid', borderColor:'gray' }}
          >
            <Grid item
              xs={10}
              sx={{ pt:'5px' }}>
              <TextField
                fullWidth
                placeholder={t('search')}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}
                value ={search}
                onChange={handleSearchChange} />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Button size='lg'
                startIcon={<SearchSm/>}
                onClick={handleSearch}/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt:'10px' }}>
          <Typography 
            variant ='textSM'
            sx ={{ color:theme.palette.custom.gray[500] }}>{t('search')}</Typography> 
        </Box>
        <Box sx={{ width: '100%', textAlign: 'left', border: '1px solid' }}>
          <TableContainer >
            <Table>
              <TableBody>
                {apiData?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ display: 'flex', alignItems: 'center' }} >
                      <Checkbox
                        color="primary"
                        checked={tempData?.some((item) => item.itemNumber === row.itemNumber) || itemsData.some((item) => item.itemNumber === row.itemNumber)}
                        onChange={(e) => handleItemChange(row.id!, e.target.checked)}
                      />
                      <Grid style={{ marginLeft: '8px' }}>
                        {row.itemNumber}< br/>
                        {row.description}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination 
            page={page}
            setPage={setPage}
            maxPages={maxPages()}
            breakpointForChangeDisplay={120000} />
        </Box>
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox />}
            label={t('tickContinueSave')}
            checked={isContinueSelecting}
            onChange={toggleCheckbox}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={close}>
          {t('cancel')}
        </Button>
        <Button
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleCreateItemsModal;