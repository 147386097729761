export enum RecentActivityPeriod 
{
  SevenDays = '7',
  ThirtyDays = '30',
  SixtyDays = '60',
  NinetyDays = '90'
}

export const availableActivityPeriods = [
  { key: RecentActivityPeriod.SevenDays, value: '7 days' },
  { key: RecentActivityPeriod.ThirtyDays, value: '30 days' },
  { key: RecentActivityPeriod.SixtyDays, value: '60 days' },
  { key: RecentActivityPeriod.NinetyDays, value: '90 days' },
];