import { Grid, Box, Button, SvgIcon } from '@mui/material';
import { Eye, Save01 } from '../../../assets';
import { useContext } from 'react';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';

const InvoiceDetailsTitleArea = () => {
  const { t, data, navigate, saveInvoice, isLoading } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  return <Grid container
    spacing={4}
    sx={{ pb: '8px' }}>
    <Grid item
      xs={12}
      sx={{ textAlign: 'left' }}>
      <Box
        sx={{
          fontWeight: 'bold',
        }}>
        <PageTitle>{t('title')}: {data?.invoiceNumber}</PageTitle>
      </Box>
    </Grid>
    <Grid item
      sm={3}
      xs={12}>
      <BackButton
        handleClick={() => navigate('/invoices')} 
        title={t('goBack')}
        isLink={false}
      />
    </Grid>
    <Grid item
      sm={9}
      xs={12}
      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {(!isLoading && 
      data?.purchaseOrderHeaderId && 
      data?.purchaseOrderHeaderId > 0) ?
        <Button size='lg'
          startIcon={<SvgIcon><Eye></Eye></SvgIcon>}
          variant='secondary'
          onClick={() => navigate('/create-po-receipt/' +  data?.purchaseOrderHeaderId)}
          sx={{ ml: 4 }}>{t('viewPurchaseOrder')}</Button>:<></>}
      {!isLoading && <Button size='lg'
        onClick={saveInvoice}
        startIcon={<SvgIcon><Save01></Save01></SvgIcon>}
        sx={{ ml: 4 }}>{t('save')}</Button>}
    </Grid>
  </Grid>;
};

export default InvoiceDetailsTitleArea;