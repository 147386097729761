import { Button, Dialog, DialogActions, DialogContent,  FormControl,  Grid,  MenuItem,  Select,  SelectChangeEvent,  TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Plus, X } from '../../../assets';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { PostReasonRequest } from '../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';

export interface Reasons  {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  reasonId : number ;
  name :string | undefined;
  description :string 
  actionType : string 
}

interface ActionTypes {
  description: string,
}
const actionTypes: ActionTypes[] = [
  { description: 'Issue' },
  { description: 'Receive' },
];
 
const EditReasonModal=( props: Reasons) => {
  const { putTransactionReasons } = useNswagClient();
  const { logError } = useLogError();
  const[ description, setDescription ] = useState<string>();
  const[ name, setName ] = useState<string>('');
  const[ actionType, setActionType ] = useState<string>();
  const[ reasonId, setReasonId ] = useState<number>();
  const { t } = useTranslation('manageTransactions');
  const readonlyReason = 'Mark Out';
  
  useEffect(() => {
    const convertActionType = props.actionType.charAt(0).toUpperCase() + props.actionType.slice(1).toLowerCase();
    setActionType(convertActionType);
    setReasonId(props.reasonId);
    setName(props.name!);
    setDescription(props.description);
  }, [ props.actionType, props.description,props.name]);

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSelect = (event: SelectChangeEvent< string>) => {
    setActionType(event.target.value);
  };

  const updateReason = () => {
    const body : PostReasonRequest = {
      name:name,
      description:description,
      actionType:actionType,
    };
    const id  =  reasonId ?? 0;
    putTransactionReasons(id, body).then(() => {
      props.onConfirm();
    })
      .catch((error) => {
        logError(error);
      });
  };
  const handleClose = () => {
    setName(props.name!);
    setActionType(props.description);
    setActionType(props.actionType);
    props.onClose();
  };

  return (
    <Dialog open={props.open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth>
      <Grid 
        sx={{ m:'2px' }}>
        <Grid
          sx={{ display:'flex',alignItems:'flex-end', justifyContent:'flex-end' }}>
          <X  onClick={props.onClose} />
        </Grid>
        <Grid 
          sx={{ display:'flex',alignItems:'center', justifyContent:'center' }}>
          <Typography variant='textXL'>{t('editTitle')}</Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid sx={{ pt:'30px' }}>
          <TextField
            placeholder={t('createTitle')}
            multiline
            fullWidth
            disabled={true}
            value={props.name}
          />
        </Grid>
        <Grid sx={{  pt:'30px' }}>
          <TextField
            placeholder={t('description')}
            value={description}
            multiline
            rows={5}
            fullWidth
            onChange={handleDescriptionChange}
          />
        </Grid>
        <Grid sx={{  pt:'30px' }}>
          <FormControl
            fullWidth>
            <Select
              value={actionType}
              disabled= {props.name === readonlyReason}
              onChange={handleSelect}
              fullWidth>
              {actionTypes.map((option) => (
                <MenuItem key={option.description}
                  value={option.description}>
                  {option.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          onClick={handleClose}> {t('cancel')}</Button>
        <Button onClick={updateReason}
        ><Plus />
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog> 
  );
};

export default EditReasonModal;