import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { MadeInStoreComponent } from '../../../../app/services/api/generated';
import ComponentRow from './ComponentRow';
import { useTranslation } from 'react-i18next';

type Props = {
    ingredients: MadeInStoreComponent[];
}

const MadeInStoreIngredients: FC<Props> = ({ ingredients }) => {
  const { t } = useTranslation('recipe');

  return (
    <Box sx={{ m: 5, py: 5 }}>
      <Box display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='textLG'
          alignSelf='flex-start'>
          {t('madeInStoreComponents')}
        </Typography>
      </Box>
      {ingredients?.length === 0 ? (
        <Typography variant='textMD'>
          {t('noComponents')}
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('item')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('uom')}</TableCell>
              <TableCell>{t('recipeQuantity')}</TableCell>
              <TableCell>{t('stockOnHand')}</TableCell>
              <TableCell>{t('totalYield')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((item) => (
              <ComponentRow
                key={item.componentId}
                item={item} />
            ))}
          </TableBody>
        </Table>
      )
      }
    </Box>
  );
};

export default MadeInStoreIngredients;