import useLocalStorage from './useLocalStorage';
import { theme } from '../theme';
import { type Theme } from '@mui/material/styles';

const getThemeByName = (theme: string): Theme => {
  return themeMap[theme];
};

const themeMap: { [key: string]: Theme } = {
  theme,
};

export const useSelectedTheme = () => {
  // set specific theme for specific users
  const [ storedValue ] = useLocalStorage('localTheme', 'theme');
  const selectedTheme = getThemeByName(storedValue);

  return { selectedTheme };
};