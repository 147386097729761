import { TableRow, TableCell, SvgIcon, Collapse, Button, Box } from '@mui/material';
import { Minus, Plus } from '../../../../assets';
import { FC, useState } from 'react';
import { MadeInStoreItem } from '../../../../app/services/api/generated';
import MadeInStoreIngredients from './MadeInStoreIngredients';

type Props = {
    item: MadeInStoreItem;
    onCreateItem: (item: MadeInStoreItem) => void;
    hasPermissionToWriteMadeInStore: boolean;
}

const MadeInStoreItemRow: FC<Props> = ({ item, onCreateItem, hasPermissionToWriteMadeInStore }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onCreate = () => {
    onCreateItem(item);
  };

  return (
    <>
      <TableRow>
        <TableCell
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Box display='flex'
            flexDirection='row'
            alignItems='center'>
            <SvgIcon sx={{ cursor: 'pointer' }}>
              {isExpanded ? <Minus /> : <Plus />}
            </SvgIcon>
            {item.itemNumber}
          </Box>
        </TableCell>
        <TableCell>
          {item.description}
        </TableCell>
        <TableCell align='center'>
          {Number(item.stockOnHandQuantity).toFixed(2)}
        </TableCell>
        <TableCell align='center'>
          {Number(item.maximumQuantity).toFixed(2)}
        </TableCell>
        {hasPermissionToWriteMadeInStore && <TableCell align='center'>
          <Button size='md'
            onClick={onCreate}
            disabled={(item.maximumQuantity ?? 0) <= 0}
          >
            Create
          </Button>
        </TableCell>}
      </TableRow>
              
      <TableRow>
        <TableCell colSpan={5}
          sx={{ p: 0, m: 0, border: 0 }}>
          <Collapse in={isExpanded}>
            <MadeInStoreIngredients
              ingredients={item.madeInStoreComponents ?? []}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MadeInStoreItemRow;