import { Paper, Typography } from '@mui/material';
import { SupportData } from '../interfaces/SupportData';

const SupportDeatils = (props: {data: SupportData}) => {
  const data = props.data;

  return <Paper sx={{ p:10 }}>
    <Typography variant='textXL'
      fontWeight='bold'>Overview</Typography>
    <Typography variant='textMD'>
      <div dangerouslySetInnerHTML={{ __html: data.overview }} />
    </Typography>
    <Typography variant='textXL'
      fontWeight='bold'>Key Features</Typography>
    <Typography variant='textMD'>
      <ul>
        {data.keyFeatures.map((kf) => <li key={kf}>{kf}</li>)}
      </ul>
    </Typography>

    <Typography variant='textXL'
      fontWeight='bold'>User Guide<br/></Typography>
    {data.userGuide.map((guide) => (
      <>
        <Typography variant='textMD'
          fontWeight='bold'
          key={guide.guideTitle}>{guide.guideTitle}</Typography>
        <Typography variant='textMD'>
          <div dangerouslySetInnerHTML={{ __html: guide.guideContent }} />
        </Typography>
      </>
    ))}
  </Paper>;
};

export default SupportDeatils;