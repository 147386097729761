import { PaletteColor, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CreditReportsDenseTable from './CreditRequestTable';
import { CreditRequestRAGRecord } from '../types/CreditRequestRAGRecord';
import { useTranslation } from 'react-i18next';

const TitleArea = (text: string | JSX.Element) => {
  return (
    <Box
      sx={{
        display: 'flex',
        py: '20px',
        px: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
      }}
    >
      <Typography
        variant="textLG"
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      >
        {text}
      </Typography>
    </Box>);
};



const CreditRequestDropDownBox = (props: {
  text: string;
  palette: PaletteColor;
  list: CreditRequestRAGRecord[] | undefined;
}) => {

  const { t } = useTranslation('common');

  let titleAreaContent;
  if (props.list === undefined) {
    titleAreaContent = TitleArea(t('Loading') + '...');
  } else if (props.list?.length === 0) {
    titleAreaContent = TitleArea(t('noData'));
  } else {
    titleAreaContent = (
      <>
        <Box
          sx={{
            display: 'flex',
            py: '20px',
            px: '16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
          }}
        >
          <Typography
            variant="textLG"
            sx={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
            }}
          >
            {props.text}
            <Box
              sx={{
                display: 'flex',
                p: '2px 8px',
                alignItems: 'center',
                borderRadius: '16px',
                border: '1px solid ',
                borderColor: props.palette[200],
                background: props.palette[50],
              }}
            >
              <Typography variant="textXS"
                sx={{ color: props.palette[700] }}>
                {props.list?.length} {t('items')}
              </Typography>
            </Box>
          </Typography>
        </Box>
        <CreditReportsDenseTable list={props.list}/>
      </>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderTopColor: props.palette[400],
        borderTopWidth: '4px',
        borderTopStyle: 'solid',
        borderRadius: '0px 0px 40px 40px',
      }}
    >
      {titleAreaContent}
    </Box>
  );
};

export default CreditRequestDropDownBox;
