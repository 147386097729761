import { Alert, Box, Button, Grid, Paper, Snackbar, SvgIcon, TextField, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import CustomPagination from '../../../../components/forms/Pagination';
import { CreateMadeInStoreItemRequestDto, MadeInStoreItem } from '../../../../app/services/api/generated';
import MadeInStoreList from './MadeInStoreList';
import { theme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../components/shared/useUser';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import useLogError from '../../../../hooks/useLogError';
import CreateRecipeDialog from '../CreateRecipeDialog';

const MadeInStoreTab: FC = () => {
  const { t } = useTranslation('recipe');
  const { selectedStore } = useContext(UserContext);
  const { getMadeInStore, createMadeInStore } = useNswagClient();
  const { logError } = useLogError();
  const [madeInStoreList, setMadeInStoreList] = useState<MadeInStoreItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ page, setPage ] = useState<number>(0);
  const [ searchTerm, setSearchTerm ] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);
  const [ isSubmittedSuccessfully, setIsSubmittedSuccessfully ] = useState(0);
  const [ showSubmitStatusMessage, setShowSubmitStatusMessage ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const rowsPerPage = 10;

  const maxPages = () => {
    return Math.ceil(totalItems / rowsPerPage);
  };

  useEffect(() => {
    loadData(page, searchTerm);
  }, [page, isSubmittedSuccessfully]);

  const onSearch = () => {
    setPage(0);
    loadData(page, searchTerm);
  };

  const comparator = (property: keyof MadeInStoreItem, isAsc: boolean) => (a: MadeInStoreItem, b: MadeInStoreItem): number => {
    if (typeof a[property] === 'string' || a[property] instanceof Date) {
      return isAsc
        ? String(a[property]).localeCompare(String(b[property]))
        : String(b[property]).localeCompare(String(a[property]));
    } else {
      return isAsc ? Number(a[property]) - Number(b[property]) : Number(b[property]) - Number(a[property]);
    }
  };

  const toSort = (arr: MadeInStoreItem[], compareFn: (a: MadeInStoreItem, b: MadeInStoreItem) => number): MadeInStoreItem[] => {
    return arr.slice().sort(compareFn);
  };

  const handleSort = (orderByProp: keyof MadeInStoreItem) => {
    const isAsc = orderBy === orderByProp && order === 'asc';
    const sortedData = toSort(madeInStoreList, comparator(orderByProp, isAsc));
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(orderByProp);
    setMadeInStoreList(sortedData);
  };

  const loadData = (page: number, searchBy: string) => {
    setIsLoading(true);
    const totalSkip = page * rowsPerPage;
    if (selectedStore) {
      getMadeInStore(searchBy, selectedStore.storeNumber, totalSkip, rowsPerPage).then((result) => {
        if (result?.data) {
          setMadeInStoreList(result.data);
          setTotalItems(result.totalCount ?? 0);
        }
      })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const resetFilters = () => {
    setPage(0);
    setSearchTerm('');
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const onClear = () => {
    resetFilters();
    loadData(0, '');
  };

  const onSubmit = (recipe: CreateMadeInStoreItemRequestDto) => {
    setIsUpdating(true);

    createMadeInStore(recipe)
      .then(() => {
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .finally(() => {
        setIsUpdating(false);
        setShowSubmitStatusMessage(true);
      });
  };


  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignContent: 'center', my: '24px', py: '5px' }}>
      <Box sx={{ px: '12px' }}>
        <Box mb={6}
          textAlign='left'
          flexDirection='row'
          alignItems='center'>
          <Typography variant='textLG'>{t('madeInStore')}</Typography>
          <Typography variant='textXS'
            sx={{ border: `1px solid ${theme.palette.primary[400]}`, py: 2, px: 3, mx: 3, borderRadius: '6px', color: theme.palette.common.white, background: theme.palette.primary[500] }}>
            {totalItems} {t('items')}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          my={5}
        >
          <Grid item
            xs={12}
            md={4}>
            <TextField
              label={t('search')}
              size='small'
              value={searchTerm}
              onChange={(event) => handleSearchChange(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item
            container
            xs={12}
            md={8}
            spacing={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <Button
                size='lg'
                onClick={onClear}
                variant='secondary'
                sx={{ mr: 4 }}
              >
                <Typography>{t('clear')}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button size='lg'
                onClick={onSearch}
                startIcon={<SvgIcon><Search /></SvgIcon>}>
                <Typography>{t('search')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {
        isLoading ? (
          <LoadingWheel />
        ) : 
          <>
            <MadeInStoreList
              list={madeInStoreList}
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={maxPages()}
              breakpointForChangeDisplay={120000}
            ></CustomPagination>
          </>
      }
      <CreateRecipeDialog
        isUpdating={isUpdating}
        onCreateConfirm={onSubmit}
        onUpdateConfirm={() => {}}
        isComplete={isSubmittedSuccessfully}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSubmitStatusMessage}
        onClose={() => setShowSubmitStatusMessage(false)}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowSubmitStatusMessage(false)}
          severity={isSubmittedSuccessfully ? 'success' : 'error'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {isSubmittedSuccessfully ? t('successMessage') : t('errorMessage') + ' ' + errorMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default MadeInStoreTab;