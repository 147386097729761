import { Invoice, InvoiceItem } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { invoiceItemStatus } from '../interfaces/InvoiceStatus';
import { theme } from '../../../theme';
import { InvoiceStatus } from '../../../pages/Invoices/interfaces/InvoiceStatus';
import { compare } from '../../../utils';

export const useInvoiceDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation('invoiceDetails');
  const { getInvoice, postInvoice, postInvoiceItem } = useNswagClient();
  const navigate = useNavigate();

  const [data, setData] = useState<Invoice>();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceNote, setInvoiceNote] = useState('');
  const [note, setNote] = useState('');
  const [acceptId, setAcceptId] = useState<number>();
  const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false);
  const [isShowProcessingAlert, setIsShowProcessingAlert] = useState(false);
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [processingMessage, setProcessingMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof InvoiceItem>('itemNumber');
  const isAsc = order === 'asc';

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = () => {
    setIsLoading(true);
    getInvoice(Number(id)).then((result) => {
      setData(result.data);
      setInvoiceNote(result.data?.note ?? '');
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const closeSuccessAlert = () => {
    setIsShowSuccessAlert(false);
  };
  
  const closeProcessingAlert = () => {
    setIsShowProcessingAlert(false);
  };

  const closeErrorAlert = () => {
    setIsShowErrorAlert(false);
  };

  const isCreditNote = data?.invoiceType == 'CreditNote';

  const handleNoteButtonClick = (note: string, passedId: number) => () => {
    setNote(note);
    setIsOpenNoteModal(!isOpenNoteModal);
  };

  const handleAcceptButtonClick = (itemId: number) => () => {
    setAcceptId(itemId);
    setIsOpenAcceptModal(!isOpenAcceptModal);
  };

  const handleEditNoteButtonClick = (note: string, passedId: number) => () => {
    setNote(note);
    setOpenEditNoteModal(!openEditNoteModal);
    setItemId(passedId);
  };

  const closeEditNoteModal = () => {
    setOpenEditNoteModal(false);
  };

  const closesNoteModal = () => {
    setIsOpenNoteModal(false);
  };

  const closesAcceptModal = () => {
    setIsOpenAcceptModal(false);
  };
  const updateItemNote = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data){
      const updatedItems = data?.invoiceItems?.map(item => {
        if (item.id === id) {
          return { ...item, note: event.target.value }; // Update the note property
        }
        return item;
      });
      const updatedData = data;
      updatedData.invoiceItems = updatedItems;
      setData(updatedData); 
      setNote(event.target.value);
    }
  };

  const changeInvoiceNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNote(event.target.value);
  };

  const acceptItem = (itemId: number) => {
    const noteToUse = data?.invoiceItems?.find(x => x.id == itemId)?.note;
    const body = {
      'accepted': true,
      'note': noteToUse,
    };

    setProcessingMessage(t('proccessingMessageForItem'));
    setSuccessMessage(t('successMessageForItem'));
    setIsShowProcessingAlert(true);
    postInvoiceItem(data?.id ?? -1, itemId, body)
      .then(() => {
        setIsShowSuccessAlert(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(t('errorMessageForItem') + ' : ' + e);
        setIsShowErrorAlert(true);
        setIsOpenAcceptModal(false);
      })
      .finally(() => {
        setIsOpenAcceptModal(false);
        loadData();
        setIsShowProcessingAlert(false);
      });
  };

  const rowSxPropToUse = (status: string) => {
    if (status == invoiceItemStatus.Failed || status == invoiceItemStatus.QuantityMismatch || status == invoiceItemStatus.PriceMismatch){
      return { backgroundColor: theme.palette.error[50] };
    }
    if ( status === invoiceItemStatus.Pending){
      return { backgroundColor: theme.palette.warning[50] };
    }
    if ( status === invoiceItemStatus.Matched || status === invoiceItemStatus.Accepted){
      return { backgroundColor: theme.palette.success[50] };
    }
    return { };
  };

  const saveInvoice = () => {
    const body = { accepted: true, note: invoiceNote };

    setProcessingMessage(t('processingMessage'));
    setSuccessMessage(t('successMessage'));
    setIsShowProcessingAlert(true);
    postInvoice(Number(id), body)
      .then(() => {
        setIsShowSuccessAlert(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(t('errorMessage') + ' ' + e);
        setIsShowErrorAlert(true);
      })
      .finally(() => {
        loadData();
        setIsShowProcessingAlert(false);
      });
  };

  const canEdit = data?.status !== InvoiceStatus.Accepted;

  const handleSort = (orderBy: keyof InvoiceItem) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(orderBy);
  };

  const sorteditems = data?.invoiceItems?.sort((a, b) => compare(a, b, orderBy, order));

  return {
    t,
    data,
    navigate,
    note,
    itemId,
    isCreditNote,
    handleEditNoteButtonClick,
    handleNoteButtonClick,
    closeEditNoteModal,
    closesNoteModal,
    updateItemNote,
    acceptItem,
    openEditNoteModal,
    rowSxPropToUse,
    saveInvoice,
    invoiceNote,
    changeInvoiceNote,
    canEdit,
    isOpenNoteModal,
    isLoading,
    sorteditems,
    order,
    orderBy,
    handleSort,
    isShowSuccessAlert,
    isShowProcessingAlert,
    isShowErrorAlert,
    errorMessage,
    processingMessage,
    successMessage,
    isOpenAcceptModal,
    closeSuccessAlert,
    closeProcessingAlert,
    closeErrorAlert,
    handleAcceptButtonClick,
    closesAcceptModal,
    acceptId,
  };
};