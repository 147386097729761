import { useEffect, useState } from 'react'; 
import {  Badge, Tabs, Tab, Card, CardContent,   CardHeader } from '@mui/material';
import { client } from '../../../app/services/api/orderManagementClient';
import { theme } from '../../../theme';
import NotificationsNews from './NotificationsNews';
import NotificationsOutOfStockActionRequired from './NotificationsOutOfStockActionRequired'; 
import { TabEnum } from '../enums/NotificationEnums';
import useLogError from '../../../hooks/useLogError';
import { useTranslation } from 'react-i18next';
import NotificationsUrgentActionRequired from './NotificationsUrgentActionRequired';
import useLocalStorage from '../../../hooks/useLocalStorage';

const Notifications = (props: { storeNumber: string | undefined }) => {
  
  const { t } = useTranslation('common');
  const [ tabValue, setTabValue ] = useState<number>(TabEnum.News); 
  const [ outOfStockTabBadge, setOutOfStockTabBadge ] = useLocalStorage('outOfStockTabBadge', false);
  const [ urgentActionTabBadge, setUrgentActionTabBadge ] = useLocalStorage('urgentActionTabBadge', false);
  const [ outOfStockLastText, setOutOfStockLastText ] = useLocalStorage('outOfStockLastText', '');
  const [ urgentActionLastText, setUrgentActionLastText ] = useLocalStorage('urgentActionLastText', '');
  const { logError } = useLogError();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {    
    client.GetOutOfStockAlert(props.storeNumber).then((ordersOutOfStockResult) => { 
      if(ordersOutOfStockResult.data.context != null)
      {
        if (outOfStockLastText != ordersOutOfStockResult.data.context)
        {
          setOutOfStockTabBadge(true);
        }
        setOutOfStockLastText(ordersOutOfStockResult.data.context);
      }
    },
    ).catch((error) => {
      logError('Error fetching data:' + error); 
    });
  
    client.GetUrgentAlerts(props.storeNumber).then((ordersUrgentAlerts) => {  
      if(ordersUrgentAlerts.data.context != null)
      {
        if (urgentActionLastText != ordersUrgentAlerts.data.context)
        {
          setUrgentActionTabBadge(true);
        }
        setUrgentActionLastText(ordersUrgentAlerts.data.context);
      }
    }).catch((error) => {
      logError('Error fetching data:' + error);
        
    });
  }, []);

  return (
    <Card sx={{
      background: theme.palette.common.white,
      borderRadius: '12px',
      border: `1px solid ${theme.palette.custom.gray[200]}`,
      boxShadow: theme.shadows[1],
    }}>
      <CardHeader sx={{ p:'24px', pb:'16px', textAlign:'left' }}
        title={
          <Tabs value={tabValue}
            onChange={handleChange}>
            <Tab label= {t('notificationNewsTab')}
              value={TabEnum.News} />
            <Tab label={urgentActionTabBadge? (
              <Badge
                badgeContent=""
                variant="dot"
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {t('notificationUrgentActionTab')} 
              </Badge>
            ): t('notificationUrgentActionTab') }
            value={TabEnum.UrgnetAlerts}/>
            <Tab label={outOfStockTabBadge? (
              <Badge
                badgeContent=""
                variant="dot"
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {t('notificationOutOfStockTab')} 
              </Badge>
            ): t('notificationOutOfStockTab')}
            value={TabEnum.OutOfStock}/>
          </Tabs>
        }
      />
      <CardContent sx={{ px: '24px', py: '0px !important', maxHeight: '500px', overflowY: 'auto' }}>
        {tabValue === TabEnum.News && <NotificationsNews  storeNumber={props?.storeNumber} />} 

        {tabValue === TabEnum.OutOfStock && <NotificationsOutOfStockActionRequired storeNumber={props?.storeNumber} 
          tabEnum={tabValue}
          setReadToTrue={setOutOfStockTabBadge}/>}

        {tabValue == TabEnum.UrgnetAlerts && <NotificationsUrgentActionRequired storeNumber={props?.storeNumber}
          tabEnum={tabValue}
          setReadToTrue={setUrgentActionTabBadge}/>}
      </CardContent>
    </Card>
  );
};

export default Notifications;