import { palette } from '../palette';

export const MuiTab = {
  styleOverrides: {
    root: {
      borderRadius: '8px 8px 0px 0px',
      color: palette.custom.gray[700],
      backgroundColor: palette.custom.gray[200],

      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      paddingRight: '12px',
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: 'none',
      fontWeight: 500,
      marginRight: '4px',
      '&:hover':{
        color: palette.custom.gray[900],
        backgroundColor: palette.custom.gray[300],
      },
      '&.Mui-selected': {
        color: palette.primary[700],
        backgroundColor: palette.primary[50],
        fontWeight: 600,
        '&:hover':{
          color: palette.primary[800],
          backgroundColor: palette.primary[100],
        },
      },
    },
  },
};

export const MuiTabs = {
  defaultProps: {
    scrollButtons: 'auto',
    allowScrollButtonsMobile: true,
    fullWidth: true, 
  },
  styleOverrides: {
    indicator: {
      // display: 'none',
    },
  },
};