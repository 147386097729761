export const transactionData = {
  title: 'Transaction',
  overview: `
        <p>The Transaction page provides a full list of all material transactions per item to support audit trail visibility. It integrates with other areas of the application to update Stock levels and adjust pricing as soon as the Transaction is recorded.</p>
        <p>Transactions from the POS feed are handled as data comes in, while PO Receipt and Stock Count information will automatically create new Transactions once actions are confirmed within those areas. Manual Transactions can also be added to account for incoming (receipt) or outgoing (issue) stock, to fully cover every scenario.</p>
    `,
  keyFeatures: [
    'Automatically creates Transactions for PO Receipts and Stock Count data when processed.',
    'Manual transactions can be made to receive and issue items, with BoM items restricted to issue only.',
    'BoM transaction will adjust their components based on component usage quantity on the BoM.',
    'Users can create new Transactions Types and Reasons to use in manual transactions for issue or receive.',
    'All material Transactions automatically update the item Stock on Hand levels.',
    'All Transactions record the quantity and UoM used, as well as the converted quantity for Primary UoM.',
    'Manual receive transactions allow the User to edit the cost price for the transaction.',
    'Manual issue transactions are set to always uses the current cost price.',
    'All receipt transactions will recalculate the cost price based on the new data.',
    'Changes to the cost price become active from the time of the transaction.',
    'Creating manual transactions restricts the UoM’s available to those listed or orderable for the item.',
    'The Transaction list can be filtered by all relevant fields by using the search options.',
    'Sort and reverse sort available by column (e.g. Item No. or Date), and combines with search filters.',
    'Multiple filter options (Item, Type, Date range, Source etc.) to display specific transactions or groups.',
  ],
  userGuide: [
    {
      guideTitle: 'Transaction',
      guideContent: `
            <p>The Transactions page can be found under the Stock heading in the main menu. When first loading it will list all Transactions in the system for the current store being used ordered by date, with the most recent at the top of the list.</p>
            <p>As with other pages, there a number of filter options above the main list to determine which Transactions are displayed. These are all optional, and can be used in combination with one another, and individually cleared if an entry exists via the red “x”. The Clear button will remove all field entries.</p>
            <p>The one difference in the filter options is the Store selection. As the Transactions list only displays data for one Store at a time, this can be used to select any Store the User has access to (where applicable). It therefore removes the requirement for them to change the Store they are using  in order to view other Transactions.</p>
            <p>The majority of columns can be sorted - indicated by the arrow against the title - by clicking the heading, and reverse-sorted by clicking again. Above the main list of Transaction is an “Exclude Product Transactions” tick-box which is un-ticked by default. This means any BoM items will be shown in the list with a blue “+” to expand the view and show the resulting ingredient Transactions.</p>
            <p>If you tick it, the Transactions list excludes these BoM items from display, and replaces them in the list with all their individual component Transactions (previously only visible by expanding the BoM view individually), alongside the other non-BoM transactions which are unchanged.</p>
            <p>Other points on the main list display are that in the Transaction Type column a small blue globe icon is displayed against any Manual Transactions (this is where the Source is Manual, regardless of the actual Transaction Type used). The right-hand column shows any notes recorded against the Transaction - the “i” icon will be highlighted if a note exists, and can be viewed and hidden by clicking on the icon.</p>
            <p>Creating Manual Transactions can be done by clicking the Create Transaction button above the main list. This will open a new pop-up window asking you to select the Type of Transaction (Issue or Receipt), for whether Stock is leaving or entering, and then use the look-up to search and find the item you want to create a transaction for. Note that Recipes, and other BoM items, can’t be selected if the Transaction Type is Receive.</p>
            <p>Once an item has been chosen, the view will expand to allow you to complete details for Quantity, and UoM, Actual Cost (shown for Receive Transactions only - Issue Transaction uses the current cost price for the item), Transaction Reason (automatically shows valid options, and may be required - depending on Transaction Type), and Note which is an optional text field.</p>
            <p>Note when filling in these fields that the Quantity applies to the selected UoM (rather than the Primary UoM), and the value entered in the Actual Cost field applies to 1 of the selected UoM. If the cost is left blank it will use the current Cost Price of the item. The value entered will be converted to the Primary UoM value when processing the Transaction - so the the cost displayed on the Transactions list is always for 1 of the Primary UoM, as is therefore consistent for future comparisons.</p>
            <p>Clicking Save will create the Transaction, or return a message to complete details if any required data is missing.</p>
        `,
    },
    {
      guideTitle: 'Transaction Management',
      guideContent: `
                <p>The Transaction Management setup is available in the Admin menu (shown in the blue header bar) for Users with the required authorisation. It allows you to create, edit or delete Transaction Types and Transaction Reasons on a Franchise level. Several ‘system’ Transaction Types are included by default to cover the non-manual transactions (e.g. POS Sale), and these are non-editable.</p>
                <p>The Create New Transaction Type button can be used to set up new Transaction Types for use with manual transactions made within the application. It will open a new pop-up window and lets you set a name, description, and action type - Issue or Receive (so it will only available to those types of transaction). There is also a Reason Required tick-box which, when ticked, means that the Transaction Reason field will be mandatory whenever you use this Transaction Type.</p>
                <p>Any manual types Transaction Types you create can be edited (name is non-editable) or deleted, and once they have been saved, will be added to the list, and automatically be included in the available options whenever you create a new Transaction. Note that the non-editable ‘system’ Transaction Types are not options when you create a new transaction.</p>
                <p>Underneath the list of Transaction Types is the list of Transaction Reasons, and you can use the Create New Transaction Reason button to set up new Transaction Reasons for use with manual transactions made within the application. It will open a new pop-up window and lets you set a name, description, and action type - Issue or Receive (so it will only available to those types of transaction).</p>
                <p>Any Transaction Reasons you create can be edited (name is non-editable) or deleted, and once they have been saved, will be added to the list, and automatically be included in the available options whenever you create a new Transaction. Note they are not always required when creating a Transaction (this depends on whether the Reason Required field is set on the Transaction Type being used), and the available options will be filtered by the Action type (Issue or Receive), so Type and Reason match.</p>
            `,
    },
  ],
};
