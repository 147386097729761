/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Link, Button, Grid, TextField, Typography, Box } from '@mui/material';
import { isEmailValid } from '../../../utils';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';

const ForgotPassword = (props: {switchDisplay: () => void}) => {
  const { t } = useTranslation('authentication');
  const { resetPassword } = useNswagClient();
  const [ email, setEmail ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const isFormValid = () => {
    if (!email) {
      setEmailError('Email is required');
      return false;
    }

    if (!isEmailValid(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    }

    return true;
  };

  const sendResetPasswordRequest = () => {
    resetPassword({ emailAddress: email })
      .then(() => {
        setShowMessage((showMessage) => !showMessage);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid()) {
      sendResetPasswordRequest();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', width: '360px' }}>
      <Typography variant="displaySM"
        sx= {{ textAlign: 'start' }}>{t('forgotPasswordTitle')}</Typography>

      {!showMessage && <Box>
        <Typography
          variant="textMD"
          sx={{ textAlign: 'start', pt: '12px' }}>
          {t('forgotPasswordMessage')}
        </Typography>
        <TextField
          sx={{ pt: '12px' }}
          value={email}
          onChange={handleEmailChange}
          variant="outlined"
          placeholder='Email'
          error={!!emailError}
          helperText={emailError}
          fullWidth
          required/>
        <Grid sx={{ pt: '12px' }}>
          <Button
            type='submit'
            variant="primary"
            fullWidth
            onClick={handleSubmit}>
            {t('forgotPasswordSubmit')}
          </Button>
        </Grid>
      </Box>}

      {showMessage && <Box>
        <Typography
          variant="textMD"
          sx= {{ textAlign: 'start', pt: '12px' }}>
          {t('forgotPasswordSuccess', { email })}
        </Typography>
      </Box>}

      <Link sx={{ textDecoration: 'none', pt: '12px' }}
        onClick={props.switchDisplay}
        href="#" >
        {t('forgotPasswordReturnToLogin')}
      </Link>        
    </Box>
  );
};

export default ForgotPassword;