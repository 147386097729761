import { Box, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import PendingChangesFilters from './components/PendingChangesFilters';
import PendingChangesTable from './components/PendingChangesTable';
import { PendingChangesContext, PendingChangesValues } from '../../contexts/PendingChangesContext';

export default function PendingChanges() {
  const { t, isLoading } = useContext(PendingChangesContext) as PendingChangesValues;

  return (
    <Paper>
      <Box sx={{ p: '8px' }}>
        <Typography variant='displayXS'>{t('pendingChanges')}</Typography>
      </Box>
      <PendingChangesFilters></PendingChangesFilters>
      {isLoading ? <LoadingWheel/> : 
        <PendingChangesTable></PendingChangesTable>}
    </Paper>

  );
}
