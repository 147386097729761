import { Popover, Box, Typography, IconButton } from '@mui/material';
import { DotsVertical } from '../../../assets';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { FC, useState } from 'react';
import useLogError from '../../../hooks/useLogError';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/ui/Loader';

type Props = {
  stockCheckId: number;
  isCompleted: boolean;
}

const StockCheckExport = ({ stockCheckId, isCompleted }: Props) => {
  const { exportStockCheck, getStockCountReportById, getExtendedStockCheckReport } = useNswagClient();
  const { logError } = useLogError();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ isExporting, setIsExporting ] = useState<boolean>(false);
  const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
  const [ isDownloadingPdf, setIsDownloadingPdf ] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const exportFile = (data: Blob, type: string, title: string) => {
    const blob = new Blob([data], { type: type });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = title;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  const exportStockCount = (): void => {
    setIsExporting(true);
    exportStockCheck(stockCheckId).then((response) => {
      if (response) {
        exportFile(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Stock-Check-${stockCheckId}.xls`);
      }
    }).catch((error) => {
      logError(error);
    }).finally(() => {
      setIsExporting(false);
      handleClose();
    });
  };

  const downloadFullReport = (): void => {
    setIsDownloading(true);

    getStockCountReportById(stockCheckId).then((response) => {
      if (response) {
        exportFile(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Stock-Check-Full-Report-${stockCheckId}.xls`);
      }
    }).catch((error) => {
      logError(error);
    }).finally(() => {
      setIsDownloading(false);
      handleClose();
    });
  };

  const downloadExtendedReportAsPdf = (): void => {
    setIsDownloadingPdf(true);

    getExtendedStockCheckReport(stockCheckId).then((response) => {
      if (response) {
        exportFile(response.data, 'application/pdf', `Stock-Check-Full-Report-${stockCheckId}.pdf`);
      }
    }).catch((error) => {
      logError(error);
    }).finally(() => {
      setIsDownloadingPdf(false);
      handleClose();
    });
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <DotsVertical />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={4}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <ExportOption label='export'
            onClick={exportStockCount}
            isExporting={isExporting} />
          {isCompleted && <ExportOption label='downloadFullReport'
            onClick={downloadFullReport}
            isExporting={isDownloading} />}
          {isCompleted && <ExportOption label='downloadExtendedReport'
            onClick={downloadExtendedReportAsPdf}
            isExporting={isDownloadingPdf} />}
        </Box>
      </Popover>
    </>
  );
};

type ExportOptionProps = {
  label: string;
  onClick: () => void;
  isExporting: boolean;
}

const ExportOption: FC<ExportOptionProps> = ({ label, onClick, isExporting }) => {
  const { t } = useTranslation('stockCountReview');

  return (
    <Typography
      variant="textMD"
      display='flex'
      flexDirection='row'
      alignItems='center'
      sx={{ p: 3, cursor: 'pointer', pointerEvents: isExporting ? 'none' : '' }}
      onClick={onClick}
    >
      {t(label)}
      {isExporting && <Loader />}
    </Typography>
  );
};

export default StockCheckExport;