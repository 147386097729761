import {  Button, Typography, Dialog, DialogContent,  DialogActions, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StockCountCreateContext, StockCountCreateValues } from '../../contexts/StockCountCreateContext';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const StockCountCreateDeleteItemsModal=() => {
  const { isDeleteModalOpen,
    handleClose,
    deleteItems,
    itemsData,
    itemNumber } = useContext(StockCountCreateContext) as StockCountCreateValues;

  const { t } = useTranslation('createStockCount');

  const handleConfirm = () => {
    if(itemNumber !== undefined){
      const data = itemsData?.filter(item  => item.id !== itemNumber );
      deleteItems(data);
    }
    else{
      deleteItems([]);
    }
  };

  return (
    <Dialog open={isDeleteModalOpen}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {t('deleteItem')}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('approvalDialogItemsMessage')}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}> {t('cancel')}</Button>
        <Button variant="bad"
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCountCreateDeleteItemsModal;