import { Grid, FormControl, TextField } from '@mui/material';
import { useContext } from 'react';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';

const InvoiceDetailsFeilds = () => {
  const { t, data, invoiceNote, changeInvoiceNote } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  return <Grid container
    spacing={5}
    sx={{
      py: '12px',
      px: '8px',
    }} >
    <Grid item
      container
      spacing={5}
      md={9}
      xs={12}>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField label={t('orderNumber')}
            disabled
            InputLabelProps={{ shrink:true }}
            value={data?.orderNumber ?? ''}/>
        </FormControl>
      </Grid>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField 
            label={t('invoiceDate')}
            disabled
            InputLabelProps={{ shrink:true }}
            value={data?.invoiceDate?.split('T')[0] ?? ''}/>
        </FormControl>
      </Grid>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField 
            label={t('deliveryDate')}
            disabled
            InputLabelProps={{ shrink:true }}
            value={data?.deliveryDate?.split('T')[0] ?? ''}/>
        </FormControl>
      </Grid>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField label={t('invoiceValue')}
            disabled
            InputLabelProps={{ shrink: true }}
            value={data?.invoiceValue ?? ''}/>
        </FormControl>
      </Grid>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField label={t('vatValue')}
            disabled
            InputLabelProps={{ shrink: true }}
            value={data?.vatValue ?? ''}/>
        </FormControl>
      </Grid>
      <Grid item
        md={4}
        sm={6}
        xs={12}>
        <FormControl fullWidth>
          <TextField label={t('invoiceTotal')}
            disabled
            InputLabelProps={{ shrink: true }}
            value={data?.invoiceTotal ?? ''}/>
        </FormControl>
      </Grid>
    </Grid>
    <Grid item
      md={3}
      xs={12}>
      <FormControl fullWidth>
        <TextField multiline
          InputLabelProps={{ shrink: true }}
          minRows={3}
          label={t('note')}
          onChange={changeInvoiceNote}
          value={invoiceNote}/>
      </FormControl>
    </Grid> 
  </Grid>;
};

export default InvoiceDetailsFeilds;