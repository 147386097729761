import { Box, Button, Grid, SvgIcon, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { GetTransfersResponse } from '../../app/services/api/generated';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import { Plus } from './../../assets';
import { StockTransfersFilterOptions } from './interfaces/StockTransfersFilterOption';
import { StockTransfersTab } from './enums/StockTransfersTab';
import useNswagClient from '../../hooks/api/useNswagClient';
import StockTransfersBox from './components/StockTransfersBox';
import SubmitModal from './components/StockTransfersCreateModal';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import useLogError from '../../hooks/useLogError';

const StockTransfersPage = () => {
  const [stockTransfers, setStockTransfers] = useState<GetTransfersResponse>({});
  const [activeTab, setActiveTab] = useState<StockTransfersTab>(StockTransfersTab.ACTIVE);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation('stockTransfers');
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const { getTransfers } = useNswagClient();
  const { logError } = useLogError();

  useEffect(() => {
    loadFilterData({     
      transferNumber: undefined,
      transferType: '',
      requestedDate: '',
      sort: 'transferDate',
      direction: 'desc',
      skip: 0,
      take: 10,
    });
  }, [activeTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: StockTransfersTab) => {
    setActiveTab(newValue);
  };

  const handleConfirmModal = async () => {
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };
  
  const loadFilterData = (options: StockTransfersFilterOptions) => {
    const {
      transferNumber,
      transferType,
      requestedDate,
      sort,
      direction,
      skip,
      take,
    } = options;

    const storeNumber = selectedStore?.storeNumber;

    getTransfers(storeNumber, transferNumber, transferType, requestedDate, sort, direction, skip, take, activeTab !== StockTransfersTab.ACTIVE )
      .then((result) => {
        if (result?.data) {
          setStockTransfers(result.data);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  return (
    <PageArea>
      <Grid container>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>{t('title')}</PageTitle>        
        </Grid>
        <Grid
          item
          md={8}
          xs={8} >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="stock-transfers-tabs">
            <Tab
              label={t('available')}
              value={StockTransfersTab.ACTIVE} />
            <Tab
              label={t('archived')}
              value={StockTransfersTab.ARCHIVED} />
          </Tabs>
        </Grid>
        <Grid
          item
          md={4}
          xs={4}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {(hasPermissionTo(['IncomingTransferWrite']) || hasPermissionTo(['OutgoingTransferWrite'])) &&
          <Button
            variant='primary'
            size='lg'
            sx={{ ml: 4 }}    
            onClick={() => handleConfirmModal()}       
            startIcon={<SvgIcon><Plus fill='white' /></SvgIcon>}>{t('create')}
          </Button>}
        </Grid>

        <Grid item
          xs={12}
          sx={{ pt: '20px' }}>
          {stockTransfers &&
            <StockTransfersBox stockTransfers={stockTransfers}
              loadData={loadFilterData}
              activeTab={activeTab}
            />
          }
        </Grid>
      </Grid>
      <SubmitModal openModal={openModal}
        onCancel={handleCancelModal}></SubmitModal>
    </PageArea>
  );
};

export default StockTransfersPage;