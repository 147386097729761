import { Box, Collapse, Radio, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ArrowDown, ArrowUp, Minus, Plus } from '../../../assets';
import UoMDetailsTable from './UoMDetailsTable';
import { useTranslation } from 'react-i18next';
import { TempCategoryItem, TempStockCheckItem } from '..';
import { applyRoundAndFormat } from '../../../utils';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';

type Props = {
  category: TempCategoryItem;
  categoryIndex: number;
  onItemStatusChange?: (itemId: number | undefined, checked: boolean) => void;
  sortPartialStockCountItems: (categoryIndex: number, fieldToSort: keyof TempStockCheckItem, sortDirection: 'asc' | 'desc') => void;
  togglePartialStockCountItem: (itemNumber: string | undefined) => void;
  isDisabled: boolean;
  isViewMode?: boolean;
  isOpenedInDialog?: boolean;
}

const StockCheckCategoryTable: FC<Props> = ({ category, categoryIndex, onItemStatusChange, sortPartialStockCountItems, togglePartialStockCountItem, isDisabled, isViewMode, isOpenedInDialog }) => {
  const { t } = useTranslation('stockCountReview');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  const initialSortByList = [
    { id: 'itemNumber', name: 'ItemNumber', is_active: false },
    { id: 'description', name: 'ItemNumber', is_active: false },
    { id: 'locationString', name: 'LocationString', is_active: false },
    { id: 'primaryUom', name: 'PrimaryUOM', is_active: false },
    { id: 'quantityOnHand', name: 'QuantityOnHand', is_active: false },
    { id: 'countQuantity', name: 'CountQuantity', is_active: false },
    { id: 'varianceQuantity', name: 'VarianceQuantity', is_active: false },
    { id: 'variancePercentage', name: 'VariancePercentage', is_active: false },
    { id: 'costPriceOnHand', name: 'CostPriceOnHand', is_active: false },
    { id: 'totalCostPrice', name: 'TotalCostPrice', is_active: false },
    { id: 'varianceValue', name: 'VarianceValue', is_active: false },
  ];

  const [ sortByList, setSortByList ] = useState(initialSortByList);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  const togglePartialOrderBy = (column: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let selectedSortItem: any = null;
    let sortDirection: 'asc' | 'desc' = 'asc';

    const updatedSortByList = sortByList.map((sortItem) => {
      if (sortItem.id === column) {
        selectedSortItem = sortItem;
        sortItem.is_active = !sortItem.is_active;
        sortDirection = sortItem.is_active ? 'asc' : 'desc';
      } else {
        sortItem.is_active = false;
      }

      return sortItem;
    });

    setSortByList(updatedSortByList);
    sortPartialStockCountItems(categoryIndex, selectedSortItem?.id ?? '', sortDirection);
  };

  const getVariancePercentage = (item: TempStockCheckItem) => {
    if((item.countQuantityTotal === 0 && (item.quantityOnHand ?? 0) > 0) || ((item.quantityOnHand ?? 0) < 0 && item.countQuantityTotal === 0)) return -100.00;

    if((item.countQuantityTotal ?? 0) > 0 && item.quantityOnHand === 0) return 100.00;

    if(item.countQuantityTotal === 0 && item.quantityOnHand === 0) return 0;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StockCheckCellHeader
              label='SKU Number'
              onClick={() => togglePartialOrderBy('itemNumber')}
              isActive={sortByList[0].is_active}
            />
            <StockCheckCellHeader
              label='SKU Description'
              onClick={() => togglePartialOrderBy('description')}
              isActive={sortByList[1].is_active}
            />
            <StockCheckCellHeader
              label='UoM'
              onClick={() => togglePartialOrderBy('primaryUom')}
              isActive={sortByList[3].is_active}
            />
            <StockCheckCellHeader
              label='System Qty'
              onClick={() => togglePartialOrderBy('quantityOnHand')}
              isActive={sortByList[4].is_active}
            />
            <StockCheckCellHeader
              label='Count Qty'
              onClick={() => togglePartialOrderBy('countQuantity')}
              isActive={sortByList[5].is_active}
            />
            <StockCheckCellHeader
              label='Variance Qty'
              onClick={() => togglePartialOrderBy('varianceQuantity')}
              isActive={sortByList[6].is_active}
            />
            <StockCheckCellHeader
              label='%'
              onClick={() => togglePartialOrderBy('variancePercentage')}
              isActive={sortByList[7].is_active}
            />
            <StockCheckCellHeader
              label='UoM Cost'
              onClick={() => togglePartialOrderBy('costPriceOnHand')}
              isActive={sortByList[8].is_active}
            />
            <StockCheckCellHeader
              label='Count Value'
              onClick={() => togglePartialOrderBy('totalCostPrice')}
              isActive={sortByList[9].is_active}
            />
            <StockCheckCellHeader
              label='Variance Value'
              onClick={() => togglePartialOrderBy('varianceValue')}
              isActive={sortByList[10].is_active}
            />
            {!isViewMode && <TableCell align="left">
              {t('approve')}
            </TableCell>
            }
            {!isViewMode && <TableCell align="left">
              {t('reject')}
            </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {category.items.map((row: TempStockCheckItem, i: number) => (
            <>
              <TableRow key={`${row.category}-${row.id}`}>
                <TableCell>
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    {
                      row.itemValues && row.itemValues.length > 1 && !isOpenedInDialog && (
                        <SvgIcon
                          onClick={() => togglePartialStockCountItem(row.itemNumber)}
                          sx={{ cursor: 'pointer' }}
                        >
                          {row.isExpanded ? <Minus /> : <Plus />}
                        </SvgIcon>
                      )
                    }
                    {row.itemNumber}
                  </Box>
                </TableCell>

                <StockCheckCellData value={row.description} />

                <StockCheckCellData value={row.primaryUom ?? '-'} />

                <StockCheckCellData value={row.quantityOnHand?.toFixed(2)}
                  title={row.quantityOnHand?.toString()}/>

                <StockCheckCellData value={row.countQuantityTotal?.toFixed(2)}
                  title={row.countQuantityTotal?.toString()} />

                <StockCheckCellData value={row.varianceQuantity?.toFixed(2) ?? '-'}
                  title={row.varianceQuantity?.toString()} />

                <StockCheckCellData value={row.variancePercentage?.toFixed(2) ?? getVariancePercentage(row)} 
                  title={row.variancePercentage?.toString()} />

                <StockCheckCellData value={applyRoundAndFormat(row.costPriceOnHand ?? 0, currencyCode)}
                  title={applyRoundAndFormat(row.costPriceOnHand ?? 0, currencyCode, 8)} />

                <StockCheckCellData value={applyRoundAndFormat(((row.countQuantityTotal ?? 0) * (row.costPriceOnHand ?? 0)), currencyCode)}
                  title={applyRoundAndFormat(((row.countQuantityTotal ?? 0) * (row.costPriceOnHand ?? 0)), currencyCode, 8)} />

                <StockCheckCellData value={applyRoundAndFormat((row.varianceValue ?? 0), currencyCode)}
                  title={applyRoundAndFormat((row.varianceValue ?? 0), currencyCode, 8)} />

                {!isViewMode && <TableCell>
                  <Radio
                    checked={row.isApproved === true}
                    onChange={() => {onItemStatusChange?.(row.id, true);}}
                    value="true"
                    name={`isApproved-${row.id}-${i}`}
                    disabled={isDisabled}
                  />
                </TableCell>
                }
                {!isViewMode && <TableCell>
                  <Radio
                    checked={row.isApproved === false}
                    onChange={() => {onItemStatusChange?.(row.id, false);}}
                    value="false"
                    name={`isApproved-${row.id}-${i}`}
                    disabled={isDisabled}
                  />
                </TableCell>
                }
              </TableRow>
              <TableRow>
                <TableCell colSpan={isViewMode ? 10 : 12}
                  sx={{ p: 0, m: 0, border: 0 }}>
                  <Collapse in={row.isExpanded}>
                    <UoMDetailsTable itemValues={row.itemValues} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type StockCheckCellHeaderProps = {
  label: string;
  onClick: () => void;
  isActive: boolean
}

const StockCheckCellHeader = ({ label, onClick, isActive } : StockCheckCellHeaderProps) => {
  const [ isCurrentActive, setIsCurrentActive ] = useState<boolean>(isActive);

  useEffect(() => {
    setIsCurrentActive(isActive);
  }, [isActive]);

  return (
    <TableCell
      sx={{ cursor: 'pointer' }}
      align="left"
      onClick={onClick}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
      >
        <Typography
          variant="textSM"
          fontWeight='bold'
        >
          {label}
        </Typography>
        <SvgIcon sx={{ width: '20px', pl: 1 }}>
          { isCurrentActive ? <ArrowUp /> : <ArrowDown /> }
        </SvgIcon>
      </Box>
    </TableCell>
  );
};

type StockCheckCellDataProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  title?: string;
}

const StockCheckCellData = ({ value, title } : StockCheckCellDataProps) => {
  return (
    <TableCell title={title}>
      {value ?? ''}
    </TableCell>
  );
};

export default StockCheckCategoryTable;
