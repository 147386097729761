import { Button, FormControl, Grid, SvgIcon, TextField } from '@mui/material';
import ScheduleTable from './ScheduleTable';
import { StockCheckScheduleDetail } from '../../../app/services/api/generated';
import { useContext, useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { PlusCircle } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../components/shared/useUser';

const MainBox = (props: { schedules: StockCheckScheduleDetail[], storeID: string, loadData: () => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('common');
  const { hasPermissionTo } = useContext(UserContext);
  const navigate = useNavigate();
  const hasRightToEdit: boolean = hasPermissionTo(['StockCountScheduleWrite']);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return <Grid container
    sx={{
      pt: '8px',
    }} >
    <Grid item
      xs={8}
      sx={{ pb: '8px' }}>
      <FormControl>
        <TextField label={t('search') + ':'}
          size='small'
          id="input-with-icon-textfield"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </FormControl>

    </Grid>
    <Grid item
      xs={4}
      sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button size='lg' 
        sx={{ ml: 4 }}
        onClick={() => navigate('/create-edit-schedule-stock-count')}
        startIcon={<SvgIcon><PlusCircle /></SvgIcon>}
        disabled={!hasRightToEdit}>{t('createNew')}</Button>
    </Grid>
    <Grid item
      xs={12} >
      <ScheduleTable list={props.schedules}
        storeID={props.storeID}
        loadData={props.loadData}
        searchTerm={searchTerm}></ScheduleTable>
    </Grid>
  </Grid>;
};

const StockCountSchedule = (props: { schedules: StockCheckScheduleDetail[], storeID: string, loadData: () => void, isLoading: boolean }) => {
  return props.isLoading ? <LoadingWheel></LoadingWheel> : <MainBox schedules={props.schedules}
    storeID={props.storeID}
    loadData={props.loadData}></MainBox>;
};

export default StockCountSchedule;