import useNswagClient from '../../../../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../components/shared/useUser';
import { ItemPossibleUOMs, ItemSelected } from '../interfaces/itemValueTypes';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../../hooks/useLogError';
import { CreateTransferRequest,
  CreateTransferRequest_CreateTransferRequestItem,
  Item,
  Store,
  Uom,
  ValidateItemQuantityRequest } from '../../../../../app/services/api/generated';
import { SelectChangeEvent } from '@mui/material';

export const useCreateStockTransfer = () => {
  const { getStores, getFranchiseStores, getTransferItems, getUoms, validateTransferItemQuantity, createTransfer } = useNswagClient();
  const { hasPermissionTo, selectedStore, user } = useContext(UserContext);
  const { logError } = useLogError();
  const [transferType, setTransferType] = useState<string | undefined>('');
  const [note, setNote] = useState('');
  const [itemsToCreate, setItemsToCreate] = useState<ItemSelected[]>([]);
  const [itemPossibleUOMs, setItemPossibleUOMs] = useState<ItemPossibleUOMs[]>([]);
  const [isValidatingItemQuantity, setIsValidatingItemQuantity] = useState<boolean>(false);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState<boolean>(false);
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNoteDisabled, setIsNoteDisabled] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState<string | null>(null);
  const [formSelectedStore, setFormSelectedStore] = useState<Store | null>(null);
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [selectableItems, setSelectableItems] = useState<Item[]>([]);
  const [isItemsLoading, setIsItemsLoading] = useState<boolean>(false);
  const [isStoresLoading, setIsStoresLoading] = useState<boolean>(false);
  const hasPermissionToIncomingTransfers = hasPermissionTo(['IncomingTransferWrite', 'TransferWrite']);
  const hasPermissionToOutgoingTransfers = hasPermissionTo(['OutgoingTransferWrite', 'TransferWrite']);
  const dropDownItems = selectableItems.map(i => i.itemNumber + ' - ' + i.description);
  const isDisableActions = isSubmittedSuccess || isProcessingSubmit;
  const [ createErrorMessage, setCreateErrorMessage ]= useState('');

  const { t } = useTranslation('stockTransfers');

  const createDate: Date = new Date();

  useEffect(() => {
    if (selectedStore?.storeNumber) {
      if (user?.roles && user.roles.includes('admin')) {
        loadAllStores();
      }
      else if (user?.roles && !user.roles.includes('admin')) {
        loadFranchiseStores();
      }
    }
  }, []);

  const loadAllStores = () => {
    setIsStoresLoading(true);
    getStores().then(
      (res) => {
        if (res?.data?.stores) {
          const sortedStores: Store[] = res.data.stores
            .filter((store) => store.storeNumber !== selectedStore?.storeNumber)
            .sort((a, b) => (a.storeName?.toUpperCase() ?? '').localeCompare(b.storeName?.toUpperCase() ?? ''));
          
          setAllStores(sortedStores);
        }
      })
      .catch((error) => logError(error))
      .finally(() => setIsStoresLoading(false));
  };


  const loadFranchiseStores = () => {
    if(user?.franchiseName)
    {
      setIsStoresLoading(true);
      getFranchiseStores(user?.franchiseName).then(
        (res) => {
          if (res?.data?.stores) {
            const sortedStores: Store[] = res.data.stores
              .filter((store) => store.storeNumber !== selectedStore?.storeNumber)
              .sort((a, b) => (a.storeName?.toUpperCase() ?? '').localeCompare(b.storeName?.toUpperCase() ?? ''));
          
            setAllStores(sortedStores);
          }
        })
        .catch((error) => logError(error))
        .finally(() => setIsStoresLoading(false));
    }
  };

  
  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setTransferType(event.target.value);
    setIsNoteDisabled(false);
  };


  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    if (value != '') {
      setIsItemsLoading(true);

      const from = transferType?.toLowerCase() === 'outgoing' ? selectedStore?.storeNumber : formSelectedStore?.storeNumber;
      const to = transferType?.toLowerCase() === 'outgoing' ? formSelectedStore?.storeNumber : selectedStore?.storeNumber;
      getTransferItems(value, from, to)
        .then(
          (result) => setSelectableItems(result.data?.items ?? []),
        )
        .catch((error) => logError(error))
        .finally(() => setIsItemsLoading(false));
    }
    else {
      setSelectableItems([]);
    }
  };


  const handleItemSelect = async (newValue: string) => {
    setValue('');
    setSearchTerm('');
    const itemToAdd = selectableItems.find(i => i.itemNumber + ' - ' + i.description == newValue);
    if (itemToAdd) {
      const preExistingItem = itemsToCreate.find(x => x.id == itemToAdd.id);
      if (!preExistingItem) {
        
        const possibleUoms = await possibleUOMs(selectedStore?.storeNumber ?? '', itemToAdd.itemNumber ?? '-1');
        setItemPossibleUOMs(itemPossibleUOMs.concat({ id: itemToAdd.id ?? -1, uoms: possibleUoms }));

        const selectedItem: ItemSelected = {
          id : itemToAdd.id,
          uom : '',
          quantity : 0,
          validatedUom : false,
          maxValidQuantity : undefined,
          itemNumber : itemToAdd.itemNumber,
          itemDescription : itemToAdd.description,
          quantityError : false,
          errorDescription : undefined,
        };
        setItemsToCreate(itemsToCreate.concat(selectedItem));
      }
    }
  };


  const handleStoreSelect = async (newValue: Store) => {
    setItemsToCreate([]);
    setItemPossibleUOMs([]);
    setFormSelectedStore(newValue);
  };


  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };


  const ValidateTransferItemQuantity = async (selectedItem: ItemSelected, id: number) => {
    setIsValidatingItemQuantity(true);

    const transferTypeLower = transferType?.toLowerCase();
    const itemQuantityRequestObj: ValidateItemQuantityRequest = {
      storeNumber: transferTypeLower === 'incoming' ? (formSelectedStore?.storeNumber ?? '') : (selectedStore?.storeNumber ?? ''),
      itemNumber: selectedItem.itemNumber ?? '',
      quantity: selectedItem.quantity,
      uom: selectedItem.uom ?? '',
    };
    
    await validateTransferItemQuantity(itemQuantityRequestObj)
      .then(
        (result) => {
          selectedItem.validatedUom = true;
          selectedItem.maxValidQuantity = result.maxValidQuantity;
          if(result.isQuantityValid){            
            if(selectedItem.quantity > result.maxValidQuantity!) {
              selectedItem.quantityError = true;
              selectedItem.errorDescription = `${t('create_modal.exceeds_available_quantity_of')} ${result.maxValidQuantity}`;
            }
            else{
              selectedItem.quantityError = false;
              selectedItem.errorDescription = '';
            }      
          }
          else{
            selectedItem.quantityError = true;
            selectedItem.errorDescription = `${t('create_modal.exceeds_available_quantity_of')} ${result.maxValidQuantity}`;
          }
        },
      )
      .catch((error) => {
        logError(error);
        selectedItem.maxValidQuantity = 0;
        if(selectedItem.quantity > selectedItem.maxValidQuantity) {
          selectedItem.quantityError = true;
          selectedItem.errorDescription = `${t('create_modal.exceeds_available_quantity_of')} ${selectedItem.maxValidQuantity}`;         
        }
      })
      .finally(() => {
        QtyOrUoMChanges(id, selectedItem.quantity, selectedItem.quantityError, selectedItem.errorDescription ?? '');
        setIsValidatingItemQuantity(false);
      });
        
  };

  const QtyOrUoMChanges = (id: number, quantity: number, quantityError: boolean, errorDescription: string) => {
    const newQuantites = itemsToCreate.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.quantity = quantity;
        returnValue.quantityError = quantityError;
        returnValue.errorDescription = errorDescription;
      }
      return returnValue;
    });
    setItemsToCreate(newQuantites);
  };

  const handleQuantityChange = (id: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    let requestedQuantity: number = Number(event.target.value);
    requestedQuantity = requestedQuantity < 0 ? 0 : requestedQuantity;
    const selectedItem = itemsToCreate.find(x => x.id == id);
    if(selectedItem) {
      selectedItem.quantity = requestedQuantity;
      if(selectedItem.uom && !selectedItem.validatedUom){
        await ValidateTransferItemQuantity(selectedItem, id);
      }
      else {    
        if((selectedItem.maxValidQuantity ?? 0) <= 0 || requestedQuantity > (selectedItem.maxValidQuantity ?? 0)) {
          selectedItem.quantityError = true;
          selectedItem.errorDescription = `${t('create_modal.exceeds_available_quantity_of')} ${selectedItem.maxValidQuantity}`; 
        }
        else{
          selectedItem.quantityError = false;
          selectedItem.errorDescription = '';
        }
        QtyOrUoMChanges(id, selectedItem.quantity, selectedItem.quantityError, selectedItem.errorDescription ?? '');
      }   
    }
  };
    

  const handleUomChange = (id: number) => async (event: SelectChangeEvent) => {
    const newUoM: string = event.target.value;
    const selectedItem = itemsToCreate.find(x => x.id == id);
    if(selectedItem)
    {
      selectedItem.uom = newUoM;
      await ValidateTransferItemQuantity(selectedItem, id);
    }
  };


  const possibleUOMs = async (storeNumber: string, itemNumber: string): Promise<Uom[]> => {
    let uomReturn: Uom[] = [];
    await getUoms(itemNumber, storeNumber, true, true)
      .then((results) => {
        const uoms = results.data?.uoms;
        uomReturn = uoms ?? [];
      })
      .catch((error) => logError(error));
    return uomReturn;
  };


  const deleteButtonHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    setItemsToCreate(itemsToCreate.filter(x => x.id != id));
    setItemPossibleUOMs(itemPossibleUOMs.filter(x => x.id != id));
  };


  const errorDismissButtonHandler = () => {
    setIsError(false);
  };


  const successDismissButtonHandler = () => {
    setItemsToCreate([]);
    setItemPossibleUOMs([]);
    setFormSelectedStore(null);
    setIsNoteDisabled(true);
    setNote('');

    setIsSubmittedSuccess(false);
  };


  const isFormInvalid = () => {
    if (transferType === '') return true;
    if (itemsToCreate.length == 0) return true;
    if (itemsToCreate.some(item => item.quantityError)) return true;
    if (itemsToCreate.some(item=>item.quantity === 0)) return true;
    return false;
  };


  const handleSubmit = () => {
    const items: CreateTransferRequest_CreateTransferRequestItem[] = itemsToCreate.map(item => {
      return {
        itemNumber: item.itemNumber ?? '',
        uom: item.uom ?? '',
        requestedQuantity: item.quantity ?? 0,
      };
    });

    const body: CreateTransferRequest = {
      transferDate: createDate.toISOString(),
      transferType: transferType ?? '',
      storeNumber: selectedStore?.storeNumber ?? '',
      otherStoreNumber: formSelectedStore?.storeNumber ?? '',
      note: note,
      items: items,
    };

    setIsProcessingSubmit(true);
    setIsError(false);
    createTransfer(body)
      .then(() => {
        setIsSubmittedSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Refresh after 3 seconds
      })
      .catch((error) => {
        logError(error);
        let errorMessage = '';
        for (const key in error?.errors) {
          if (Object.prototype.hasOwnProperty.call(error?.errors, key)) {
            const value = error?.errors[key];
            if (Array.isArray(value) && value.length > 0) {
              errorMessage = value[0];  // Return the message part
            }
          }
        }

        setCreateErrorMessage(errorMessage);
        setIsError(true);
      })
      .finally(() => setIsProcessingSubmit(false));

  };

  return {
    hasPermissionToIncomingTransfers,
    hasPermissionToOutgoingTransfers,
    allStores,
    isFormInvalid,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleStoreSelect,
    handleNoteChange,
    handleQuantityChange,
    handleUomChange,
    deleteButtonHandler,
    dropDownItems,
    handleSubmit,
    setItemsToCreate,
    transferType,
    createDate,
    value,
    formSelectedStore,
    searchTerm,
    itemsToCreate,
    itemPossibleUOMs,
    isValidatingItemQuantity,
    note,
    isItemsLoading,
    isStoresLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    isNoteDisabled,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    t,
    createErrorMessage,
    setNote,
    setTransferType,
    setFormSelectedStore,
    setValue,
  };
};