import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Item, PrimaryCategoryStockCount, StockCheckSchedule, StockCheckScheduleCategory, StockCheckScheduleDetail, StockCheckScheduleItem, Store2 } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { InternalStockCheckSchedule } from '../interfaces/StockInterfaces';
import { useNavigate, useParams, useBlocker } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';
import { ConfigurationName } from '../../../enums/ConfigurationName';

export const useScheduleCreate = () => {
  //use hooks
  const { t } = useTranslation('createStockCountSchedule');
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const { getConfiguration, generatestockchecks, getStockCheckScheduleByNumber, stockcheckschedulePOST } = useNswagClient();
  const navigate = useNavigate();
  const { logError } = useLogError();
  //consts
  const today = new Date().toISOString().split('T')[0];

  //modal open state
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCategoryDeleteModalOpen, setIsCategoryDeleteModalOpen] = useState(false);
  const [isScheduleDeleteModalOpen, setIsScheduleDeleteModalOpen] = useState(false);

  //snackbar modal state
  const [snackBarDuration, setSnackBarDuration] = useState<number | null>(6000);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');

  //other state
  const [categoryData, setCategoryData] = useState<StockCheckScheduleCategory[]>([]);

  const [itemsData, setItemsData] = useState<StockCheckScheduleItem[]>([]);
  const [schedules, setSchedules] = useState<InternalStockCheckSchedule[]>([]);
  const [stores, setStores] = useState<Store2[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreated, setHasCreated] = useState(false);

  //row delete ids
  const [categoryNameToDelete, setCategoryNameToDelete] = useState<string>();
  const [itemNumberToDelete, setItemNumberToDelete] = useState<string>();
  const [scheduleIds, setScheduleIds] = useState<number[]>();
  const [scheduleToDeleteIndex, setScheduleToDeleteIndex] = useState<number | undefined>();
  const [deleteAllSchedulesFlag, setDeleteAllSchedulesFlag] = useState<boolean>(false);

  //main box values
  const [name, setName] = useState('');
  const [allUsers, setAllUsers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [approvalValue, setApprovalValue] = useState<number | undefined>();
  const [selectedTime, setSelectedTime] = useState<number>();
  const [isTimeLocked, setIsTimeLocked] = useState<boolean>(false);
  const [isSmartCount, setIsSmartCount] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(50);
  const [isActive, setIsActive] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [hasRightToEdit, setHasRightToEdit] = useState(false);

  const { id } = useParams();

  const isCreating = Number(id) <= 0 || id === undefined;

  const blocker = useBlocker(() => hasChanged && !hasCreated);

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  useEffect(() => {
    loadData();
    getCurrencyCode();
    if (hasPermissionTo(['StockCountScheduleWrite'])) {
      setHasRightToEdit(true);
    }
  }, []);

  const getCurrencyCode = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.CurrencyCode, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      const code = new Intl.NumberFormat('en', { style: 'currency', currency: res.data?.value }).formatToParts(1)[0].value;
      setCurrencySymbol(code);
    });
  };

  // group schedules by multiStoresGroupId
  const groupSchedules = (schedules: StockCheckSchedule[]): InternalStockCheckSchedule[] => {
    const schedulesByMultiStoreGroup = schedules.reduce((acc, item) => {
      const groupId = item?.multiStoresGroupId;
      if (groupId) {
        acc[groupId] = acc[groupId] || [];
        acc[groupId].push(item);
      }
      return acc;
    }, {} as { [key: string]: StockCheckSchedule[] });

    const groupedSchedules: InternalStockCheckSchedule[] = Object.values(schedulesByMultiStoreGroup).map((groups) => ({
      ids: groups.map((item: StockCheckSchedule) => item.id) as number[],
      stockCheckScheduleDetailId: groups[0].stockCheckScheduleDetailId,
      storeNumbers: groups.map((item: StockCheckSchedule) => item.storeNumber) as string[],
      isActive: groups[0].isActive,
      generationDayOffset: groups[0].generationDayOffset,
      startDate: groups[0].startDate,
      recurrenceFrequency: groups[0].recurrenceFrequency,
      recurrenceInterval: groups[0].recurrenceInterval,
      scheduleType: groups[0].scheduleType,
      scheduledDayOfMonth: groups[0].scheduledDayOfMonth,
      stockCheckLastCreated: groups[0].stockCheckLastCreated,
      allStoresSelected: groups[0].allStoresSelected,
      multiStoresGroupId: groups[0].multiStoresGroupId,
    }));

    return groupedSchedules;
  };

  const loadData = () => {
    setIsLoading(true);
    getStockCheckScheduleByNumber(selectedStore?.storeNumber ?? '', Number(id ?? 0)).then((result) => {
      if (result.data) {
        setStores(result.data.stores ?? []);
        const StockCheckScheduleDetails = result.data;

        setCategoryData(StockCheckScheduleDetails.stockCheckSchedule?.categories ?? []);

        setItemsData(StockCheckScheduleDetails.stockCheckSchedule?.items ?? []);

        setSchedules(groupSchedules(StockCheckScheduleDetails.stockCheckSchedule?.schedules ?? []));

        setAllUsers(StockCheckScheduleDetails.approverEmails ?? []);
        setName(StockCheckScheduleDetails.stockCheckSchedule?.name ?? '');
        setSelectedUser(StockCheckScheduleDetails.stockCheckSchedule?.approver ?? '');
        setApprovalValue(StockCheckScheduleDetails.stockCheckSchedule?.toleranceValue);
        setSelectedTime(StockCheckScheduleDetails.stockCheckSchedule?.lockTillHour);
        setIsTimeLocked(StockCheckScheduleDetails.stockCheckSchedule?.lockTillCountDay ?? false);
        setIsSmartCount(StockCheckScheduleDetails.stockCheckSchedule?.isSmartCount ?? false);
        setSliderValue(StockCheckScheduleDetails.stockCheckSchedule?.tolerancePercentage ?? 0);
        setIsActive(StockCheckScheduleDetails.stockCheckSchedule?.isActive ?? false);
      }
    })
      .catch(e => {
        logError(e);
        setSnackBarProps('error', `Error loading schedules: ${e}. please try again`);
      })
      .finally(() => setIsLoading(false));
  };

  const handleActiveChange = (event: SelectChangeEvent) => {
    setIsActive(!isActive);
    setHasChanged(true);
  };

  const handleIsTimeLockedChanged = (event: SelectChangeEvent) => {
    setIsTimeLocked(!isTimeLocked);
    setHasChanged(true);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSliderValue(newValue);
    }
    setHasChanged(true);
  };

  const handleCategories = (data: PrimaryCategoryStockCount[]) => {
    const convertedCategories = data.map(x => {
      return {
        primaryCategoryId: x.id,
        primaryCategoryName: x.primaryCategoryName,
        stockCheckScheduleDetailId: id ? Number(id) : 0,
      };
    });
    const categories = Array.from(new Set(categoryData.concat(convertedCategories)));
    setCategoryData(categories);
    setIsCategoriesModalOpen(false);
    setHasChanged(true);
  };

  const deleteCategories = () => {
    let data: PrimaryCategoryStockCount[] = [];
    if (categoryNameToDelete != undefined) {
      data = categoryData?.filter(item => item.primaryCategoryName !== categoryNameToDelete);
    }
    setCategoryData(data);
    handleClose();
    setHasChanged(true);
  };

  const deleteItems = () => {
    let data: Item[] = [];
    if (itemNumberToDelete != undefined) {
      data = itemsData?.filter(item => item.itemNumber !== itemNumberToDelete);
    }
    setItemsData(data);
    handleClose();
    setHasChanged(true);
  };

  const areArraysEqual = (first: number[], second: number[]) => {
    if (first.length !== second.length) {
      return false;
    }

    for (let i = 0; i < first.length; i++) {
      if (first[i] !== second[i]) {
        return false;
      }
    }

    return true;
  };

  const confirmDeleteSchedule = () => {
    let data: InternalStockCheckSchedule[] = [];

    if (deleteAllSchedulesFlag === true) {
      data = [];
    } else if (scheduleToDeleteIndex !== undefined) {
      // remove non existing schedule items
      data = schedules?.filter((item, index) => index !== scheduleToDeleteIndex);
    } else {
      // remove existing schedule items
      data = schedules?.filter(item => !areArraysEqual(item.ids ?? [], scheduleIds ?? []));
    }
    setSchedules(data);
    handleClose();
    setHasChanged(true);
  };

  const deleteCategoryRow = (nameToDelete?: string) => {
    setCategoryNameToDelete(nameToDelete);
    setIsCategoryDeleteModalOpen(true);
  };

  const deleteItemRow = (itemNumberToDelete?: string) => {
    setItemNumberToDelete(itemNumberToDelete);
    setIsDeleteModalOpen(true);
  };

  const deleteScheduleRow = (index?: number) => {
    if (index !== undefined) {
      const scheduleToDelete: InternalStockCheckSchedule = schedules[index];
      if (scheduleToDelete?.ids?.length !== 0) {
        // delete existing schedule row
        setScheduleIds(scheduleToDelete?.ids ?? []);
      } else {
        // delete non-existing schedule row
        setScheduleToDeleteIndex(index);
      }
    } else {
      // delete all schedule rows
      setDeleteAllSchedulesFlag(true);
    }

    setIsScheduleDeleteModalOpen(true);
  };

  const handleitems = (data: Item[]) => {
    const convertedData = data.filter(item => !itemsData.some(x => x.itemNumber === item.itemNumber)).map(item => {
      return {
        id: 0,
        stockCheckScheduleDetailId: undefined,
        itemNumber: item.itemNumber,
        description: item.description,
        category: item.primaryCategory,
        tolerancePercentage: 0,
        toleranceValue: 0,
      };
    });
    const items = Array.from(new Set(itemsData.concat(convertedData ?? [])));
    setItemsData(items);
    setHasChanged(true);
  };

  const handleClose = () => {
    setIsCategoriesModalOpen(false);
    setIsItemsModalOpen(false);
    setIsBulkModalOpen(false);
    setIsCategoryDeleteModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsScheduleDeleteModalOpen(false);
    setDeleteAllSchedulesFlag(false);
    setScheduleToDeleteIndex(undefined);
  };

  const handleAddCategoriesClick = () => {
    setIsCategoriesModalOpen(true);
  };

  const handleAddItemsClick = () => {
    setIsItemsModalOpen(true);
  };

  const handleAddBulkItemClick = () => {
    setIsBulkModalOpen(true);
  };

  const submitStock = () => {
    isCreating ? createStockSchedule() : updateStock();
  };

  const mapSchedules = (): StockCheckSchedule[] => {
    let multiStoresGroupId = 1;

    return schedules.flatMap((schedule: InternalStockCheckSchedule) => {
      multiStoresGroupId = schedule?.multiStoresGroupId ? schedule.multiStoresGroupId : multiStoresGroupId + 1;
      return (schedule.storeNumbers || []).map((store, index) => {
        const { storeNumbers, ...rest } = schedule;

        return {
          ...rest,
          id: schedule?.ids?.[index] ?? 0,
          storeNumber: store,
          multiStoresGroupId: schedule.multiStoresGroupId ? schedule.multiStoresGroupId : multiStoresGroupId + 1,
        };
      });
    });
  };

  const createStockSchedule = () => {
    const mappedSchedules: StockCheckSchedule[] = mapSchedules();

    const body: StockCheckScheduleDetail = {
      id: 0,
      name: name,
      franchise: selectedStore?.franchiseId,
      isActive: isActive,
      tolerancePercentage: sliderValue,
      toleranceValue: approvalValue,
      isSmartCount: isSmartCount,
      canMergeCount: true,
      lockTillCountDay: isTimeLocked,
      lockTillHour: selectedTime,
      updateSmartCountItems: false,
      approver: selectedUser,
      nextUpcomingCount: '',
      schedules: mappedSchedules,
      items: itemsData,
      categories: categoryData,
    };

    setSnackBarProps('info', 'Creating schedule');
    setHasCreated(true);

    stockcheckschedulePOST(selectedStore?.storeNumber ?? '-1', body)
      .then(() => {
        setSnackBarProps('success', 'Created schedule');
        navigate('/stock-count-schedule');
      })
      .catch((error) => {
        setSnackBarProps('error', t('errorMessage') + ' ' + error);
      });
  };

  const updateStock = () => {
    const mappedSchedules: StockCheckSchedule[] = mapSchedules();

    const body: StockCheckScheduleDetail = {
      id: Number(id),
      name: name,
      franchise: selectedStore?.franchiseId,
      isActive: isActive,
      tolerancePercentage: sliderValue,
      toleranceValue: approvalValue,
      isSmartCount: isSmartCount,
      canMergeCount: true,
      lockTillCountDay: isTimeLocked,
      lockTillHour: selectedTime,
      updateSmartCountItems: false,
      approver: selectedUser,
      nextUpcomingCount: '',
      schedules: mappedSchedules,
      items: itemsData,
      categories: categoryData,
    };

    setSnackBarProps('info', 'Updating schedule');
    stockcheckschedulePOST(selectedStore?.storeNumber ?? '-1', body)
      .then(() => {
        setSnackBarProps('success', 'Updated schedule');
        setHasChanged(false);
        loadData();
      })
      .catch((error) => {
        setSnackBarProps('error', t('errorMessage') + ' ' + error);
      });
  };

  const generateStock = () => {
    setSnackBarProps('info', 'Generating schedules');
    generatestockchecks(selectedStore?.storeNumber ?? '-1', Number(id))
      .then(() => {
        setSnackBarProps('success', 'Generated schedules');
      })
      .catch((error) => {
        setSnackBarProps('error', t('errorMessage') + ' ' + error);
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setHasChanged(true);
  };

  const handleSmartCountChange = () => {
    setIsSmartCount(!isSmartCount);
    setHasChanged(true);
  };

  const handleInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setApprovalValue(newQuantity);
    } else {
      setApprovalValue(undefined);
    }
    setHasChanged(true);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedTime = hour;
      options.push({
        value: formattedTime,
        label: `${String(hour).padStart(2, '0')}:00`,
      });
    }
    return options;
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setSelectedTime(event.target.value as number | undefined);
    setHasChanged(true);
  };

  const handleUserSelect = (event: SelectChangeEvent<string>) => {
    setSelectedUser(event.target.value);
    setHasChanged(true);
  };

  const handleLeaveClick = () => {
    navigate('/stock-count-schedule');
  };

  const handleValueChange = (itemNumber: string, newValue: string) => {
    let newNum = Number(newValue) || 0;
    newNum = newNum < 0 ? 0 : newNum;

    const updatedItems = itemsData.map((item) => {
      if (item.itemNumber === itemNumber) {
        return {
          ...item,
          toleranceValue: newNum,
        };
      }
      return item;
    });
    setItemsData(updatedItems);
    setHasChanged(true);
  };

  const handleQtyChange = (itemNumber: string, newValue: string) => {
    let newNum = parseFloat(newValue) || 0;
    newNum = newNum < 0 ? 0 : newNum;

    const updatedItems = itemsData.map((item) => {
      if (item.itemNumber === itemNumber) {
        return {
          ...item,
          tolerancePercentage: newNum,
        };
      }
      return item;
    });
    setItemsData(updatedItems);
    setHasChanged(true);
  };

  const handleCategoryChange = (id: number, newValue: string) => {
    const updatedItems = itemsData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          category: newValue,
        };
      }
      return item;
    });
    setItemsData(updatedItems);
    setHasChanged(true);
  };

  const setSnackBarProps = (severity: 'error' | 'warning' | 'info' | 'success', message: string) => {
    severity === 'success' ? setSnackBarDuration(6000) : setSnackBarDuration(null);
    setIsSnackBarOpen(true);
    setSnackBarSeverity(severity);
    setSnackbarMessage(message);
  };

  const closeSnackBar = () => {
    setIsSnackBarOpen(false);
  };

  return {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    allUsers,
    handleActiveChange,
    handleQtyChange,
    handleValueChange,
    handleLeaveClick,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleAddBulkItemClick,
    handleAddItemsClick,
    handleAddCategoriesClick,
    handleClose,
    handleitems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    schedules,
    stores,
    setSchedules,
    deleteScheduleRow,
    isScheduleDeleteModalOpen,
    confirmDeleteSchedule,
    handleSmartCountChange,
    isSmartCount,
    handleCategoryChange,
    isActive,
    isLoading,
    generateStock,
    isCreating,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    currencySymbol,
    hasRightToEdit,
  };
};
