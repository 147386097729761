import { MadeInStoreItem, Recipe, RecipeComponent, Store } from '../../../app/services/api/generated';
import { createContext, useContext, useState, useMemo, Dispatch, SetStateAction } from 'react';

export interface FormState {
  selectedEntityType: number;
  description: string;
  primaryUOM?: string;
  weight?: number;
  unitCostPrice?: number;
  uomConversion?: number;
  isAllStores?: boolean;
  selectedStores?: Store[];
  selectedCategory?: string;
  itemNumber: string;
  recipeComponents: RecipeComponent[];
}

interface RecipeDialogContextProps {
  showRecipeDialog: boolean;
  setShowRecipeDialog: Dispatch<SetStateAction<boolean>>;
  updateFormField: (fieldName: string, value: string | number) => void,
  mapRecipeToFormState: (entity: Recipe) => FormState,
  mapMadeInStoreToFormState: (entity: MadeInStoreItem) => FormState;
  resetFormState: (allStores: Store[]) => void,
  initialFormState: Recipe,
  formState: FormState,
  setInitialFormState: Dispatch<SetStateAction<Recipe>>;
  setFormState: Dispatch<SetStateAction<FormState>>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

const initialContextState: RecipeDialogContextProps = {
  showRecipeDialog: false,
  setShowRecipeDialog: () => {},
  updateFormField: (fieldName: string, value: string | number) => {},
  mapRecipeToFormState: (entity: Recipe) => ({
    selectedEntityType: 1,
    description: '',
    primaryUOM: '',
    weight: 0,
    unitCostPrice: 0,
    uomConversion: 0,
    isAllStores: true,
    selectedStores: [],
    selectedCategory: '',
    itemNumber: '',
    recipeComponents: [],
  }),
  mapMadeInStoreToFormState: (entity: MadeInStoreItem) => ({
    selectedEntityType: 2,
    description: '',
    primaryCategory: '',
    itemNumber: '',
    recipeComponents: [],
  }),
  resetFormState: (allStores: Store[]) => {},
  initialFormState: {
    description: '',
    primaryCategory: '',
    itemNumber: '',
    recipeComponents: [],
  },
  setInitialFormState: () => {},
  formState: {
    selectedEntityType: 1,
    description: '',
    primaryUOM: '',
    weight: 0,
    unitCostPrice: 0,
    uomConversion: 0,
    isAllStores: true,
    selectedStores: [],
    selectedCategory: '',
    itemNumber: '',
    recipeComponents: [],
  },
  setFormState: () => {},
  isEditMode: false,
  setIsEditMode: () => {},
};

const RecipeDialogContext = createContext<RecipeDialogContextProps>(initialContextState);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RecipeDialogProvider = ({ children }: any) => {
  const [showRecipeDialog, setShowRecipeDialog] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [initialFormState, setInitialFormState] = useState<Recipe>({
    description: '',
    primaryCategory: '',
    itemNumber: '',
    recipeComponents: [],
  });
  const [formState, setFormState] = useState<FormState>({
    selectedEntityType: 1,
    description: '',
    primaryUOM: '',
    weight: 0,
    unitCostPrice: 0,
    uomConversion: 0,
    isAllStores: true,
    selectedStores: [],
    selectedCategory: '',
    itemNumber: '',
    recipeComponents: [],
  });

  const mapMadeInStoreToFormState = (entity: MadeInStoreItem): FormState => {
    return {
      selectedEntityType: 1,
      description: entity?.description ?? '',
      itemNumber: entity?.itemNumber ?? '',
      recipeComponents: entity?.madeInStoreComponents ?? [],
    };
  };

  const mapRecipeToFormState = (entity: Recipe): FormState => {
    return {
      selectedEntityType: 1,
      description: entity?.description ?? '',
      unitCostPrice: entity?.costPriceOnHand ?? 0,
      selectedCategory: entity?.primaryCategory ?? '',
      itemNumber: entity?.itemNumber ?? '',
      recipeComponents: entity?.recipeComponents ?? [],
    };
  };

  const contextValue = useMemo(() => {
    const updateFormField = (fieldName: string, value: string | number) => {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    };

    const resetFormState = (allStores: Store[]) => {
      setFormState({
        selectedEntityType: 1,
        description: '',
        primaryUOM: '',
        weight: 0,
        unitCostPrice: 0,
        uomConversion: 0,
        isAllStores: true,
        selectedStores: allStores,
        selectedCategory: '',
        itemNumber: '',
        recipeComponents: [],
      });
    };

    return {
      showRecipeDialog,
      setShowRecipeDialog,
      initialFormState,
      setInitialFormState,
      formState,
      setFormState,
      updateFormField,
      mapRecipeToFormState,
      mapMadeInStoreToFormState,
      resetFormState,
      isEditMode,
      setIsEditMode,
    };

  }, [formState]);

  return (
    <RecipeDialogContext.Provider value={contextValue}>{children}</RecipeDialogContext.Provider>
  );
};

const useRecipeDialog = () => {
  const context = useContext(RecipeDialogContext);

  return context;
};

export { RecipeDialogProvider, useRecipeDialog };
