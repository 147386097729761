import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Franchise, Persona, SuccessResponse_1OfIEnumerable_1OfFranchise, KeyValuePair_2OfStringAndString } from '../../../app/services/api/generated';
import { PersonaPutPostRequest } from '../types/PersonaPutPostRequest';
import { Alert, Autocomplete, Box, Checkbox, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useLogError from '../../../hooks/useLogError';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface SubmitModalProps {
    openModal: boolean;
    onConfirm: Function;
    roles: KeyValuePair_2OfStringAndString[],
    persona: Persona | undefined;
    onCancel: () => void;
}

const CreateEditModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, persona, onCancel, roles }) => {
  const { t } = useTranslation('manageRoles');
  const { getFranchises } = useNswagClient();
  const [request, setRequest] = useState<PersonaPutPostRequest | undefined>(undefined);
  const [franchiseList, setFranchiseList] = useState<Franchise[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [madeInStoreRoleError, setMadeInStoreRoleError] = useState<boolean>(false);
  const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);
  const { logError } = useLogError();
  const [count, setCount] = useState(0);
  const [remainingChars, setRemainingChars] = useState(50);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const newRequest = {
      description: persona?.description,
      franchiseName: persona?.franchiseName,
      personaRoles: persona?.personaRoles,
    };
    setRequest(newRequest);
    setDescription(persona?.description ?? '');
    setCount(persona?.personaRoles?.length ?? 0);
    setSelectedRoles(persona?.personaRoles ?? []);
    setRemainingChars(50 - (persona?.description?.length ?? 0));
  }, [persona]);

  useEffect(() => {
    if (openModal) {
      loadData();
    }
  }, [openModal]);

  const loadData = () => {
    setIsLoading(true);
    getFranchises().then((result: SuccessResponse_1OfIEnumerable_1OfFranchise) => {
      if (result?.data) {
        setFranchiseList(result.data);
      }
    })
      .catch((error) => {
        logError(error);
      })
      .finally(() => (setIsLoading(false)));
  };

  const handleCancel = () => {
    onCancel();
    setRequest(undefined);
  };

  const hasValidMadeInStoreRoles = () => {
    const madeInStoreWriteSelected = selectedRoles.includes('MadeInStoreWrite');
    if (madeInStoreWriteSelected) {
      const madeInStoreReadSelected = selectedRoles.includes('MadeInStoreRead');
      if (!madeInStoreReadSelected) {
        setMadeInStoreRoleError(true);
        return false;
      }
    }
    return true;
  };

  const handleConfirm = () => {
    if (hasValidMadeInStoreRoles()) {
      const newRequest = {
        ...request,
        personaRoles: selectedRoles,
      };
      onConfirm(newRequest);
      setRequest(undefined);
      onCancel();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value.length <= 50) {
      setDescription(value);
      setRemainingChars(50 - value.length);
    }
    setRequest({ ...request, [name]: value });
  };

  const handleFranchiseChange = (event: SelectChangeEvent<string>) => {
    setRequest({ ...request, franchiseName: event.target.value });
  };

  const handleSelectChange = (event: React.SyntheticEvent, value: (string | undefined)[]) => {
    setSelectedRoles(value as string[]);
    setCount(value.length);
    handleAutoCompleteOpen();
  };

  const autoCompleteRef = useRef<HTMLDivElement | null>(null);

  const handleAutoCompleteOpen = () => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleFocus = () => {
    setIsAutocompleteFocused(true);
  };

  const handleBlur = () => {
    setIsAutocompleteFocused(false);
  };

  const formItemStyling = { display: 'flex', flexDirection: 'column', alignItems: 'left' };

  const isValid = request?.description && request?.franchiseName && selectedRoles.length > 0;

  return isLoading ? <LoadingWheel></LoadingWheel> : (
    <Dialog
      maxWidth='lg'
      onClose={handleCancel}
      open={openModal}>
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('manageApplicationRoles')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column', overflow: isAutocompleteFocused ? 'hidden' : 'auto' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}
          ref={autoCompleteRef}>
          {t('addOrAdjustRoles')}
        </Typography>
        <Grid container
          spacing={6}
          alignItems='self-end'
          p={6}>
          <Grid item
            xs={12}
            sx={{ mb: 2 }}>
            <Typography variant='textSM'>{t('roleTitle')} ({remainingChars} {t('remainingCharacters')})</Typography>
            <TextField
              fullWidth
              required
              name='description'
              placeholder='Title'
              id="input-with-icon-textfield"
              value={description}
              inputProps={{ maxLength: 50 }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('franchiseName')}</Typography>
            <FormControl>
              <Select
                value={request?.franchiseName}
                onChange={handleFranchiseChange}
                fullWidth
                required
              >
                {franchiseList && franchiseList.length > 0 && franchiseList.map((f) => (
                  <MenuItem key={f.franchiseId}
                    value={f.franchiseName}>
                    {f.franchiseName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('pageAccess')} ({count} {t('selectedItems')})</Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                options={roles.map(x => x.key)}
                value={selectedRoles}
                onOpen={handleAutoCompleteOpen}
                onChange={handleSelectChange}
                onScroll={handleAutoCompleteOpen}
                onFocus={handleFocus}
                onBlur={handleBlur}
                getOptionKey={(option) => option ?? 0}
                getOptionLabel={(option) => option ?? ''}
                disableCloseOnSelect
                sx={{ maxHeight: '200px' }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      sx={{ marginRight: 8, p: 0 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                ListboxProps={{ style: { maxHeight: '350px', overflow: 'auto' } }}
                renderInput={(params) => (
                  <TextField {...params}
                    required />
                )}
              />
              {madeInStoreRoleError &&
                                <Box mt={2}>
                                  <Alert severity="error">{t('madeInStoreRoleError')}</Alert>
                                </Box>
              }
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ my: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          disabled={!isValid}
          onClick={handleConfirm}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateEditModal;