import ScheduleCreateCategoriesModal from './modals/ScheduleCreateCategoriesModal';
import ScheduleCreateItemsModal from './modals/ScheduleCreateItemsModal';
import ScheduleCreateBulkModal from './modals/ScheduleCreateBulkModal';
import ScheduleCreateDeleteCategoryModal from './modals/ScheduleCreateDeleteCategoryModal';
import ScheduleCreateDeleteItemsModal from './modals/ScheduleCreateDeleteItemsModal';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';
import ScheduleCreateDeleteScheduleModal from './modals/ScheduleCreateDeleteScheduleModal';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';

const ScheduleCreatePopUps=() => {
  const { closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm } = useContext(ScheduleCreateContext) as ScheduleCreateValues;
    
  return (
    <>
      <ScheduleCreateCategoriesModal/>
      <ScheduleCreateItemsModal/>
      <ScheduleCreateBulkModal/>
      <ScheduleCreateDeleteCategoryModal/>
      <ScheduleCreateDeleteItemsModal/>
      <ScheduleCreateDeleteScheduleModal/>
      <LeavePageModal open={blocker.state === 'blocked'}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onConfirmUrl='/store/orders-history'/>
      <MessagesSnackbar onClose={closeSnackBar}
        open={isSnackBarOpen}
        severity={snackBarSeverity}
        message={snackbarMessage}
        duration={snackBarDuration}/>
    </>
  );
};

export default ScheduleCreatePopUps;