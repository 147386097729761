import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../hooks/useLogError';

export const ConfirmDisregardModal = (props: {isOpen: boolean, closeDialog: () => void, changeId: number[], loadData: ()=>void}) => {
  const { disregardChange } = useNswagClient();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isErrorProcessing, setIsErrorProcessing] = useState(false);
  const [errorMessage, setErrorMessage ] = useState('');
  const { logError } = useLogError();

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
  
  const loadDataWithDelay = async () => {
    await delay(1000);
    props.loadData();
  };

  const { t } = useTranslation('recipeUpdates');

  const handleDisregard = async () => {
    setIsProcessing(true);
    setIsErrorProcessing(false);

    const promises = props.changeId.map(async id => {
      disregardChange(id);
    });

    await Promise.all(promises)
      .then(() => {
        props.closeDialog();
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);})
      .finally(() => {
        setIsProcessing(false);
        setIsErrorProcessing(false);
        loadDataWithDelay();
      });
  };
  
  return (
    <Dialog open={props.isOpen}
      onClose={props.closeDialog}>
      <DialogTitle>
        <Typography variant='displayXS'>
          {t('disregardModal.title')}
        </Typography>
        <IconButton
          onClick={props.closeDialog}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {t('disregardModal.message')}
        {isProcessing && <Alert severity='info'>{t('disregardModal.processingMessage')}</Alert>}
        {isErrorProcessing && <Alert severity='error'>{t('disregardModal.errorMessage') + ' ' + errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button size='lg'
          variant='secondary'
          onClick={props.closeDialog}>
          {t('disregardModal.close')}
        </Button>
        <Button size='lg'
          variant='badSecondary'
          onClick={handleDisregard}>
          {t('disregardModal.disregardChange')}
        </Button>
      </DialogActions>
    </Dialog>
    
  );
};
