import { Alert, Snackbar, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { theme } from '../../../../theme';
import { FC, useContext, useEffect, useState } from 'react';
import { MadeInStoreItem } from '../../../../app/services/api/generated';
import MadeInStoreItemRow from './MadeInStoreItemRow';
import { useTranslation } from 'react-i18next';
import CreateFinishedGoodsItemDialog from './CreateFinishedGoodsItemDialog';
import { InfoCircle } from '../../../../assets';
import { UserContext } from '../../../../components/shared/useUser';

type Props = {
    list: MadeInStoreItem[];
    handleSort: (orderBy: keyof MadeInStoreItem) => void;
    orderBy: string;
    order: 'asc' | 'desc';
}

const MadeInStoreList: FC<Props> = ({ list, handleSort, orderBy, order }) => {
  const { t } = useTranslation('recipe');
  const { hasPermissionTo } = useContext(UserContext);
  const [ hasPermissionToWriteMadeInStore, setHasPermissionToWriteMadeInStore] = useState<boolean>(false);
  const [ showCreateDialog, setShowCreateDialog ] = useState<boolean>(false);
  const [ showSubmitStatusMessage, setShowSubmitStatusMessage ] = useState<boolean>(false);
  const [ isSubmittedSuccessfully, setIsSubmittedSuccessfully ] = useState<boolean>(false);
  const [ currentItem, setCurrentItem ] = useState<MadeInStoreItem | undefined>();

  useEffect(() => {
    setHasPermissionToWriteMadeInStore(hasPermissionTo(['MadeInStoreWrite']));
  }, []);
  
  const onCreateItem = (item: MadeInStoreItem) => {
    setShowCreateDialog(true);
    setCurrentItem(item);
  };

  const onConfirm = (submittedSuccessfully: boolean) => {
    setShowCreateDialog(false);
    setShowSubmitStatusMessage(true);
    setIsSubmittedSuccessfully(submittedSuccessfully);
    setCurrentItem({});
  };

  const onCancelCreate = () => {
    setShowCreateDialog(false);
    setCurrentItem({});
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                  active={orderBy === 'itemNumber'}
                  direction={order}
                  onClick={() => handleSort('itemNumber')}
                >
                  {t('itemNumber')}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                  active={orderBy === 'description'}
                  direction={order}
                  onClick={() => handleSort('description')}
                >
                  {t('itemDescription')}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel
                  sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                  active={orderBy === 'stockOnHandQuantity'}
                  direction={order}
                  onClick={() => handleSort('stockOnHandQuantity')}
                >
                  {t('stockOnHand')}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                {t('maximumAmount')}
                <Tooltip title={t('maximumAmountInfo')}>
                  <SvgIcon sx={{ width: '15px', ml: '5px', cursor: 'pointer', verticalAlign: 'top' }}><InfoCircle color={theme.palette.custom.blue[500]} /></SvgIcon>
                </Tooltip>
              </TableCell>
              {hasPermissionToWriteMadeInStore && <TableCell align='center'>
                {t('createFinishedGoodsItem')}
                <Tooltip title={t('createFinishedGoodsItemInfo')}>
                  <SvgIcon sx={{ width: '15px', ml: '5px', cursor: 'pointer', verticalAlign: 'top' }}><InfoCircle color={theme.palette.custom.blue[500]} /></SvgIcon>
                </Tooltip>
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <MadeInStoreItemRow key={row.id}
                item={row}
                onCreateItem={onCreateItem}
                hasPermissionToWriteMadeInStore={hasPermissionToWriteMadeInStore}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateFinishedGoodsItemDialog
        showDialog={showCreateDialog}
        madeInStoreItem={currentItem}
        onConfirm={onConfirm}
        onCancel={onCancelCreate}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSubmitStatusMessage}
        onClose={() => setShowSubmitStatusMessage(false)}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowSubmitStatusMessage(false)}
          severity={isSubmittedSuccessfully ? 'success' : 'error'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {isSubmittedSuccessfully ? t('successMessage') : t('errorMessage')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MadeInStoreList;