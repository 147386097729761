import { Box, Typography, Stack, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../../shared/useUser';

const textClamp = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

export const StoreDisplay = () => {
  const { selectedStore } = useContext(UserContext);

  return (<Box
    display='flex'
    my={6}
    gap={5}
    alignItems='stretch'
  >
    <img src={'/starbucks.png'}
      height='40px'
      alt='avatar' />
    <Tooltip title={selectedStore ? selectedStore.storeName : 'No store selected'}>
      <Stack
        textAlign='left'
        justifyContent='space-around'
      >
        <Typography
          color='common.white'
          variant='textSM'
          sx={{
            ...textClamp,
          }}>
          {selectedStore ? selectedStore.storeName : 'No store selected'}
        </Typography>
        <Typography
          color='common.white'
          variant='textXS'
          sx={{
            ...textClamp,
          }}
        >
          {selectedStore ? selectedStore?.storeGroup : 'No location'}
        </Typography>
      </Stack>
    </Tooltip>
    
  </Box>);
};
