import { Box, PaletteColor, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';

function CountButton(props: {
  readonly number: number | undefined;
  readonly handleClick: () => void;
  readonly isOn: boolean;
  readonly statusGroup: string;
  readonly palette: PaletteColor;
}) {
  const { t } = useTranslation('common');

  const colourToUse = props.isOn
    ? '0px 0px 0px 2px ' + props.palette[400] + '88'
    : '0px 0px 0px 2px ' + props.palette[100] + '88';
  const hoverColourToUse = props.isOn
    ? '0px 0px 0px 2px ' + props.palette[500] + '88'
    : '0px 0px 0px 2px ' + props.palette[300] + '88';

  return (
    <Box
      onClick={props.handleClick}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: theme.palette.custom.gray[200],
        cursor: 'pointer',
        boxShadow: colourToUse,
        '&:hover': {
          boxShadow: hoverColourToUse,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '6px',
          px: '12px',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '6px',
          alignSelf: 'stretch',
          flex: 1,
          boxShadow: '0px 2px 0px 0px rgba(16, 24, 40, 0.05)',
          color: theme.palette.custom.gray[600],
        }}
      >
        <Box sx={{ textAlign: 'left', flex: 1 }}>
          <Typography variant="textXS"
            fontWeight={400}>
            {props.statusGroup}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '8px',
            alignSelf: 'stretch',
            color: theme.palette.custom.gray[900],
          }}
        >
          <Typography variant="displayXS"
            lineHeight={'22px'}
            fontWeight={600}>
            {props.number ?? t('loading') + '...'}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          p: '8px 12px',
          justifyContent: 'flex-end',
          gap: '8px',
          alignSelf: 'stretch',
          color: theme.palette.primary[700],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '3px',
          }}
        >
          <Typography variant="textXS"
            fontWeight={600}>
            {props.isOn ? t('hideDetails') : t('viewDetails')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CountButton;
