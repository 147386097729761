import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../../../components/forms/Pagination';
import { theme } from '../../../../../theme';
import { Box, Button, Checkbox } from '@mui/material';
import { ItemChangeTypeMap } from '../../../data/ItemChangeTypeMap';
import { ChangesDialog } from '../../ChangesDialog';
import { AllStoresContext, AllStoresValues } from '../../../contexts/AllStoresChangesContext';
import { ConfirmDisregardModal } from '../../ConfirmDisregardModal';
import { AllStoresChangesButtons } from './AllStoresChangesButtons';

export default function AllStoresChangesTable() {
  const { 
    t, 
    data, 
    page, 
    changePage, 
    maxPages, 
    handleViewButtonClick, 
    viewId, 
    isChangesDialogOpen, 
    closeDialog,
    isDisregardDialogOpen,
    closeDisregardDialog,
    loadData,
    handleDisregardButtonClick,
    handleRowSelection,
    handleHeaderSelection,
    selectedRows,
  } = React.useContext(AllStoresContext) as AllStoresValues;

  React.useEffect(() => {}, [data, page]);

  return (
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[200],
    }}>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table"
          sx={{
            borderTop: '1px solid',
            borderColor: theme.palette.custom.gray[200],
          }}
        >
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedRows.length == 10}
                  onChange={handleHeaderSelection}/>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align="left"
              >
                {t('type')}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align="left"
              >
                {t('description')}
              </TableCell>
              <TableCell/>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.description + String(row.ids)}
                sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.ids ?? [])}
                    onChange={handleRowSelection(row.ids ?? [])}/>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  {ItemChangeTypeMap[row.type ?? 999]}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  {String(row.description)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  <Button variant='badSecondary'
                    onClick={handleDisregardButtonClick(row.ids ?? [])}
                    size='lg'>{t('disregard')}</Button>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  <Button size='lg'
                    onClick={handleViewButtonClick(row.ids ?? [])}>{t('view')} ({row.ids?.length})</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={changePage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>
      <AllStoresChangesButtons/>
      <ChangesDialog isOpen={isChangesDialogOpen}
        closeDialog={closeDialog}
        changeId={viewId}
        loadData={loadData}
        showActionButtons={true}/>
      <ConfirmDisregardModal isOpen={isDisregardDialogOpen}
        closeDialog={closeDisregardDialog}
        changeId={viewId}
        loadData={loadData}/>
    </Box>
  );
}
