import { Button, Checkbox, FormControl, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/InfoRounded';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import { LineRecord } from '../interfaces/LineRecord';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { availablePurchaseOrderLineReasons, getReason } from '../interfaces/PurchaseOrderLineReason';

const TableCellSort = (props: { children: ReactNode, tableKey: keyof LineRecord }) => {
  const { order,
    orderBy,
    handleSort,
  } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  return <TableCell>
    <TableSortLabel active={orderBy === props.tableKey}
      hideSortIcon
      direction={order}
      onClick={() => handleSort(props.tableKey)}>
      {props.children}
    </TableSortLabel>
  </TableCell>;
};

const PurchaseOrdersDetailsTable = () => {
  const navigate = useNavigate();
  const { handleNoteButtonClick,
    handleQuantityChange,
    handleUomsChange,
    handleReasonChange,
    sortedPoLines,
    isShowingTrafficCode,
    isReceiving,
    purchaseOrders,
    isEdited,
    isEditable } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;
  const { t } = useTranslation('purchaseOrderDetails');

  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {!isReceiving &&
            <TableCell>
              {t('selected')}
            </TableCell>}
          <TableCell >
          </TableCell>
          <TableCellSort tableKey='quantity'>
            {t('qty')}
          </TableCellSort>
          {
            purchaseOrders?.showReason &&
            <TableCell>
              {t('reason')}
            </TableCell>
          }
          <TableCellSort tableKey='uom'>
            {t('uom')}
          </TableCellSort>
          <TableCellSort tableKey='itemNumber'>
            {t('item')}
          </TableCellSort>
          <TableCellSort tableKey='itemDescription'>
            {t('itemDescription')}
          </TableCellSort>
          <TableCellSort tableKey='expectedQuantity'>
            {t('expectedQty')}
          </TableCellSort>
          <TableCellSort tableKey='expectedUom'>
            {t('expectedUom')}
          </TableCellSort>
          <TableCellSort tableKey='lineNumber'>
            {t('poLine')}
          </TableCellSort>
          {isShowingTrafficCode &&
            <TableCellSort tableKey='tariffCode'>
              {t('tariffCode')}
            </TableCellSort>}
          <TableCellSort tableKey='note'>
            {t('note')}
          </TableCellSort>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedPoLines?.map((PoLine) =>
          <TableRow
            key={PoLine.id}
            hover
          >
            {!isReceiving &&
              <TableCell>
                <Checkbox
                  disabled
                  checked={isEdited(PoLine)}
                />
              </TableCell>}
            <TableCell>
              <Button size='md'
                sx={{ pr: '5px', pl: '5px' }}
                variant='primary'
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/store/item/${PoLine.id}?sku=${PoLine.itemNumber}`);
                }}>
                View
              </Button>
            </TableCell>
            <TableCell>
              {isEditable ?
                <FormControl fullWidth
                  sx={{ minWidth: '60px' }}>
                  <TextField type='text'
                    inputProps={{ pattern: '[0-9]*' }}
                    onClick={($event) => $event.stopPropagation()}
                    value={PoLine.quantity}
                    onChange={handleQuantityChange(PoLine.id ?? -1)}>
                  </TextField>
                </FormControl>
                :
                PoLine.quantity}
            </TableCell>
            {
              purchaseOrders?.showReason &&
              <TableCell>
                {
                  PoLine.hasDiscrepancy && isEditable &&
                  <FormControl fullWidth>
                    <Select
                      value={PoLine.reasonForCreditRequest ?? ''}
                      onChange={($event) => handleReasonChange(PoLine.id, $event.target.value)}
                      onClick={($event) => $event.stopPropagation()}
                      displayEmpty
                      renderValue={(value) => {
                        const selectedItem = availablePurchaseOrderLineReasons.find(item => item.value === value);
                        return selectedItem ? t(getReason(selectedItem.key)) : '';
                      }}
                    >
                      {availablePurchaseOrderLineReasons.map((item) => (
                        <MenuItem key={item.key}
                          value={item.value}>
                          {t(getReason(item.key))}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                {
                  !isEditable &&
                  <Typography>{PoLine.reasonForCreditRequest}</Typography>
                }
              </TableCell>
            }
            <TableCell>
              {isEditable ?
                <FormControl fullWidth>
                  <Select
                    value={PoLine.uom}
                    onChange={handleUomsChange(PoLine.id ?? -1)}
                    onClick={($event) => $event.stopPropagation()}
                    displayEmpty
                    renderValue={(value) => {
                      const selectedUom = PoLine.uomList?.find(uom => uom.code === value);
                      return selectedUom ? selectedUom.code : value;
                    }}
                  >
                    {PoLine.uomList?.map((uom) => (
                      <MenuItem key={uom.code}
                        value={uom.code}>
                        {uom.code} {uom.description && `(${uom.description})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                :
                PoLine.uom}
            </TableCell>
            <TableCell>
              {PoLine.itemNumber}
            </TableCell>
            <TableCell>
              {PoLine.itemDescription}
            </TableCell>
            <TableCell>
              {PoLine.expectedQuantity}
            </TableCell>
            <TableCell>
              {PoLine.expectedUom}
            </TableCell>
            <TableCell>
              {PoLine.lineNumber}
            </TableCell>
            {isShowingTrafficCode && <TableCell>
              {PoLine.tariffCode}
            </TableCell>}
            <TableCell>
              {isEditable &&
                <Tooltip title={PoLine.note}>
                  <IconButton onClick={($event) => { handleNoteButtonClick(PoLine.id, PoLine.note); $event.stopPropagation(); }}>
                    {PoLine.note != '' ? <EditNoteIcon /> : <AddIcon />}
                  </IconButton>
                </Tooltip>}
              {!isEditable && PoLine.note != '' &&
                <Tooltip title={PoLine.note}>
                  <IconButton onClick={($event) => { handleNoteButtonClick(PoLine.id, PoLine.note); $event.stopPropagation(); }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>}
            </TableCell>
          </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
};

export default PurchaseOrdersDetailsTable;