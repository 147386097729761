import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OrderStatus, OrderStatusTranslationMap, isOrderStatus } from '../../../enums/OrderStatus';
import { theme } from '../../../theme';
import { PaletteColor } from '@mui/material';
import { useTranslation } from 'react-i18next'; 

const OrderStatusChip: React.FC<{ orderStatus: string; size?: 'small' | 'medium' | 'large' }> = ({
  orderStatus,
  size = 'small',
}) => { 
  const { t } = useTranslation('orderHistory');
  const internalStatus =  isOrderStatus(orderStatus) ? t(OrderStatusTranslationMap[orderStatus]) : orderStatus;
 
  switch (internalStatus) {
  case t(OrderStatusTranslationMap[OrderStatus.OrderRequested]) :
    return renderChip(theme.palette.custom.blue, t(OrderStatusTranslationMap[OrderStatus.OrderRequested]), size);
  case t(OrderStatusTranslationMap[OrderStatus.OrderConfirmed]):
  case  t(OrderStatusTranslationMap[OrderStatus.OrderShipped]):
    return renderChip(theme.palette.success, t(OrderStatusTranslationMap[OrderStatus.OrderShipped]), size);
  case t(OrderStatusTranslationMap[OrderStatus.OrderNotPlaced]):
    return renderChip(theme.palette.warning, t(OrderStatusTranslationMap[OrderStatus.OrderNotPlaced]), size);
  case  t(OrderStatusTranslationMap[OrderStatus.Editable]):
  default:
    return renderChip(theme.palette.custom.gray, t(OrderStatusTranslationMap[OrderStatus.Editable]), size);
  }
}; 

const getTypographyVariant = (size: string) => {
  if (size === 'small') {
    return 'textSM';
  } else if (size === 'medium') {
    return 'textMD';
  } else {
    return 'textLG';
  }
};

const renderChip = (palette: PaletteColor, status: string, size: 'small' | 'medium' | 'large') => {

  let padding;
  let borderRadius;

  switch (size) {
  case 'small':
    padding = '2px 6px';
    borderRadius = '12px';
    break;
  case 'large':
    padding = '6px 10px';
    borderRadius = '20px';
    break;
  default:
    padding = '4px 8px';
    borderRadius = '16px';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: padding,
        borderRadius: borderRadius,
        border: '1px solid',
        borderColor: palette[200],
        backgroundColor: palette[50],
      }}
    >
      <Typography variant={getTypographyVariant(size)}
        sx={{ color: palette[700] }}>
        {status}
      </Typography>
    </Box>
  );
};

export default OrderStatusChip;
