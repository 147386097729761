import { InformationModalProps } from '../interfaces/CreditRequestInterfaces';
import { Button, DialogActions,Dialog, DialogContent,DialogTitle, IconButton,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'; 

const InformationModal = (props: InformationModalProps) => {
  const { t } = useTranslation('createCreditRequest');
  return (  
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {props.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {props.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={props.onClose}>
          {t('modals.close')}
        </Button> 
      </DialogActions>
    </Dialog>
   
  );
};

export default InformationModal;