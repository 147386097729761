import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { X } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/ui/Loader';
import { StockCheckItem } from './../../../app/services/api/generated';
import { applyRoundAndFormat } from './../../../utils';
import StockCheckCategoryTable from './StockCheckCategoryTable';
import { TempStockCheckItem } from '..';
import { Dispatch, SetStateAction, useEffect } from 'react';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isApproving: boolean;
  maxToleranceWarningValue?: number;
  itemsAboveMaxTolerance: StockCheckItem[];
  setItemsAboveMaxTolerance: Dispatch<SetStateAction<StockCheckItem[]>>;
  onItemStatusChange: (itemId: number | undefined, checked: boolean) => void;
}

const StockCheckApproveDialog = ({ open, onClose, onSubmit, isApproving, maxToleranceWarningValue, itemsAboveMaxTolerance, setItemsAboveMaxTolerance, onItemStatusChange }: Props) => {
  const { t } = useTranslation('stockCountReview');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  const isAnyItemsInvalid = () => {
    return itemsAboveMaxTolerance.some(item => {
      return item.isApproved !== true && item.isApproved !== false;
    });
  };

  const handleItemStatusChange = (itemId: number | undefined, checked: boolean) => {
    const updatedItems = itemsAboveMaxTolerance.map(item => {
      if (item.id === itemId) {
        return { ...item, isApproved: checked };
      }
      return item;
    });

    setItemsAboveMaxTolerance(updatedItems);
    onItemStatusChange(itemId, checked);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ p: 0 }}
      fullWidth
      maxWidth={itemsAboveMaxTolerance.length > 0 ? 'lg' : 'sm'}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('approvalDialogTitle')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant='textMD'
          sx={{ my: 4, mx: 6 }}>
          {
            itemsAboveMaxTolerance.length > 0 ? t('approvalDialogWarning', { value: applyRoundAndFormat(maxToleranceWarningValue ?? 0, currencyCode) }) : t('approvalDialogMessage')
          }
        </Typography>
        {
          itemsAboveMaxTolerance.length > 0 && 
          <Box sx={{ p: '10px' }}>
            <StockCheckCategoryTable category={{ name: '', items: itemsAboveMaxTolerance  as TempStockCheckItem[] }}
              categoryIndex={0}
              sortPartialStockCountItems={() => {}}
              togglePartialStockCountItem={() => {}}
              onItemStatusChange={handleItemStatusChange}
              isDisabled={false}
              isOpenedInDialog
            ></StockCheckCategoryTable>
          </Box>
        }
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button
          variant="secondary"
          onClick={onClose}
          autoFocus
        >
          {t('close')}
        </Button>
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={isApproving || isAnyItemsInvalid()}
          startIcon={isApproving && <Loader />}
          autoFocus
        >
          {isApproving ? t('isConfirming') : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCheckApproveDialog;