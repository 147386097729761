import { useContext, useEffect, useState } from 'react';
import { UserContext } from './useUser';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Role } from '../../enums/Role';
import Layout from './layout/Layout';
import ErrorPage from '../../pages/ErrorPage';

interface AuthGuardProps {
  children: JSX.Element;
  optionalChildren?: JSX.Element;
  permissions: Role[];
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children, optionalChildren, permissions }) => {
  const navigate = useNavigate();
  const [ storedValue ] = useLocalStorage('token', null);
  const { hasPermissionTo } = useContext(UserContext);
  const [ checked, setChecked ] = useState(false);

  const isAuthenticated = (): boolean => {
    return storedValue && storedValue !== '';
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!isAuthenticated()) {
        navigate('/login');
      }

      if (permissions && permissions.length > 0) {
        const isAuthorized = hasPermissionTo(permissions);
        if (!isAuthorized) {
          return <NoPermissionPage/>;
        } else {
          setChecked(true);
        }
      }
      else{
        setChecked(true);
      }
    };

    checkAuthorization();
  });

  if (!checked) {
    return <NoPermissionPage/>;
  }

  return <>{children}</>;
};

const NoPermissionPage = () => {
  return (<Layout>
    <ErrorPage variant='403'/>
  </Layout>);
};

export default AuthGuard;
