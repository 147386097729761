import React, { Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { TFunction } from 'i18next';
import { Invoice } from '../../../app/services/api/generated';
import { useInvoices } from '../hooks/useInvoices';

export interface CreateInvoicesValues {
    t: TFunction<'invoices', undefined>, 
    invoices: Invoice[], 
    totalRows: number, 
    invoiceNumber: string, 
    handleInvoiceNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    orderNumber: string, 
    handleOrderNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    startDate: string, 
    handleStartDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    endDate: string, 
    handleEndDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    handleClearAllClick: () => void, 
    loadData: () => void, 
    isLoading: boolean,
    orderBy: string, 
    order: 'desc' | 'asc', 
    handleSort: (orderBy: keyof Invoice)  => void, 
    rowSxPropToUse: (status: string) => {backgroundColor: string;} | {backgroundColor: undefined;}, 
    handleNoteButtonClick: (note: string) => () => void, 
    handleAcceptClick: (id: number) => void, 
    changePage: (newPage: number) => void, 
    page: number, 
    isShowSuccessAlert: boolean, 
    setIsShowSuccessAlert: Dispatch<SetStateAction<boolean>>, 
    isShowErrorAlert: boolean, 
    setIsShowErrorAlert: Dispatch<SetStateAction<boolean>>, 
    isShowProcessingAlert: boolean, 
    setIsShowProcessingAlert: Dispatch<SetStateAction<boolean>>, 
    openNoteModal: boolean, 
    handleCloseNoteModal: ()=> void, 
    note: string,
    errorMessage: string,
    isOpenModal: boolean,
    acceptId: number | undefined,
    closesAcceptModal: () => void,
    handleButtonClick: (id: number) => () => void
}

export const InvoicesContext = createContext<null | CreateInvoicesValues>(null);

interface ProviderProps {
    children: React.ReactNode;
}

export const InvoicesProvider = ({ children }: ProviderProps) => {
  const {
    t, 
    invoices, 
    totalRows, 
    invoiceNumber, 
    handleInvoiceNumberChange, 
    orderNumber, 
    handleOrderNumberChange, 
    startDate, 
    handleStartDateChange, 
    endDate, 
    handleEndDateChange, 
    handleClearAllClick, 
    loadData, 
    isLoading,
    isOpenModal,
    orderBy, 
    order, 
    handleSort, 
    rowSxPropToUse, 
    handleNoteButtonClick, 
    handleAcceptClick, 
    changePage, 
    page, 
    isShowSuccessAlert, 
    setIsShowSuccessAlert, 
    isShowErrorAlert, 
    setIsShowErrorAlert, 
    isShowProcessingAlert, 
    setIsShowProcessingAlert, 
    openNoteModal, 
    handleCloseNoteModal, 
    note,
    errorMessage,
    acceptId,
    closesAcceptModal,
    handleButtonClick,
  } = useInvoices();

  const passedValue = {
    t, 
    invoices, 
    totalRows, 
    invoiceNumber, 
    handleInvoiceNumberChange, 
    orderNumber, 
    handleOrderNumberChange, 
    startDate, 
    handleStartDateChange, 
    endDate, 
    handleEndDateChange, 
    handleClearAllClick, 
    loadData, 
    isLoading,
    isOpenModal,
    orderBy, 
    order, 
    handleSort, 
    rowSxPropToUse, 
    handleNoteButtonClick, 
    handleAcceptClick, 
    changePage, 
    page, 
    isShowSuccessAlert, 
    setIsShowSuccessAlert, 
    isShowErrorAlert, 
    setIsShowErrorAlert, 
    isShowProcessingAlert, 
    setIsShowProcessingAlert, 
    openNoteModal, 
    handleCloseNoteModal, 
    note,
    errorMessage,
    acceptId,
    closesAcceptModal,
    handleButtonClick,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <InvoicesContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </InvoicesContext.Provider>
  );
};