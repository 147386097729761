import * as React from 'react';
import { CreateTransactionProvider } from './contexts/CreateTransactionsContext';
import { MainDialog } from './components/CreateTransactionsMainDialog';

interface SubmitModalProps {
  openModal: boolean;
  onCancel: () => void;
}

const SubmitModal: React.FC<SubmitModalProps> = ({ openModal, onCancel }) => {
  return (
    <CreateTransactionProvider openModal={openModal}
      onCancel={onCancel}>
      <MainDialog></MainDialog>
    </CreateTransactionProvider>
    
  );
};
export default SubmitModal;