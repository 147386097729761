import { Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Link, TableSortLabel } from '@mui/material';
import { useContext, useEffect } from 'react';
import CustomPagination from '../../../components/forms/Pagination';
import InfoIcon from '@mui/icons-material/Info';
import { formatDateString } from '../../../utils';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';

export const InvoiceTable = () => {
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { t, totalRows, invoices, order, orderBy, handleButtonClick, handleNoteButtonClick, handleSort, isLoading, rowSxPropToUse, changePage, page } = useContext(InvoicesContext) as CreateInvoicesValues;
  const rowsPerPage = 10;

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  const maxPages = () => {
    return Math.ceil(totalRows / rowsPerPage);
  };

  return <>
    {isLoading ? <LoadingWheel/> : 
      <>
        <TableContainer>
          <Table>
            <TableHead >
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'invoiceNumber'}
                    direction={order}
                    onClick={() => handleSort('invoiceNumber')}>
                    {t('invoiceNumber')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'orderNumber'}
                    direction={order}
                    onClick={() => handleSort('orderNumber')}>
                    {t('orderNumber')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'invoiceType'}
                    direction={order}
                    onClick={() => handleSort('invoiceType')}>
                    {t('invoiceType')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'ewsOrderNumber'}
                    direction={order}
                    onClick={() => handleSort('ewsOrderNumber')}>
                    {t('ewsOrderNumber')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'invoiceDate'}
                    direction={order}
                    onClick={() => handleSort('invoiceDate')}>
                    {t('invoiceDate')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'deliveryDate'}
                    direction={order}
                    onClick={() => handleSort('deliveryDate')}>
                    {t('deliveryDate')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'invoiceTotal'}
                    direction={order}
                    onClick={() => handleSort('invoiceTotal')}>
                    {t('invoiceTotal')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'receivedValue'}
                    direction={order}
                    onClick={() => handleSort('receivedValue')}>
                    {t('receivedValue')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'creditValue'}
                    direction={order}
                    onClick={() => handleSort('creditValue')}>
                    {t('creditValue')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'invoiceValue'}
                    direction={order}
                    onClick={() => handleSort('invoiceValue')}>
                    {t('actualCost')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'discrepancyValue'}
                    direction={order}
                    onClick={() => handleSort('discrepancyValue')}>
                    {t('discrepency')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ '.MuiTableSortLabel-icon': { m:0 } }}
                    active={orderBy === 'status'}
                    direction={order}
                    onClick={() => handleSort('status')}>
                    {t('status')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align='center'>
                  {t('note')}
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => <TableRow key={invoice.id}
                sx={rowSxPropToUse(invoice.status ?? '')}>
                <TableCell>
                  <Link href={'/invoice/' + invoice.id}>
                    {invoice.invoiceNumber}
                  </Link>
                </TableCell >
                <TableCell>
                  <Link href={'/order/' + invoice.orderNumber}>
                    {invoice.orderNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  {invoice.invoiceType}
                </TableCell>
                <TableCell>
                  {invoice.ewsOrderNumber}
                </TableCell>
                <TableCell>
                  {formatDateString(invoice.invoiceDate ?? '0')}
                </TableCell>
                <TableCell>
                  {formatDateString(invoice.deliveryDate ?? '0')}
                </TableCell>
                <TableCell align='right'>
                  <RoundAndFormatValue value={invoice.invoiceTotal ?? 0}
                    currencyCode={currencyCode} />
                </TableCell>
                <TableCell align='right'>
                  <RoundAndFormatValue value={invoice.receivedValue ?? 0}
                    currencyCode={currencyCode} />
                </TableCell>
                <TableCell align='right'>
                  <RoundAndFormatValue value={invoice.creditValue ?? 0}
                    currencyCode={currencyCode} />
                </TableCell>
                <TableCell align='right'>
                  <RoundAndFormatValue value={invoice.invoiceValue ?? 0}
                    currencyCode={currencyCode} />
                </TableCell>
                <TableCell align='right'>
                  <RoundAndFormatValue value={invoice.discrepancyValue ?? 0}
                    currencyCode={currencyCode} />
                </TableCell>
                <TableCell>
                  {invoice.status}
                </TableCell>
                <TableCell align='center'>{invoice.note && <Tooltip title={invoice.note}>
                  <IconButton onClick={handleNoteButtonClick(invoice.note ?? '')}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>}
                </TableCell>
                <TableCell>
                  <Button size='lg'
                    disabled={invoice.status !== 'Failed' 
                              || (invoice.note && invoice.note === 'Purchase order not found')
                              || (invoice.note && invoice.note === 'Purchase order not received')
                              || invoice.invoiceType === 'CreditNote'}
                    onClick={handleButtonClick(invoice.id?? -1)}>
                    {t('accept')}</Button>
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination setPage={changePage}
          page={page}
          maxPages={maxPages()}
          breakpointForChangeDisplay={120000}/>
      </>}
  </>;
};