import React from 'react';
import { Alert, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControl, 
  IconButton, 
  SvgIcon, 
  TextField, 
  Typography } from '@mui/material';
import { MarkerPin01, Plus, Save01, X } from '../../../assets';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  isSubmitZoneProcessing: boolean;
  isErrorNameTxtF: boolean;
  errorNameTxtFDescr: string;
  nameTxtFValue: string;
  handleNameTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddNewZoneConfirm: () => void;
  handleEditZoneConfirm: () => void;
  error: string;
  isSubmitBtnDisabled: boolean;
  candidateZoneToEdit?: number
}

const StockCountZonesCreateEditDialog: React.FC<Props> = ({
  open,
  onClose,
  isSubmitZoneProcessing,
  isErrorNameTxtF,
  errorNameTxtFDescr,
  nameTxtFValue,
  handleNameTextFieldChange,
  handleAddNewZoneConfirm,
  handleEditZoneConfirm,
  error,
  isSubmitBtnDisabled,
  candidateZoneToEdit,
}) => {

  const { t } = useTranslation('stockCountZones');

  return (
    <Dialog open={open}
      fullWidth
      onClose={onClose}
      aria-labelledby={candidateZoneToEdit ? 'edit-zone-dialog-title' : 'create-zone-dialog-title'}
      sx={{ p: 0 }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ width: '20px', mr: '5px' }}>
          <MarkerPin01 />
        </SvgIcon>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
          {candidateZoneToEdit ? t('editDialog.title') : t('createDialog.title')}
        </Typography>
        <IconButton aria-label="close"
          disabled={isSubmitZoneProcessing}
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.custom.gray[800] }}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <DialogContentText sx={{ my: 4, mx: 6 }}>
          {candidateZoneToEdit ? t('editDialog.text') : t('createDialog.text')}
        </DialogContentText>
        <FormControl sx={{ my: 4, mx: 6 }}
          size='small'>
          <TextField
            id="zone-name-field"
            error={isErrorNameTxtF}
            helperText={errorNameTxtFDescr}
            label={candidateZoneToEdit ? t('editDialog.txtFName') : t('createDialog.txtFName')}
            size='small'
            variant="outlined"
            value={nameTxtFValue}
            onChange={handleNameTextFieldChange}
            disabled={isSubmitZoneProcessing}
            required
          />
        </FormControl>
        {isSubmitZoneProcessing && <Alert severity="info"
          sx={{ my: 4, mx: 6 }}>
          {candidateZoneToEdit ? t('editDialog.processingMessage') : t('createDialog.processingMessage')}
        </Alert>}
        {!isSubmitZoneProcessing && error !== '' && <Alert severity="error"
          sx={{ my: 4, mx: 6 }}>
          {error}
        </Alert>}   
      </DialogContent>
      <DialogActions sx={{ my: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          disabled={isSubmitZoneProcessing}
          onClick={onClose}>
          {candidateZoneToEdit ? t('editDialog.cancelButton') : t('createDialog.cancelButton')}
        </Button>
        <Button variant='primary'
          size="lg"
          startIcon={candidateZoneToEdit ? <SvgIcon><Save01 fill='white' /></SvgIcon> : <SvgIcon><Plus fill='white' /></SvgIcon>}
          disabled={isSubmitZoneProcessing || isSubmitBtnDisabled}
          onClick={candidateZoneToEdit ? handleEditZoneConfirm : handleAddNewZoneConfirm}>
          {candidateZoneToEdit ? t('editDialog.confirmButton') : t('createDialog.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCountZonesCreateEditDialog;
