import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { ConversionDto } from '../../../app/services/api/generated';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const UomConversionsTable = (props: {page: number, maxPage: number, data: ConversionDto[],  handlePageChange: (passedPage: number) => void}) => {
  const { t } = useTranslation('uomConversions');
  
  useEffect(()=>{}, [props.data]);
  
  const headerStyle = { fontWeight: 'bold' };

  const cellStyle = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
  };
  
  return (
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[200],
    }}>
      <TableContainer component={'div'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('sku')}
              </TableCell>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('itemName')}
              </TableCell>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('fromUom')}
              </TableCell>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('conversion')}
              </TableCell>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('toUom')}
              </TableCell>
              <TableCell
                sx={headerStyle}
                align="left"
              >
                {t('UomClass')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow
                key={(row.itemId ?? '-') + '|' + (row.fromUom ?? '-') + '|' + (row.toUom ?? '-')}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {row.itemId}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {String(row.itemDescription)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {String(row.fromUom)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {String(row.conversionNumber)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {String(row.toUom)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={cellStyle}
                >
                  {String(row.toUomClass)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={props.page}
        setPage={props.handlePageChange}
        maxPages={props.maxPage}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>
    </Box>
  );
};
