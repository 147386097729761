import { Stack } from '@mui/material';
import StockCountCreateMain from './components/StockCountCreateMain';
import StockCountCreatePopUps from './components/StockCountCreatePopUps';
import { StockCountCreateProvider } from './contexts/StockCountCreateContext';
import StockCountCreateTop from './components/StockCountCreateTop';
import StockCountCreateItems from './components/StockCountCreateItems';
import StockCountScheduleCreateCategories from './components/StockCountCreateCategories';
import PageArea from '../../components/shared/PageArea';

const StockCountCreate=() => {
  return (
    <StockCountCreateProvider>
      <PageArea>
        <Stack spacing={5}>
          <StockCountCreateTop/>
          <StockCountCreateMain/>
          <StockCountScheduleCreateCategories/>
          <StockCountCreateItems/>
        </Stack>
        <StockCountCreatePopUps/>
      </PageArea>
    </StockCountCreateProvider>
  );
};

export default StockCountCreate;