import { Box, Pagination, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { ArrowLeft, ArrowRight } from '../../assets';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { palette } from '../../theme/palette';

export default function CustomPagination(props: {
  readonly setPage: (page: number) => void;
  readonly page: number;
  readonly maxPages: number;
  readonly breakpointForChangeDisplay: number;
}) {
  const { t } = useTranslation('common');
  const { page, setPage, maxPages } = props;

  const handleChangePageUP = () => {
    if (page + 1 < maxPages) setPage(page + 1);
  };
  const handleChangePageDown = () => {
    if (page > 0) setPage(page - 1);
  };

  const renderPageButtonUsingPageNo = (p:number)=>{
    return( 
      <button
        key={p}
        onClick={() => setPage(p)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '36px',
          width: '36px',
          borderRadius: '8px',
          color: p === page ? '#182230' : '#475467',
          background: 'transparent',
          border: 'none',
          padding: '8px',
          cursor: 'pointer',
          margin: '0 2px',
          textDecoration: p === page ? 'underline' : 'none',
        }}
      >
        {p + 1}
      </button>
    );};

  const renderPageButtons = () => {
    const pages = [];
    const lastPage = maxPages - 1;
  
    if (maxPages <= 5) {
      for (let p = 0; p < maxPages; p++) {
        pages.push(renderPageButtonUsingPageNo(p));
      }
    } else {
      const middlePages = [Math.max(1, page - 1), page, Math.min(page + 1, lastPage - 1)];
  
      if (page <= 1) {
        middlePages[0] = 1;
        middlePages[1] = 2;
        middlePages[2] = 3;
      } else if (page >= lastPage - 1) {
        middlePages[0] = lastPage - 3;
        middlePages[1] = lastPage - 2;
        middlePages[2] = lastPage - 1;
      }
  
      pages.push(renderPageButtonUsingPageNo(0));
  
      if (middlePages[0] > 1) {
        pages.push(<span key="start-ellipsis"
          style={{ margin: '0 4px' }}>...</span>);
      }
  
      middlePages.forEach((p) => {
        pages.push(renderPageButtonUsingPageNo(p));
      });
  
      if (middlePages[2] < lastPage - 1) {
        pages.push(<span key="end-ellipsis"
          style={{ margin: '0 4px' }}>...</span>);
      }
  
      pages.push(renderPageButtonUsingPageNo(lastPage));
    }
  
    return pages;
  };

  const isMobile = useMediaQuery(`(max-width:${props.breakpointForChangeDisplay}px)`);

  const smallPageDisplay = () => {
    return (<>
      <button
        onClick={handleChangePageDown}
        disabled={page <= 0}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '36px',
          width: '36px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.custom.gray[300],
          background: theme.palette.common.white,
          padding: '8px',
          cursor: 'pointer',
        }}
      >
        <SvgIcon sx={{ width: '20px' }}><ArrowLeft /></SvgIcon>
      </button>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        {renderPageButtons()}
      </Box>
      <button
        onClick={handleChangePageUP}
        disabled={Math.min((page + 1)) >= maxPages}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '36px',
          width: '36px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.custom.gray[300],
          background: theme.palette.common.white,
          padding: '8px',
          cursor: 'pointer',
        }}
      >
        <SvgIcon sx={{ width: '20px' }}><ArrowRight /></SvgIcon>
      </button></>);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const bigPageDisplay = <><div></div><Pagination count={maxPages}
    shape="rounded"
    page={page}
    onChange={handleChange}
    sx={{ alignSelf: 'center' }}></Pagination><div></div></>;

  const pageDisplayToUse = isMobile ? smallPageDisplay() : bigPageDisplay;

  
  return (
    maxPages > 0 ?
      <Box
        sx={{
          height: '60px',
          display: 'flex',
          p: '12px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        }}
      >
        {pageDisplayToUse}
      </Box>
      :
      <Box
        sx={{
          py: '20px',
          px: '16px',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="textLG"
          sx={{
            textAlign: 'center',
          }}
        >
          {(t('noData'))}
        </Typography>
      </Box>
  );
}
