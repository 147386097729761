export const uomConversionsData = {
  title: 'UoM Conversions',
  overview: `
        <p>Unit of measure (UOM) conversions are maintained in the PIM per item, and are published together with the items by the Item Integration. The UoM Conversions page includes all these items, so values used in the purchasing, receiving and stocking items processes can be better understood.</p>
        <p>The main purpose is as a reference, so the master list can be searched and filtered to enable Users to look up and cross-check the conversions for a given item or group of items.</p>
    `,
  keyFeatures: [
    'Conversion factors are received by the Publish Item Integration.',
    'Item conversions are view-only values, and not editable by Users.',
    'Conversion factors clearly stated with From and To UoM’s listed.',
    'List can be search and filtered to find given items.',
    'The list includes conversions between different UOM Classes in the From and To fields.',
    'UOM Class field included on the “To” value for further reference on each line.',
    'Additional Generic Conversion page for given value includes Base Units of Measure.',
  ],
  userGuide: [
    {
      guideTitle: '',
      guideContent: `
                <p>The main Unit of Measure Conversions page is listed as UOM Conversions under the Recipes section in the main menu. By default it will list all entries by item number, and includes a paging control underneath for simple navigation. Often a single item will have several different conversions listed, and so each different conversion will be displayed on a different line.</p>
                <p>The list has a search box and filter controls above – the Status filter can be set to show either Active, Inactive or All items, while the UoM Class filter includes all available options and will return results for all classes you tick. The Search box will return all matches where what you enter is included anywhere in the name or number of an item in the list. You can edit or clear the box to search again.</p>
                <p>The main list includes data for the From UoM value, the Conversion value, and then the To UoM value, followed by the UoM Class which the To UoM value belongs to. Below are examples of how this can be interpreted in different scenarios, as the calculation order is always the same:<ul><li>1. &nbsp;From UoM = CS (Case); Conversion = 50; To UoM = PLT (Pallet); UoM Class = Pallet.<br>
                This is read as one PLT (which is a Pallet) contains 50 Cases. i.e. (CS * 50) = One PLT.</li><li>2. &nbsp;From UoM = EA (Each); Conversion = 0.454; To UoM = LBS (Pounds); UoM Class = Weight.<br>
                This is read as one LBS (which is a Weight) contains 0.454 Eaches. i.e. (EA * 0.454) = One LB.</li><li>Note this does not mean one ‘Each’ weighs (is equal to) 0.454 lbs. To get the weight of a single each, you would use 1 divided by the Conversion value to give the weight in lbs of the From UoM : (1 / 0.454) = 2.20264 … so 1 Each weighs 2.20264 lbs.</li></ul></p>
                <p>In addition to the main UoM Conversions page, there is an additional Generic Conversion page. This is visible in the Admin menu (in the blue header bar) if the User has authorisation, and lists a number of common conversions for given Base UoM’s. The conversion process acts in the same way as on the main page (detailed above), with Base UOM used instead of the To UOM.</p>
            `,
    },
  ],
};
