import React from 'react';
import { Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  IconButton, 
  SvgIcon, 
  Alert } from '@mui/material';
import { Trash01, X } from '../../../assets';
import { useTranslation } from 'react-i18next';

interface StockCountZonesDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  error: string;
  zoneName: string | undefined;
}

const StockCountZonesDeleteDialog: React.FC<StockCountZonesDeleteDialogProps> = ({ open, onClose, onDelete, isLoading, error, zoneName }) => {
  const { t } = useTranslation('stockCountZones');

  return (
    <Dialog open={open}
      onClose={onClose}
      aria-labelledby="delete-zone-dialog-title"
      sx={{ p: 0 }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ width: '20px', mr: '5px' }}>
          <Trash01 />
        </SvgIcon>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
          {t('deleteDialog.title')}
        </Typography>
        <IconButton aria-label="close"
          disabled={isLoading}
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.custom.gray[800] }}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('deleteDialog.text')} <Typography component='span'
            sx={{ fontWeight: 'bold' }}>{zoneName}</Typography>?
        </Typography>
        {isLoading && <Alert severity="info"
          sx={{ my: 4, mx: 6 }}>{t('deleteDialog.processingMessage')}</Alert>}
        {!isLoading && error !== '' && <Alert severity="error"
          sx={{ my: 4, mx: 6 }}>{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{ my: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          disabled={isLoading}
          onClick={onClose}>
          {t('deleteDialog.cancelButton')}
        </Button>
        <Button variant='bad'
          size="lg"
          startIcon={<SvgIcon><Trash01 color='white' /></SvgIcon>}
          disabled={isLoading}
          onClick={onDelete}>
          {t('deleteDialog.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCountZonesDeleteDialog;