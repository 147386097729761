import { TableRow, TableCell, Button } from '@mui/material';
import { FC } from 'react';
import { Item } from '../../../../app/services/api/generated';
import RoundAndFormatValue from '../../../../components/shared/RoundAndFormatValue';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  item: Item;
  currencyCode?: string;
}

const ItemRow: FC<Props> = ({ item, currencyCode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('products');
   
  return (
    <TableRow
      sx={{ cursor: 'pointer' }}
      hover>
    
      <TableCell sx={{ cursor: 'pointer' }}>
        <Button size='md'
          sx={{ pr:'5px', pl:'5px' }}
          variant='primary'
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/store/item/${item.id}?sku=${item.itemNumber}&orderable=false`);
          }}>
          {t('view')}
        </Button>
      </TableCell>
      <TableCell>
        {item.itemNumber}
      </TableCell>
      <TableCell>
        {item.description}
      </TableCell>
      <TableCell>
        {item.uom}
      </TableCell>
      <TableCell>
        {item.pickPackUom}
      </TableCell>
      <TableCell>
        {item.primaryCategory}
      </TableCell>
      <TableCell>
        {item.status}
      </TableCell>
      <TableCell>
        <RoundAndFormatValue value={item.costPrice ?? 0}
          currencyCode={currencyCode} />
      </TableCell>
    </TableRow>
  );
};

export default ItemRow;

 