import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import IngredientRow from './IngredientRow';
import { RecipeComponent } from '../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../components/shared/useUser';

type Props = {
    ingredients: RecipeComponent[];
    onEdit: () => void;
    currencyCode?: string;
}

const RecipeIngredients: FC<Props> = ({ ingredients, onEdit, currencyCode }) => {
  const { hasPermissionTo } = useContext(UserContext);
  const { t } = useTranslation('recipe');
  
  return (
    <Box sx={{ m: 5, py: 5 }}>
      <Box display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='textLG'
          alignSelf='flex-start'>
          {t('recipeComponents')}
        </Typography>
        {hasPermissionTo(['RecipeWrite']) && <Button size='md'
          onClick={onEdit}
          sx={{ alignSelf: 'flex-end', mb: 3 }}>{t('editRecipe')}</Button>}
      </Box>
      {ingredients?.length === 0 ? (
        <Typography variant='textMD'>
          {t('noComponents')}
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('thumbnailImage')}</TableCell>
              <TableCell>{t('sku')}</TableCell>
              <TableCell>{t('itemName')}</TableCell>
              <TableCell>{t('netWeightInput')}</TableCell>
              <TableCell>{t('quantityInput')}</TableCell>
              <TableCell>{t('costPriceInput')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((item) => (
              <IngredientRow
                key={item.componentId}
                item={item}
                currencyCode={currencyCode} />
            ))}
          </TableBody>
        </Table>
      )
      }
    </Box>
  );
};

export default RecipeIngredients;