import * as React from 'react';
import { CreateStockTransfersProvider } from './contexts/CreateStockTransfersContext';
import { MainDialog } from './components/CreateStockTransfersMainDialog';

interface SubmitModalProps {
  openModal: boolean;
  onCancel: () => void;
}

const SubmitModal: React.FC<SubmitModalProps> = ({ openModal, onCancel }) => {
  return (
    <CreateStockTransfersProvider openModal={openModal}
      onCancel={onCancel}>
      <MainDialog></MainDialog>
    </CreateStockTransfersProvider>
    
  );
};
export default SubmitModal;