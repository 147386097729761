import { Box } from '@mui/material';
import { theme } from '../../theme';
import InvoiceDetailsTable from './components/InvoiceDetailsTable';
import { InvoiceDetailsContext, InvoiceDetailsProvider, InvoiceDetailsValues } from './contexts/InvoicesContext';
import InvoiceDetailsTitleArea from './components/InvoiceDetailsTitleArea';
import InvoiceDetailsFeilds from './components/InvoiceDetailsFeilds';
import { useContext } from 'react';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { InvoiceDetailsPopups } from './components/InvoiceDetailsPopups';
import PageArea from '../../components/shared/PageArea';

const InvoiceDetailsInner = () => {
  const { isLoading } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  return <PageArea>
    <InvoiceDetailsTitleArea/>
    {isLoading ? <LoadingWheel/> : 
      <Box sx={{
        border: '1px solid',
        borderColor: theme.palette.custom.gray[300],
      }} >
        <InvoiceDetailsFeilds/>
        <InvoiceDetailsTable/>
      </Box>}
  </PageArea>;
};

const InvoiceDetails = () => {
  return <InvoiceDetailsProvider>
    <InvoiceDetailsInner/>
    <InvoiceDetailsPopups/>
  </InvoiceDetailsProvider>;
};

export default InvoiceDetails;