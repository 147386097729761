import { useContext, useEffect } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Autocomplete } from '@mui/material';
import { Store } from '../../../../../app/services/api/generated';
import { StockTransferType } from '../../../interfaces/StockTransferType';

export const CreateStockTransfersTopForms = () => {
  const { 
    hasPermissionToIncomingTransfers,
    hasPermissionToOutgoingTransfers,
    handleTypeChange,
    handleItemSelect,
    handleSearchTermChange,
    allStores,
    transferType,
    createDate,
    value,
    isItemsLoading,
    isStoresLoading,
    searchTerm,
    dropDownItems,
    itemsToCreate,
    formSelectedStore,
    handleStoreSelect,
    setTransferType,
    setFormSelectedStore,
    setValue,
    t,
  } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;

  const stockTransferTypes: StockTransferType[] = [
    { value: 'All', description: 'type.all' },
    { value: 'Outgoing', description: 'type.outgoing' },
    { value: 'Incoming', description: 'type.incoming' },
  ];
  
  useEffect(() => {
    setTransferType('');
    setFormSelectedStore(null);
    setValue('');
  }, []);

  return (
    <>
      <Grid item
        xs={6}>
        <FormControl fullWidth
          size='small'>
          <TextField id="outlined-basic"
            label={t('create_modal.date_placeholder')}
            type='date'
            size='small'
            variant="outlined"
            disabled={true}
            value={createDate.toISOString().split('T')[0]}
          />
        </FormControl>
      </Grid>
      <Grid item
        xs={6}>
        <FormControl fullWidth
          size='small'>
          <InputLabel id="transaction-types-label">{t('create_modal.type_placeholder')}</InputLabel>
          <Select
            labelId="transaction-types-label"
            id="transaction-types-select"
            value={transferType}
            label={t('create_modal.type_placeholder')}
            onChange={handleTypeChange}
          >
            <MenuItem value="">
              <em>{t('create_modal.none')}</em>
            </MenuItem>
            {stockTransferTypes.map((type) => (
              (type.value.toLowerCase() === 'incoming' && hasPermissionToIncomingTransfers) ||
              (type.value.toLowerCase() === 'outgoing' && hasPermissionToOutgoingTransfers)) && (
              <MenuItem key={type.value}
                value={type.value}>
                {t(type.description)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      { allStores.length > 0 && <Grid item
        xs={12}>
        <Autocomplete
          size='small'
          loading={isStoresLoading}
          options={allStores}
          value={formSelectedStore}
          disabled={!transferType || transferType === ''}
          onChange={(event, newValue: Store | null | undefined) => {
            if (newValue) {
              handleStoreSelect(newValue);
            }
          }}
          getOptionKey={(option) => option?.storeNumber ?? ''}
          getOptionLabel={(option) => option?.storeName ?? ''}
          renderInput={(params) => (
            <TextField {...params}
              label={transferType?.toLowerCase() === 'incoming' ? t('create_modal.from_store_label') : t('create_modal.to_store_label')}
              placeholder={t('create_modal.store_placeholder')} />
          )}
        />
      </Grid>}
      <Grid item
        xs={12}>
        <Autocomplete
          size='small'
          loading={isItemsLoading}
          value={value}
          disabled={!formSelectedStore}
          onChange={(event, newValue: string | null | undefined) => {
            if (newValue) {
              handleItemSelect(newValue);
            }
          }}
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            handleSearchTermChange(newInputValue);
          }}
          isOptionEqualToValue={(x, y) => true}
          options={dropDownItems}
          filterOptions={(x) => x}
          getOptionDisabled={(option) => itemsToCreate.some(x => x.itemNumber + ' - ' + x.itemDescription == option)}
          id="controllable-states-demo"
          renderInput={(params) => <TextField {...params}
            label={t('create_modal.item_number_or_description')} />} />
      </Grid>
    </>
  );
};

