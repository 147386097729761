import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { TransferDetail } from '../../../app/services/api/generated';
import { theme } from '../../../theme';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { useTranslation } from 'react-i18next';
import { TransferItemRow } from '../interfaces/StockTransferDetailsTypes';
import { Copy01 } from '../../../assets';

const StockTransferDetailTable = (props: {
  transferDetails: TransferDetail[] | undefined,
  isDeleted: boolean,
  acceptedDate: string | undefined,
  transferType: string,
  transferItemRows: TransferItemRow[],
  handleUomChange: (id: number) => (event: SelectChangeEvent) => void,
  handleQuantityChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleCopyQtysButtonClick: () => void,
  currencyCode?: string
}) => {
  
  const { t } = useTranslation('stockTransfersDetails');

  const BodyTableCell = (props: { children?: React.ReactNode, align?: 'center' | 'left' }) => {
    return (<TableCell
      scope="row"
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.custom.gray[200],
        textAlign: props.align ?? 'left',
      }}
    >
      {props.children}
    </TableCell>);
  };
  
  return (
    <Box sx={{ width: '100%', mt: '30px' }}>
      <Grid container
        sx={{ mb: '8px' }}>
        <Grid item
          xs={8}></Grid>
        <Grid item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: '8px' }}>
          {!props.isDeleted && !props.acceptedDate && props.transferType === 'Incoming' && <Button
            variant="primary"
            size='lg'
            startIcon={<SvgIcon><Copy01 color='white' /></SvgIcon>}
            onClick={props.handleCopyQtysButtonClick}
          >{t('copyQuantitiesButton')}
          </Button>}
        </Grid>
      </Grid>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table"
          sx={{
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderColor: theme.palette.custom.gray[200],
            width: '100%',
          }}
        >
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.itemNumber')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.itemDescription')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.uom')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.requestedQuantity')}
              </TableCell>
              {!props.isDeleted && !props.acceptedDate && props.transferType === 'Incoming' && <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.receivedUom')}
              </TableCell>}
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.receivedQuantity')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.requestedValue')}
              </TableCell>
              {props.acceptedDate && <TableCell sx={{ fontWeight: 'bold' }}
                align="left">
                {t('table.receivedValue')}
              </TableCell>}
              
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transferDetails?.map((row, index) => (
              <TableRow
                key={row.id?.toString() + '-' + row.itemNumber}
                sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <BodyTableCell>{row.itemNumber}</BodyTableCell>
                <BodyTableCell>{row.description}</BodyTableCell>
                <BodyTableCell>{row.primaryUom}</BodyTableCell>
                <BodyTableCell>{row.requestedQuantity}</BodyTableCell>
                { !props.isDeleted && !props.acceptedDate && props.transferType === 'Incoming' && <BodyTableCell>
                  <FormControl fullWidth
                    size='small'>
                    <Select
                      labelId={`uoms-label-${row.id}`}
                      id={`uoms-select-${row.id}`} 
                      value={props.transferItemRows.find(x => x.id === row.id)?.selectedItemUom ?? ''}
                      sx={{ backgroundColor: theme.palette.common.white }}
                      onChange={props.handleUomChange(row.id ?? -1)}
                    >
                      <MenuItem value=''>
                        <em>{t('table.none')}</em>
                      </MenuItem>
                      {props.transferItemRows.find(x => x.id === row.id)?.possibleItemUoms?.map((uom) => {
                        return <MenuItem key={uom.code}
                          value={uom.code}>{uom.code}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </BodyTableCell>}
                <BodyTableCell>
                  { !props.isDeleted && !props.acceptedDate && props.transferType === 'Incoming' && <FormControl
                    size='small'
                    sx={{ width: '80px' }}>
                    <TextField
                      type='number'
                      size='small'
                      id={`quantity-input-${row.id}`} 
                      sx={{ backgroundColor: theme.palette.common.white }}
                      onChange={props.handleQuantityChange(row.id ?? -1)}
                      value={props.transferItemRows.find(x => x.id === row.id)?.receivedQuantity ?? 0}
                    />
                  </FormControl>}
                  { (props.isDeleted || props.acceptedDate || props.transferType === 'Outgoing') && ((row.receivedQuantity !== null) ? row.receivedQuantity : '-')}
                </BodyTableCell>
                <BodyTableCell>
                  <RoundAndFormatValue value={row.requestedValue!}
                    currencyCode={props.currencyCode} />
                </BodyTableCell>
                {props.acceptedDate && <BodyTableCell>{row.receivedValue !== undefined ? <RoundAndFormatValue value={row.receivedValue}
                  currencyCode={props.currencyCode} /> : '-'}</BodyTableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StockTransferDetailTable;