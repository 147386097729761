import useLogError from '../../../hooks/useLogError';
import { ItemFeedChange } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const useHistory = () => {
  const { t } = useTranslation('recipeUpdates');
  const { getRecipeChanges } = useNswagClient();
  const { logError } = useLogError();

  const [data,  setData] = useState<ItemFeedChange[]>([]);
  const [page,  setPage] = useState(0);
  const [maxPages,  setMaxPages] = useState<number>(0);
  const [isLoading, setIsLoading]= useState(false);

  const [isChangesDialogOpen, setIsChangesDialogOpen] = useState(false);
  const [isDisregardDialogOpen, setIsDisregardDialogOpen] = useState(false);
  const [viewId, setViewId] = useState(-1);
  const [searchTerm,  setSearchTerm] = useState<string>('');
  const [appliedChecked, setAppliedChecked] = useState(true);
  const [disregardedChecked, setDisregardedChecked] = useState(true);
  
  const { selectedStore } = useContext(UserContext); 

  const selectedStoreNumber = selectedStore?.storeNumber ?? '-1';

  const handleViewButtonClick = (id: number) => () => {
    setViewId(id);
    setIsChangesDialogOpen(true);
  };

  const closeDialog = () => {
    setIsChangesDialogOpen(false);
  };

  const handleDisregardButtonClick = (id: number) => () => {
    setViewId(id);
    setIsDisregardDialogOpen(true);
  };

  const closeDisregardDialog = () => {
    setIsDisregardDialogOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAppliedSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppliedChecked(event.target.checked);
    if(!event.target.checked){
      setDisregardedChecked(true);
    }
  };

  const handleDisregardedSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisregardedChecked(event.target.checked);
    if(!event.target.checked){
      setAppliedChecked(true);
    }
  };

  const handleSearchButtonClick = () => {
    setPage(0);
    LoadData(searchTerm, 0);
  };

  const LoadData = (passedSearch?: string, passedPage?: number) => {
    const searchTermToUse = passedSearch ?? searchTerm;
    const pageToUse = passedPage ?? page;
    setIsLoading(true);
    getRecipeChanges(searchTermToUse, selectedStoreNumber,pageToUse * 10, 10, false, appliedChecked, disregardedChecked)
      .then(
        (result) => {
          setData(result.data ?? []);
          const count = result.totalCount ?? 0;
          const maxPages = Math.ceil(count / 10);
          setMaxPages(maxPages);
        },
      )
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleClearButtonClick = () => {
    setSearchTerm('');
    setPage(0);
    LoadData('', 0);
  };

  return {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData,
    handleClearButtonClick,
    setPage,
    handleAppliedSwitchChange,
    handleDisregardedSwitchChange,
    appliedChecked,
    disregardedChecked,
    t,
  };
};