import React, { Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { ItemFeedChange } from '../../../app/services/api/generated';
import { TFunction } from 'i18next';
import { useHistory } from '../hooks/useHistory';

export interface HistoryValues {
    data: ItemFeedChange[],
    page: number,
    maxPages: number,
    isLoading: boolean,
    isChangesDialogOpen: boolean,
    isDisregardDialogOpen: boolean,
    viewId: number,
    searchTerm: string,
    handleViewButtonClick: (id: number) => () => void,
    closeDialog: () => void,
    handleDisregardButtonClick: (id: number) => () => void,
    closeDisregardDialog: () => void,
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleSearchButtonClick: () => void,
    LoadData: (passedSearch?: string, passedPage?: number) => void,
    handleClearButtonClick: () => void,
    t: TFunction<'recipeUpdates', undefined>,
    setPage: Dispatch<SetStateAction<number>>,
    handleAppliedSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleDisregardedSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    appliedChecked: boolean,
    disregardedChecked: boolean,
}

export const HistoryContext = createContext<null | HistoryValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const HistoryContextProvider = ({ children }: ProviderProps) => {
  const {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData,
    handleClearButtonClick,
    setPage,
    t,
    handleAppliedSwitchChange,
    handleDisregardedSwitchChange,
    appliedChecked,
    disregardedChecked,
  } = useHistory();

  const passedValue = {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData,
    handleClearButtonClick,
    setPage,
    t,
    handleAppliedSwitchChange,
    handleDisregardedSwitchChange,
    appliedChecked,
    disregardedChecked,
  };

  const HistoryProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <HistoryContext.Provider value={HistoryProviderValue.passedValue}>
      {children}
    </HistoryContext.Provider>
  );
};