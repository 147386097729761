export const recipeData = {
  title: 'Recipes',
  overview: `
        <p>The Recipe page allows administrators to maintain Recipes details on a store by store basis. The data provided is maintained in the Starbucks product hub PIM as Retail Bill of Materials (BOM), which consists of a parent item and one or more components (ingredients).</p>
        <p>To making editing quicker the application uses Generic Items for ‘mid-level’ components, so different items can be specified for different regions - i.e. Global (Worldwide); Legal Entity (e.g. UK); Store Group (Distribution Point) - without users needing to update all stores individually. This also avoids recipe edits for one store applying to all stores by default, but is still possible if desired.</p>
    `,
  keyFeatures: [
    'Users will not see items and components that do not apply to their store.',
    'Editing of Recipe data is restricted to Authorised Users.',
    'Components can be de-activated by applying an End Date.',
    'Cloning items allows quick updates, and allows visibility of recipe changes.',
    'New items can be added, and quantities altered to modify recipe details.',
    'Ingredient component quantity is shown in the item’s primary unit of measure.',
    'The cost price of a parent item is calculated from the cost prices of the components on the BOM.',
    'BOM items are identified by a blue “+” which can be clicked to view its components.',
    'Filters exist to change which components are displayed for BOM items - All, Active or Inactive.',
    'If a BOM is manually updated by the SysAdmin user, an exception message is sent to the Starbucks Master Data Management team.',
    'Export option to provide XLS of recipe data displayed.',
  ],
  userGuide: [
    {
      guideTitle: '',
      guideContent: `
                <p>The Recipes page itself is included under the main Recipes section in the left-hand menu, and shows a list of recipes available to the current store. A search box above the list can be used to return either exact matches on a recipe / component number; or a full or partial match on the recipe / component description (e.g. 011008628, or Frapp).</p>
                <p>A paging control is included underneath the list when applicable, and most of the column headings can be clicked to order the list (or reverse order when clicked again). Ordering the list can also be done from the control under the search bar, and an additional filter to return either Active or Inactive items is included.</p>
                <p>Note that search, filter and sorting options can all be used together, and you will need to clear the search to view the full list of items again. At any point the Products and Ingredients numbers alongside the search will update to reflect how many entries are included in the filtered list.</p>
                <p>All recipes, as with all BOM items, have a blue “+” on the left to indicate it has components. Clicking this blue icon will expand the display, allowing you to view all the components (ingredients) of that recipe. Typically these components will themselves be BOM items, so can also be expanded in order to view the base-level ingredients that make up the recipe.</p>
                <p>When expanding the view, on the line below the main recipe title, you will see additional filters (All, Active, Inactive) as well as options for Audit and Edit. The filter is set to Active by default, so any Inactive components are hidden. Note that visibility of these features will depend on the authorisation your user has been assigned.</p>
                <p>Clicking on the name of any recipe or other BoM in the list will take you to the individual page for that item, which gives the same options, but with a better overview for making changes. The individual component names can also be clicked to view its details separately.</p>
                <p>Clicking the Audit link will open a new page listing all the changes made to that component. The Audit link only shows changes to that item, and doesn’t include changes made to any of its components – you will have to expand the list further and view the separate Audit for them separately.</p>
                <p>Clicking the Edit option for any item allows a number of options: While there is no option to delete an item, you can set an End Date, which will make it Inactive from the date selected. You can also copy an existing item (right hand icon) which will end date the original component as today - making it Inactive – and replace the same item so you can quickly edit the quantity on a recipe, while maintaining a history of changes.</p>
                <p>To add items to a recipe, click in the empty item number box at the bottom of the list. A pop-up box will appear to allow you to search for the item you want to add, clicking the item will add it and allow you to set the quantity, start and end date. Other fields are automatically set. You can delete the line in case of a mistake, then Save Edit to keep changes or Cancel Edit to undo changes.</p>
                <p>The UoM field will display the Primary UoM for that item (in the case of BoM items it will typically be EA (each). You can click this field for any item, which will take you to the Unit of Measure Conversion page filtered by the item you have selected. Note that for BoM items this will be usually be blank.</p>
                <p>Above the top right of the main Recipes list is an Export Recipes button. Clicking this will export all the recipe data currently displayed (i.e. after search / filter is applied) to a new spreadsheet. This will display all the top-level Recipes, all their active ingredients, and the weight used to make up the recipe. The Export ignores the mid-level Generic Kits and any Inactive items for a clearer visibility of how the given recipe(s) are currently made.</p>
            `,
    },
  ],
};
