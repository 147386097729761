import {  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Plus, Trash01, Tag } from '../../assets';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../components/shared/useUser';
import { AutomatedTask } from '../../app/services/api/generated';
import useNswagClient from '../../hooks/api/useNswagClient';
import { theme } from '../../theme';
import useLogError from '../../hooks/useLogError';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DeleteModal from './modals/DeleteReport';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const ScheduledReports=() => {
  const { selectedStore } = useContext(UserContext);
  const { getAutomatedTasks } = useNswagClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AutomatedTask[]>();
  const { logError } = useLogError();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const[ name, setName ] = useState<string>('');
  const[ reportId, setReportId ] = useState<number>();
  const[ refreshPage, setRefreshPage ] = useState<boolean>(false);
  const { t } = useTranslation('scheduledReports');
  
  useEffect(() => {
    setLoading(true);
    getData();
  },[refreshPage]);

  const handleDeleteSuccess = () => {
    setOpenDelete(false); 
    setRefreshPage(!refreshPage);
  };
  const handleClose = () => {
  
    setOpenDelete(false);
  };
  const getData = () => {
    const storeNumber = selectedStore?.storeNumber;
    if (storeNumber) {
      getAutomatedTasks(storeNumber).then((result) => {
        if (result) {  
          const transactions  = result.data;
          if(transactions){
            setData(transactions);
          }
        } else {
          logError(result);
        }})
        .catch((error) => {
          logError(error);
        }).finally(() => {
          setLoading(false);
        });
    }
  };
  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, rowId: number) => {
    if ((event.target as HTMLTableRowElement).classList.contains('delete-icon')) {
      return;
    }
    const id = rowId.toString();
    navigate(`/create-scheduled-reports/${id}`);
  };
  
  const deleteReport = (rowId: number, name:string) =>{
    setName(name);
    setReportId(rowId);
    setOpenDelete(true);
  };

  const handleCreate = () => {
    navigate(`/create-scheduled-reports/${0}`);
  };
  return (
    <PageArea>
      <Grid container
        spacing={5}
      >
        <Grid item 
          xs={12}>
          <PageTitle> {t('mainTitle')}</PageTitle>
        </Grid>
        <Grid item
          xs={6}
          sx={{ display:'flex', justifyContent:'flex-start' }}>
          <Typography variant='textLG'>{t('name')}</Typography>
        </Grid>
        <Grid item
          xs={6}
          sx={{ display:'flex', justifyContent:'flex-end' }}>
          <Button
            size='lg'
            sx={{ mr: 3 }}
            variant='primary'
            onClick={handleCreate}
          ><Plus />{t('createNew')}
          </Button>
        </Grid>
       
        <Grid item
          xs={12}>
          <Box sx={{
            border: '1px solid',
            borderColor: theme.palette.custom.gray[200],
          }}>
            {loading ? <LoadingWheel />: (
              <TableContainer sx={{ overflowy:'auto' }}
              >
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableBody>
                    {data?.map((row:AutomatedTask) => (
                      <TableRow
                        hover
                        key={row.id}      
                        onClick={(event) => handleRowClick(event, row?.id?? 0 )}              
                      >
                        <TableCell >
                          <Tag height={'15px'}/> {row.name}
                        </TableCell>                      
                        <TableCell>
                          <Trash01 color={theme.palette.error[500]}
                            className='delete-icon'
                            onClick={(event) => deleteReport(row?.id ?? 0, row.name ?? '')}  
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> 
            )}
          </Box>
        </Grid>
      </Grid>
      <DeleteModal
        open={openDelete}
        onClose={handleClose}
        onConfirm={handleDeleteSuccess}
        reportId={reportId ?? 0}
        name={name}
      />
    </PageArea>
  );
};

export default ScheduledReports;