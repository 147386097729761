import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

const LoadingWheel = () => {
  const { t } = useTranslation('common');

  return (
    <Stack spacing={theme.spacing(6)}
      sx={{ width: '82px', margin: 'auto' }}>
      <Box sx={{ alignContent: 'center', height: '64.5px', textAlign: 'center' }}>
        <CircularProgress
          size={60}
          thickness={5}
          sx={{
            '& .${circularProgressClasses.circle}': {
              strokeLinecap: 'round',
            },
          }}
        ></CircularProgress>
      </Box>
      <Typography
        variant="textLG"
        sx={{ color: theme.palette.custom.gray[700], textAlign: 'center' }}
      >
        {t('loading')}...
      </Typography>
    </Stack>
  );
};

export default LoadingWheel;
