import { Box, Paper, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import PendingChangesTable from './components/FranchiseChangesTable';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import { FranchiseChangesContext, FranchiseChangesContextValues } from '../../contexts/FranchiseChangesContext';

export default function FranchiseChanges() {
  const { t, isLoading, loadData, page } = useContext(FranchiseChangesContext) as FranchiseChangesContextValues;

  useEffect(() => loadData(), [page]);

  return (
    <Paper>
      <Box sx={{ p: '8px' }}>
        <Typography variant='displayXS'>{t('franchiseChanges')}</Typography>
      </Box>
      {isLoading ? <LoadingWheel/> : 
        <PendingChangesTable></PendingChangesTable>}
    </Paper>
  );
}