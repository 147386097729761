
import  { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface VoidedTransactionModalProps {
  readonly message: string;
}

function VoidedTransactionModal({ message }: VoidedTransactionModalProps) {
  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open}
        onClose={handleClose}>
        <DialogTitle>
          <IconButton aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VoidedTransactionModal;
