import { Alert, Box, FormControl, Grid, SelectChangeEvent, SvgIcon, TextField, useMediaQuery } from '@mui/material';
import { theme } from '../../../theme';
import { ArrowNarrowDown, ArrowNarrowLeft, ArrowNarrowRight, ArrowNarrowUp } from '../../../assets';
import { applyRoundAndFormat, formatDateString } from '../../../utils';
import StockTransferDetailTable from './StockTransferDetailTable';
import { TransferDetail } from '../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { TransferItemRow } from '../interfaces/StockTransferDetailsTypes';

const StockTransferDetailBox = (props: {
  transferId: number,
  transferDate: string,
  otherStoreName: string,
  requestedValue: number,
  receivedValue: number,
  transferType: string,
  note: string,
  currentStore: string,
  isDeleted: boolean,
  acceptedDate: string | undefined,
  transferDetails: TransferDetail[] | undefined,
  transferItemRows: TransferItemRow[],
  handleUomChange: (id: number) => (event: SelectChangeEvent) => void,
  handleQuantityChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleCopyQtysButtonClick: () => void,
  currencyCode?: string
}) => {

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('stockTransfersDetails');
  
  return (
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
      py: '0px',
      textAlign: 'left',
    }} >
      <Grid container
        spacing={5}
        sx={{
          borderColor: theme.palette.custom.gray[300],
          py: '12px',
          px: '8px',
        }} >  
        {props.isDeleted && <Grid item
          xs={12}>
          <Alert severity='error'>{t('deletedAlert')}</Alert>
        </Grid> }
        {!props.isDeleted && props.acceptedDate && <Grid item
          xs={12}>
          <Alert severity='success'>{t('approvedAlert')}</Alert>
        </Grid> }
        <Grid item
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('box.transferId')}
              size='small'
              variant="outlined"
              value={props.transferId} />
          </FormControl>
        </Grid>
        <Grid item
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('box.transferDate')}
              size='small'
              variant="outlined"
              value={formatDateString(props.transferDate)} />
          </FormControl>
        </Grid>       
        <Grid item
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('box.requestedValue')}
              size='small'
              variant="outlined"
              value={applyRoundAndFormat(props.requestedValue, props.currencyCode)} />
          </FormControl>
        </Grid>
        <Grid item
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('box.receivedValue')}
              size='small'
              variant="outlined"
              value={props.receivedValue ? applyRoundAndFormat(props.receivedValue, props.currencyCode) :  'N/A'} />
          </FormControl>
        </Grid>
        <Grid item
          md={5}
          sm={5}         
          xs={12}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={props.transferType.toLowerCase() === 'incoming' ? t('box.toStore') : t('box.fromStore')}
              size='small'
              variant="outlined"
              value={props.currentStore} />
          </FormControl>
        </Grid>
        <Grid item
          md={2}
          sm={2}  
          sx={{ textAlign: 'center', verticalAlign: 'center' }} 
          xs={12}>
          {isMobile && props.transferType.toLowerCase() === 'incoming' && <SvgIcon fontSize='large'
            color='success'><ArrowNarrowUp /></SvgIcon>}
          {isMobile && props.transferType.toLowerCase() === 'outgoing' && <SvgIcon fontSize='large'
            color='warning'><ArrowNarrowDown /></SvgIcon>}
          {!isMobile && props.transferType.toLowerCase() === 'incoming' && <SvgIcon fontSize='large'
            color='success'><ArrowNarrowLeft /></SvgIcon>}
          {!isMobile && props.transferType.toLowerCase() === 'outgoing' && <SvgIcon fontSize='large'
            color='warning'><ArrowNarrowRight /></SvgIcon>}         
        </Grid>
        <Grid item
          md={5}
          sm={5}         
          xs={12}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={props.transferType.toLowerCase() === 'incoming' ? t('box.fromStore') : t('box.toStore')}
              size='small'
              variant="outlined"
              value={props.otherStoreName} />
          </FormControl>
        </Grid>
        <Grid item
          xs={12}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('box.note')}
              size='small'
              multiline
              rows={4}
              variant="outlined"
              value={props.note} />
          </FormControl>
        </Grid>
      </Grid>
      <StockTransferDetailTable
        transferDetails={props.transferDetails}
        isDeleted={props.isDeleted}
        acceptedDate={props.acceptedDate}
        transferType={props.transferType}
        transferItemRows={props.transferItemRows}
        handleUomChange={props.handleUomChange}
        handleQuantityChange={props.handleQuantityChange}
        handleCopyQtysButtonClick={props.handleCopyQtysButtonClick}
        currencyCode={props.currencyCode}
      />
        
      
    </Box>);
};

export default StockTransferDetailBox;