import { useNavigate, useParams } from 'react-router-dom';
import useLogError from '../../hooks/useLogError';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import useNswagClient from '../../hooks/api/useNswagClient';
import { UserContext } from '../../components/shared/useUser';
import { ReceiveTransferHeaderRequest, TransferDetail, TransferHeader } from '../../app/services/api/generated';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { Alert, Box, Button, CircularProgress, Grid, IconButton, SelectChangeEvent, Snackbar, SvgIcon, Typography } from '@mui/material';
import { theme } from '../../theme';
import { ArrowLeft, Check, X } from '../../assets';
import StockTransferDetailBox from './components/StockTransferDetailBox';
import StockTransferDeactivateModal from './components/StockTransferDeactivateModal';
import { TransferItemRow } from './interfaces/StockTransferDetailsTypes';
import useCurrencyCodeConfiguration from '../../hooks/configurations/useCurrencyCodeConfiguration';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { Pill } from '../../components/shared/Pill';

const StockTransferDetailPage = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const stockTransferId = id ? parseInt(id) : 0;
  const { logError } = useLogError();
  const { getTransfer, deactivateTransfer, getUoms, receiveTransfer } = useNswagClient();
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const { t } = useTranslation('stockTransfersDetails');
  const [isDeactivationProcessing, setIsDeactivationProcessing] = useState(false);
  const [deactivationErrorResponse, setDeactivationErrorResponse] = useState<string | undefined>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeactivationSuccessAlert, setShowDeactivationSuccessAlert] = useState<boolean>(false);
  const [isDeactivateButtonDisabled, setIsDeactivateButtonDisabled] = useState<boolean>(false);
  const [isReceiveButtonDisabled, setIsReceiveButtonDisabled] = useState<boolean>(false);
  const [stockTransferDetails, setStockTransferDetails] = useState<TransferHeader>();
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [transferItemRows, setTransferItemRows] = useState<TransferItemRow[]>([]);
  const [isReceiving, setIsReceiving] = useState(false);
  const [receivingError, setReceivingError] = useState<string | undefined>('');
  const [showReceivingErrorAlert, setShowReceivingErrorAlert] = useState<boolean>(false);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  const navigate = useNavigate();

  useEffect(() => {
    if (stockTransferId !== 0) {
      getStockTransferDetails(stockTransferId);
    }
    getCurrencyCodeConfiguration();
  }, []);

  const getStockTransferDetails = (id: number) => {
    setLoading(true);
    getTransfer(id, selectedStore?.storeNumber).then((response) => {
      if(response.data) {
        setStockTransferDetails(response.data);
        if(!response.data.isDeleted && !response.data.acceptedDate && response.data.transferDetails){
          Promise.all(
            response.data.transferDetails.map(item => (
              getUoms(item.itemNumber ?? '', selectedStore?.storeNumber, true, true)
                .then((uomResponse) => {
                  return { id: item.id ?? '', possibleItemUoms: uomResponse.data?.uoms };
                })
                .catch((error) => {
                  logError(error);
                  return null;
                })
            )),
          ).then((allItems) => {
            const filteredItems = allItems.filter(possibleItemUoms => possibleItemUoms !== null) as TransferItemRow[];
            setTransferItemRows(currentItems => currentItems.concat(filteredItems));
          });
        }
        else
        {
          setTransferItemRows(response.data.transferDetails as TransferItemRow[]);
        }
      }
    }).catch((error) => {
      logError(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleReceiveButtonClick = () => {
    setIsReceiving(true);
    setIsDeactivateButtonDisabled(true);
    const requestObj: ReceiveTransferHeaderRequest = { receiveTransferDetailRequests: [] };
    let incompleteLines: number = 0;

    for(const detail of transferItemRows){
      if (!detail.selectedItemUom || detail.receivedQuantity === undefined) {
        incompleteLines++;
      }
      else {
        requestObj.receiveTransferDetailRequests.push({
          id: detail.id,
          receivedUom: detail.selectedItemUom,
          receivedQuantity: detail.receivedQuantity,
        });
      }
    }

    if(incompleteLines > 0) {
      setIsReceiving(false);
      setIsDeactivateButtonDisabled(false);
      let errorMsg: string = t('receivedQuantityCannotBeEmpty');
      errorMsg = errorMsg.replace('[NUMBER_INCOMPLETE_LINES]', incompleteLines.toString());
      setReceivingError(errorMsg);
      setShowReceivingErrorAlert(true);
    }
    else {
      receiveTransfer(stockTransferId, requestObj).then(() => {
        setIsReceiving(false);
        setIsDeactivateButtonDisabled(false);
        getStockTransferDetails(stockTransferId);
      }).catch((error) => {
        setIsReceiving(false);
        setIsDeactivateButtonDisabled(false);
        logError(error);
        setReceivingError(t('errorReceivingTransfer') + ' ' + error);
        setShowReceivingErrorAlert(true);
      });
    }
  };

  const handleDeactivateButtonClick = () => {
    setOpenDeactivateModal(true);
  };

  const handleDeactivateConfirm = () => {
    if(stockTransferId !== 0){
      setIsDeactivationProcessing(true);
      deactivateTransfer(stockTransferId).then(() => {
        setIsDeactivateButtonDisabled(true);
        setIsReceiveButtonDisabled(true);
        setOpenDeactivateModal(false);
        setShowDeactivationSuccessAlert(true);
        setTimeout(() => {
          navigate('/store/stock-transfers');
        }, 3000);
      }).catch((error) => {
        logError(error);
        setDeactivationErrorResponse(t('deactivateModal.errorDeactivatingTransfer') + ' ' + error);
        setIsDeactivateButtonDisabled(false);
        setIsReceiveButtonDisabled(false);
      }).finally(() => {
        setIsDeactivationProcessing(false);
      });
    }
  };

  const handleDeactivateCancel = () => {
    setDeactivationErrorResponse('');
    setOpenDeactivateModal(false);
  };

  const goBack = () => {
    navigate('/store/stock-transfers');
  };

  const handleUomChange = (id: number) => async (event: SelectChangeEvent) => {
    const newUoM: string = event.target.value;
    const updatedItems = transferItemRows.map((item) => {
      if (item.id === id) {
        return { ...item, selectedItemUom: newUoM }; // Update the selected UOM for the specific item
      }
      return item;
    });

    setTransferItemRows(updatedItems);
  };

  const handleQuantityChange = (id: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    let requestedQuantity: number = Number(event.target.value);
    if(requestedQuantity < 0)
      requestedQuantity = 0;
    const updatedItems = transferItemRows.map((item) => {
      if (item.id === id) {
        return { ...item, receivedQuantity: requestedQuantity }; // Update the selected UOM for the specific item
      }
      return item;
    });

    setTransferItemRows(updatedItems);

    // Ensure focus remains on the input field after the component re-renders
    setTimeout(() => {
      const inputField = document.getElementById(`quantity-input-${id}`);
      if (inputField) {
        inputField.focus();
      }
    });
  };

  const handleCopyQtysButtonClick = () => {
    const updatedRows = updateTransferItemRows(stockTransferDetails?.transferDetails || [], transferItemRows);
    setTransferItemRows(prevRows => [...updatedRows]);
  };

  const updateTransferItemRows = (details: TransferDetail[], rows: TransferItemRow[]) => {
    // Iterate over the details array
    details.forEach(detail => {
      // Find the matching TransferItemRow by id
      const matchingRow = rows.find(row => row.id === detail.id);

      // If a matching row is found, update its properties
      if (matchingRow) {
        matchingRow.selectedItemUom = detail.primaryUom;
        matchingRow.receivedQuantity = detail.requestedQuantity;
      }
    });

    // Return the updated array of TransferItemRow objects
    return rows;
  };

  const closeReceivingErrorAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setReceivingError('');
    setShowReceivingErrorAlert(false);
  };

  if (loading) {
    return <LoadingWheel />;
  }

  return (
    <PageArea>
      <Box
        display='flex'
        flexDirection='row'
        justifySelf='start'
        alignItems='center'
        sx={{ color: theme.palette.primary.main }}
      >
        <IconButton
          sx={{ color: theme.palette.primary.main }}
          size='small'
          onClick={goBack}
        >
          <ArrowLeft />
        </IconButton>
        <Typography variant='textSM'>{t('back')}</Typography>
      </Box>
      <Grid container
        sx={{ pb: '8px' }}>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
            }}>
            <PageTitle>
              {t('pageTitle')}
              {' '}
            </PageTitle>
            <Pill text={String(transferItemRows.length) + ' ' + String(transferItemRows.length == 1 ? 'Item' : 'Items')}/>
          </Box>
        </Grid>
        {hasPermissionTo(['TransferWrite']) && 
        <Grid item
          xs={12}
          sx={{ pr: '8px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {stockTransferDetails?.transferType === 'Incoming' && !stockTransferDetails?.isDeleted && !stockTransferDetails?.acceptedDate && <Button
            variant="primary"
            size="lg"
            disabled={isReceiving || isReceiveButtonDisabled}
            startIcon={!isReceiving ? <SvgIcon><Check color='white' /></SvgIcon> : null}
            endIcon={isReceiving ? <CircularProgress size={20} /> : null}
            onClick={handleReceiveButtonClick}
            sx={{ mr: '10px' }}
          >
            {!isReceiving ? t('approveButton') : t('approveButtonLoading')}
          </Button>}
          {!stockTransferDetails?.isDeleted && !stockTransferDetails?.acceptedDate && <Button
            variant="bad"
            size="lg"
            disabled={isDeactivateButtonDisabled}
            startIcon={<SvgIcon><X fill='white' /></SvgIcon>}
            onClick={handleDeactivateButtonClick}
          >
            {t('deactivateButton')}
          </Button>}
        </Grid>}
      </Grid>
      <StockTransferDetailBox
        transferId={stockTransferDetails?.transferId ?? 0}
        transferDate={stockTransferDetails?.transferDate ?? ''}
        otherStoreName={stockTransferDetails?.storeName ?? ''}
        requestedValue={stockTransferDetails?.totalRequestedValue ?? 0}
        receivedValue={stockTransferDetails?.totalReceivedValue ?? -1}
        transferType={stockTransferDetails?.transferType ?? ''}
        currentStore={selectedStore?.storeName ?? ''}
        transferDetails={stockTransferDetails?.transferDetails}
        transferItemRows={transferItemRows}
        isDeleted={stockTransferDetails?.isDeleted ?? false}
        acceptedDate={stockTransferDetails?.acceptedDate}
        note={stockTransferDetails?.note ?? ''}
        handleUomChange={handleUomChange}
        handleCopyQtysButtonClick={handleCopyQtysButtonClick}
        handleQuantityChange={handleQuantityChange}
        currencyCode={currencyCode}
      />
      <StockTransferDeactivateModal open={openDeactivateModal} 
        onConfirm={handleDeactivateConfirm} 
        onCancel={handleDeactivateCancel}
        isDeactivationProcessing={isDeactivationProcessing}
        errorMessage={deactivationErrorResponse}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showDeactivationSuccessAlert}
        onClose={() => setShowDeactivationSuccessAlert(false)}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setShowDeactivationSuccessAlert(false)}
          severity='success'
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('deactivateModal.successDeactivatingTransfer')}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showReceivingErrorAlert}
        onClose={closeReceivingErrorAlert}
        autoHideDuration={10000}
      >
        <Alert
          onClose={closeReceivingErrorAlert}
          severity='error'
          variant="filled"
          sx={{ width: '100%' }}
        >
          {receivingError}
        </Alert>
      </Snackbar>
    </PageArea>
  );
};

export default StockTransferDetailPage;