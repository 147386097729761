import { useContext } from 'react';
import { PurchaseOrderDetailsNoteModal } from './modals/PurchaseOrderDetailsNoteModal';
import { PurchaseOrderDetailsStockCheckModal } from './modals/PurchaseOrderDetailsStockCheckModal';
import MessagesSnackbar from '../../../components/shared/MessageSnackbar';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import { PurchaseOrderDetailsConfirmModal } from './modals/PurchaseOrderDetailsConfirmModal';
import { PurchaseOrderDetailsCopyConfirmModal } from './modals/PurchaseOrderDetailsCopyConfirmModal';
import { PurchaseOrderDetailsCreditRequestModal } from './modals/PurchaseOrderDetailsCreditRequestModal';
import { PurchaseOrderDetailCreditRequestCutOffModal } from './modals/PurchaseOrderDetailCreditRequestCutOffModal';
import { PurchaseOrderDetailsAddItemsModal } from './modals/PurchaseOrderDetailsAddItemsModal';

const PurchaseOrdersDetailsPopUps = () => {
  const { closeSnackBar,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  return <>
    <PurchaseOrderDetailsNoteModal />
    <PurchaseOrderDetailsStockCheckModal />
    <PurchaseOrderDetailsCopyConfirmModal />
    <PurchaseOrderDetailsConfirmModal />
    <PurchaseOrderDetailsCreditRequestModal />
    <PurchaseOrderDetailCreditRequestCutOffModal />
    <PurchaseOrderDetailsAddItemsModal />
    <MessagesSnackbar onClose={closeSnackBar}
      open={isSnackBarOpen}
      severity={snackBarSeverity}
      message={snackbarMessage}
      duration={snackBarDuration} />
  </>;
};

export default PurchaseOrdersDetailsPopUps;