import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid, TableSortLabel } from '@mui/material';
import { User } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import DeleteModal from './UserDeleteModal';
import CreateEditModal from './UserEditCreateModal';
import ExpandedRow from './ExpandedRow';
import { UserPutPostRequest } from '../types/UserPutPostRequest';
import useLogError from '../../../hooks/useLogError';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';

export default function DenseTable(props: {
  readonly list: User[], readonly loadData: () => void, readonly page: number, readonly setPage: React.Dispatch<React.SetStateAction<number>>
}) {
  const { t } = useTranslation('manageUsers');
  const { deleteUser, editUser } = useNswagClient();
  const [data, setData] = React.useState(props.list);
  const [selectedEntity, setSelectedEntity] = React.useState<User | undefined>(undefined);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [expandedRow, setExpandedRow] = React.useState<User | undefined>(undefined); 
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('emailAddress' as keyof User);
  const [showUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState<boolean>(false);
  const [showUpdateErrorMessage, setShowUpdateErrorMessage] = React.useState<boolean>(false);
  
  const rowsPerPage = 10;
  const maxPages = props.list
    ? Math.max(1, Math.ceil(props.list.length / rowsPerPage))
    : 1;

  const { logError } = useLogError();

  React.useEffect(() => {
    setData(props.list);
    handleSort('emailAddress');
  }, [props.list]);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSearchChange(property);
    }
  };

  const sortedData = (property: string) => {
    const isAsc = order === 'asc';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const comparator = (a: any, b: any) => {

      if (property === 'persona') {
        const descriptionA = a.persona?.description ?? '';
        const descriptionB = b.persona?.description ?? '';
        return isAsc ? descriptionA.localeCompare(descriptionB) : descriptionB.localeCompare(descriptionA);
      }
  
      if (property === 'storeAccessCount') {
        //make admin value highest
        const storeAccessCountA = a.userLevel === 'Admin' ? Number.MAX_SAFE_INTEGER : (a.persona?.personaRoles?.length ?? 0);
        const storeAccessCountB = b.userLevel === 'Admin' ? Number.MAX_SAFE_INTEGER : (b.persona?.personaRoles?.length ?? 0);
  
        return isAsc ? storeAccessCountA - storeAccessCountB : storeAccessCountB - storeAccessCountA;
      }
      
      if (typeof a[orderBy] === 'string') {
        return (isAsc ? a[orderBy]?.localeCompare(b[orderBy]) : b[orderBy]?.localeCompare(a[orderBy]));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]) as any;
      }
    };
    return props.list.sort(comparator);
  };

  const edit = (entity: User | undefined) => {
    setSelectedEntity(entity);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedEntity(undefined);
  };

  const expandRow = (entity: User) => {
    if (expandedRow && entity.id == expandedRow.id){
      setExpandedRow(undefined);
      return;
    }
    setExpandedRow(entity);
  };

  const deleteEntity = (entity: User) => {
    setSelectedEntity(entity);
    setShowDeleteModal(true);
  };

  const handleEdit = (body: UserPutPostRequest) => {
    editUser(selectedEntity?.id ?? 0, body).
      then(() => { setShowUpdateSuccessMessage(true);        
        setTimeout(() => props.loadData(), 2000);
      })
      .catch((error) => {
        logError(error);
      });
  };

  const closeProcessingAlert = () => {
    setShowUpdateSuccessMessage(false);
  };

  const closeErrorAlert = () => {
    setShowUpdateErrorMessage(false);
  };

  const handleDelete = () => {
    deleteUser(selectedEntity?.id ?? 0).then(() => props.loadData()).catch((error) => {
      logError(error);
    });
  };

  const handleSearchChange = (property: string) => {
    setData(sortedData(property));
  };

  const visibleRows = () => {
    return data.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage);
  };
  
  const getTableCellStyle = (row: User) => {
    return {
      borderBottom: expandedRow && expandedRow.id === row.id ? 'none' : '1px solid',
      borderBottomColor: theme.palette.custom.gray[200],
    };
  };

  return (
    <Grid item
      xs={12}
      p={5}>
      <TableContainer>
        <Table
          size="small"
          aria-label="a table"
        >
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell
                align="left"
                width={'30%'}
              >
                <TableSortLabel
                  active={orderBy === 'emailAddress'}
                  direction={order}
                  onClick={() => handleSort('emailAddress')}
                >
                  {t('userEmail')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
              >
                <TableSortLabel
                  active={orderBy === 'franchiseName'}
                  direction={order}
                  onClick={() => handleSort('franchiseName')}
                >
                  {t('franchise')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                width={'30%'}
              >
                <TableSortLabel
                  active={orderBy === 'persona'}
                  direction={order}
                  onClick={() => handleSort('persona')}
                >
                  {t('role')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                width={'20%'}
              >
                <TableSortLabel
                  active={orderBy === ''}
                  direction={order}
                  onClick={() => handleSort('storeAccessCount')}
                >
                  {t('storeAccessCount')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <React.Fragment key={row.id}>
                <TableRow
                  onClick={() => expandRow(row)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell
                    sx={{ 
                      ...getTableCellStyle(row),
                      textDecoration: 'underline' }}
                  >
                    {row.emailAddress}
                  </TableCell>
                  <TableCell
                    sx={getTableCellStyle(row)}
                  >
                    {row.userLevel == 'Admin' ? t('all') : String(row.franchiseName)}
                  </TableCell>
                  <TableCell
                    sx={getTableCellStyle(row)}
                  >
                    {row.userLevel == 'Admin' ? t('admin') : row.persona?.description ?? t('noRoles') ?? t('noFranchise')}
                  </TableCell>
                  <TableCell
                    sx={getTableCellStyle(row)}
                  >
                    {row.userLevel == 'Admin' ? t('all') : row.stores?.length ?? t('noPageAccess')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} 
                    colSpan={4}>
                    <Collapse in={expandedRow && expandedRow.id === row.id} 
                      timeout="auto" 
                      unmountOnExit>
                      <ExpandedRow entity={row}
                        delete={(user: User) => deleteEntity(user)}
                        edit={(user: User) => edit(user)}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={props.page}
        setPage={props.setPage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>

      <CreateEditModal openModal={showEditModal}
        onConfirm={(requestBody: UserPutPostRequest) => handleEdit(requestBody)}
        user={selectedEntity}
        onCancel={handleCloseModal}></CreateEditModal>
        
      <DeleteModal openModal={showDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModal}></DeleteModal>
      <MessagesSnackbar
        open={showUpdateSuccessMessage}
        onClose={closeProcessingAlert}
        message={t('successUpdateMessage')}
        severity="success"
        duration={null}
      />
      <MessagesSnackbar
        open={showUpdateErrorMessage}
        onClose={closeErrorAlert}
        message={t('errors.updatingUsers')}
        severity="error"
        duration={null}
      />
    </Grid>
  );
}
