import { Button, Grid } from '@mui/material';
import { Save01 } from '../../../assets';
import { AutomatedTask } from '../../../app/services/api/generated';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';

export interface AutomatedTasks  {
automatedTasks : AutomatedTask | undefined
}

const CreateScheduledReports =() => {
  const { 
    t,
    isEdit,
    onSave,
    goBack,
    endDateErrors,
  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  return (
    <Grid container 
    >
      <Grid item 
        xs={12}>
        <BackButton
          handleClick={goBack} 
          title={t('exit')}
          isLink={false}
        />
      </Grid>
      <Grid item 
        xs={12}>
        <PageTitle>{isEdit ? 'Edit Automated Task' : t('title')}</PageTitle>
      </Grid>
      <Grid item 
        xs ={12}
        sx ={{ display:'flex', justifyContent:'flex-end',alignItems:'flex-end' }}>
        <Button  size='lg'
          sx={{ ml: 3 }}
          disabled={Object.values(endDateErrors).some((error) => error !== null)}
          onClick={onSave}
        ><Save01/>{t('save')} 
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateScheduledReports;