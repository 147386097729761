import { Box, Button, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreditRequestBox from './components/CreditRequestBox';
import { SendCreditReqRequest, SendCreditReqRequest_LineItems } from '../../app/services/api/generated';
import { useState } from 'react';
import { Reason } from './enums/reason';
import { CreditRequestType } from './enums/credit-request-types';
import SendCreditRequestModal from './modals/SendCreditRequestModal';
import SendIcon from '@mui/icons-material/Send';
import { ArrowLeft } from '@mui/icons-material';
import PageTitle from '../../components/shared/PageTitle';

const CreditRequest = () => {
  const { t } = useTranslation('createCreditRequest');
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const PurchaseOrdersId = id ? parseInt(id) : undefined;
  const [sendCreditRequest, setSendCreditRequest] = useState<SendCreditReqRequest | undefined | null>();
  const [displayButton, setDisplayButton] = useState<boolean>(false); 
  const [creditRequestType, setCreditRequestType] = useState<number | undefined>();
  const [openSendRequestDialog, setOpenSendRequestDialog] = useState(false);

  const handleRequestTypeChange = (newValue: number) => {
    setCreditRequestType(newValue);
  };
  const handleCloseRequestDialog = () => {
    setOpenSendRequestDialog(false);
  };
  const handleSubmit = () => {
    setOpenSendRequestDialog(true);
  };
  const isPhotoRequired = (item: SendCreditReqRequest_LineItems) => {
    const reason = sendCreditRequest?.items.find(x => x.itemNumber == item.itemNumber)?.reasonForCreditRequest;
    return sendCreditRequest?.customerRmaNumber ? false : reason === Reason.DAMAGE || reason === Reason.EXPIRED_STALE_PRODUCT;
  };
  const isValid = () => {
    return (
      sendCreditRequest?.items.every(item =>
        item.itemNumber &&
        item.reasonForCreditRequest &&
        (!isPhotoRequired(item) || (item.photos && item.photos.length > 0)))

    );
  }; 
  const disableSendButton = () => {
    return (
      !isValid() || (sendCreditRequest != null && creditRequestType === CreditRequestType.ReturnToVendor &&
        (!sendCreditRequest.deliveryNoteNumber ||
          sendCreditRequest.deliveryNoteNumber === sendCreditRequest.customerRmaNumber ||
          !sendCreditRequest.documents))
    );
  };
  return (
    <Box display="flex"
      flexDirection="column" >
      <Grid container
        sx={{ p: '30px' }}>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <Link
            onClick={() => navigate('/store/po-receipt')}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <ArrowLeft /> {t('goBack')}
          </Link>
          <PageTitle>{t('creditRequest')} {PurchaseOrdersId}</PageTitle> 
        </Grid>
        <Grid item
          xs={12}
          sx={{ textAlign: 'right' }}>
            
          {displayButton && (sendCreditRequest?.items?.length ?? 0) > 0 && <Button variant="primary"
            onClick={handleSubmit}
            disabled={disableSendButton()}
            size="lg">
            <SendIcon /> {t('send')}
          </Button>}
        </Grid>
        <Grid item
          xs={12}
          sx={{ pt: '20px' }}>
          {<CreditRequestBox setSendCreditRequest={setSendCreditRequest}
            setDisplayButton={setDisplayButton} 
            onRequestTypeChange={handleRequestTypeChange} 
            purchaseOrderId={PurchaseOrdersId!}
            onInput={disableSendButton}
          />}
        </Grid>
      </Grid>
      {creditRequestType &&   <SendCreditRequestModal open={openSendRequestDialog}
        onClose={handleCloseRequestDialog}
        creditRequest={sendCreditRequest!}
        requestType={creditRequestType} />}
    </Box>
  );
};

export default CreditRequest;