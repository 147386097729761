import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { useTranslation } from 'react-i18next';
import { IconButton, Link, SvgIcon, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { GetPurchaseOrderHeadersResponse } from '../../../app/services/api/generated';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from './../../../utils';
import { Lock01, CreditCard } from './../../../assets';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { ConfigurationName } from '../../../enums/ConfigurationName';
import { UserContext } from '../../../components/shared/useUser';

export default function PurchaseOrdersTable(props: {
  readonly list: GetPurchaseOrderHeadersResponse, readonly setPageZero: number;
  readonly loadData: (sort: string, direction: string, skip: number, take: number) => void
}) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [data, setData] = useState(props.list.purchaseOrderHeaders);

  const rowsPerPage = 10;
  const maxPages = props.list?.purchaseOrderHeadersCount
    ? Math.max(1, Math.ceil(props.list.purchaseOrderHeadersCount / rowsPerPage))
    : 1;
  //Header sort
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('deliveryDate'); 
  const [loading, setLoading] = useState(false);
  const [isReturnToVendorEnabled, setIsReturnToVendorEnabled] = useState(false);
  const [isCreditRequestEnabled, setIsCreditRequestEnabled] = useState(false);

  const { getConfiguration } = useNswagClient();
  const { selectedStore } = React.useContext(UserContext);

  const isAsc = order === 'asc';

  const handleSort = (property: string) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(property);
    props.loadData(property, order, page * rowsPerPage, rowsPerPage);
  };

  useEffect(() => {
    setData(props.list.purchaseOrderHeaders);
    setLoading(false); 

  }, [props.list]);

  useEffect(() => {
    setLoading(true); 
    props.loadData(orderBy, order, page * rowsPerPage, rowsPerPage);
  }, [page]);

  useEffect(() => {
    setPage(0);
  }, [props.setPageZero]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.ReturnToVendorEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      if (res.data.value === 'true') {
        setIsReturnToVendorEnabled(true);
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.CreditRequestsEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      if (res.data.value === 'true') {
        setIsCreditRequestEnabled(true);
      }
    });
  },[]);

  const getCRStatusText = (status?: number) => {
    const actualStatus = status ?? 0;
    switch (actualStatus) {
    case 0:
      return t('creditRequest.status.notCreated');
    case 1:
      return t('creditRequest.status.createdNotSent');
    case 2:
      return t('creditRequest.status.sent');
    default:
      return '';
    }
  };

  const getRtVStatusText = (status?: number) => {
    const actualStatus = status ?? 0;
    switch (actualStatus) {
    case 0:
      return t('returnToVendor.status.notCreated');
    case 1:
      return t('returnToVendor.status.createdNotSent');
    case 2:
      return t('returnToVendor.status.sent');
    default:
      return '';
    }
  };

  return (
    <> {loading ? <LoadingWheel/> : (
      
      data &&
        <>
          <TableContainer component={'div'}>
            <Table
              size="small"
              aria-label="a table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'ewsOrderNumber'}
                      direction={order}
                      onClick={() => handleSort('ewsOrderNumber')}
                    >
                      {t('purchaseOrderReceipt.purchaseOrder')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'orderNumber'}
                      direction={order}
                      onClick={() => handleSort('orderNumber')}
                    >
                      {t('purchaseOrderReceipt.orderReference')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'acceptedDate'}
                      direction={order}
                      onClick={() => handleSort('acceptedDate')}
                    >
                      {t('purchaseOrderReceipt.receiptDate')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'deliveryDate'}
                      direction={order}
                      onClick={() => handleSort('deliveryDate')}
                    >
                      {t('purchaseOrderReceipt.deliveryDate')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'receivedBy'}
                      direction={order}
                      onClick={() => handleSort('receivedBy')}
                    >
                      {t('purchaseOrderReceipt.receivedBy')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'orderType'}
                      direction={order}
                      onClick={() => handleSort('orderType')}
                    >
                      {t('purchaseOrderReceipt.orderType')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    {t('purchaseOrderReceipt.purchaseOrderStatus')}
                  </TableCell>
                  { isCreditRequestEnabled && <TableCell align="left">
                    {t('purchaseOrderReceipt.creditRequestStatus')}
                  </TableCell>}
                  { isReturnToVendorEnabled && <TableCell align="left">
                    {t('purchaseOrderReceipt.rtvStatus')}
                  </TableCell>
                  }
                  <TableCell align="left">
                    {t('purchaseOrderReceipt.note')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => navigate(`/create-po-receipt/${row.id}`)}
                  >
                    <TableCell>
                      {row.creditRequestStatus !== 0 ? (
                        < CreditCard /> ) :(
                        <>
                        </>

                      )}
                    </TableCell>
                    <TableCell>
                      {row.ewsOrderNumber}
                    </TableCell>
                    <TableCell>
                      {row.orderNumber}
                    </TableCell>
                    <TableCell>
                      {row.acceptedDate ? getFormattedDate(new Date(row.acceptedDate)) : null}
                    </TableCell>
                    <TableCell>
                      {row.deliveryDate ? getFormattedDate(new Date(row.deliveryDate)) : null}
                    </TableCell>
                    <TableCell>
                      {row.receivedBy}
                    </TableCell>
                    <TableCell>
                      {row.orderType}
                    </TableCell>
                    <TableCell>
                      {row.isReceived ? <SvgIcon><Lock01 color='gray'
                        width='80%' /></SvgIcon> : ''}
                    </TableCell>
                    { isCreditRequestEnabled && <TableCell>
                      {row.creditRequestStatus !== 0 ? ( <Link href={`/store/credit-request/${row.id}`}  
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        color="primary"
                        underline ={'always'}
                        style={{ cursor: 'pointer' }}
                      >
                        {getCRStatusText(row.creditRequestStatus)}
                      </Link>) : (
                        <span>{getCRStatusText(row.creditRequestStatus)}</span> )}
                    </TableCell>}
                    { isReturnToVendorEnabled && <TableCell>
                      {row.returnToVendorStatus !== 0 ? (
                        <Link href={`/store/credit-request/${row.id}`}  
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          color="primary"
                          underline ={'always'}
                          style={{ cursor: 'pointer' }}
                        >
                          {getRtVStatusText(row.returnToVendorStatus)}
                        </Link>) : (
                        <span>{getRtVStatusText(row.returnToVendorStatus)}</span> )}
                    </TableCell>
                    }
                    <TableCell>
                      <Tooltip title={row.note}>
                        <IconButton disabled ={!row.note}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>                   
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {props.list?.purchaseOrderHeadersCount === 0 ? (
            <Typography variant="textSM">
              {t('purchaseOrderReceipt.noDataMessage')}
            </Typography>
          ) : (
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={maxPages}
              breakpointForChangeDisplay={120000}
            />
          )}
        </>
    )}
    </>
  );
}
