import { Button, Dialog, DialogActions, DialogContent,  FormControl,  Grid,  MenuItem,  Select,  SelectChangeEvent,  TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { Plus, X } from '../../../assets';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { PostReasonRequest } from '../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';

export interface Items  {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface ActionTypes {
  description: string,
}
const actionTypes: ActionTypes[] = [
  { description: 'Please Select Action Type' },
  { description: 'Issue' },
  { description: 'Receive' },
];
 
const CreateReasonModal=( props: Items) => {
  const { saveTransactionReasons } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const { logError } = useLogError();
  const[ description, setDescription ] = useState<string>('');
  const[ name, setName ] = useState<string>('');
  const [actionType, setActionType] = useState<string>(actionTypes[0].description);
  const { t } = useTranslation('manageTransactions');

  const saveReasons = () => {
    const body : PostReasonRequest = {
      name:name,
      description:description,
      actionType:actionType,
    };
    const storeNumber = selectedStore?.storeNumber ?? '';
    saveTransactionReasons(storeNumber, body).then(() => {
      props.onConfirm();
    })
      .catch((error) => {
        logError(error);
      });
  };
  
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };
  
  const handleSelect = (event: SelectChangeEvent< string>) => {
    setActionType(event.target.value);
  };
  
  const handleClose = () => {
    setName('');
    setActionType(actionTypes[0].description);
    setDescription('');
    props.onClose();
  };
  
  return (
    <Dialog open={props.open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth>
      <Grid 
        sx={{ m:'2px' }}>
        <Grid
          sx={{ display:'flex',alignItems:'flex-end', justifyContent:'flex-end' }}>
          <X  onClick={props.onClose} />
        </Grid>
        <Grid 
          sx={{ display:'flex',alignItems:'center', justifyContent:'center' }}>
          <Typography variant='textXL'>{t('createTitle')}</Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid sx={{ pt:'30px' }}>
          <TextField
            placeholder={t('createTitle')}
            multiline
            fullWidth
            onChange={handleNameChange}
          />
        </Grid>
        <Grid sx={{  pt:'30px' }}>
          <TextField
            placeholder={t('description')}
            multiline
            rows={5}
            fullWidth
            onChange={handleDescriptionChange}
          />
        </Grid>
        <Grid sx={{  pt:'30px' }}>
          <FormControl
            fullWidth>
            <Select
              value={actionType}
              onChange={handleSelect}
              fullWidth>
              {actionTypes.map((option) => (
                <MenuItem key={option.description}
                  value={option.description}>
                  {option.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="secondary"
          onClick={handleClose}> {t('cancel')}</Button>
        <Button 
          disabled={!name || !description || actionType === actionTypes[0].description}
          onClick={saveReasons}
        ><Plus />
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog> 
  );
};

export default CreateReasonModal;