import { Box, SvgIcon, Collapse, Divider, useTheme } from '@mui/material';
import { DotsVertical } from '../../../../../assets';
import { useContext, useState } from 'react';
import { DrawerContext, DrawerValues } from '../../contexts/DrawerContext';
import { LayeredNavGroup } from '../LayeredNavGroup';
import { UserNavItemsList } from './UserNavItemsList';
import { StoreDisplay } from './StoreDisplay';

interface MobileFooterProps {
    onLogOut: () => void,
    onSwitchStore: () => void,
}

export const MobileFooter = ({ onLogOut, onSwitchStore }: MobileFooterProps) => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const { systemNavItemsToUse, slidingSubMenuWidth, setSelectedNavItem } = useContext(DrawerContext) as DrawerValues;

  return <Box
    borderRadius={3}
    bgcolor={!collapsed ? theme.palette.primary[400] : 'transparent'}
    p={0}
    my={3}
    bottom={0}
    position="fixed"
    width={slidingSubMenuWidth}
    sx={{ cursor: 'pointer' }}
  >
    <Collapse in={!collapsed}>
      <LayeredNavGroup navItemList={systemNavItemsToUse}/>
      <UserNavItemsList logOutOnClick={onLogOut}
        switchStoreOnClick={onSwitchStore} />
    </Collapse>
    {!collapsed && <Divider
      variant={'middle'}
      color={theme.palette.custom.gray[300]}
      sx={{
        root: {
          margin: 'auto',
          width: '90%',
          opacity: '50%',
        },
      }}
    />}
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      gap={5}
      alignItems='stretch'
      onClick={() => {
        if (collapsed) setSelectedNavItem(undefined);
        setCollapsed(prev => !prev);
      }}
      px={6}
    >
      <StoreDisplay />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        color={theme.palette.common.white}
      >
        <SvgIcon
          sx={{
            fontSize: 16,
          }}>
          <DotsVertical />
        </SvgIcon>
      </Box>
    </Box >
  </Box>;
};