import { StockCheck, StockCheckItem } from '../../app/services/api/generated';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNswagClient from '../../hooks/api/useNswagClient';
import { compare, compareStringsAsLower } from '../../utils';
import { Alert, Box, Button, Collapse, IconButton, Skeleton, Snackbar, Typography } from '@mui/material';
import { theme } from '../../theme';
import StockCheckHeader from './components/StockCheckTop';
import StockCheckContent from './components/StockCheckContent';
import StockCheckApproveDialog from './components/StockCheckApproveDialog';
import useLogError from '../../hooks/useLogError';
import { useTranslation } from 'react-i18next';
import { ChevronUp, ChevronDown, ArrowLeft } from '../../assets';
import { UserContext } from './../../components/shared/useUser';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

export const StockCheckStatusMap = {
  1: 'Counting',
  2: 'Complete',
  3: 'Pending',
  4: 'Pending Approval',
};

export interface TempStockCheckItem extends StockCheckItem {
  isExpanded: boolean;
}

export interface TempCategoryItem {
  name: string;
  items: TempStockCheckItem[];
}

export interface TempStockCheck extends StockCheck {
  tempCategoryItems?: TempCategoryItem[];
  tempCategoryAllItems?: TempCategoryItem[];
}

export interface GroupedStockCheckDetails {
  stockCheck?: TempStockCheck;
  lastPosDate?: string;
  hasPosData?: boolean;
  maxToleranceWarningValue?: number;
}

const StockCountReviewPage = () => {
  const { getStockCheckById, approveStockCheck } = useNswagClient();
  const navigate = useNavigate();
  const { t } = useTranslation('stockCountReview');
  const { id } = useParams<{ id: string | undefined }>();
  const stockCheckId = id ? parseInt(id) : 0;
  const { logError } = useLogError();
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ isApproving, setIsApproving ] = useState<boolean>(false);
  const [ groupedStockCount, setGroupedStockCount ] = useState<GroupedStockCheckDetails>();
  const [ isViewMode, setIsViewMode ] = useState<boolean>(false);
  const [ showApproveDialog, setShowApproveDialog ] = useState<boolean>(false);
  const [ saveErrorMessage, setSaveErrorMessage ] = useState<string>('');
  const [ showSubmitStatusMessage, setShowSubmitStatusMessage ] = useState<boolean>(false);
  const [ isSubmittedSuccessfully, setIsSubmittedSuccessfully ] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [ isCompleted, setIsCompleted ] = useState<boolean>(false);
  const [ itemsAboveMaxTolerance, setItemsAboveMaxTolerance ] = useState<StockCheckItem[]>([]);
  const { hasPermissionTo } = useContext(UserContext);

  useEffect(() => {
    if (stockCheckId !== 0) {
      getStockCheck(stockCheckId);
    }
  }, [isSubmittedSuccessfully]);

  const getStockCheck = (id: number) => {
    setLoading(true);
    getStockCheckById(id).then((response) => {
      if (response.data) {
        const formattedStockCount = groupItemsByCategory(response.data);
        setGroupedStockCount(formattedStockCount);

        // if stock count is completed
        if (formattedStockCount?.stockCheck?.status === 2) {
          setIsCompleted(true);
        }
      }
    }).catch((error) => {
      logError(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const createCategoriesOfItems = (items: StockCheckItem[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const categories: any = {};

    if (items) {
      // Group stock check items into categories to list them for display
      for (const item of items) {
        if (item.category) {
          if (categories[item.category]?.items) {
            categories[item.category].items.push(item);
          } else {
            categories[item.category] = {
              name: item.category,
              items: [item],
            };
          }
        }
      }
    }

    return categories;
  };

  const filterOutAutoApprovedItems = (categoryItems: TempCategoryItem[]) => {
    return categoryItems.map((tempStockCheck: TempCategoryItem) => {
      const filteredItems = tempStockCheck.items
        .filter((item: TempStockCheckItem) => 
          Number(item.quantityOnHand).toFixed(2) !== Number(item.countQuantityTotal).toFixed(2),
        );
    
      return {
        ...tempStockCheck,
        items: sortItemsByVariance(filteredItems),
      };
    })
      .filter((tempStockCheck: TempCategoryItem) => tempStockCheck.items.length > 0);
  };

  const sortCategoryItems = (categoryItems: TempCategoryItem[]) => {
    return categoryItems.map((tempStockCheck: TempCategoryItem) => {
      const sortedItems = sortItemsByVariance(tempStockCheck.items);
      return {
        ...tempStockCheck,
        items: sortedItems,
      };
    });
  };

  const sortItemsByVariance = (items: TempStockCheckItem[]): TempStockCheckItem[] => {
    return items.sort((a: TempStockCheckItem, b: TempStockCheckItem) => compare(a, b, 'varianceValue', 'asc'));
  };

  const groupItemsByCategory = (stockCountData: GroupedStockCheckDetails) => {
    if ((stockCountData.stockCheck?.items?.length ?? 0) <= 0) {
      return;
    }

    if (stockCountData?.stockCheck?.items) {
      
      stockCountData.stockCheck.tempCategoryItems = [];
      stockCountData.stockCheck.tempCategoryAllItems = [];
      
      const categories = createCategoriesOfItems(stockCountData.stockCheck.items);
  
      for (const i of Object.keys(categories)) {
        stockCountData.stockCheck.tempCategoryItems.push(categories[i]);
        stockCountData.stockCheck.tempCategoryAllItems.push(categories[i]);
      }
  
      // Sort alphabetically
      stockCountData.stockCheck.tempCategoryItems.sort((a, b) => compareStringsAsLower(a.name ?? '', b.name ?? ''));
      stockCountData.stockCheck.tempCategoryAllItems.sort((a, b) => compareStringsAsLower(a.name ?? '', b.name ?? ''));
  
      // Filter stock count to remove items with no variance (auto approved ones)
      if (stockCountData.stockCheck.status === 4 && stockCountData.stockCheck.tempCategoryItems.length > 0) {
        stockCountData.stockCheck.tempCategoryItems = filterOutAutoApprovedItems(stockCountData.stockCheck.tempCategoryItems);
      }

      if (stockCountData.stockCheck.status === 4 && stockCountData.stockCheck.tempCategoryAllItems.length > 0) {
        stockCountData.stockCheck.tempCategoryAllItems = sortCategoryItems(stockCountData.stockCheck.tempCategoryAllItems);
      }
    }

    return stockCountData;
  };

  const updateAllItems = (checked: boolean) => {
    if (saveErrorMessage !== '') {
      setSaveErrorMessage('');
    }

    const updatedData = (groupedStockCount?.stockCheck?.tempCategoryItems ?? []).map((tempCategoryItem: TempCategoryItem) => {
      return {
        ...tempCategoryItem,
        items: (tempCategoryItem.items ?? []).map((item: TempStockCheckItem) => {
          return {
            ...item,
            isApproved: checked,
          };
        }),
      };
    });

    updateGroupedStockCount(updatedData);
  };

  const rejectAllApprovalItems = () => {
    updateAllItems(false);
  };

  const approveAllApprovalItems = () => {
    updateAllItems(true);
  };

  const hasUndefinedStockCheckItems = (): boolean => {
    let initialItemsLength: number = 0;
    let updatedItemsLength: number = 0;

    if (groupedStockCount?.stockCheck?.items && groupedStockCount?.stockCheck?.tempCategoryItems) {
      // filter out auto approved items
      initialItemsLength = groupedStockCount.stockCheck.items.filter((item) => item.isApproved !== null).length;

      // count updated items of each category
      for (const category of groupedStockCount.stockCheck.tempCategoryItems) {
        const updatedCategoryItems = category.items.filter((item) => item.isApproved === null).length;
        updatedItemsLength += updatedCategoryItems;
      }
      return initialItemsLength !== updatedItemsLength;
    }
    return true;
  };

  const onShowConfirmApprovalModal = () => {
    if (hasUndefinedStockCheckItems()) {
      setSaveErrorMessage(t('saveErrorMessage'));
      return;
    }

    const maxToleranceItems = groupedStockCount?.stockCheck?.tempCategoryItems
      ? groupedStockCount.stockCheck.tempCategoryItems.flatMap(category => category.items)
        .filter(item => item.isApproved && Math.abs(item.varianceValue ?? 0) > (groupedStockCount.maxToleranceWarningValue ?? 0))
        .map(item => ({ ...item, isApproved: undefined }))
      : [];

    setItemsAboveMaxTolerance(maxToleranceItems);

    setShowApproveDialog(true);
  };

  const getAllTempCategoryItems = (): StockCheckItem[] => {
    const updatedStockCheckItems: StockCheckItem[] = groupedStockCount?.stockCheck?.tempCategoryItems
      ? groupedStockCount.stockCheck.tempCategoryItems.flatMap(category => category.items)
      : [];

    const autoApprovedItems: StockCheckItem[] = groupedStockCount?.stockCheck?.items
      ? groupedStockCount?.stockCheck?.items.filter((item: StockCheckItem) => 
        Number(item.quantityOnHand).toFixed(2) === Number(item.countQuantityTotal).toFixed(2),
      ).map((item: StockCheckItem) => {
        return {
          ...item,
          isApproved: true,
        };
      }) : [];

    return updatedStockCheckItems.concat(autoApprovedItems);
  };

  const submitApprovedStockCount = () => {
    if (groupedStockCount?.stockCheck?.tempCategoryItems) {
      setSaveErrorMessage('');
      setIsApproving(true);

      const updatedStockCheckItems = getAllTempCategoryItems();

      approveStockCheck(stockCheckId, updatedStockCheckItems).then(() => {
        setIsSubmittedSuccessfully(true);
      }).catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsSubmittedSuccessfully(false);
      }).finally(() => {
        setIsApproving(false);
        setShowApproveDialog(false);
        setShowSubmitStatusMessage(true);
      });
    }
  };

  const handleItemStatusChange = (itemId: number | undefined, checked: boolean) => {
    if (groupedStockCount?.stockCheck?.tempCategoryItems) {
      const updatedData: TempCategoryItem[]= groupedStockCount?.stockCheck?.tempCategoryItems.map(category => {
        const updatedItems = category.items.map((item: TempStockCheckItem) => {
          if (item.id === itemId) {
            return {
              ...item,
              isApproved: checked,
            };
          }
          return item;
        });

        return {
          ...category,
          items: updatedItems,
        };
      });
  
      updateGroupedStockCount(updatedData);
    }
  };

  const sortPartialStockCountItems = (categoryIndex: number, fieldToSort: keyof TempStockCheckItem, sortDirection: 'asc' | 'desc') => {
    if (groupedStockCount?.stockCheck?.tempCategoryItems) {
      const updatedData = (groupedStockCount.stockCheck?.tempCategoryItems ?? []).map((tempCategory, index) => ({
        ...tempCategory,
        items: index === categoryIndex
          ? [...tempCategory.items].sort((a, b) => compare(a, b, fieldToSort, sortDirection))
          : tempCategory.items,
      }));
  
      updateGroupedStockCount(updatedData);
    }
  };

  const togglePartialStockCountItem = (itemNumber: string | undefined) => {
    if (groupedStockCount?.stockCheck?.tempCategoryItems) {
      const updatedData = groupedStockCount.stockCheck.tempCategoryItems.map((tempCategory: TempCategoryItem) => {
        return {
          ...tempCategory,
          items: tempCategory.items.map((item: TempStockCheckItem) => {
            return {
              ...item,
              isExpanded: item.itemNumber === itemNumber ? !item.isExpanded : item.isExpanded,
            };
          }),
        };
      });
  
      updateGroupedStockCount(updatedData);
    }
  };

  const updateGroupedStockCount = (updatedData: TempCategoryItem[]): void => {
    setGroupedStockCount((prevGroupedStockCount: GroupedStockCheckDetails | undefined) => ({
      ...prevGroupedStockCount,
      stockCheck: {
        ...prevGroupedStockCount?.stockCheck,
        tempCategoryItems: updatedData,
      },
    }));
  };

  const sortPartialStockCountAllItems = (categoryIndex: number, fieldToSort: keyof TempStockCheckItem, sortDirection: 'asc' | 'desc') => {
    if (groupedStockCount?.stockCheck?.tempCategoryAllItems) {
      const updatedData = (groupedStockCount.stockCheck?.tempCategoryAllItems ?? []).map((tempCategory, index) => ({
        ...tempCategory,
        items: index === categoryIndex
          ? [...tempCategory.items].sort((a, b) => compare(a, b, fieldToSort, sortDirection))
          : tempCategory.items,
      }));
  
      updateGroupedFullStockCount(updatedData);
    }
  };

  const togglePartialStockCountAllItems = (itemNumber: string | undefined) => {
    if (groupedStockCount?.stockCheck?.tempCategoryAllItems) {
      const updatedData = groupedStockCount.stockCheck.tempCategoryAllItems.map((tempCategory: TempCategoryItem) => {
        return {
          ...tempCategory,
          items: tempCategory.items.map((item: TempStockCheckItem) => {
            return {
              ...item,
              isExpanded: item.itemNumber === itemNumber ? !item.isExpanded : item.isExpanded,
            };
          }),
        };
      });
  
      updateGroupedFullStockCount(updatedData);
    }
  };

  const updateGroupedFullStockCount = (updatedData: TempCategoryItem[]): void => {
    setGroupedStockCount((prevGroupedStockCount: GroupedStockCheckDetails | undefined) => ({
      ...prevGroupedStockCount,
      stockCheck: {
        ...prevGroupedStockCount?.stockCheck,
        tempCategoryAllItems: updatedData,
      },
    }));
  };

  const goBack = () => {
    navigate('/store/stock-count');
  };

  return (
    <PageArea>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignContent: 'center', p: '24px' }}>
        <Box
          display='flex'
          flexDirection='row'
          justifySelf='start'
          alignItems='center'
          sx={{ color: theme.palette.primary.main }}
        >
          <IconButton
            sx={{ color: theme.palette.primary.main }}
            size='small'
            onClick={goBack}
          >
            <ArrowLeft />
          </IconButton>
          <Typography variant='textSM'>{t('goBack')}</Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'left',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignSelf: 'stretch',
            width: '100%',
          }}>
          {loading ? (
            <Skeleton variant="rounded"
              height={40}
              width={300}
              animation="wave"
              sx={{ my: 3 }}
            />
          ): (
            <PageTitle>{t('stockCount')} {groupedStockCount?.stockCheck?.name ?? ''}</PageTitle>
          )
          }
          {groupedStockCount?.stockCheck?.status ? (
            <Typography variant="textSM">
              {t('status')} {StockCheckStatusMap[groupedStockCount?.stockCheck?.status]}
            </Typography>
          ) : (
            <Skeleton variant="rounded"
              height={30}
              width={150}
              animation="wave"
              sx={{ my: 3 }}
            />
          )
          }
        </Box>
        {!isCompleted && hasPermissionTo(['StockCountAdmin']) && <Box
          display="flex"
          justifyContent="flex-end"
          my={4}
        >
          <Button
            variant="primary"
            onClick={() => onShowConfirmApprovalModal()}
            disabled={isApproving}
          >
            <Typography
              variant="textSM"
              sx={{
                px: '2px',
                textTransform: 'none',
              }}
            >
              {t('approve')}
            </Typography>
          </Button>
        </Box>
        }
        <Box
          sx={{ p: '12px', border: `solid 1px ${theme.palette.custom.gray[200]}`, borderRadius: '4px' }}
        >
          {
            loading ? (
              <Box display="flex"
                flexDirection="row"
                justifyContent='space-between'>
                <Skeleton animation="wave"
                  variant="rounded"
                  width="33%"
                  height={50} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="33%"
                  height={50} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="33%"
                  height={50} />
              </Box>
            ) : (
              <StockCheckHeader
                stockCheckId={stockCheckId}
                countedBy={groupedStockCount?.stockCheck?.countedBy ?? ''}
                approvedBy={groupedStockCount?.stockCheck?.approvedBy ?? ''}
                plannedCountDate={new Date(groupedStockCount?.stockCheck?.plannedCountDate ?? '').toDateString()}
                isCompleted={isCompleted}
                hasPosData={groupedStockCount?.hasPosData}
                lastPosDate={groupedStockCount?.lastPosDate}
                status={groupedStockCount?.stockCheck?.status}
              />
            )
          }
          {!isCompleted && <Box
            display="flex"
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            pt={8}
          >
            {
              saveErrorMessage && 
              <Alert severity="error"
                sx={{ display: 'flex', flex: 3, mr: 7 }}>{saveErrorMessage}</Alert>
            }
            <Box
              display="flex"
              flex={1}
              flexDirection='row'
              justifyContent="flex-end"
            >
              <Button
                variant="bad"
                onClick={rejectAllApprovalItems}
                disabled={isApproving}
              >
                <Typography
                  variant="textSM"
                  sx={{
                    px: '2px',
                    textTransform: 'none',
                  }}
                >
                  {t('rejectAll')}
                </Typography>
              </Button>
              <Button
                variant="primary"
                onClick={approveAllApprovalItems}
                disabled={isApproving}
                sx={{ marginLeft: 3 }}
              >
                <Typography
                  variant="textSM"
                  sx={{
                    px: '2px',
                    textTransform: 'none',
                  }}
                >
                  {t('acceptAll')}
                </Typography>
              </Button>
            </Box>
          </Box>
          }
          {
            loading ? (
              <Box display='flex'
                flexDirection='column'
                rowGap={5}
                my={10}>
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
              </Box>
            ) : (
              <StockCheckContent
                groupedStockCountItems={groupedStockCount?.stockCheck?.tempCategoryItems}
                onItemStatusChange={handleItemStatusChange}
                sortPartialStockCountItems={sortPartialStockCountItems}
                togglePartialStockCountItem={togglePartialStockCountItem}
                isDisabled={isCompleted || isApproving}
              />
            )
          }
          {
            loading ? (
              <Box display='flex'
                flexDirection='column'
                rowGap={5}
                my={10}>
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
                <Skeleton animation="wave"
                  variant="rounded"
                  width="100%"
                  height={30} />
              </Box>
            ) : (
              !isCompleted && <>
                <Box sx={{ background: theme.palette.custom.gray[200], height: 3, my: 5 }} />
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                  py={3}
                >
                  <Typography
                    variant='textMD'
                    sx={{ display: 'flex', justifySelf: 'start' }}
                  >
                    {t('viewFull')}
                  </Typography>
                  <Box display='flex'
                    flexDirection='row'
                    justifySelf='end'
                    alignItems='center'
                  >
                    <Typography variant='textMD'>{t('view')}</Typography>
                    <IconButton onClick={() => setIsViewMode(!isViewMode)}>
                      {isViewMode ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={isViewMode}>
                  <StockCheckContent
                    groupedStockCountItems={groupedStockCount?.stockCheck?.tempCategoryAllItems}
                    sortPartialStockCountItems={sortPartialStockCountAllItems}
                    togglePartialStockCountItem={togglePartialStockCountAllItems}
                    isDisabled={true}
                    isViewMode
                  />
                </Collapse>
              </>
            )
          }
          
        </Box>
        <StockCheckApproveDialog
          open={showApproveDialog}
          onClose={() => setShowApproveDialog(false)}
          onSubmit={submitApprovedStockCount}
          onItemStatusChange={handleItemStatusChange}
          isApproving={isApproving}
          maxToleranceWarningValue={groupedStockCount?.maxToleranceWarningValue}
          itemsAboveMaxTolerance={itemsAboveMaxTolerance}
          setItemsAboveMaxTolerance={setItemsAboveMaxTolerance}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showSubmitStatusMessage}
          onClose={() => setShowSubmitStatusMessage(false)}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => setShowSubmitStatusMessage(false)}
            severity={isSubmittedSuccessfully ? 'success' : 'error'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {isSubmittedSuccessfully ? t('successMessage') : t('errorMessage') + ' ' + errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </PageArea>
  );
};

export default StockCountReviewPage;
