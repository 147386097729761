import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useContext } from 'react';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';

export function AcceptModal() {
  const { t, closesAcceptModal,  isOpenAcceptModal, acceptItem, acceptId } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  const handleClose = () => {
    closesAcceptModal();
  };

  return (
    <Dialog onClose={handleClose}
      open={isOpenAcceptModal}>
      <DialogTitle> {t('acceptModalTitle')}</DialogTitle>
      <DialogContent sx={{ pt:'4px' }}>
        {t('acceptInvoice')}
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleClose}>
          {t('close')}
        </Button>
        <Button variant='primary'
          size="lg"
          onClick={() => acceptItem(acceptId ?? -1)}>
          {t('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
