export enum OrderStatus {
    Editable = 'Editable',
    OrderRequested = 'Order Requested',
    OrderConfirmed = 'Order Confirmed',
    OrderShipped = 'Order Shipped',
    OrderNotPlaced = 'Order Not Placed',
  } 

export const OrderStatusTranslationMap = { 
  [OrderStatus.Editable]: 'editable',
  [OrderStatus.OrderRequested]: 'orderRequested',
  [OrderStatus.OrderConfirmed]: 'orderConfirmed',
  [OrderStatus.OrderShipped]: 'orderShipped',
  [OrderStatus.OrderNotPlaced]: 'orderNotPlaced',
} as const; 
 
export function isOrderStatus(value: string | null | undefined): value is OrderStatus {
  return value != null && Object.values(OrderStatus).includes(value as OrderStatus);
}

