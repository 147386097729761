import { useContext, useState } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from '@mui/material';
import { theme } from '../../../../../theme';
import InfoIcon from '@mui/icons-material/Info';
import { NoteModal } from '../../TransactionNoteModal';

export const CreateTransactionsDuplicateItemsTable = () => {
  const { duplicateTransactions, t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [note, setNote] = useState('');

  const handleNoteButtonClick = (note: string) => () => {
    setNote(note);
    setIsNoteModalOpen(true);
  };

  const closeNoteModal = () => {
    setIsNoteModalOpen(false);
  };

  const bodyStyle = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
    px: '12px',
  };

  return (
    <Grid item
      xs={12}>
      <TableContainer component={'div'}>
        <Table
          stickyHeader
          size="small"
          aria-label="a table"
          sx={{
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderColor: theme.palette.custom.gray[200],
            width: '100%',
          }}
        >
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.type')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.sku')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.itemDescription')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.quantity')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.transactionDate')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.reason')}
              </TableCell>
              <TableCell sx={{ px: '12px', fontWeight: 'bold' }}
                align="left">
                {t('itemTable.note')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {duplicateTransactions.map((row) => (
              <TableRow
                key={row.transactionId?.toString() + '-' + row.productItemNumber?.toString()}
                sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.type}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.productItemNumber}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.productItemDescription}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.productQuantity}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {new Date(row.transactionTime ?? 0).toLocaleString()}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.reason}
                </TableCell>
                <TableCell scope="row"
                  sx={bodyStyle}>
                  {row.productNote && <Tooltip title={row.productNote}>
                    <IconButton onClick={handleNoteButtonClick(row.productNote)}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NoteModal onClose={closeNoteModal}
        open={isNoteModalOpen}
        note={note}
      ></NoteModal>
    </Grid>
  );
};