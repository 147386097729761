import useLogError from '../../../hooks/useLogError';
import { GroupedItemFeedChange } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useAllStoresChanges = () => {
  const { t } = useTranslation('recipeUpdates');
  const { getAllStoresRecipeChanges, disregardChanges, applyChanges } = useNswagClient();
  const { logError } = useLogError();
  
  const [data,  setData] = useState<GroupedItemFeedChange[]>([]);
  const [page,  setPage] = useState(0);
  const [maxPages,  setMaxPages] = useState<number>(0);
  const [isLoading, setIsLoading]= useState(false);
  const [isProcessing, setIsProcessing]= useState(false);
  const [isFinishedProcessing, setIsFinishedProcessing]= useState(false);
  const [isErrorProcessing, setIsErrorProcessing]= useState(false);
  const [errorMessage, setErrorMessage ] = useState('');

  const [isChangesDialogOpen, setIsChangesDialogOpen] = useState(false);
  const [isDisregardDialogOpen, setIsDisregardDialogOpen] = useState(false);
  const [viewId, setViewId] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[][]>([]);
  
  const handleViewButtonClick = (id: number[]) => () => {
    setViewId(id);
    setIsChangesDialogOpen(true);
  };

  const closeDialog = () => {
    setIsChangesDialogOpen(false);
  };

  const handleDisregardButtonClick = (id: number[]) => () => {
    setViewId(id);
    setIsDisregardDialogOpen(true);
  };

  const closeDisregardDialog = () => {
    setIsDisregardDialogOpen(false);
  };

  const loadData = (passedPage?: number) => {
    const pageToUse = passedPage ?? page;
    setIsLoading(true);
    getAllStoresRecipeChanges(pageToUse * 10, 10)
      .then(
        (result) => {
          setData(result.data ?? []);
          const count = result.totalCount ?? 0;
          const maxPages = Math.ceil(count / 10);
          setMaxPages(maxPages);
        },
      )
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleRowSelection = (itemId: number[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(itemId)) {
        return prevSelectedRows.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedRows, itemId];
      }
    });
  };

  const handleHeaderSelection = () => {
    if (selectedRows.length == 10){
      setSelectedRows([]);
    }
    else{
      setSelectedRows(data.map((row) => row.ids ?? []));
    }
  };

  const handleDisregardSelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await disregardChanges(selectedRows.flat())
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        setSelectedRows([]);
        setPage(0);
        loadData(0);
      });
  };

  const handleApplySelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await applyChanges(selectedRows.flat())
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        setSelectedRows([]);
        setPage(0);
        loadData(0);
      });
  };

  const dismissAlerts = () => {
    setIsErrorProcessing(false);
    setIsFinishedProcessing(false);
  };

  const changePage = (pageNumber: number) => {
    setPage(pageNumber);
    setSelectedRows([]);
  };

  return {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    loadData,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  };
};