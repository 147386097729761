import { Uom } from '../../../app/services/api/generated';

export interface LineRecord {
  id: number;
  quantity: string | undefined;
  uom: string;
  note: string;  
  readonly originalQuantity: string | undefined;
  readonly originalUom: string;
  readonly originalNote: string;
  readonly originalReason: string | undefined;
  expectedUom: string;
  expectedQuantity: number | undefined;
  uomList: Uom[] | undefined;
  itemNumber: string | undefined;
  itemDescription: string | undefined;
  lineNumber: string | undefined;
  tariffCode: string | undefined;
  hasDiscrepancy: boolean | undefined;
  reasonForCreditRequest: string | undefined;
}

export const enum CreditRequestStatus {
    NOT_CREATED = 0,
    CREATED_NOT_SENT,
    SENT,
    RECEIVED
}
