export const typography = {
  fontFamily: '"SoDoSans"',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  display2XL: {
    lineHeight: 5.62, // 90
    fontSize: '4.5rem', // 72px
    letterSpacing: '-2%',
  },
  displayXL: {
    lineHeight: 4.5, // 72
    fontSize: '3.75rem', // 60px
    letterSpacing: '-2%',
  },
  displayLG: {
    lineHeight: 3.75, // 60
    fontSize: '3rem', // 48px
    letterSpacing: '-2%',
  },
  displayMD: {
    lineHeight: 2.75, // 44
    fontSize: '2.25rem', // 36px
  },
  displaySM: {
    lineHeight: 2.375, // 38
    fontSize: '1.875rem', // 30px
  },
  displayXS: {
    lineHeight: 2, // 32
    fontSize: '1.5rem', // 24px
  },
  textXL: {
    lineHeight: 1.875, // 30
    fontSize: '1.25rem', // 20px
  },
  textLG: {
    lineHeight: 1.75, // 28
    fontSize: '1.125rem', // 18px
  },
  textMD: {
    lineHeight: 1.5, // 24
    fontSize: '1rem', // 16px
  },
  textSM: {
    lineHeight: 1.25, // 20,
    fontSize: '0.875rem', // 14px
  },
  textXS: {
    lineHeight: 1.125, // 18,
    fontSize: '0.75rem', // 12px
  },
};
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display2XL: true;
    displayXL: true;
    displayLG: true;
    displayMD: true;
    displaySM: true;
    displayXS: true;
    text2XL: true;
    textXL: true;
    textLG: true;
    textMD: true;
    textSM: true;
    textXS: true;

    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    button: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
  }
}
