import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Plus } from '../../../../../assets';
import { CreateTransactionsDuplicateItemsTable } from './CreateTransactionsDuplicateItemsTable';

export const CreateTransactionsDuplicateConfirmDialog = () => {
  const { handleDuplicateDialogClose, isDuplicateModalOpen, createTransactions, t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      onClose={handleDuplicateDialogClose}
      open={isDuplicateModalOpen}
    >
      <DialogTitle sx={{ pr:'48px' }}>
        {t('duplicateDialog.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleDuplicateDialogClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pb: 0 }}>
        <Box sx={{ mb: 8 }}>
          <Typography variant='textMD'>
            {t('duplicateDialog.message1')}
          </Typography>
        </Box>
        <Box sx={{ mb: 8 }}>
          <Typography variant='textMD'>
            {t('duplicateDialog.message2')}
          </Typography>
        </Box>
        <CreateTransactionsDuplicateItemsTable />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}>
          <Button variant='secondary'
            size="lg"
            onClick={handleDuplicateDialogClose}>
            {t('cancel')}
          </Button>
          <Button
            variant='primary'
            size="lg"
            onClick={() => {
              createTransactions();
              handleDuplicateDialogClose();
            }}
            startIcon={<SvgIcon><Plus /></SvgIcon>}>
            {t('duplicateDialog.confirm')}
          </Button>
        </Stack>
      </DialogActions >
    </Dialog>
  );
};

