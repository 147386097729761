import { useTranslation } from 'react-i18next';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { Trash01 } from '../../../assets';
import { Persona } from '../../../app/services/api/generated';
import { PersonaPutPostRequest } from '../types/PersonaPutPostRequest';
import { Edit } from '@mui/icons-material';

export default function ExpandedRow(props: {
  readonly entity: Persona,
  readonly delete: (persona: Persona) => void,
  readonly edit: (body: PersonaPutPostRequest) => void,
}) {
  const { t } = useTranslation('manageRoles');

  const scrollableBoxStyles = () => {
    return {
      display: 'flex',
      p: '2px 8px',
      alignItems: 'center',
      width: '100%', overflowY: 'auto', overflowX: 'hidden',
      maxHeight: '300px', flexFlow: 'wrap',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid rgba(0,0,0,.1)',
      },
    };
  };

  const outerBoxStyles = {
    display: 'flex',
    py: '20px',
    px: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flex: 1,
    gap: '5px',
    marginRight: '50px',
    minWidth: '300px',
  };

  const innerBoxStyles = {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    paddingLeft: '6px',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: '10px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      <Box
        sx={outerBoxStyles}
      >
        <Typography
          variant="textLG"
          sx={innerBoxStyles}
        >
          {t('roles')}
        </Typography>
        <Box
          sx={scrollableBoxStyles()}
        >
          <ul style={{ paddingLeft: 0 }}>
            {props.entity?.personaRoles && props.entity.personaRoles?.length > 0  ? (
              props.entity.personaRoles?.map((r) => (
                <li key={r}
                  style={{ marginBottom: '8px', opacity: '0.7', display: 'block'  }}>
                  <Typography 
                    variant="textSM">
                    {r}
                  </Typography>
                </li>
              ))) 
              : <Typography 
                sx={{ opacity: '0.7' }}
                variant="textSM">
                {t('noRoles')}
              </Typography>}
          </ul>
        </Box>
      </Box>
     
      <Box
        sx={outerBoxStyles}
      >
        <Typography
          variant="textLG"
          sx={innerBoxStyles}
        >
          {t('users')}
        </Typography>
        <Box
          sx={scrollableBoxStyles()}
        >
          <ul style={{ paddingLeft: 0 }}>
            {props.entity?.users && props.entity.users.length > 0 ? (
              props.entity.users.map((r) => (
                <li key={r.id}
                  style={{ marginBottom: '8px', opacity: '0.7', display: 'block'  }}>
                  <Typography
                    variant="textSM">
                    {r.emailAddress}
                  </Typography>
                </li>
              ))) 
              : <Typography 
                sx={{ opacity: '0.7' }}
                variant="textSM">
                {t('noUsers')}
              </Typography>}
          </ul>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignSelf: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <Button
          size="lg"
          onClick={() => props.edit(props.entity as PersonaPutPostRequest)}
          startIcon={<SvgIcon><Edit /></SvgIcon>}
        >
          {t('editRole')}
        </Button>
        <Button
          variant="bad"
          size="lg"
          sx={{ marginLeft: '10px' }}
          onClick={() => props.delete(props.entity)}
          startIcon={<SvgIcon><Trash01 /></SvgIcon>}
        >
          {t('deleteRole')}
        </Button>
      </Box>
    </Box>
  );
}
