import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { WarningDialog } from './dialogs/WarningDialog';
import { SvgIcon } from '@mui/material';
import { AlertTriangle } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const IdleTimer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [isIdle, setIsIdle] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);

  useEffect(() => {
    if (!isIdle) return;

    if (isIdle && timeLeft === 0) {
      setIsDialogOpen(false);
      setIsIdle(false);
      setTimeLeft(15); // Reset countdown interval
      handleOnLogout();
    }

    if (!isDialogOpen) {
      setIsDialogOpen(true);
    }
      
    // Set up an interval to decrease the timer by 1 every second
    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(countdownInterval);
  }, [isIdle, timeLeft]);

  const handleOnLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleOnStay = () => {
    setIsDialogOpen(false);
    setIsIdle(false);
    setTimeLeft(15); // Reset countdown interval
  };

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minutes in milliseconds
    onIdle: handleOnIdle,
    debounce: 250,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
  });

  return (
    <WarningDialog
      open={isDialogOpen}
      title={t('iddleStateTitle')}
      message={t('iddleStateMessage', { timeLeft })}
      cancelButtonText={t('stay')}
      onCancel={handleOnStay}
      icon={<SvgIcon sx={{ width: '20px' }}>
        <AlertTriangle />
      </SvgIcon>}
    />
  );
};

export default IdleTimer;