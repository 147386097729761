import { Box, Divider, Grid, List, ListItem, ListItemText, TextField } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import { GetSourceTransactionsResponse } from '../../app/services/api/generated';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { theme } from '../../theme';
import { UserContext } from '../../components/shared/useUser';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import BackButton from '../../components/shared/actions/BackButton';

const SourceTransactionDetails = () => {
  const { getSourceTransactions } = useNswagClient();
  const { logError } = useLogError();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const { selectedStore } = useContext(UserContext);
  const [sourceTransactionsData, setSourceTransactionsData] = useState<GetSourceTransactionsResponse>({});

  const { transactionSourceId, transactionDate } = useParams();


  useEffect(() => {
    setLoading(true);
    GetSourceTransactions();
  }, []);

  const GetSourceTransactions = () => {
    const storeNumber = selectedStore ? selectedStore.storeNumber : '';

    setLoading(true);
    if (storeNumber) {
      getSourceTransactions(storeNumber, transactionSourceId ?? '', transactionDate ?? '').then((result) => {
        if (result?.data) {
          setSourceTransactionsData(result.data);
          setLoading(false);
        }
      })
        .catch((error) => {
          logError(error);
        });
    }
  };


  return (
    <Box display="flex"
      width="100%"
      height="100%"
      flexDirection="column" >
      <PageArea>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <BackButton
            url="/transactions"
            title={t('back')}
            isLink
          />
        </Grid>
        <Grid>
          <PageTitle>
            {t('transactions.sourceTransactionTitle') + ': ' + transactionSourceId}
          </PageTitle>
        </Grid>
        <Box sx={{
          border: '1px solid',
          borderColor: theme.palette.custom.gray[200],
          py: '0px',
          textAlign: 'left',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        }}>
          <Grid container
            spacing={5}
            sx={{
              alignItems: 'flex-end',
              borderColor: theme.palette.custom.gray[300],
              px: '8px',
              py: '12px',
            }} >
            <Grid item
              xs={12}
              md={4}>
              <TextField
                id="outlined-basic"
                label={t('transactions.sourceStoreNumber')}
                variant="outlined"
                value={selectedStore?.storeNumber}
                disabled={true}
                sx={{
                  width: '90%', backgroundColor: theme.palette.custom.gray[100],
                }}
              />
            </Grid>
            <Grid item
              xs={12}
              md={4}>
              <TextField
                type="date"
                label={t('transactions.sourceDate')}
                variant="outlined"

                value={transactionDate}
                disabled={true}

                onChange={() => { }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '90%', backgroundColor: theme.palette.custom.gray[100],
                }}
              />
            </Grid>
            {loading && <Grid item
              xs={12}> <LoadingWheel/> </Grid>}
            {!loading && <Box sx={{ mt: '40px', overflowX: 'auto' }}>
              <List >
                {sourceTransactionsData?.dataFiles?.[0]?.dataLines?.map((item, index) => (
                  <Fragment  key={`${item}_${index}`}>
                    {index !== 0 && <Divider variant="middle"
                      component="li" />}
                    <ListItem>
                      <ListItemText primary={index === 0 ? <b>{item}</b> : item} />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </Box>}
          </Grid>
        </Box>
      </PageArea>
    </Box>
  );
};

export default SourceTransactionDetails;