import { useContext } from 'react';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';
import { NoteModal } from './NoteModal';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';
import { EditNoteModal } from './EditNoteModal';
import { AcceptModal } from './AcceptModal';

export const InvoiceDetailsPopups = () => {
  const { isShowSuccessAlert, isShowProcessingAlert, isShowErrorAlert, errorMessage, processingMessage, successMessage, closeSuccessAlert, closeProcessingAlert, closeErrorAlert } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;
  
  return <>
    <MessagesSnackbar
      open={isShowSuccessAlert}
      onClose={closeSuccessAlert}
      message={successMessage}
      severity="success"
      duration={3000}
    />
    <MessagesSnackbar
      open={isShowErrorAlert}
      onClose={closeErrorAlert}
      message={errorMessage}
      severity="error"
      duration={null}
    />
    <MessagesSnackbar
      open={isShowProcessingAlert}
      onClose={closeProcessingAlert}
      message={processingMessage}
      severity="info"
      duration={null}
    />
    <EditNoteModal/>
    <NoteModal/>
    <AcceptModal/>
  </>;
      
};