
import useNswagClient from '../../../hooks/api/useNswagClient';
import { CreditRequestsValuesResponse, PurchaseOrderLine, PurchaseOrderPhoto, SendCreditReqRequest, SendCreditReqRequest_LineItemFile } from '../../../app/services/api/generated';
import { useState, useEffect, useContext } from 'react';
import { Select, SelectChangeEvent, Grid, FormControl, Box, MenuItem,TextField, InputAdornment } from '@mui/material'; 
import { useSelectedTheme } from '../../../hooks/useSelectedTheme';
import CreditRequestTable from './CreditRequestTable';
import { creditRequestReasons, Reason } from '../enums/reason';
import { CreditRequestType } from '../enums/credit-request-types';
import { RtvReason, returnToVendorReasons } from '../enums/return-to-vendor-reason'; 
import { ConfigurationName } from '../../../enums/ConfigurationName';
import { UserContext } from './../../../components/shared/useUser';
import { CreditRequestBoxProps } from './../interfaces/CreditRequestInterfaces';
import MediaModal from '../modals/MediaModal';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';

const CreditRequestBox = (props: Readonly<CreditRequestBoxProps>) => {
  const { selectedTheme: theme } = useSelectedTheme();
  const { getCreditRequest, getCreditRequestDetails, getReturnToVendorDetails, getConfiguration } = useNswagClient();
  const [creditRequestsOptions, setCreditRequestsOptions] = useState<CreditRequestsValuesResponse[]>([]);
  const [purchaseOrderLines, setPurchaseOrderLines] = useState<PurchaseOrderLine[]>([]);
  const [creditRequestReasonsArray, setCreditRequestReasonsArray] = useState<Reason[] | RtvReason[]>(creditRequestReasons);
  const [selectedRequest, setSelectedRequest] = useState<CreditRequestsValuesResponse>();
  const [orderType, setOrderType] = useState<string>('');
  const [creditRequest, setCreditRequest] = useState<SendCreditReqRequest | undefined | null>();
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const [selectedRow, setSelectedRow] = useState<null | PurchaseOrderLine>(null);
  const [evidenceRequired, setEvidenceRequired] = useState(false);
  const [disableReasoneField, setDisableReasoneField] = useState(false);  
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logError } = useLogError();
   

  const { t } = useTranslation('createCreditRequest');
  useEffect(() => {
    if (selectedRequest) {  
      getRequestDetailsData();
    }
  }, [selectedRequest]);
  
  useEffect(() => {
    loadAfterData();
  }, [creditRequestsOptions]);

  useEffect(() => {
    getCreditRequest(props.purchaseOrderId)
      .then((result) => {
        if (result?.data) {
          setCreditRequestsOptions(result.data);
        } else {
          logError('Invalid API response:');  
        }
      })
      .catch((error) => {
        logError(error); 
      });
  }, []);
 
  const loadAfterData = () => {
    const creditRequest = creditRequestsOptions?.find(x => x.id === props.purchaseOrderId);
    if (creditRequest) {
      setSelectedRequest(creditRequest);
      if (creditRequest.type == CreditRequestType.CreditRequest) {
        getRequestDetailsData();
      }
    }
  };

  const checkIfEvidenceIsRequired = () => {
    getConfiguration(ConfigurationName.CreditRequestsEnabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      setEvidenceRequired(res.data.value);
      initialiseCreditRequests();
    });
  };

  const initialiseCreditRequests = () => {
    getCreditRequestDetails(props.purchaseOrderId).then((result) => {
      const filteredPurchaseOrderLines = result.data?.purchaseOrderLines!.filter(x => x.creditedAmount != null);
      displaySendButton(result.data?.creditRequestStatus!); 
      const items = filteredPurchaseOrderLines!.map((purchaseOrderLine) => ({
        itemNumber: purchaseOrderLine.itemNumber ?? '',
        reasonForCreditRequest: parseInt(purchaseOrderLine.reasonForCreditRequest ?? '1'),
        photos: purchaseOrderLine.photos ? mapPurchaseOrderPhotosToSendCreditReqRequestPhotos(purchaseOrderLine.photos) : undefined,
      }));

      const updatedCreditRequest: SendCreditReqRequest = {
        ...creditRequest,
        ewsOrderNumber: result.data?.ewsOrderNumber  ?? '',
        storeNumber: selectedStore?.storeNumber ?? '',
        purchaseOrderHeaderId: props.purchaseOrderId,
        items: items,
      };
      setCreditRequest(updatedCreditRequest);
      props.setSendCreditRequest(updatedCreditRequest); 
      setOrderType(result.data?.orderType!);
      setPurchaseOrderLines(result.data?.purchaseOrderLines?.filter(x => x.creditedAmount != null)!);
    })
      .catch((error) => {
        logError(error);  
      });

  };
  const getRequestDetailsData = () => {
    if (hasPermissionTo(['PurchaseOrderWrite'])) { 
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getConfiguration(ConfigurationName.CreditRequestsEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
        if (res.data.value && selectedRequest) {   
          props.onRequestTypeChange(selectedRequest?.type!);
          if (selectedRequest?.type == CreditRequestType.CreditRequest) {
            checkIfEvidenceIsRequired();
            setCreditRequestReasonsArray(creditRequestReasons);
          }
          else { 
            returnToVendorDetails();
            setCreditRequestReasonsArray(returnToVendorReasons);
          } 
        }
      }).catch((error) => {
        logError(error); 
      }).finally(() => {
        setLoading(false);
      }); 
    }
  };
  
  function mapPurchaseOrderPhotosToSendCreditReqRequestPhotos(PurchaseOrderPhoto: PurchaseOrderPhoto[]): SendCreditReqRequest_LineItemFile[] {
    return PurchaseOrderPhoto.map((item) => ({
      fileData: item.fileData!,
      fileName: item.fileName!,
    }));
  }
  const displaySendButton = (status: number) => {   
    if (status !== null && status !== undefined) {
      props.setDisplayButton(status <= 1);
      setDisableReasoneField(status<=1); 
    } 
  };

  const returnToVendorDetails = () => {
    getReturnToVendorDetails(selectedRequest?.id).then((result) => {
      
      displaySendButton(result.data?.status!);
      const items = result.data?.items!.map((purchaseOrderLine) => ({
        itemNumber: purchaseOrderLine.itemNumber ?? '',
        reasonForCreditRequest: parseInt(purchaseOrderLine.reasonForCreditRequest ?? '1'),
        photos: undefined,
      }));
      const updatedCreditRequest: SendCreditReqRequest = {
        ...creditRequest,
        ewsOrderNumber: result.data?.ewsOrderNumber ?? '',
        storeNumber: selectedStore?.storeNumber ?? '',
        purchaseOrderHeaderId: props.purchaseOrderId,
        items: items!,
        customerRmaNumber: result.data?.customerRmaNumber ?? '',
        deliveryNoteNumber: result.data?.deliveryNoteNumber ?? '',
        documents: result.data?.documents ?? '',
      };
      setEvidenceRequired(false);
      setCreditRequest(updatedCreditRequest);
      props.setSendCreditRequest(updatedCreditRequest);
      setPurchaseOrderLines(result.data?.items!); 
    })
      .catch((error) => {
        logError(error); 
      });
  };

  const handleCreditRequestChange = (event: SelectChangeEvent<number>) => {
    const selectedCreditRequestId = event.target.value as number;
    const selectedCreditRequest = creditRequestsOptions.find(item => item.id === selectedCreditRequestId);
    setCreditRequest(null);
    props.setSendCreditRequest(null); 
    setSelectedRequest(selectedCreditRequest); 
    props.onRequestTypeChange(selectedCreditRequest?.type!);
  };
  
  const handleUpdateResonCreditRequest = (row: PurchaseOrderLine) => {
    const indexToUpdateCreditRequest = creditRequest?.items.findIndex(item => item.itemNumber === row.itemNumber) as number;
    const updatedCreditRequest: SendCreditReqRequest = {
      ...creditRequest,
      ewsOrderNumber: creditRequest?.ewsOrderNumber ?? '', 
      items: creditRequest?.items ?? [], 
    };
    updatedCreditRequest.items[indexToUpdateCreditRequest].reasonForCreditRequest = parseInt(row.reasonForCreditRequest!);
    setCreditRequest(updatedCreditRequest);
    props.setSendCreditRequest(updatedCreditRequest);
  };
  

  const onViewModal = (row: PurchaseOrderLine) => {
    setSelectedRow(row);
    setOpenImageDialog(true);
  };
  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };
  const handleDocumentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCreditRequest: SendCreditReqRequest = {
      ...creditRequest!,
      ewsOrderNumber: creditRequest?.ewsOrderNumber ?? '', 
      documents:  event.target.value ?? '',
    }; 
    
    setCreditRequest(updatedCreditRequest);
    props.setSendCreditRequest(updatedCreditRequest);
   
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCreditRequest: SendCreditReqRequest = {
      ...creditRequest!,
      ewsOrderNumber: creditRequest?.ewsOrderNumber ?? '', 
      deliveryNoteNumber:  event.target.value ?? '',
    }; 
    
    setCreditRequest(updatedCreditRequest);
    props.setSendCreditRequest(updatedCreditRequest);
   
  };
   
  const updateSelectedRow = (updatedRow: PurchaseOrderLine) => { 
    const indexToUpdate = purchaseOrderLines.findIndex(item => item.id === updatedRow.id);
    if (indexToUpdate !== -1) {
      const updatedLines = [...purchaseOrderLines];
      updatedLines[indexToUpdate] = updatedRow;
      setPurchaseOrderLines(updatedLines);

      const indexToUpdateCreditRequest = creditRequest?.items.findIndex(item => item.itemNumber === updatedRow.itemNumber) as number;
      if (indexToUpdateCreditRequest !== undefined && indexToUpdateCreditRequest !== -1) {
        const updatedPhotos = updatedRow.photos ?? [];
        const updatedCreditRequest: SendCreditReqRequest = {
          ...creditRequest,
          ewsOrderNumber: creditRequest?.ewsOrderNumber ?? '',
          storeNumber: creditRequest?.storeNumber ?? '',
          purchaseOrderHeaderId: props.purchaseOrderId,
          items: creditRequest?.items ?? [],
          customerRmaNumber: creditRequest?.customerRmaNumber ?? '',
          deliveryNoteNumber: creditRequest?.deliveryNoteNumber ?? '',
          documents: creditRequest?.documents ?? '',
        }; 
        if (updatedCreditRequest.items && updatedPhotos.length > 0) { 
          updatedCreditRequest.items[indexToUpdateCreditRequest].photos = mapPurchaseOrderPhotosToSendCreditReqRequestPhotos(updatedPhotos);

        } 
        else
        {
          updatedCreditRequest.items[indexToUpdateCreditRequest].photos = [];
        }
        setCreditRequest(updatedCreditRequest);
        props.setSendCreditRequest(updatedCreditRequest);
      }
    }
    setSelectedRow(updatedRow);
  };
  return (
    <>
      {loading ? (
        <LoadingWheel />
      ) : (
        <>
          {creditRequestsOptions && selectedRequest && (
            <Box  
              border='1px solid' 
              borderColor={theme.palette.custom.gray[300]}>
              <Grid
                container
                spacing={2} 
                display='flex'
                alignItems='self-end'
                p={6}
              > 
                <Grid item
                  md={4}
                  sm={4}
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <FormControl>
                    <Select
                      value={selectedRequest?.id}
                      onChange={handleCreditRequestChange}
                      fullWidth>
                      {creditRequestsOptions.map((item) => (
                        <MenuItem key={item.id}
                          value={item.id}>
                          {item.creditRequestNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item
                  md={4}
                  sm={4}
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}> 
                  <TextField
                    label= {t('orderNumber')}
                    fullWidth
                    disabled
                    value={creditRequest?.ewsOrderNumber ?? '' }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                <Grid item
                  md={4}
                  sm={4}
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <TextField
                    fullWidth
                    label = {t('orderType')}
                    value={orderType ?? '' }
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                {selectedRequest?.type != CreditRequestType.CreditRequest && (
                  <>
                    <Grid item
                      md={4}
                      sm={4}
                      xs={12}
                      sx={{  my:'15px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}
                    > 
                      <TextField
                        fullWidth
                        label = {t('customerRMA')}
                        disabled
                        value={creditRequest?.customerRmaNumber ?? '' }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                          ),
                        }} />
                    </Grid>
                    <Grid item
                      md={4}
                      sm={4}
                      xs={12}
                      sx={{ my:'15px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}> 
                      <TextField
                        fullWidth 
                        label={t('note')}
                        onInput={props.onInput}
                        value={creditRequest?.deliveryNoteNumber ?? '' }
                        onChange={handleNoteChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                          ),
                        }} />
                    </Grid>
                    <Grid item
                      md={4}
                      sm={4}
                      xs={12}
                      sx={{ my:'15px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}> 
                      <TextField
                        fullWidth 
                        label = {t('documents')}
                        value={creditRequest?.documents ?? ''}
                        onChange={handleDocumentsChange}
                        onInput={props.onInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                          ),
                        }} 
                      />
                    </Grid>
                  </>
                ) }
                {selectedRequest && <Grid item
                  xs={12}
                  sx={{ pt: '20px' }}>
                  {purchaseOrderLines && creditRequest && <CreditRequestTable
                    onRequestMedia={onViewModal}
                    purchaseOrderLines={purchaseOrderLines}
                    onChangeReasonUpdate={handleUpdateResonCreditRequest}
                    selectedRequest={selectedRequest}
                    creditRequest={creditRequest}
                    reason={creditRequestReasonsArray} 
                    evidenceRequired = {evidenceRequired}
                    isButtonVisible = {disableReasoneField}

                  />}
                </Grid>}
                <MediaModal open={openImageDialog}
                  onClose={handleCloseImageDialog} 
                  isButtonVisible = {disableReasoneField}
                  updateSelectedRow={updateSelectedRow}
                  selectedRow={selectedRow!}
                /> 
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  ); 
};

export default CreditRequestBox;