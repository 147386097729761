import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { useTranslation } from 'react-i18next';
import { Button, Link, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { GetStockResponse } from '../../../app/services/api/generated';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';

const StockOnHandTable = (props: {
  readonly list: GetStockResponse;
  readonly setPageZero: number;
  readonly loadData: (sort: string, direction: string, skip: number, take: number) => void;
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
 
  const maxPages = props.list?.totalCount? Math.ceil(props.list.totalCount / 10):0;
  
  const [data, setData] = React.useState(props.list.stock);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('itemNumber');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    setData(props.list.stock);
    setIsLoading(false);
  }, [props.list]);

  useEffect(() => {
    setIsLoading(true);
    props.loadData(orderBy, order, page * rowsPerPage, rowsPerPage);
  }, [page]);

  useEffect(() => {
    setPage(0);
  }, [props.setPageZero]);

  const isAsc = order === 'asc';

  const handleSort = (property: string) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(property);
    props.loadData(property, order, page * rowsPerPage, rowsPerPage);
  };

  return (
    <> {isLoading ? <LoadingWheel /> : (
      data &&
      <><TableContainer>
        <Table
          size="small"
          aria-label="a table"
        >
          <TableHead sx={{ paddingTop: '10px' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'itemNumber'}
                  direction={order}
                  onClick={() => handleSort('itemNumber')}
                >
                  {t('stockOnHand.sku')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'description'}
                  direction={order}
                  onClick={() => handleSort('description')}
                >
                  {t('stockOnHand.name')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'primaryCategory'}
                  direction={order}
                  onClick={() => handleSort('primaryCategory')}
                >
                  {t('stockOnHand.category')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'quantity'}
                  direction={order}
                  onClick={() => handleSort('quantity')}
                >
                  {t('stockOnHand.qty')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'costPrice'}
                  direction={order}
                  onClick={() => handleSort('costPrice')}
                >
                  {t('stockOnHand.costPrice')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'totalCost'}
                  direction={order}
                  onClick={() => handleSort('totalCost')}
                >
                  {t('stockOnHand.totalCost')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                {t('store')}
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'minimumQuantity'}
                  direction={order}
                  onClick={() => handleSort('minimumQuantity')}
                >
                  {t('stockOnHand.minQty')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.itemNumber}
              >
                <TableCell>
                  <Button size='md'
                    sx={{ pr:'5px', pl:'5px' }}
                    variant='primary'
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/store/item/${row.storeNumber}?sku=${row.itemNumber}`);
                    }}>
                    View
                  </Button>
                </TableCell>
                <TableCell>
                  {row.itemNumber}
                </TableCell>
                <TableCell>
                  {row.description}
                </TableCell>
                <TableCell>
                  {row.primaryCategory}
                </TableCell>
                <TableCell>
                  <Tooltip title={t('stockOnHand.viewTransactions')}
                    arrow>
                    <Link
                      href={`/transactions/${row.itemNumber}`} 
                      onClick={(event) => {
                        event.stopPropagation(); 
                      }} 
                      color="primary"
                      underline="hover"
                      style={{ cursor: 'pointer' }}
                    >
                      {row.quantity !== undefined ? Number(row.quantity).toFixed(2) : ''}
                    </Link>
                  </Tooltip>
                  (<Tooltip title={t('stockOnHand.viewUOM')}
                    arrow>
                    <Link
                      href={`/uom-conversions/${row.itemNumber}`} 
                      onClick={(event) => {
                        event.stopPropagation(); 
                      }} 
                      color="primary"
                      underline="hover"
                      style={{ cursor: 'pointer' }}
                    >
                      {row.uom}
                    </Link>
                  </Tooltip>)
                </TableCell>
                <TableCell>
                  <RoundAndFormatValue
                    value={row?.costPrice ?? 0}
                    currencyCode={currencyCode}
                  />
                </TableCell>
                <TableCell>
                  <RoundAndFormatValue
                    value={row?.totalCost ?? 0}
                    currencyCode={currencyCode}
                  />
                </TableCell>
                <TableCell>
                  {row.starbucksStoreNumber}
                </TableCell>
                <TableCell>
                  <Link href="#"
                    color="primary"
                    underline="none"
                    style={{ cursor: 'pointer' }}>
                    {row.minimumQuantity !== undefined ? Number(row.minimumQuantity).toFixed(2) : ''}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.list?.totalCount === 0 ? (
        <Typography variant="textSM">
          {t('stockOnHand.noDataMessage')}
        </Typography>
      ) : (
        <CustomPagination
          page={page}
          setPage={setPage}
          maxPages={maxPages}
          breakpointForChangeDisplay={120000}
        />
      )}
      </>
    )}
    </>
  );
};

export default StockOnHandTable;
