import { Box, Typography } from '@mui/material';
import StockCheckCategoryTable from './StockCheckCategoryTable';
import { FC } from 'react';
import { TempCategoryItem, TempStockCheckItem } from '..';

type Props = {
    groupedStockCountItems?: TempCategoryItem[];
    onItemStatusChange?: (itemId: number | undefined, checked: boolean) => void;
    sortPartialStockCountItems: (categoryIndex: number, fieldToSort: keyof TempStockCheckItem, sortDirection: 'asc' | 'desc') => void;
    togglePartialStockCountItem: (itemNumber: string | undefined) => void;
    isDisabled: boolean;
    isViewMode?: boolean;
}

const StockCheckContent: FC<Props> = ({ groupedStockCountItems, onItemStatusChange, sortPartialStockCountItems, togglePartialStockCountItem, isDisabled, isViewMode }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      py={3}
    >
      {
        groupedStockCountItems?.map((category: TempCategoryItem, index: number) => (
          <Box
            key={category.name}
            display='flex'
            flexDirection='column'
            my={4}
          >
            <Box
              display="flex"
              flexDirection="row"
              p={4}
            >
              <Typography
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {category.name}
              </Typography>
            </Box>
            <StockCheckCategoryTable
              category={category}
              categoryIndex={index}
              onItemStatusChange={onItemStatusChange}
              sortPartialStockCountItems={sortPartialStockCountItems}
              togglePartialStockCountItem={togglePartialStockCountItem}
              isDisabled={isDisabled}
              isViewMode={isViewMode}
            />
          </Box>
        ))
      }
    </Box>
  );
};

export default StockCheckContent;