import { TableRow, TableCell, Grid, Typography, TextField, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, Alert, Snackbar, Box, Tooltip, useMediaQuery } from '@mui/material';
import { AddOrderItemRequest, Item, client } from '../../../../app/services/api/orderManagementClient';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applyRoundAndFormat } from '../../../../utils';
import useLogError from '../../../../hooks/useLogError';
import { UserContext } from '../../../../components/shared/useUser';
import { theme } from '../../../../theme';
import { isOrderCategory, OrderCategoriesTranslationMap } from '../../../../enums/OrderCategories';
import TextWithOverflowTooltip from '../../../../components/shared/TextWithOverflowTooltip';
import { useNavigate } from 'react-router-dom';

type ProductRowProps = {
  product: Item;
  selectedOrderId?: number;
  basketQuantity?: number;
  onAddItemConfirmed: () => void;
  currencyCode?: string;
  showMessageWhenNoCost: boolean;
}

const ProductRow: FC<ProductRowProps> = ({ product, selectedOrderId, basketQuantity, currencyCode, onAddItemConfirmed, showMessageWhenNoCost }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('products');
  const { logError } = useLogError();
  const { selectedStore } = useContext(UserContext);
  const [quantity, setQuantity] = useState<string>(basketQuantity?.toString() ?? '0');
  const [startingQuantity, setStartingQuantity] = useState<string>(basketQuantity?.toString() ?? '0');
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    setQuantity(basketQuantity?.toString() ?? '0');
    setStartingQuantity(basketQuantity?.toString() ?? '0');
  }, [basketQuantity]);

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setQuantity(value);
    }
  };

  const onAddItemToOrder = async (itemId: number, quantity: number) => {
    if (selectedOrderId) {
      try {
        setLoading(true);
        const request: AddOrderItemRequest = {
          orderId: selectedOrderId,
          itemId,
          quantity,
        };
        const response = await client.addOrderItem(selectedStore?.storeNumber, request);
        if (response.success) {
          handleSnackbarOpen(t('addItemSuccessMessage'), 'success');
          setQuantity('');
          setStartingQuantity(quantity.toString());
          onAddItemConfirmed();
        }
      } catch (error) {
        handleSnackbarOpen(t('addItemErrorMessage') + ' ' + error, 'error');
        logError(error);
      } finally {
        setLoading(false);
        setShowDialog(false);
      }
    }
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const hideDialogAndResetQuantity = () => {
    setShowDialog(false);
    setQuantity(startingQuantity);
  };
  
  return (
    <>
      <TableRow key={product.id}>
        <TableCell sx={{ width: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Grid container
            alignItems={'center'}
            spacing={1}
            onClick={() => navigate(`/store/item/${product.id}?sku=${product.sku}&orderable=true`)} 
            wrap="nowrap">
            <Grid item
              xs="auto">
              <img
                style={{
                  objectFit: 'cover',
                  width: '150px',
                  height: isLargeScreen? '75px' : '150px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  display: 'block',
                  margin: '0',
                  padding: '0',
                }}
                alt="item-thumbnail"
                src={
                  product.thumbnailUrl
                    ? product.thumbnailUrl
                    : 'https://placehold.co/150x150'
                }
                loading="lazy"
              />
            </Grid>
            <Grid item
              sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
              {isLargeScreen ? (
                <Grid container 
                  spacing={2}>
                  <Grid item
                    xs={6}>
                    <TextWithOverflowTooltip text={product.name} />
                    <TextWithOverflowTooltip text={product.sku} />
                    <TextWithOverflowTooltip text={isOrderCategory(product.category)
                      ? t(OrderCategoriesTranslationMap[product.category as keyof typeof OrderCategoriesTranslationMap])
                      : product.category} />
                    {(showMessageWhenNoCost && product.price == 0) ? 
                      <TextWithOverflowTooltip text={`${t('noCostPriceMessage')}`} /> 
                      :
                      <TextWithOverflowTooltip text={applyRoundAndFormat(product.price, currencyCode)}  /> 
                    }  
           
                  </Grid>
                  <Grid item
                    xs={6}> 
                    <TextWithOverflowTooltip text={`${t('orderableUom')}: ${product.orderableUom} (${product.packSize}${product.primaryUom})` }/>
                    <TextWithOverflowTooltip text= {`${t('primaryUom')}: ${product.primaryUom}`}/> 
                    <Tooltip title={`${t('stockOnHand')}: ${product.stockOnHand}`}
                      arrow>
                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                        {t('soh')} {product.stockOnHand}
                      </Box>
                    </Tooltip>
                    <Tooltip title={`${t('stockOnOrder')}: ${product.stockOnOrder}`}
                      arrow>
                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                        {t('soo')}: {product.stockOnOrder}
                      </Box>
                    </Tooltip> 
                  </Grid>
                </Grid>
              ) : (
                <>
                  {product.name}  <br/>
                  {product.sku}   <br/>
                  {isOrderCategory(product.category)
                    ? t(OrderCategoriesTranslationMap[product.category as keyof typeof OrderCategoriesTranslationMap])
                    : product.category}   <br/>
                  {(showMessageWhenNoCost && product.price == 0) ?
                    t('noCostPriceMessage')
                    :
                    applyRoundAndFormat(product.price, currencyCode)
                  } <br/>
                  {t('orderableUom')}: {product.orderableUom} ({product.packSize}{product.primaryUom})<br/>
                  {t('primaryUom')}: {product.primaryUom}  <br/>
                  <Tooltip title={`${t('stockOnHand')}: ${product.stockOnHand}`}
                    arrow>
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                      {t('soh')} {product.stockOnHand}
                    </Box>
                  </Tooltip>
                  <Tooltip title={`${t('stockOnOrder')}: ${product.stockOnOrder}`}
                    arrow>
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                      {t('soo')}: {product.stockOnOrder}
                    </Box>
                  </Tooltip> 
                </>
              )}
            </Grid>
          </Grid>
        </TableCell> 
        <TableCell>
          <TextField
            size="small"
            value={quantity}
            onChange={(e) => onQuantityChange(e)}
            disabled={!selectedOrderId}
            sx={{
              maxWidth: 100,
              input: {
                textAlign: 'center',
                padding: '3px',
                fontSize: '12px',
              },
            }} />
        </TableCell>
        <TableCell>
          <Button
            disabled={!selectedOrderId}
            variant="primary"
            onClick={() => setShowDialog(true)}
            size="lg"
          >
            {t('updateQuantity')}
          </Button>
        </TableCell>
      </TableRow>
      <Dialog open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>
          {t('confirmDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item
              xs={12}>
              <Typography variant='textMD'>{t('confirmDialogContent', { item: product.name })}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions >
          <Button 
            variant="secondary"
            onClick={hideDialogAndResetQuantity}>
            {t('cancel')}
          </Button>
          <Button variant="primary"
            onClick={() => onAddItemToOrder(product.id, Number(quantity))}
            disabled={loading}>
            {t('update')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductRow;