import { useState } from 'react';
import { CardMedia, IconButton, Typography, Box, useTheme } from '@mui/material';
import Agriculture from '../../../assets/images/agriculture.jpg';
import Sea from '../../../assets/images/shifaaz.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';


interface CarouselItem  {
	image: string;
	title: string;
  description:string;
}

const ImageCarousel=() => {
  const { t } = useTranslation('authentication');
  const theme = useTheme();
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0);
  
  const items: CarouselItem[] = [
    {
      image: Agriculture,
      title: t('carouselImages.0.title'),
      description: t('carouselImages.0.description'),
    },
    {
      image: Sea,
      title: t('carouselImages.1.title'),
      description: t('carouselImages.1.description'),
    },
  ];

  const getCurrentCauroselItem = (): CarouselItem => {
    return items[currentImageIndex];
  };
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = prevIndex - 1 < 0 ? items.length - 1 : prevIndex - 1;
      return newIndex;
    });
  };
  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = prevIndex + 1 >= items.length ? 0 : prevIndex + 1;
      return newIndex;
    });
  };

  return (
    <Box position="relative"
      display="flex"
      flex={1}>
      <CardMedia
        sx={{ filter: 'brightness(50%)', borderTopLeftRadius: '80px', borderBottomLeftRadius: '80px', minWidth: '100%', maxHeight: '100vh' }}
        component="img"
        alt="Image"
        image={getCurrentCauroselItem().image}
      />
      <Box
        position="absolute"
        style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-end', bottom: '12px', padding: '0px 56px 56px 56px' }}
      >
        <Typography variant="displayMD"
          style={{ color: theme.palette.common.white, letterSpacing: '-0.72px', lineHeight: '44px', textAlign: 'left' }}>{getCurrentCauroselItem().title}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography variant="textMD"
              style={{ color: theme.palette.common.white, textAlign: 'left' }}>{getCurrentCauroselItem().description}</Typography>
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'end' }} >
            <IconButton
              onClick={handlePrevClick}
              sx={{ border: '0.5px solid #fff', borderRadius: '50%', color: '#fff !important' }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              onClick={handleNextClick}
              sx={{ border: '0.5px solid #fff', borderRadius: '50%',color: '#fff !important', marginLeft: '32px' }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCarousel;