import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, SvgIcon, TextField } from '@mui/material';
import PurchaseOrdersTable from './PurchaseOrdersTable';
import { GetPurchaseOrderHeadersResponse, OrderTypesResponse } from '../../../app/services/api/generated';
import { useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { PurchaseOrdersFilterOptions } from '../interfaces/PurchaseOrdersFilterOptions';
import { SearchSm } from '../../../assets';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';

const PurchaseOrderBox = (props: {
  purchaseOrders: GetPurchaseOrderHeadersResponse, ordersType: OrderTypesResponse,
  loadData: (options: PurchaseOrdersFilterOptions) => void;
}) => {

  const defaultSort = 'deliveryDate';
  const defaultTake = 10;
  const defaultSkip = 0;
  const defaultDirection = 'desc';

  const [search, setSearch] = useState('');
  const [orderType, setOrderType] = useState('All');
  const [received, setReceived] = useState<boolean | undefined>();
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [page, setPage] = useState(0);
  const { t } = useTranslation('common');

  const callLoadDataFromAPI = (options: PurchaseOrdersFilterOptions) => {
    props.loadData(options);
  };

  const loadDataFromOrderTable = (sort: string, direction: string, skip: number, take: number) => {
    callLoadDataFromAPI({
      searchTerm: search,
      orderType,
      from,
      to,
      received,
      sort,
      direction,
      skip,
      take,
    });
  };
  useEffect(() => {
    if (from) {
      const minDate = new Date(from);
      minDate.setDate(minDate.getDate() + 1);
      if (to && new Date(to) < minDate) {
        setTo(''); 
      }
    }
  }, [from]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    setSearch(newSearchValue);
  };

  const handleOrderTypeChange = (event: SelectChangeEvent<string>) => {

    const newOrderTypeValue = event.target.value;
    setOrderType(newOrderTypeValue);
  };

  const handleReceivedChange = (event: SelectChangeEvent<string>) => {
    const receiptStatus = event.target.value;
    let newReceivedValue: boolean | undefined;

    if (receiptStatus === '1') {
      newReceivedValue = true;
    } else if (receiptStatus === '0') {
      newReceivedValue = false;
    } else {
      newReceivedValue = undefined;
    }
    setReceived(newReceivedValue);
  };

  const clearAll = () => {
    setFrom('');
    setTo('');
    setSearch('');
    setReceived(undefined);
    setOrderType('All');
    setPage(1);

    callLoadDataFromAPI({
      searchTerm: '',
      orderType: '',
      from: '',
      to: '',
      received: undefined,
      sort: defaultSort,
      direction: defaultDirection,
      skip: 0,
      take: 10,
    });

    const receivedSelect = document.getElementById('received-select') as HTMLSelectElement | null;
    if (receivedSelect) {
      receivedSelect.value = '';
    }

    const orderType = document.getElementById('order-type') as HTMLSelectElement | null;
    if (orderType) {
      orderType.value = '';
    }
  };

  const searchPurchaseOrders = () => {
    setPage(1);

    callLoadDataFromAPI({
      searchTerm: search,
      orderType,
      from,
      to,
      received: received,
      sort: defaultSort,
      direction: defaultDirection,
      skip: defaultSkip,
      take: defaultTake,
    });
  };

  return <Box sx={{
    border: '1px solid',
    borderColor: theme.palette.custom.gray[200],
    py: '0px',
    textAlign: 'left',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  }}>
    <Grid container
      spacing={5}
      sx={{
        alignItems: 'flex-end',
        borderColor: theme.palette.custom.gray[300],
        px: '8px',
        py: '12px',
      }} >
      <Grid item
        xs={12}
        md={4}>
        <FormControl fullWidth
          size='small'>
          
          <TextField placeholder={t('purchaseOrderReceipt.filterForm.searchForPO')}
            size='small'
            id="input-with-icon-textfield"
            label={t('purchaseOrderReceipt.filterForm.searchTitle')}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon><SearchSm /></SvgIcon>
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={handleSearchChange}
          />
        </FormControl>
      </Grid>
      <Grid item
        xs={12}
        md={4}>
        <FormControl fullWidth
          size='small'>
          <InputLabel id="received-select">{t('purchaseOrderReceipt.filterForm.labelReceiptStatus')}</InputLabel>
          <Select
            value={received ? '1' : received === false ? '0' : ''}
            label={t('purchaseOrderReceipt.filterForm.labelReceiptStatus')}
            onChange={handleReceivedChange}
          >
            <MenuItem value={'1'}>{t('purchaseOrderReceipt.filterForm.statusReceived')}</MenuItem>
            <MenuItem value={'0'}>{t('purchaseOrderReceipt.filterForm.statusNotReceived')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item
        xs={12}
        md={4}>
        <FormControl fullWidth
          size='small'>
          <InputLabel id="type-label">{t('purchaseOrderReceipt.filterForm.labelOrderType')}</InputLabel>
          <Select
            id='order-type'
            value={orderType}
            onChange={handleOrderTypeChange}
            label={t('purchaseOrderReceipt.filterForm.labelOrderType')}
          >
            <MenuItem value="All">
                All
            </MenuItem>
            {props.ordersType?.name?.map((name) => (
              <MenuItem key={name}
                value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item
        xs={12}
        md={4}>
        <DatePickerLocalized
          useCalendar = {false}
          label={t('purchaseOrderReceipt.filterForm.labelReceiptDateFrom')}
          value={from}
          translations={{
            clearButtonLabel: t('calendar.clearButtonLabel'),
            todayButtonLabel: t('calendar.todayButtonLabel'),
            cancelButtonLabel: t('calendar.cancelButtonLabel'),
            okButtonLabel: t('calendar.okButtonLabel'), 
          }}
          onChange={(newValue) => {
            if (newValue === '') {
              setFrom(''); 
            } else if (newValue) { 
              setFrom(newValue);
            }
          }}
        /> 
      </Grid>
      <Grid item
        xs={12}
        md={4}>     
        <DatePickerLocalized
          useCalendar = {false}
          label={t('purchaseOrderReceipt.filterForm.labelReceiptDateTo')}
          value={to}
          translations={{
            clearButtonLabel: t('calendar.clearButtonLabel'),
            todayButtonLabel: t('calendar.todayButtonLabel'),
            cancelButtonLabel: t('calendar.cancelButtonLabel'),
            okButtonLabel: t('calendar.okButtonLabel'), 
          }}
          onChange={(newValue) => {
            if (newValue === '') {
              setTo('');
            } else if (newValue) {
              const minDate = new Date(from);
              minDate.setDate(minDate.getDate() + 1);
              if (new Date(newValue) < minDate) {
                setTo(''); 
              } else {
                setTo(newValue);
              }
            }
          }}
        /> 
      </Grid>
      <Grid item
        xs={12}
        md={2}
      >
        <Button
          variant="secondary"
          size="lg"
          onClick={clearAll}
          fullWidth
        >
          {t('clearAll')}
        </Button>
      </Grid>
      <Grid item
        xs={12}
        md={2}
      >
        <Button
          variant="primary"
          size="lg"
          onClick={searchPurchaseOrders}
          startIcon={<SearchSm />}
          fullWidth
        >
          {t('search')}
        </Button>
      </Grid>
    </Grid>
    <PurchaseOrdersTable
      list={props.purchaseOrders}
      setPageZero={page}
      loadData={loadDataFromOrderTable}
    />
  </Box>;
};

export default PurchaseOrderBox;