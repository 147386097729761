import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import { useContext } from 'react';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';

export interface SimpleDialogProps {
  readonly open: boolean;
  readonly note: string;
  readonly onClose: () => void;
}

export function NoteModal(props: SimpleDialogProps) {
  const { t } = useContext(InvoicesContext) as CreateInvoicesValues;
  const { onClose, note, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose}
      open={open}>
      <DialogContent>{note}</DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
