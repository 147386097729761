import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { StockCheckStatus } from './../../../enums/StockCheckStatus';
import { theme } from '../../../theme';
import { StockCheck, StockCheckItem } from './../../../app/services/api/generated';

type IProps = {
    item: StockCheckItem,
    uom: string,
    stockCheckDetail?: StockCheck,
    selectedZoneId: string,
    allZonesEnabled: boolean,
    handleQuantityChange: (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => void;
    handleQuantityBlur: (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => void;
}

const StockCountQuantityInput = ({ item, uom, stockCheckDetail, selectedZoneId, allZonesEnabled, handleQuantityChange, handleQuantityBlur } : IProps) => {
  const foundItem = item?.itemValues?.find(value => value.uom === uom);

  return (
    <>
      <span className="title">{uom}</span>
      {foundItem ? (
        <>
          <span  className="conversion-to-each"
            style={{ color: theme.palette.custom.gray[300], marginLeft: '10px', width: '60px' }}>
            {
              foundItem.conversionToEach && uom !== 'EA' &&
                <Typography  variant='textXS'>({foundItem.conversionToEach} EA)</Typography>
            }
          </span>
          <TextField
            className="quantity-field"
            type="text"
            size="small"
            name="quantity-input"
            variant="outlined"
            value={foundItem.countQuantity ?? ''}
            disabled={stockCheckDetail?.status === StockCheckStatus.Pending || (selectedZoneId === '-1' && !allZonesEnabled)}
            sx={{
              width: '73px', ml: '10px', mr: '60px', backgroundColor: stockCheckDetail?.status === StockCheckStatus.Pending
                ? theme.palette.custom.gray[200]
                : undefined,
            }}
            onChange={(e) => handleQuantityChange(item.id, foundItem.id, e.target.value)}
            onBlur={(e) => handleQuantityBlur(item.id, foundItem.id, e.target.value)}
          />
        </>
      ) : (
        <>
          <span className="conversion-to-each"
            style={{ color: theme.palette.custom.gray[300], marginLeft: '10px', width: '60px' }}></span>
          <TextField
            className="quantity-field"
            type="text"
            size="small"
            name="quantity-input"
            variant="outlined"
            value='---'
            disabled
            sx={{
              width: '73px', ml: '10px', mr: '60px', backgroundColor: theme.palette.custom.gray[200],
            }}
          />
        </>
      )}
    </>
  );
};

export default StockCountQuantityInput;
