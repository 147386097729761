import { useContext } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { DialogActions, Button, Alert, Stack, Grid } from '@mui/material';

export const CreateStockTransfersButtons = () => {
  const { onCancel, 
    isFormInvalid, 
    isProcessingSubmit, 
    errorDismissButtonHandler, 
    successDismissButtonHandler, 
    isError, 
    isSubmittedSuccess, 
    isDisableActions,   
    handleSubmit, 
    createErrorMessage,
    t } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;

  return (
    <DialogActions sx={{ mb: 4, px: '24px' }}>
      <Grid container
        spacing={4}
        justifyContent="space-between">
        <Grid item
          xs={12}>
          {isError && <Alert severity="error"
            onClose={errorDismissButtonHandler}>
            {t('create_modal.error_message')} - {createErrorMessage}
          </Alert>}
          {isSubmittedSuccess && <Alert severity="success"
            onClose={successDismissButtonHandler}>
            {t('create_modal.success_message')}
          </Alert>}
          {isProcessingSubmit && <Alert severity="info">
            {t('create_modal.processing_message')}
          </Alert>}
        </Grid>
        <Grid item
          xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}>
            <Button variant='secondary'
              size="lg"
              onClick={onCancel}>
              {t('create_modal.cancel_button')}
            </Button>
            <Button
              variant='primary'
              size="lg"
              disabled={isFormInvalid() || isDisableActions}
              onClick={handleSubmit}>
              {t('create_modal.save_button')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

