import { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { MadeInStoreComponent } from '../../../../app/services/api/generated';

type Props = {
    item: MadeInStoreComponent;
}

const ComponentRow: FC<Props> = ({ item }) => {
  return (
    <TableRow>
      <TableCell>
        {item.componentItemNumber}
      </TableCell>
      <TableCell>
        {item.componentDescription}
      </TableCell>
      <TableCell>
        {item.uom}
      </TableCell>
      <TableCell>
        {Number(item.quantity).toFixed(2)}
      </TableCell>
      <TableCell>
        {Number(item.stockOnHandQuantity).toFixed(2)}
      </TableCell>
      <TableCell>
        {Number(item.totalYield).toFixed(2)}
      </TableCell>
    </TableRow>
  );
};

export default ComponentRow;