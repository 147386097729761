import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { DrawerContext, DrawerValues } from '../../contexts/DrawerContext';
import { SelectStoreModal } from './SelectStoreModal';
import { DesktopFooter } from './DesktopFooter';
import { MobileFooter } from './MobileFooter';

export const Footer = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile } = useContext(DrawerContext) as DrawerValues;

  const onLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const onStoreSelected = () => {
    setModalOpen(false);
    window.location.reload();
  };

  return <>
    {isMobile
      ?
      <MobileFooter onSwitchStore={() => setModalOpen(true)}
        onLogOut={onLogout}/>
      : <DesktopFooter onSwitchStore={() => setModalOpen(true)}
        onLogOut={onLogout}/>
    }
    <SelectStoreModal
      open={modalOpen}
      onConfirm={onStoreSelected}
      onClose={() => setModalOpen(false)}/>
  </>;
};
