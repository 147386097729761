export const viewItemsData = {
  title: 'View Items',
  overview: `
        <p>The View Items page gives Users a full and transparent list of all items that exist for a store. It includes all BoM items used by a store, as well as all ingredients (base materials) they have access to, in the same view. This data is listed for reference, and is not editable by users, but this option and other enhancements may be added in the future.</p>
    `,
  keyFeatures: [
    'Full visibility of all items (BoM and non-BoM) listed for a given store.',
    'Search option to return matches for item number or description.',
    'Total items number displayed to note how many items are in the list at one time.',
    'Key details displayed for all items including Primary UoM, Category, Status, and Cost Price.',
    'Sort and Paging controls to navigate the list.',
  ],
  userGuide: [
    {
      guideTitle: '',
      guideContent: `
                <p>The Items page is another reference page accessible under the Recipe heading in the main menu, and will display all the items available to the store you are currently using. This includes BoM and non-BoM items, so Recipes, Generic Kits, Ingredients (and any other item types) can be seen in the same view.</p>
                <p>By default the list is order by item number, this can be reversed by clicking the Item title again, or you can sort (and reverse sort) by the description column. These options are also listed in the main Sort control under the Search box. As for other pages the Search will return results when what you input is included in any part of the item number or description.</p>
                <p>Alongside the Search and Sort options is an Items number which displays however many items are in the list at that point, and will update after searching to indicate the number of results returned. The list includes a paging control underneath for basic navigation – as the page displays 10 lines at any time, this will only be shown if more than 10 items are being returned at that point.</p>
                <p>The UoM field display the Primary Unit of Measure for that item, and also acts as a link to the UoM Conversions page which will list all results for that item (if any). The Item number and Description field are also links, and both go the Item Details page for that item (similar to the Stock Count version, but without the Pricing history).</p>
            `,
    },
  ],
};
