import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { LocationResponse, StockCheckDetails, StockCheckItem } from '../../../app/services/api/generated';
import { DotsVertical } from '../../../assets';
import { BarcodeScannerModal } from './BarcodeScanner/BarcodeScannerModal';
import StockCountQuantityInput from './StockCountQuantityInput';
import OnlineOfflineSnackbar from './OnlineOfflineSnackbar';
import { StockCheckStatus } from '../../../enums/StockCheckStatus';

interface StockCheckDetailsProps {
  stockCheckDetails: StockCheckDetails,
  isScannerModalOpen: boolean,
  zonesData: LocationResponse[],
  selectedZoneId: string,
  handleSelectedZoneChange: (event: SelectChangeEvent<string>) => void;
  allZonesEnabled: boolean,
  closeModal: () => void
  handleSavingDataUserBackOnline: () => void;
}

export interface TempCategoryItem {
  name: string;
  items: StockCheckItem[];
}

const StockCountDetails = forwardRef<{ getUpdatedValues: () => { scheduledDate: string, stockCheckItems: StockCheckItem[] } | null }, StockCheckDetailsProps>(({ stockCheckDetails, isScannerModalOpen, closeModal, zonesData, selectedZoneId, handleSelectedZoneChange, allZonesEnabled, handleSavingDataUserBackOnline }, ref) => {
  const { t } = useTranslation('common');
  const stockCheckDetail = stockCheckDetails.stockCheck;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [scheduledDate, setScheduledDate] = useState<string>(stockCheckDetail?.plannedCountDate?.split('T')[0] ?? '');
  const [stockCheckItems, setStockCheckItems] = useState<StockCheckItem[]>(stockCheckDetail?.items ?? []);


  // to create a ref for the child component
  useImperativeHandle(ref, () => ({
    getUpdatedValues: () => ({
      scheduledDate,
      stockCheckItems,
    }),
  }), [scheduledDate, stockCheckItems]);

  const handleClickSetting = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorEl(null);
  };
  
  const handleScheduledDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setScheduledDate(event.target.value);
  };

  const updateItemValues = (item: StockCheckItem, itemId: number | undefined, valueId: number | undefined, newQuantity: number | string | undefined) => {
    if (item.id === itemId) {
      return {
        ...item,
        itemValues: item.itemValues?.map(value => {
          if (value.id === valueId) {
            return {
              ...value,
              countQuantity: newQuantity as number,
            };
          }
          return value;
        }),
      };
    }
    return item;
  };
  
  const handleQuantityChange = (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => {
    if (/^\d*\.?\d*$/.test(newQuantityString) || newQuantityString === '') {
  
      setStockCheckItems(prevItems => {
        const updatedItems = prevItems.map(item => updateItemValues(item, itemId, valueId, newQuantityString));
        return updatedItems;
      });
    }
  };

  const handleQuantityBlur = (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => {
    const value = Number(newQuantityString);
    const newQuantity = isNaN(value) ? 0 : value;

    setStockCheckItems(prevItems => {
      const updatedItems = prevItems.map(item => updateItemValues(item, itemId, valueId, newQuantity));
      return updatedItems;
    });
  };

  const printTableContainer = () => {

    const printableGridItems = document.querySelectorAll('.stock-count-categories');

    if (printableGridItems.length > 0) {
      // Hide the fieldsets with class MuiOutlinedInput-notchedOutline
      printableGridItems.forEach((printableGridItem) => {
        const fieldsets = printableGridItem.querySelectorAll('.MuiOutlinedInput-notchedOutline');
        fieldsets.forEach((fieldset) => {
          (fieldset as HTMLElement).style.display = 'none';
        });
      });
      // Create a new window for printing
      const WinPrint = window.open('', '', 'left=0,top=0,width=750,height=900,toolbar=0,scrollbars=0,status=0');
  
      if (WinPrint) {
      
        // Open the new window and begin writing the content
        WinPrint.document.write('<html><head><title>Print</title>');
        WinPrint.document.write('<style>');
        WinPrint.document.write(`
          .stock-count-categories .category {margin-bottom: 20px; margin-top: 30px}
          .stock-count-categories .category .title {font-size: 1.3em; color: #636363; margin-bottom: 5px;}
          td {border-top: 1px solid #ddd; padding: 10px 0px;}
          tr:first-child > td {border-top: 2px solid #ddd;}
          tr {border-top: 1px solid #ddd; }
          .quantity-column .quantity-fields-wrapper .quantity-field { width: 150px; display: flex; align-items: center; }
          .quantity-column .quantity-fields-wrapper .quantity-field .title { width: 50px; margin-left: 20px; }
          .quantity-column .quantity-fields-wrapper .quantity-field .conversion-to-each { width: 70px; font-size: 0.8em; }
          .quantity-column .quantity-fields-wrapper .quantity-field input { width: 50px; height: 24px; border: 1px solid #d3d3d3; text-align: center; }
          .category-table-cell { padding-left: 60px; padding-right: 60px;}
          .item { border-bottom: 1px solid #ccc; padding-bottom: 20px;}
          .item:last-child { border-bottom: none; }
        `);
        WinPrint.document.write('</style></head><body>');
  
        // Loop through each Grid item and append its content to the print window
        printableGridItems.forEach((printableGridItem) => {
          WinPrint.document.write('<div class="stock-count-categories">');
          WinPrint.document.write(printableGridItem.innerHTML);
          WinPrint.document.write('</div>');
        });
  
        WinPrint.document.write('</body></html>');
        WinPrint.document.close();
        WinPrint.focus();
  
        // Delay the print function by a short timeout to ensure content is fully loaded
        setTimeout(() => {
          // Initiate the print action
          WinPrint.print();
          WinPrint.close();
          // Restore visibility of input fields after printing
          printableGridItems.forEach((printableGridItem) => {
            const fieldsets = printableGridItem.querySelectorAll('.MuiOutlinedInput-notchedOutline');
            fieldsets.forEach((fieldset) => {
              (fieldset as HTMLElement).style.display = '';
            });
          });
        }, 500);
      }
    }
  };
  
  

  const itemsGroupedByCategory = (items: StockCheckItem[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const categories: { [key: string] : TempCategoryItem } = {};

    if (items) {
      // Group stock check items into categories to list them for display
      for (const item of items) {
        if (item.category) {
          if (categories[item.category]?.items) {
            categories[item.category].items.push(item);
          } else {
            categories[item.category] = {
              name: item.category,
              items: [item],
            };
          }
        }
      }
    }
    const sortedCategories = Object.values(categories).sort((a, b) => a.name.localeCompare(b.name));
    return sortedCategories;
  };

  return (
    
    <Grid container
      sx={{ border: '1px solid', borderColor: theme.palette.custom.gray[300] }}>
      <Grid item
        xs={12}
        textAlign="right"
        mr="20px">
        <IconButton aria-label="settings"
          sx={{ mr: '-16px' }}
          onClick={handleClickSetting}>
          <DotsVertical />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseSetting}
        >
          <MenuItem onClick={printTableContainer}>Print</MenuItem>
        </Menu>
      </Grid>
      <Grid item
        xs={12}
        md={3}
        container
        spacing={2}
        sx={{ textAlign: 'left', ml: '10px' }}>
        <Grid item
          xs={12}>
          <Typography variant='textLG'>
            {t('createdBy')}
          </Typography>
        </Grid>
        <Grid item
          xs={12}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={stockCheckDetail?.createdBy}
            disabled={true}
            sx={{
              width: '90%', backgroundColor: theme.palette.custom.gray[200],
            }}
          />
        </Grid>
        
      </Grid>

      {/* Scheduled date section */}
      <Grid item
        xs={12}
        md={3}
        container
        spacing={2}
        sx={{ textAlign: 'left', ml: '10px' }}>
        <Grid item
          xs={12}>
          <Typography variant='textLG'>
            {t('scheduledDate')}
          </Typography>
        </Grid>
        <Grid item
          xs={12}>
          <TextField
            type="date"
            required
            value={scheduledDate}
            onChange={handleScheduledDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{
              width: '90%', 
            }}
          />
        </Grid>
      </Grid>
      {/* Stock Count Zones */}
      {zonesData && zonesData.length > 0 &&
      <Grid item
        xs={12}
        md={3}
        container
        spacing={2}
        sx={{ textAlign: 'left', ml: '10px' }}>
        <Grid item
          xs={12}>
          <Typography variant='textLG'>
            {t('stockCountZonesDdl')}
          </Typography>
        </Grid>
        <Grid item
          xs={12}>
          <Select
            id="stock-count-zones-ddl"
            sx={{
              width: '90%', 
            }}
            value={selectedZoneId}
            onChange={handleSelectedZoneChange}
          >
            <MenuItem value="-1">
              <em>{t('stockCountZonesDdlAll')}</em>
            </MenuItem>
            {zonesData.map((zone) => (
              <MenuItem key={zone.id}
                value={zone.id}>{zone.name}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>}
      {stockCheckDetail?.status === StockCheckStatus.Counting && <Grid item
        xs={12}
        md={zonesData?.length > 0 ? 2 : 5}
        alignSelf={{ xs: 'center', md: 'end' }}
        textAlign={{ xs: 'center', md: 'end' }}
        sx={{ mt: '20px' }}>

        <OnlineOfflineSnackbar  
          onOnline={handleSavingDataUserBackOnline}
        />
      </Grid>}
      <Grid item
        className="stock-count-categories" 
        xs={12}
        sx={{ mt: '20px' }}>
        {itemsGroupedByCategory(stockCheckItems).map((category : TempCategoryItem) => (
          <Box
            key={category.name}
            display='flex'
            flexDirection='column'
            my={4}
            className="category" 

          >
            <Box
              display="flex"
              flexDirection="row"
              p={4}
              className="title" 
            >
              <Typography
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {category.name}
              </Typography>
            </Box>
            <TableContainer component={'div'}
              id={`stock-items-table-container-${category.name}`}
              key={category.name}>
              <Table
                size="small"
                aria-label="a table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '1x' }}
                      align="left"
                    >
                      {t('skuNumber')}
                    </TableCell>
                    <TableCell sx={{ width: '2x' }}
                      align="left"
                      className='category-table-cell'>
                      {t('skuDescription')}
                    </TableCell>
                    <TableCell sx={{ width: '3x' }}
                      align="left"
                    >
                      {t('quantity')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.items.sort((a, b) => a.description?.toLocaleLowerCase().localeCompare(b.description?.toLocaleLowerCase() ?? '') ?? -1)
                    .map((item) => (
                      <TableRow sx={{
                        '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '1px solid',
                        borderBottomColor: theme.palette.custom.gray[200],
                        backgroundColor: theme.palette.custom.gray[50],
                      }}
                      className='item'
                      key={item.id}>
                        <TableCell >
                          {item.itemNumber}
                        </TableCell>
                        <TableCell sx={{ width: '2x' }}
                          className='category-table-cell'>
                          {item.description}
                        </TableCell>
                        <TableCell className="quantity-column"
                          sx={{ width: '3x', height: '60px' }}>
                          {
                            item.itemValues &&
                          <div className="quantity-fields-wrapper"
                            style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <StockCountQuantityInput item={item}
                              uom={'CS'}
                              stockCheckDetail={stockCheckDetail}
                              selectedZoneId={selectedZoneId}
                              allZonesEnabled={allZonesEnabled}
                              handleQuantityChange={handleQuantityChange}
                              handleQuantityBlur={handleQuantityBlur} />

                            <StockCountQuantityInput item={item}
                              uom={'IP'}
                              stockCheckDetail={stockCheckDetail}
                              selectedZoneId={selectedZoneId}
                              allZonesEnabled={allZonesEnabled}
                              handleQuantityChange={handleQuantityChange}
                              handleQuantityBlur={handleQuantityBlur} />

                            <StockCountQuantityInput item={item}
                              uom={'EA'}
                              stockCheckDetail={stockCheckDetail}
                              selectedZoneId={selectedZoneId}
                              allZonesEnabled={allZonesEnabled}
                              handleQuantityChange={handleQuantityChange}
                              handleQuantityBlur={handleQuantityBlur} />
                          </div>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Grid>
      <BarcodeScannerModal isScannerOpen={isScannerModalOpen}
        closeModal={closeModal}
        
        stockCheckItems={stockCheckItems}
        setStockCheckItems={setStockCheckItems}
        stockCountName={stockCheckDetail?.name}></BarcodeScannerModal>
    
    </Grid>
  );
});

StockCountDetails.displayName = 'StockCountDetails';
export default StockCountDetails;

