import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, SvgIcon, TableSortLabel, Tooltip, Checkbox } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import DeleteModal from './CategoriesDeleteModal';
import CreateEditModal from './CategoriesEditCreateModal';
import { PrimaryCategoryStockCount } from '../../../app/services/api/generated';
import { Check, Trash01 } from '../../../assets';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { PostAdd } from '@mui/icons-material';
import BulkAddModal from './CategoriesBulkAddModal';

export default function DenseTable(props: { readonly isSalesConfigEnabled: boolean, readonly isDailyWasteConfigEnabled: boolean, readonly categories: PrimaryCategoryStockCount[]
  readonly list: PrimaryCategoryStockCount[], readonly loadData: () => void, readonly page: number, readonly setPage: React.Dispatch<React.SetStateAction<number>>
}) {
  const { t } = useTranslation('manageCategories');
  const { savePrimaryCategory, deletePrimaryCategory, updateCategorySalesForecast, updateCategoryWasteCheckSheet } = useNswagClient();
  const [data, setData] = React.useState(props.list);
  const [selectedCategory, setSelectedCategory] = React.useState<PrimaryCategoryStockCount | undefined>(undefined);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showBulkEditModal, setShowBulkEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const rowsPerPage = 10;
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof PrimaryCategoryStockCount>('primaryCategoryName' as keyof PrimaryCategoryStockCount);
  const maxPages = props.list
    ? Math.max(1, Math.ceil(props.list.length / rowsPerPage))
    : 1;
  const [selectedStore, defaultStoreValue] = useLocalStorage('selectedStore', null);
  const [checkedWasteChecks, setCheckedWasteChecks] = React.useState<number[]>([]);
  const [checkedSalesConfig, setCheckedSalesConfig] = React.useState<number[]>([]);

  React.useEffect(() => {
    setData(props.list);
    setCheckedSalesConfig(props.list.filter(x => x.salesForecast == true).map(x => x.id!));
    setCheckedWasteChecks(props.list.filter(x => x.wasteCheckSheet == true).map(x => x.id!));
  }, [props.list]);

  const handleSort = (property: keyof PrimaryCategoryStockCount) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSearchChange();
    }
  };

  const sortedData = () => {
    const isAsc = order === 'asc';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const comparator = (a: any, b: any) => {
      
      if (typeof a[orderBy] === 'string') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]) as any;
      }
    };
    return props.list.sort(comparator);
  };

  const edit = (c: PrimaryCategoryStockCount | undefined) => {
    setSelectedCategory(c);
    setShowEditModal(true);
  };

  const bulkEdit = (c: PrimaryCategoryStockCount | undefined) => {
    setSelectedCategory(c);
    setShowBulkEditModal(true);
  };

  const handleCloseModal = () => {
    setShowBulkEditModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedCategory(undefined);
  };

  const deleteCategory = (c: PrimaryCategoryStockCount) => {
    setSelectedCategory(c);
    setShowDeleteModal(true);
  };

  const handleEdit = (body: PrimaryCategoryStockCount) => {
    savePrimaryCategory(body).then(props.loadData);
  };

  const handleDelete = (substituteId: number) => {
    deletePrimaryCategory(selectedStore?.storeNumber ?? defaultStoreValue, selectedCategory?.id!, substituteId).then(props.loadData);
  };

  const handleSearchChange = () => {
    setData(sortedData());
  };

  const visibleRows = () => {
    return data.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage);
  };

  const handleUpdateWasteSheet = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { checked } = event.target;

    if (checked){
      setCheckedWasteChecks(checkedWasteChecks.concat(id));
    }
    else {
      setCheckedWasteChecks(checkedWasteChecks.filter(item => item !== id));
    }

    updateCategoryWasteCheckSheet(id, checked);
  };

  const handleUpdateSalesConfig = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { checked } = event.target;

    if (checked){
      setCheckedSalesConfig(checkedSalesConfig.concat(id));
    }
    else {
      setCheckedSalesConfig(checkedSalesConfig.filter(item => item !== id));
    }

    updateCategorySalesForecast(id, checked);
  };

  return (
    <Grid item
      xs={12}
      p={5}>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table">
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell
                align="left"
                width={'25%'}>
                <TableSortLabel
                  active={orderBy === 'primaryCategoryName'}
                  direction={order}
                  onClick={() => handleSort('primaryCategoryName')}>
                  {t('primaryCategory')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'systemGenerated'}
                  direction={order}
                  onClick={() => handleSort('systemGenerated')}>
                  {t('system')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'itemCount'}
                  direction={order}
                  onClick={() => handleSort('itemCount')}>
                  {t('items')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'itemOverrideCount'}
                  direction={order}
                  onClick={() => handleSort('itemOverrideCount')}>
                  {t('itemOverrides')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'smartCount'}
                  direction={order}
                  onClick={() => handleSort('smartCount')}>
                  {t('smart')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'zeroStockItem'}
                  direction={order}
                  onClick={() => handleSort('zeroStockItem')}>
                  {t('0Stock')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'includeInReports'}
                  direction={order}
                  onClick={() => handleSort('includeInReports')}>
                  {t('report')}
                </TableSortLabel>
              </TableCell>
              {props.isDailyWasteConfigEnabled &&
              <TableCell
                align="left"> 
                {t('wasteCheckSheet')} 
              </TableCell>
              }
              {props.isSalesConfigEnabled &&
              <TableCell
                align="left"> 
                {t('salesForecast')} 
              </TableCell>
              }
              <TableCell align="left">
                {t('bulkAdd')}
              </TableCell>
              <TableCell align="left">
                {t('remove')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <React.Fragment key={row.id}>
                <TableRow hover>
                  <TableCell
                    onClick={() => edit(row)}>
                    {row.primaryCategoryName}
                  </TableCell>
                  <TableCell 
                    onClick={() => edit(row)}>{row.systemGenerated === true ? <Check /> : row.systemGenerated}</TableCell>
                  <TableCell
                    onClick={() => edit(row)}>
                    {row.itemCount}
                  </TableCell>
                  <TableCell
                    onClick={() => edit(row)}>
                    {row.itemOverrideCount}
                  </TableCell>
                  <TableCell
                    onClick={() => edit(row)}>{row.smartCount === true ? <Check /> : row.smartCount}</TableCell>
                  <TableCell 
                    onClick={() => edit(row)}>{row.zeroStockItem === true ? <Check /> : row.zeroStockItem}</TableCell>
                  <TableCell >{row.includeInReports === true ? <Check /> : row.includeInReports}</TableCell>
                  {props.isDailyWasteConfigEnabled &&
                  <TableCell>
                    <Checkbox
                      onChange={(event) => handleUpdateWasteSheet(event, row.id!)}
                      checked={checkedWasteChecks.includes(row.id!)}/>
                  </TableCell>
                  }
                  {props.isSalesConfigEnabled &&
                    <TableCell>
                      <Checkbox
                        onChange={(event) => handleUpdateSalesConfig(event, row.id!)}
                        checked={checkedSalesConfig.includes(row.id!)}/>
                    </TableCell>                  
                  }
                  <TableCell>
                    <Tooltip title='Bulk Add'
                      color='dark'
                      placement='top'>
                      <IconButton
                        size='small'
                        onClick={() => bulkEdit(row)}>
                        <SvgIcon><PostAdd></PostAdd></SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {!row.systemGenerated &&
                    <Tooltip title='Delete category'
                      color='dark'
                      placement='top'>
                      <IconButton
                        size='small'
                        onClick={() => deleteCategory(row)}>
                        <SvgIcon color='error'><Trash01></Trash01></SvgIcon>
                      </IconButton>
                    </Tooltip>
                    }   
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={props.page}
        setPage={props.setPage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>

      <CreateEditModal openModal={showEditModal}
        onConfirm={(requestBody: PrimaryCategoryStockCount) => handleEdit(requestBody)}
        category={selectedCategory}
        onCancel={handleCloseModal}></CreateEditModal>

      <BulkAddModal 
        loadData={props.loadData}
        id={selectedCategory?.id!}
        openModal={showBulkEditModal}
        onCancel={handleCloseModal}></BulkAddModal>
        
      <DeleteModal openModal={showDeleteModal}
        list={props.categories.filter(x => x.id !== selectedCategory?.id)}
        onConfirm={(substituteId: number) => handleDelete(substituteId)}
        onCancel={handleCloseModal}></DeleteModal>              
    </Grid>
  );
}
