import { palette } from '../palette';

const MuiTooltipDark = {
  tooltip: {
    backgroundColor: palette.custom.gray[900],
    color: 'white',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    borderRadius: '8px',
    fontSize: '0.75rem',
    fontWeight: 600,
    maxWidth: '320px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  arrow: {
    color: palette.custom.gray[900],
  },
};

const MuiTooltipLight = {
  ...MuiTooltipDark,
  tooltip: {
    ...MuiTooltipDark.tooltip,
    backgroundColor: palette.common.white,
    color: palette.custom.gray[700],
  },
  arrow: {
    ...MuiTooltipDark.arrow,
    color: 'white',
  },
};

export const MuiTooltip = {
  defaultProps: {
    placement: 'top',
    arrow: true,
    color: 'light',
  },
  styleOverrides: MuiTooltipDark,
  variants: [
    {
      props: { color: 'dark' },
      style: { ...MuiTooltipDark },
    },
    {
      props: { color: 'light' },
      style: { ...MuiTooltipLight },
    },
  ],
};

declare module '@mui/material/Tooltip' {
  interface TooltipProps {
    color?: 'dark' | 'light'
  }
}