import { Alert, Box, Button, Dialog, DialogActions, DialogContent,  DialogTitle,  Grid,  IconButton,  TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { Plus } from '../../../../assets';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { GetItemsByIdsRequest, Item, SuccessResponse_1OfList_1OfItem } from '../../../../app/services/api/generated';
import useLogError from '../../../../hooks/useLogError';
import { ScheduleCreateContext, ScheduleCreateValues } from '../../contexts/ScheduleCreateContext';
import CloseIcon from '@mui/icons-material/Close';

const ScheduleCreateBulkModal=() => {
  const { t,
    isBulkModalOpen,
    handleClose,
    handleitems } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const [inputString, setInputString] = useState('');
  const { selectedStore } = useContext(UserContext);
  const { getItemsById } = useNswagClient();
  const [bulkAddSuccess, setBulkAddSuccess] =useState<Item[] >([]);
  const [bulkAddFailed, setBulkAddFailed] = useState<string[]>([]);
  const [changeContext, setChangeContext] = useState<boolean>(false);
  const { logError } = useLogError();
  
  const handleConfirm =  (data : Item[], failedData : string[]) => {
    handleitems(data);
    if (failedData.length === 0) {
      closeModal();
    }else{
      setChangeContext(true);
    }
  };

  const closeModal =  ( ) => {
    setChangeContext(false);
    setBulkAddFailed([]);
    setBulkAddSuccess([]);
    handleClose();
  };
  
  const getData = () => {
    let array = inputString.split(' ').map((item) => item.trim());
    array = array.flatMap((item) => item.split('\n').map((innerItem) => innerItem.trim()));
    const body : GetItemsByIdsRequest = {
      storeNumber : selectedStore?.storeNumber,
      itemNumbers: array,
      stockItemsOnly: true,
    };
    
    getItemsById(body).then((result) => {
      processGetResponse(result, body);
    })
      .catch((error) => {
        logError(error);
      });
  };

  const processGetResponse = (result: SuccessResponse_1OfList_1OfItem, body : GetItemsByIdsRequest) => {
    if (result) { 
      const data  = result.data;
      const newItemsList = [...bulkAddSuccess];
      for (const item of data || []) {
        if (!newItemsList.some(x => x.itemNumber === item.itemNumber)) {
          newItemsList.push(item);
        }
      }
      setBulkAddSuccess(newItemsList);
      const failedList = [...bulkAddFailed];
      const missingItemNumbers = body.itemNumbers?.filter( x => !result.data?.some ( y =>x === y.itemNumber));
        
      for (const missingItemNumber of  missingItemNumbers!) {
        if (!failedList.some(x => x === missingItemNumber)) {
          failedList.push(missingItemNumber);
        }
      }
      setBulkAddFailed(failedList);
      handleConfirm(newItemsList , failedList);
    } else {
      logError(result);
    }
  };

  return (
    <>
      {!changeContext ? (
        <Dialog open={isBulkModalOpen}
          onClose={closeModal}
          maxWidth='md'
          fullWidth>
          <DialogTitle>
            {t('addBulk')}
            <IconButton
              onClick={closeModal}
              sx={{ position: 'absolute',
                right: 16,
                top: 8,
                color: (theme) => theme.palette.custom.gray[800] }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Alert severity='info'>
              {t('info')}
            </Alert>
            <Box sx={{ pt:'10px' }}>
              <TextField placeholder={t('addText')}
                multiline
                rows={10} 
                onChange={(e) => setInputString(e.target.value)} 
                fullWidth/>
            </Box>
          </DialogContent>
          <DialogActions >
            <Button 
              variant="secondary"
              onClick={closeModal}>
              {t('cancel')}
            </Button>
            <Button onClick={getData}
              startIcon={<Plus />}>
              {t('add')}
            </Button>
          </DialogActions>
        </Dialog> 
      )
        :
        (
          <Dialog open={isBulkModalOpen}
            onClose={closeModal}
            maxWidth='md'
            fullWidth>
            <DialogTitle>
              {t('failedSkusTitle')}
              <IconButton
                onClick={closeModal}
                sx={{  position: 'absolute',
                  right: 16,
                  top: 8,
                  color: (theme) => theme.palette.custom.gray[800] }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Alert severity='warning'>
                {t('failedSkus')}
              </Alert>
              <Grid sx={{ pt:'10px' }}>
                <TextField value={bulkAddFailed}
                  multiline
                  rows={10} 
                  disabled
                  fullWidth/>
              </Grid>
            </DialogContent>
            <DialogActions >
              <Button variant="secondary"
                size='lg'
                onClick={closeModal}>
                {t('cancel')}
              </Button>
            </DialogActions>
          </Dialog> 
        )}
    </>
  );
};

export default ScheduleCreateBulkModal;