import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../../contexts/PurchaseOrderDetailsContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const PurchaseOrderDetailsCreditRequestModal = () => {
  const { t } = useTranslation('purchaseOrderDetails');

  const { isCreditRequestModalOpen, closeCreditRequestModal } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;


  return (
    <Dialog onClose={closeCreditRequestModal}
      open={isCreditRequestModalOpen}>
      <DialogTitle>
        {t('requestCreditModal.title')}
      </DialogTitle>
      <IconButton
        onClick={closeCreditRequestModal}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography>
          {t('requestCreditModal.body')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='primary'
          size="lg"
          onClick={closeCreditRequestModal}>
          {t('requestCreditModal.yes')}
        </Button>
        <Button variant='secondary'
          size="lg"
          onClick={closeCreditRequestModal}>
          {t('requestCreditModal.no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};