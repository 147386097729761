import { useState, useRef, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

interface TextWithOverflowTooltipProps {
  text: string; 
} 
const TextWithOverflowTooltip = (props: Readonly<TextWithOverflowTooltipProps>) => { 
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();  
    window.addEventListener('resize', checkOverflow); 

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [props.text]);

  return (
    <Tooltip title={isOverflowing ? props.text : ''}
      arrow>
      <Box
        ref={textRef}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          width: '100%',
        }}
      >
        {props.text}
      </Box>
    </Tooltip>
  );
};

export default TextWithOverflowTooltip;
