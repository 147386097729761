import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Trash01 } from '../../../assets';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';
import CloseIcon from '@mui/icons-material/Close';

export interface Reports  {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  reportId: number ;
  name: string | undefined;
 }

const DeleteModal=( props: Reports) => {
  const { deleteAutomatedTasks } = useNswagClient();
  const { logError } = useLogError();
  const { t } = useTranslation('scheduledReports');
 
  const deleteReason = () => {
    deleteAutomatedTasks(props.reportId).then(() => {
      props.onConfirm();
    })
      .catch((error) => {
        logError(error);
      });
  };
  const handleClose = () => {
    props.onClose();
  };
  return (
    <Dialog open={props.open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth>
      <DialogTitle>
        {t('deleteTitle')}
        <IconButton
          onClick={props.onClose}
          sx={{ position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='textMD'>{t('confirmDelete')}{props.name}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          onClick={handleClose}>{t('cancel')}</Button>
        <Button onClick={deleteReason}
          variant="bad"
        ><Trash01 />
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog> 
  );
};

export default DeleteModal;