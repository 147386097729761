import React, { createContext, useMemo } from 'react';
import { usePendingChanges } from '../hooks/usePendingChanges';
import { ItemFeedChange } from '../../../app/services/api/generated';
import { TFunction } from 'i18next';

export interface PendingChangesValues {
    data: ItemFeedChange[],
    page: number,
    maxPages: number,
    isLoading: boolean,
    isChangesDialogOpen: boolean,
    isDisregardDialogOpen: boolean,
    viewId: number,
    searchTerm: string,
    handleViewButtonClick: (id: number) => () => void,
    closeDialog: () => void,
    handleDisregardButtonClick: (id: number) => () => void,
    closeDisregardDialog: () => void,
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleSearchButtonClick: () => void,
    LoadData: (passedSearch?: string, passedPage?: number) => void,
    handleClearButtonClick: () => void,
    t: TFunction<'recipeUpdates', undefined>,
    changePage: (pageNumber: number) => void,
    handleRowSelection: (itemId: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleHeaderSelection:  () => void,
    handleDisregardSelected:  () => void,
    handleApplySelected:  () => void,
    isProcessing: boolean,
    isFinishedProcessing: boolean,
    isErrorProcessing: boolean,
    selectedRows: number[],
    dismissAlerts: () => void,
    errorMessage: string,
}

export const PendingChangesContext = createContext<null | PendingChangesValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const PendingChangesProvider = ({ children }: ProviderProps) => {
  const {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData,
    handleClearButtonClick,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  } = usePendingChanges();

  const passedValue = {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    searchTerm,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    handleSearchChange,
    handleSearchButtonClick,
    LoadData,
    handleClearButtonClick,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  };

  const PendingChangesProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <PendingChangesContext.Provider value={PendingChangesProviderValue.passedValue}>
      {children}
    </PendingChangesContext.Provider>
  );
};