import React, { createContext, useMemo } from 'react';
import { GroupedItemFeedChange } from '../../../app/services/api/generated';
import { TFunction } from 'i18next';
import { useAllStoresChanges } from '../hooks/useAllStoresChanges';

export interface AllStoresValues {
    data: GroupedItemFeedChange[],
    page: number,
    maxPages: number,
    isLoading: boolean,
    isChangesDialogOpen: boolean,
    isDisregardDialogOpen: boolean,
    viewId: number[],
    handleViewButtonClick: (id: number[]) => () => void,
    closeDialog: () => void,
    handleDisregardButtonClick: (id: number[]) => () => void,
    closeDisregardDialog: () => void,
    loadData: (passedPage?: number) => void,
    t: TFunction<'recipeUpdates', undefined>,
    changePage: (pageNumber: number) => void,
    handleRowSelection: (itemId: number[]) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleHeaderSelection: () => void,
    handleDisregardSelected: () => Promise<void>,
    handleApplySelected: () => Promise<void>,
    isProcessing: boolean,
    isFinishedProcessing: boolean,
    isErrorProcessing: boolean,
    selectedRows: number[][],
    dismissAlerts: () => void,
    errorMessage: string,
}

export const AllStoresContext = createContext<null | AllStoresValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const AllStoresContextProvider = ({ children }: ProviderProps) => {
  const {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    loadData,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  } = useAllStoresChanges();

  const passedValue = {
    data,
    page,
    maxPages,
    isLoading,
    isChangesDialogOpen,
    isDisregardDialogOpen,
    viewId,
    handleViewButtonClick,
    closeDialog,
    handleDisregardButtonClick,
    closeDisregardDialog,
    loadData,
    changePage,
    t,
    handleRowSelection,
    handleHeaderSelection,
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  };

  const AllStoresProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <AllStoresContext.Provider value={AllStoresProviderValue.passedValue}>
      {children}
    </AllStoresContext.Provider>
  );
};