import { Box, Typography, TextField, Alert } from '@mui/material';
import StockCheckExport from './StockCheckExport';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import { StockCheckStatus as StatusEnum } from './../../../enums/StockCheckStatus';
import { StockCheckStatus } from './../../../app/services/api/generated';

type Props = {
  stockCheckId: number;
  countedBy: string;
  approvedBy: string;
  plannedCountDate: string;
  isCompleted: boolean;
  hasPosData?: boolean;
  lastPosDate?: string;
  status?: StockCheckStatus;
}

const StockCheckTop: FC<Props> = ({ stockCheckId, countedBy, approvedBy, plannedCountDate, isCompleted, hasPosData, lastPosDate, status }) => {
  const { t } = useTranslation('stockCountReview');

  return (
    <Box>
      <Box sx={{ display: 'flex', flex: 1, alignSelf: 'start', justifyContent: 'end' }}>
        <StockCheckExport
          stockCheckId={stockCheckId}
          isCompleted={isCompleted} />
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex={3}
          justifyContent="space-between"
          sx={{ flexDirection: { xs: 'column', md: 'row', columnGap: '6px', rowGap: '18px' } }}
        >
          <Box
            display="flex"
            flex={{ xs: 12, md: 12, lg: 1 }}
            flexDirection="column"
          >
            <Typography
              variant="textSM"
              sx={{
                display: 'flex',
                alignItems: 'left',
                textAlign: 'start',
                pb: '6px',
              }}
            >
              {t('countedBy')}:
            </Typography>
            <TextField
              value={countedBy}
              placeholder={t('countedBy')}
              disabled
              variant="outlined"
            />
          </Box>
          <Box
            display="flex"
            flex={{ xs: 12, md: 12, lg: 1 }}
            flexDirection="column"
          >
            <Typography
              variant="textSM"
              sx={{
                display: 'flex',
                alignItems: 'left',
                textAlign: 'start',
                pb: '6px',
              }}
            >
              {t('approvedBy')}:
            </Typography>
            <TextField
              value={approvedBy}
              placeholder={t('approvedBy')}
              disabled
              variant="outlined"
            />
          </Box>
          <Box
            display="flex"
            flex={{ xs: 12, md: 12, lg: 1 }}
            flexDirection="column"
          >
            <Typography
              variant="textSM"
              sx={{
                display: 'flex',
                alignItems: 'left',
                textAlign: 'start',
                pb: '6px',
              }}
            >
              {t('planedCountDate')}:
            </Typography>
            <TextField
              value={formatDate(new Date(plannedCountDate))}
              placeholder={t('planedCountDate')}
              disabled
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      { 
        hasPosData && status == StatusEnum.PendingApproval &&
        <Box
          display="flex"
          flex={1}
        >
          <Alert
            sx={{ mt: '18px', flex: '1', overflow: 'hidden' }}
            severity="error">
            {
              lastPosDate ? `${t('lastPosReceived')} ${formatDate(new Date(lastPosDate))}` : t('lastPostNotReceived')
            }
          </Alert>
        </Box>
      }
    </Box>
  );
};

export default StockCheckTop;