export enum ReportType {
  ActualVsTheory = 'reporting.report-names.actual-vs-theorectical',
  StockOnHand = 'reporting.report-names.stock-on-hand',
  Waste = 'reporting.report-names.waste',
  ItemActivity = 'reporting.report-names.item-activity',
  Transfers = 'reporting.report-names.transfers',
  ExtendedStockCount = 'reporting.report-names.extendedstockcount',
  StockCount = 'reporting.report-names.stock-count',
  ItemTimeout = 'reporting.report-names.item-time-out',
  CostOfSales = 'reporting.report-names.cost-of-sales',
  StockDays = 'reporting.report-names.stock-days',
  Invoice = 'reporting.report-names.invoice',
  StockCountSummary = 'reporting.report-names.stockcountsummary',
  Margin = 'reporting.report-names.margin',
  EgActualVsTheory = 'reporting.report-names.eg-actual-vs-theory-report',
  EgWaste = 'reporting.report-names.eg-waste-report',
  EgCostOfSales = 'reporting.report-names.eg-cost-of-sale-report',
  EgItemActivity = 'reporting.report-names.eg-item-activity-report',
  EgItemTimeout = 'reporting.report-names.eg-item-timeout-report',
  EgStockDays = 'reporting.report-names.eg-stock-days-report',
  EgStockOnHand = 'reporting.report-names.eg-stock-on-hand-report',
  EgTransfers = 'reporting.report-names.eg-transfer-report',
  CreditPurchase = 'reporting.report-names.credit-purchase-report',
  StockCountVsUsage = 'reporting.report-names.stockcountsvsusage',
  DcShortage = 'reporting.report-names.dc-shortage',
  ConstrainedItem = 'reporting.report-names.constrained-item',
  ItemStockOut = 'reporting.report-names.item-stock-out',
  Receipt = 'reporting.report-names.receipt',
  MadeInStore = 'reporting.report-names.made-in-store',
  Cogs = 'reporting.report-names.cogs',
  SalesCheck = 'reporting.report-names.sales-check',
}
