import { ManageMediaModal } from '../interfaces/CreditRequestInterfaces';
import ImageUpload from '../components/ImageUpload';
import {  Box,Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import Camera from '@mui/icons-material/CameraAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const MediaModal = (props: ManageMediaModal) => {
  const { t } = useTranslation('createCreditRequest');
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' , m: 4, p: 4 }}>
        <Camera style={{ marginRight: '8px' }}/> {t('modals.mediaImages')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ py: 5,
          px: 8,
          width: '100%',
          textAlign: 'left' }}>
          <ImageUpload selectedRow={props.selectedRow}
            updateSelectedRow={props.updateSelectedRow}
            isButtonVisible = {props.isButtonVisible}
          />
        </Box>
      </DialogContent>
    </Dialog> 
  );
};

export default MediaModal;