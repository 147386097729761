import { Box, IconButton } from '@mui/material';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../../theme';
import { NavItem } from './NavItem';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';

export const SlidingSubMenu = () => {
  const { handleNavItemClick, isMobile, selectedNavItem, slidingSubMenuWidth, setSelectedNavItem } = useContext(DrawerContext) as DrawerValues;

  if (isMobile) return <></>;
  if (!selectedNavItem?.children) return <></>;
  
  return <Box className='AppSlidingSubMenu-root'
    width={slidingSubMenuWidth}
    py={9}
    px={6}>
    <IconButton onClick={() => setSelectedNavItem(undefined)}
      style={{ position: 'absolute', right: 8, top: 0, color: theme.palette.primary[300] }}>
      <CloseIcon />
    </IconButton>
    {selectedNavItem.children?.map((each, i) => {
      return <Box key={`SlidingSubMenu-${each.translationKey}-${i}`}>
        <NavItem item={each}
          onClick={() => handleNavItemClick(each)}/>
      </Box>;
    })}
  </Box>;};