import { type Theme, createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './components';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 1600,
  },
};

const zIndex = {};
const spacing = [
  0, 2, 4, 6, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80, 96, 128, 160,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const themeOverrides: Record<string, any> = {
  breakpoints,
  palette,
  typography,
  zIndex,
  components,
  spacing,
};

export const darkThemeOverrides = {
  ...themeOverrides,
  palette,
};

export const theme: Theme = createTheme(themeOverrides);
