export enum OrderType {
    DirectStoreDelivery = 12,
}

export enum OrdersType {
  Fresh = 'Fresh',
  Ambient = 'Ambient',
  AmbientAutoship = 'Ambient (autoship)',
  HVI = 'HVI',
  FreshAutoship = 'Fresh (autoship)',
  StoreDevelopment = 'Store Development',
  HVIAutoship = 'HVI (autoship)',
  All = 'All'
}
export const OrderTypeTranslationMap = {
  [OrdersType.Fresh]: 'ordersType.fresh',
  [OrdersType.Ambient]: 'ordersType.ambient',
  [OrdersType.AmbientAutoship]: 'ordersType.ambient_autoship',
  [OrdersType.HVI]: 'ordersType.hvi',
  [OrdersType.FreshAutoship]: 'ordersType.fresh_autoship',
  [OrdersType.StoreDevelopment]: 'ordersType.store_development',
  [OrdersType.HVIAutoship]: 'ordersType.hvi_autoship',
  [OrdersType.All]: 'ordersType.all',
};
   
export function isOrderType(value: string | null | undefined): value is OrdersType {
  return value != null && Object.values(OrdersType).includes(value as OrdersType);
}