import { Stack } from '@mui/material';
import ScheduleCreateMain from './components/ScheduleCreateMain';
import ScheduleCreatePopUps from './components/ScheduleCreatePopUps';
import { ScheduleCreateProvider } from './contexts/ScheduleCreateContext';
import ScheduleCreateTop from './components/ScheduleCreateTop';
import ScheduleCreateItems from './components/ScheduleCreateItems';
import StockCountScheduleCreateCategories from './components/ScheduleCreateCategories';
import ScheduleCreateSchedules from './components/ScheduleCreateSchedules';
import PageArea from '../../components/shared/PageArea';

const StockCountScheduleCreate=() => {
  return (
    <ScheduleCreateProvider>
      <PageArea>
        <Stack spacing={5}>
          <ScheduleCreateTop/>
          <ScheduleCreateMain/>
          <ScheduleCreateSchedules/>
          <StockCountScheduleCreateCategories/>
          <ScheduleCreateItems/>
        </Stack>
      </PageArea>
      <ScheduleCreatePopUps/>
    </ScheduleCreateProvider>
  );
};

export default StockCountScheduleCreate;