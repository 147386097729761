import { Box, Grid } from '@mui/material';
import ManageUsersBox from './components/ManageUsersBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { SuccessResponse_1OfUser, SuccessResponse_1OfUserListResponse, User } from '../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import useLogError from '../../hooks/useLogError';
import PageTitle from '../../components/shared/PageTitle';
import { UserContext } from '../../components/shared/useUser';

const UserManagement = () => {
  const { getUsers, getCurrentUser , getUsersByFranchise } = useNswagClient();
  const { t } = useTranslation('manageUsers');
  const [users, setUsers] = useState<User[]>([]);
  const [franchise, setFranchise] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logError } = useLogError();
  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    if (isAdmin()){
      loadData();
    }
    else{
      getActiveUser();
    }
    
  }, []);

  useEffect(() => {
    if (franchise) {
      loadData();
    }
  }, [franchise]);

  const loadData = () => {
    setIsLoading(true);
    const fetchUser = isAdmin() ? getUsers(): getUsersByFranchise(franchise);
    fetchUser.then((result: SuccessResponse_1OfUserListResponse) => {
      if (result?.data) {
        setUsers(result.data?.users ?? []);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };
  
  const getActiveUser = () => {
    setIsLoading(true);
    getCurrentUser().then((result: SuccessResponse_1OfUser) => {
      if (result?.data) {
        const activeUser  = result.data;
        setFranchise(activeUser['franchiseName']);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  return <Box p={10}>
    <Grid container>
      <Grid item
        xs={12}
        sx={{ textAlign: 'left' }}>
        <PageTitle>{t('title')}</PageTitle>
      </Grid>
      <ManageUsersBox users={users}
        loadData={loadData}
        isLoading={isLoading}></ManageUsersBox>
    </Grid>
  </Box>;
};

export default UserManagement;