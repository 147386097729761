import { useContext } from 'react';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';
import { NoteModal } from './NoteModal';
import { ConfirmModal } from './ConfirmModal';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';

export const InvoicePopups = () => {
  
  const { t, handleCloseNoteModal, isShowErrorAlert, isShowProcessingAlert, isShowSuccessAlert, setIsShowErrorAlert, setIsShowProcessingAlert, setIsShowSuccessAlert, openNoteModal, note, errorMessage } = useContext(InvoicesContext) as CreateInvoicesValues;

  return <>
    <MessagesSnackbar
      open={isShowSuccessAlert}
      onClose={() => setIsShowSuccessAlert(false)}
      message={t('success')}
      severity="success"
      duration={3000}
    />
    <MessagesSnackbar
      open={isShowErrorAlert}
      onClose={() => setIsShowErrorAlert(false)}
      message={t('error') + ' ' + errorMessage}
      severity="error"
      duration={null}
    />
    <MessagesSnackbar
      open={isShowProcessingAlert}
      onClose={() => setIsShowProcessingAlert(false)}
      message={t('processing')}
      severity="info"
      duration={null}
    />
    <NoteModal open={openNoteModal}
      onClose={handleCloseNoteModal}
      note={note}></NoteModal>

    <ConfirmModal/>
  </>;
      
};