import { Box, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import HistoryPageFilters from './components/HistoryPageFilters';
import PendingChangesTable from './components/HistoryPageTable';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import { HistoryContext, HistoryValues } from '../../contexts/HistoryContext';

export default function HistoryPage() {
  const { t, isLoading } = useContext(HistoryContext) as HistoryValues;

  return (
    <Paper>
      <Box sx={{ p: '8px' }}>
        <Typography variant='displayXS'>{t('history')}</Typography>
      </Box>
      <HistoryPageFilters></HistoryPageFilters>
      {isLoading ? <LoadingWheel/> : 
        <PendingChangesTable></PendingChangesTable>}
    </Paper>
  );
}
