import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import DeleteScheduleModal from '../modals/DeleteScheduleModal';
import PreviewModal from '../modals/PreviewModal';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';
import DeleteTaskType from '../modals/DeleteTaskType';

const ScheduleCreatePopUps=() => {
  const { 
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    closeSnackBar,

  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;
    
  return (
    <>
      <DeleteScheduleModal/>
      <PreviewModal />
      <DeleteTaskType />
      <MessagesSnackbar onClose={closeSnackBar}
        open={isSnackBarOpen}
        severity={snackBarSeverity}
        message={snackbarMessage}
        duration={snackBarDuration}/>
    </>
  );
};

export default ScheduleCreatePopUps;