import { Box, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { ActualTheorySummary } from '../../../app/services/api/generated';
import CustomPagination from './../../../components/forms/Pagination';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { getFormattedDate } from './../../../utils';
import { File06 } from '../../../assets';
import { UserContext } from './../../../components/shared/useUser';
import LoadingWheel from './../../../components/ui/LoadingWheel';

const ActualTheoryReportsTable = () => {
  const [data, setData] = useState<ActualTheorySummary[]>([]);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const { getActualTheorySummariesReport } = useNswagClient();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!user?.userLevel) {
      return;
    }

    setIsLoading(true);
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(toDate.getDate() - 7);
    const fromDateValue = fromDate.toISOString().slice(0, 10);
    const toDateValue = toDate.toISOString().slice(0, 10);

    getActualTheorySummariesReport(fromDateValue, toDateValue, user.userLevel).then((response) => {
      setData(response);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [user]);

  const maxPages = () => {
    return Math.ceil(data.length / rowsPerPage);
  };

  const visibleRows = () => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <Paper 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        m: '12px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        border: `1px solid ${theme.palette.custom.gray[200]}`,
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          textAlign: 'left',
          p: '12px',
          color: 'black',
          mb: '2px',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      >
        <SvgIcon sx={{ width: '24px' }}><File06 /></SvgIcon>
        <Typography variant="textLG">{' ' + t('actualTheoryReports')}</Typography>
        {
          !isLoading &&
            <Typography
              variant="textLG"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  p: '2px 8px',
                  alignItems: 'center',
                  borderRadius: '16px',
                  border: '1px solid ',
                  borderColor: theme.palette.success[200],
                  background: theme.palette.success[50],
                }}
              >
                <Typography variant="textXS"
                  sx={{ color: theme.palette.success[700] }}>
                  {data.length} {t('items')}
                </Typography>
              </Box>
            </Typography>
        }
      </Box>
      {
        isLoading ?
          <LoadingWheel /> :
          <>
            <TableContainer component={'div'}>
              <Table
                size="small"
                aria-label="Table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {t('storeNumber')}
                    </TableCell>
                    <TableCell align="left">
                      {t('storeName')}
                    </TableCell>
                    <TableCell align="left">
                      {t('from')}
                    </TableCell>
                    <TableCell align="left">
                      {t('to')}
                    </TableCell>
                    <TableCell align="left">
                      {t('waste')}
                    </TableCell>
                    <TableCell align="left">
                      {t('missing')}
                    </TableCell>
                    <TableCell align="left">
                      {t('variance')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    visibleRows().map((row, index) => (
                      <TableRow key={`item-${index}`}>
                        <TableCell
                          component="th"
                          scope="row">
                          {row.storeNumber}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row">
                          {row.storeName}
                        </TableCell>
                        <TableCell
                          align="left">
                          {(row.from === undefined ? '' : getFormattedDate(new Date(row.from)))}
                        </TableCell>
                        <TableCell
                          align="left">
                          {(row.to === undefined ? '' : getFormattedDate(new Date(row.to)))}
                        </TableCell>
                        <TableCell
                          align="left">
                          {row.waste}
                        </TableCell>
                        <TableCell
                          align="left">
                          {row.missing}
                        </TableCell>
                        <TableCell
                          align="left">
                          {row.variance}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              visibleRows().length > 0 ?
                <CustomPagination
                  page={page}
                  setPage={setPage}
                  maxPages={maxPages()}
                  breakpointForChangeDisplay={120000}
                ></CustomPagination>
                :
                <Typography sx={{ p: '12px' }}>{t('noData')}</Typography> 
            }
          </>
      }
    </Paper>
  );
};

export default ActualTheoryReportsTable;