import { Box, Typography, Grid, Tabs, Tab } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import PendingChanges from './components/PendingChanges';
import FranchiseChanges from './components/FranchiseChanges';
import AllStoresChanges from './components/AllStoreChanges';
import HistoryPage from './components/HistoryPage';
import { UserContext } from './../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import { PendingChangesProvider } from './contexts/PendingChangesContext';
import { HistoryContextProvider } from './contexts/HistoryContext';
import { FranchiseChangesContextProvider } from './contexts/FranchiseChangesContext';
import { AllStoresContextProvider } from './contexts/AllStoresChangesContext';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';


export const RecipeUpdates = () => {
  type TabOptions = 'Pending Changes' | 'History' | 'Franchise Changes' | 'Changes for all stores';

  const [tabValue, setTabValue] = useState<TabOptions>('Pending Changes');
  const { user } = useContext(UserContext);
  const { t } = useTranslation('recipeUpdates');
  const [canSeeAllStoreChanges, setCanSeeAllStoreChanges] = useState<boolean>();
  const [canSeeFranchiseChanges, setCanSeeFranchiseChanges] = useState<boolean>();
  
  useEffect(() => getUserPermissions(), []);

  const handleTabChange = (event: SyntheticEvent, newValue: TabOptions) => {
    setTabValue(newValue);
  };

  const getUserPermissions = () => {
    const canSeeAllStoreChanges =
      (user?.username?.indexOf('orderly.io') ?? -1) > -1 ||
      (user?.username?.indexOf('orderly.eu') ?? -1) > -1 ||
      (user?.username?.indexOf('easywebstore.net') ?? -1) > -1;
    setCanSeeAllStoreChanges(canSeeAllStoreChanges);
    if (user?.roles?.includes('recipeadmin')) {
      setCanSeeFranchiseChanges(true);
    }
  };

  return (
    <PageArea>
      <Box>
        <PageTitle>{t('title')}</PageTitle>
      </Box>
      <Box sx={{ pb: '4px' }}>
        <Typography variant='textMD'>{t('subTitle')}</Typography>
      </Box>
      <Grid container
        item
        xs={12}>
        <Grid item
          xs={12}>
          <Tabs value={tabValue}
            onChange={handleTabChange}
            variant="scrollable">
            <Tab label={t('pendingChanges')}
              value="Pending Changes" />
            <Tab label={t('history')}
              value="History" />
            {canSeeFranchiseChanges && <Tab label={t('franchiseChanges')}
              value="Franchise Changes" />}
            {canSeeAllStoreChanges && <Tab label={t('changesForAllStores')}
              value="Changes for all stores" />}
          </Tabs>
        </Grid>
      </Grid>
      {tabValue === 'Pending Changes' && <PendingChangesProvider><PendingChanges/></PendingChangesProvider>}
      {tabValue === 'History' && <HistoryContextProvider><HistoryPage/></HistoryContextProvider>}
      {tabValue === 'Franchise Changes' && <FranchiseChangesContextProvider><FranchiseChanges/></FranchiseChangesContextProvider>}
      {tabValue === 'Changes for all stores' && <AllStoresContextProvider><AllStoresChanges/></AllStoresContextProvider>}
    </PageArea>
  );
};
