import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const BarcodeScannerErrorModal = (props: {isOpen: boolean, closeModal: () => void, title: string, content: string}) => {
  const { t } = useTranslation('stockCountScanning');

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle><b>{props.title}</b></DialogTitle>
      <DialogContent>
        {props.content} 
      </DialogContent>
      <DialogActions>
        <Button variant='secondary'
          onClick={props.closeModal}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
