import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, TextField } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import useLogError from '../../../hooks/useLogError';

interface SubmitModalProps {
  openModal: boolean;
  id: number,
  loadData: Function;
  onCancel: () => void;
}

const BulkAddModal: React.FC<SubmitModalProps> = ({ openModal, id, loadData, onCancel }) => {
  const { t } = useTranslation('manageCategories');
  const { bulkAddPrimaryCategory } = useNswagClient();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [itemNumbers, setItemNumbers] = React.useState<string>('');
  const [successItems, setSuccessItems] = React.useState<string[]>([]);
  const [failedItems, setFailedItems] = React.useState<string[]>([]);
  const { logError } = useLogError();

  const handleConfirm = () => {
    setIsLoading(true);
    setItemNumbers('');

    const regex = itemNumbers.match(/[\S]+/g);
    const uniqueStrings = Array.from(new Set(regex));
    const unprocessedSkus = uniqueStrings.filter(item => !successItems.includes(item));

    bulkAddPrimaryCategory(id, unprocessedSkus)
      .then(
        (result) => {
          setSuccessItems(successItems.concat(result.data?.success ?? []));
          setFailedItems(failedItems.concat(result.data?.fails ?? []));
        },
      )
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleCancel = () => {
    onCancel();
    loadData();
  };

  const outerBoxStyles = {
    display: 'flex',
    px: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flex: 1,
    marginTop: '20px',
  };

  const scrollableBoxStyles = () => {
    return {
      display: 'flex',
      p: '2px 8px',
      alignItems: 'center',
      width: '100%', overflowY: 'auto', overflowX: 'hidden',
      maxHeight: '300px', flexFlow: 'wrap',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid rgba(0,0,0,.1)',
      },
    };
  };

  const isValid = itemNumbers.length >= 1;

  return (
    <Dialog
      onClose={() => handleCancel()}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('bulkAdd')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column', maxWidth: '450px' }}>
        <Grid
          container
          spacing={6}
          alignItems='self-end'
          p={6}
        >
          <Grid item
            xs={12}>
            <Typography variant='textSM'
            >
              {t('items')}
            </Typography>
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={itemNumbers}
                onChange={(event) => setItemNumbers(event.target.value)}
                placeholder={t('enterListOf')}
                multiline
                maxRows={20}
              />
            </FormControl>
          </Grid>
          <Box
            sx={outerBoxStyles}
          >
            <Typography
              variant="textLG"
            >
              {t('succeeded')}
            </Typography>
            <Box
              sx={scrollableBoxStyles()}
            >
              <ul style={{ paddingLeft: 0 }}>
                {successItems && successItems.length > 0  ? (
                  successItems.map((r) => (
                    <li key={r}
                      style={{ marginBottom: '8px', opacity: '0.7', display: 'block'  }}>
                      <Typography 
                        variant="textSM">
                        {r}
                      </Typography>
                    </li>
                  ))) 
                  : <Typography 
                    sx={{ opacity: '0.7' }}
                    variant="textSM">
                    {t('none')}
                  </Typography>}
              </ul>
            </Box>
          </Box>
          <Box
            sx={outerBoxStyles}
          >
            <Typography
              variant="textLG"
            >
              {t('failed')}
            </Typography>
            <Box
              sx={scrollableBoxStyles()}
            >
              <ul style={{ paddingLeft: 0 }}>
                {failedItems && failedItems.length > 0  ? (
                  failedItems.map((r) => (
                    <li key={r}
                      style={{ marginBottom: '8px', opacity: '0.7', display: 'block'  }}>
                      <Typography 
                        variant="textSM">
                        {r}
                      </Typography>
                    </li>
                  ))) 
                  : <Typography 
                    sx={{ opacity: '0.7' }}
                    variant="textSM">
                    {t('none')}
                  </Typography>}
              </ul>
            </Box>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          disabled={isLoading}
          onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          disabled={!isValid}
          onClick={handleConfirm}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BulkAddModal;