import { Button, Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Action } from '../../../enums/Action';

export interface ModalProps {
    open: boolean;
    onClose: (action?: Action, actionValue?: string) => void;
    onConfirm: (action?: Action, actionValue?: string) => void;
    action?: Action;
    actionValue?: string;
}

const ReloadPageModal = (props: ModalProps) => {
  return (
    <Dialog open={props.open}
      onClose={() => props.onClose(props.action, props.actionValue)}
      maxWidth='sm'
      aria-labelledby="responsive-dialog-title"
      fullWidth>
      <DialogTitle>
            Are you sure you want to make this change?
        <IconButton onClick={() => props.onClose(props.action, props.actionValue)}
          style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
            Please ensure all changes have been saved before you confirm this action.
      </DialogContent>
      <DialogActions >
        <Button
          variant="secondary"
          onClick={() => props.onClose(props.action, props.actionValue)}>
            Cancel
        </Button>
        <Button variant="primary"
          onClick={() => props.onConfirm(props.action, props.actionValue)}>
            Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

};

export default ReloadPageModal;