import React, { useContext, useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  SvgIcon,
  Select,
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { GetStockResponse } from '../../../app/services/api/generated';
import { StockFilterOptions } from './interfaces/StockFilterOptions';
import StockOnHandTable from './StockOnHandTable';
import { SearchSm } from '../../../assets';
import { UserContext } from '../../../components/shared/useUser';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useLogError from '../../../hooks/useLogError';

const StockOnHandBox = (props: {
  stockOnHands: GetStockResponse,
  loadData: (options: StockFilterOptions) => void;
}) => {
  const { t } = useTranslation('common');
  const { logError } = useLogError();
  const defaultSort = 'itemNumber';
  const defaultTake = 10;
  const defaultSkip = 0;
  const defaultDirection = 'desc';

  const { user, hasPermissionTo } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [categoryFilters, setCategoryFilters] = useState('');
  const [stockFilters, setStockFilters] = useState('');
  const [storeFilters, setStoreFilters] = useState('');
  const [category, setCategory] = React.useState<string[]>([]);
  const [stores, setStores] = React.useState<string[]>([]);
  const [stock, setStock] = React.useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [hasRecentActivity, setHasRecentActivity] = useState(true);
  const [isMadeInStore, setIsMadeInStore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasPermissionToReadMadeInStore = hasPermissionTo(['MadeInStoreRead']);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const stockLevelOptions = [
    { id: 'positivestock', name: t('stockOnHand.positiveStock') },
    { id: 'negativestock', name: t('stockOnHand.negativeStock') },
    { id: 'belowminimumquantity', name: t('stockOnHand.belowMinimumQuantity') },
    { id: 'outofstock', name: t('stockOnHand.outOfStock') },
  ];
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearch(newSearchValue);
  };

  const handleCategoryChange = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event;

    setCategory(typeof value === 'string' ? value.split(',') : value);
    setCategoryFilters(typeof value === 'string' ? value : value.join(','));
  };

  const handleStoreChange = (event: SelectChangeEvent<typeof stores>) => {
    const {
      target: { value },
    } = event;
    setStores(typeof value === 'string' ? value.split(',') : value);

    if (typeof value === 'string') {
      setStoreFilters(value);
    } else {
      const storeKeys = value
        .map((item) => user?.stores?.find((x) => x.storeName === item)?.storeNumber)
        .filter((key) => key !== undefined);

      setStoreFilters(storeKeys.join(','));
    }
  };

  const handleStockLevelChange = (event: SelectChangeEvent<typeof stock>) => {
    const {
      target: { value },
    } = event;
    setStock(typeof value === 'string' ? value.split(',') : value);

    if (typeof value === 'string') {
      setStockFilters(value);
    }
    else {
      const stockLevelIds = value
        .map((item) => stockLevelOptions.find((x) => x.name === item)?.id)
        .filter((id) => id !== undefined);
      setStockFilters(stockLevelIds.join(','));
    }
  };

  const handleRecentActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasRecentActivity(event.target.checked);
  };

  const handleIsMadeInStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMadeInStore(event.target.checked);
  };

  const callLoadDataFromAPI = (options: StockFilterOptions) => {
    setIsLoading(true);
    try {
      props.loadData(options);
    }
    catch (error) {
      logError(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const loadDataFromOrderTable = (fieldToSort: string, sortDirection: string, skip: number, take: number) => {
    callLoadDataFromAPI({
      search,
      fieldToSort,
      sortDirection,
      skip,
      take,
      filters: stockFilters,
      categoryFilters,
      storeFilters,
      hasRecentActivity,
      isMadeInStore,
    });
  };

  const clearAll = () => {
    setPage(1);
    setCategoryFilters('');
    setCategory([]);
    setStock([]);
    setStockFilters('');
    setStoreFilters('');
    setStores([]);
    setHasRecentActivity(false);
    setSearch('');

    callLoadDataFromAPI({
      search: '',
      fieldToSort: defaultSort,
      sortDirection: defaultDirection,
      skip: defaultSkip,
      take: defaultTake,
      filters: undefined,
      categoryFilters: undefined,
      storeFilters: undefined,
      hasRecentActivity: undefined,
      isMadeInStore: undefined,
    });
  };

  const searchCall = () => {
    setPage(1);
    callLoadDataFromAPI({
      search,
      fieldToSort: undefined,
      sortDirection: undefined,
      skip: defaultSkip,
      take: defaultTake,
      filters: stockFilters,
      categoryFilters,
      storeFilters,
      hasRecentActivity,
      isMadeInStore,
    });
  };

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: theme.palette.custom.gray[300],
        py: '0px',
        textAlign: 'left',
      }}>
      <Grid container
        spacing={7}
        sx={{
          py: '12px',
          px: '8px',
        }}>
        <Grid item
          md={6}
          sm={4}
          xs={12}
        >
          <FormControl fullWidth
            size='small'>
            <TextField placeholder={t('stockOnHand.itemNumberOrDescription')}
              size='small'
              id="input-with-icon-textfield"
              label={t('search')}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon><SearchSm /></SvgIcon>
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}
          xs={12}
        >
          <FormControlLabel
            control={<Switch
              checked={hasRecentActivity}
              onChange={handleRecentActivity}
            />}
            label={t('stockOnHand.recentActivity')}
            labelPlacement="start"
          />
        </Grid>
        {hasPermissionToReadMadeInStore && 
        (<Grid item
          md={3}
          sm={4}
          xs={12}
        >
          <FormControlLabel
            control={<Switch
              checked={isMadeInStore}
              onChange={handleIsMadeInStore}
            />}
            label={t('stockOnHand.madeInStore')}
            labelPlacement="start"
          />
        </Grid>)}
        <Grid item
          xs={12}
          md={4}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="stock-stock-level-label">{t('stockOnHand.stockLevel')}</InputLabel>
            <Select
              labelId="stock-stock-level-label"
              id="stock-stock-level"
              size='small'
              label={t('stockOnHand.stockLevel')}
              multiple
              value={stock}
              onChange={handleStockLevelChange}
              renderValue={(selected) => selected.toString()}
              MenuProps={MenuProps}
            >
              {stockLevelOptions.map((option) => (
                <MenuItem key={option.name}
                  value={option.name}>
                  <Checkbox checked={stock.some(stockLevel =>
                    stockLevel === option.name)} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          md={4}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="category-multiple-checkbox-label">{t('stockOnHand.category')}</InputLabel>
            <Select
              labelId="category-multiple-checkbox-label"
              id="category-multiple-checkbox"
              size='small'
              label={t('stockOnHand.category')}
              multiple
              value={category}
              onChange={handleCategoryChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {props.stockOnHands?.categories?.filter((name) => name !== null).map((name) => (
                <MenuItem key={name}
                  value={name}>
                  <Checkbox checked={category.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          md={4}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="store-multiple-checkbox-label">{t('Store')}</InputLabel>
            <Select
              labelId="store-multiple-checkbox-label"
              id="store-multiple-checkbox"
              size='small'
              label={t('Store')}
              multiple
              value={stores}
              onChange={handleStoreChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {user?.stores?.map((store) => (
                <MenuItem key={store.storeNumber}
                  value={store.storeName}>
                  <Checkbox checked={stores.some(s =>
                    s === store.storeName)} />
                  <ListItemText primary={store.storeName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item
          xs={12}
          sm={6}
          md={6}>
          <Button
            fullWidth
            variant="secondary"
            size="lg"
            onClick={clearAll}
          >
            {t('clearAll')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          sm={6}
          md={6}>
          <Button
            fullWidth
            variant="primary"
            size="lg"
            onClick={searchCall}
            startIcon={<SearchSm />}
          >
            {t('search')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          sx={{ mt: '20px' }} >
          {
            isLoading ? <LoadingWheel /> : (
              <div style={{ overflowX: 'auto' }}>
                {props.stockOnHands &&
                  <StockOnHandTable
                    list={props.stockOnHands}
                    setPageZero={page}
                    loadData={loadDataFromOrderTable}
                  />
                }
              </div>
            )
          }
        </Grid>
      </Grid>
    </Box>);
};

StockOnHandBox.displayName = 'StockCountDetails';
export default StockOnHandBox;

