import {  Alert, Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Trash01 ,Plus } from '../../../assets';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';
import { theme } from '../../../theme';

const ScheduleCreateMain=() => {
  const { t,
    isLoading,
    isSmartCount,
    handleAddCategoriesClick,
    deleteCategoryRow,
    categoryData, hasRightToEdit } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const hasCategories = categoryData.length > 0;

  if(isLoading){
    return <></>;
  } 

  return (
    <Box sx={{ border:'2px solid', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        sx={{ pt: '12px', pb: '6px', px: '8px' }}>
        <Grid item 
          xs={3}>
          <Typography variant="textMD"
            sx={{ fontWeight:'bold' }}>{t('categories')}</Typography>
        </Grid>
        <Grid item 
          xs={9}
          sx={{ display:'flex', justifyContent:'flex-end',alignItems:'center' }}>
          {hasCategories && (
            <Button
              size='lg'
              sx={{ mr: 4 }}
              variant='bad'
              disabled ={!hasRightToEdit}
              onClick={() => deleteCategoryRow()}
            ><Trash01 />{t('deleteAll')}
            </Button>
          )}
          <Button
            size='lg'
            variant='primary'
            disabled ={!hasRightToEdit}
            onClick={handleAddCategoriesClick}><Plus/>{t('add')}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ pt:'10px' }}>
        {hasCategories ? (
          <TableContainer sx={{ overflowy:'auto' }}>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell >{t('category')}</TableCell>
                  <TableCell 
                    style={{ display:'flex', justifyContent:'flex-end' }} >{t('remove')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData?.map((row) => (
                  <TableRow key={row.primaryCategoryName}>
                    <TableCell >
                      {row.primaryCategoryName}
                    </TableCell>
                    <TableCell style ={{ display:'flex', justifyContent:'flex-end' }} >
                      <IconButton
                        disabled={!hasRightToEdit} 
                        onClick={() => { deleteCategoryRow(row.primaryCategoryName ?? ''); }}>
                        <Trash01 style={{ color:!hasRightToEdit ? 'action' : 'red' }} />
                      </IconButton> 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) :(
          <Alert severity='info'
            variant= {isSmartCount ? 'filled': 'outlined'}
            sx={{ mb: '12px', mx: '8px' }}>
            {isSmartCount ?
              t('smartCountMessage')
              :
              t('noCategories')}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleCreateMain;