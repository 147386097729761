export enum OrdersCategoriesParents {
  FreshFood = 'Fresh Food',
  FoodAndDairy = 'Food & Dairy',
  HotDisposables = 'Hot Disposables'
}

export enum OrdersCategories {
  All = 'All',
  Bakery = 'Bakery',
  Dairy = 'Dairy',
  Desserts = 'Desserts',
  FreshFrozenDesserts = 'Fresh Frozen Desserts',
  FreshFood = 'Fresh Food',
  Fruit = 'Fruit',
  Juice = 'Juice',
  Panini = 'Panini',
  PaperPlasticProduct = 'Paper/Plastic Product',
  Pastry = 'Pastry',
  Salad = 'Salad',
  Sandwiches = 'Sandwiches',
  SandwichesPreparedFood = 'Sandwiches & Prepared Food',
  Yoghurt = 'Yoghurt'
}
 
export const OrderCategoriesTranslationMap = {
  [OrdersCategories.All]: 'all',  
  [OrdersCategories.Bakery]: 'bakery',  
  [OrdersCategories.Dairy]: 'dairy',
  [OrdersCategories.Desserts]: 'desserts',
  [OrdersCategories.FreshFood]: 'freshFood',
  [OrdersCategories.FreshFrozenDesserts]: 'freshFoodFrozenDezert',
  [OrdersCategories.Fruit]: 'fruit',
  [OrdersCategories.Juice]: 'juice',
  [OrdersCategories.Panini]: 'panini',
  [OrdersCategories.PaperPlasticProduct]: 'paperPlasticProduct',
  [OrdersCategories.Pastry]: 'pastry',
  [OrdersCategories.Salad]: 'salad',
  [OrdersCategories.Sandwiches]: 'sandwiches',
  [OrdersCategories.SandwichesPreparedFood]: 'sandwichesPreparedFood',
  [OrdersCategories.Yoghurt]: 'yoghurt',
} as const; 

export const OrdersCategoriesParentsMap = {
  [OrdersCategoriesParents.FoodAndDairy]: 'freshFoodParentCategroy',  
  [OrdersCategoriesParents.FreshFood]: 'foodAndDairy',  
  [OrdersCategoriesParents.HotDisposables]: 'hotDisposables', 
} as const;

export function isOrderCategory(value: string | null | undefined): value is OrdersCategories {
  return value != null && Object.values(OrdersCategories).includes(value as OrdersCategories);
}

export function isOrderCategoryParent(value: string | null | undefined): value is OrdersCategoriesParents {
  return value != null && Object.values(OrdersCategoriesParents).includes(value as OrdersCategoriesParents);
}


const getFullCategory = (parent: OrdersCategoriesParents, category: OrdersCategories): string => {
  return `${parent} > ${category}`;
};