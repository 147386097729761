import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Grid, FormControl, TextField } from '@mui/material';

export const CreateTransactionsNote = () => {
  const { handleNoteChange, note, isDisableActions, t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <Grid item
      xs={12}>
      <FormControl fullWidth
        size='small'>
        <TextField id="textfeild-note"
          label={t('note')}
          multiline
          rows={4}
          size='small'
          disabled={isDisableActions}
          value={note}
          onChange={handleNoteChange} />
      </FormControl>
    </Grid>
  );
};

