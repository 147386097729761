import React, { Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { useCreateStockTransfer } from '../hooks/useCreateStockTransfer';
import { TFunction } from 'i18next';
import { Store } from '../../../../../app/services/api/generated';
import { SelectChangeEvent } from '@mui/material';
import { ItemPossibleUOMs, ItemSelected } from '../interfaces/itemValueTypes';

export interface CreateStockTransfersValues {
  hasPermissionToIncomingTransfers: boolean,
  hasPermissionToOutgoingTransfers: boolean,
  isFormInvalid: () => boolean,
  allStores: Store[],
  onCancel: () => void,
  handleTypeChange: (event: SelectChangeEvent) => void,
  handleSearchTermChange: (value: string) => void,
  handleItemSelect: (newValue: string) => Promise<void>,
  handleStoreSelect: (newValue: Store) => Promise<void>,
  handleNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleQuantityChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleUomChange: (id: number) => (event: SelectChangeEvent) => void,
  deleteButtonHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void,
  dropDownItems: string[],
  handleSubmit: () => void,
  openModal: boolean,
  note: string | undefined,
  transferType: string | undefined,
  createDate: Date,
  value: string | null,
  formSelectedStore: Store | null,
  searchTerm: string,
  itemsToCreate: ItemSelected[],
  itemPossibleUOMs: ItemPossibleUOMs[],
  isNoteDisabled: boolean,
  isItemsLoading: boolean,
  isStoresLoading: boolean,
  isProcessingSubmit: boolean,
  isSubmittedSuccess: boolean,
  isDisableActions: boolean,
  isError: boolean,
  isValidatingItemQuantity: boolean,
  setNote: Dispatch<SetStateAction<string>>,
  errorDismissButtonHandler: () => void,
  successDismissButtonHandler: () => void,
  setItemsToCreate: Dispatch<SetStateAction<ItemSelected[]>>,
  setTransferType: Dispatch<SetStateAction<string | undefined>>,
  setFormSelectedStore: Dispatch<SetStateAction<Store | null>>,
  setValue: Dispatch<SetStateAction<string | null>>,
  t: TFunction<'common', undefined>,
  createErrorMessage: string,
}

export const CreateStockTransfersContext = createContext<null | CreateStockTransfersValues>(null);

interface ProviderProps {
  children: React.ReactNode;
  openModal: boolean;
  onCancel: () => void;
}

export const CreateStockTransfersProvider = ({ children, openModal, onCancel }: ProviderProps) => {
  const {
    hasPermissionToIncomingTransfers,
    hasPermissionToOutgoingTransfers,
    allStores,
    isFormInvalid,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleStoreSelect,
    handleNoteChange,
    handleQuantityChange,
    handleUomChange,
    isValidatingItemQuantity,
    deleteButtonHandler,
    dropDownItems,
    handleSubmit,
    transferType,
    createDate,
    value,
    formSelectedStore,
    searchTerm,
    itemsToCreate,
    isNoteDisabled,
    itemPossibleUOMs,
    note,
    isItemsLoading,
    isStoresLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    setNote,
    setItemsToCreate,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    setTransferType,
    setFormSelectedStore,
    setValue,
    t,
    createErrorMessage,
  } = useCreateStockTransfer();

  const passedValue = {
    hasPermissionToIncomingTransfers,
    hasPermissionToOutgoingTransfers,
    allStores,
    onCancel,
    isFormInvalid,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleStoreSelect,
    handleNoteChange,
    handleQuantityChange,
    isValidatingItemQuantity,
    handleUomChange,
    deleteButtonHandler,
    dropDownItems,
    handleSubmit,
    openModal,
    transferType,
    createDate,
    value,
    formSelectedStore,
    searchTerm,
    itemsToCreate,
    isNoteDisabled,
    itemPossibleUOMs,
    note,
    isItemsLoading,
    isStoresLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    setItemsToCreate,
    setNote,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    setTransferType,
    setFormSelectedStore,
    setValue,
    t,
    createErrorMessage,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <CreateStockTransfersContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </CreateStockTransfersContext.Provider>
  );
};