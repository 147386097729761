import { Box, IconButton, Slider } from '@mui/material';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import { ZoomIn, ZoomOut } from '../../../../assets';
import { Html5Qrcode } from 'html5-qrcode';
import { Dispatch, SetStateAction, useEffect } from 'react';

const BarcodeScanner = (props: {
  isLoading: boolean, 
  isScanning: boolean, 
  zoomNotSupportedAlert: boolean,
  handleZoomOutChange: () => void, 
  handleZoomInChange: () => void, 
  zoomFactor: number,
  handleZoomChange: (event: Event, newValue: number | number[]) => void, 
  scanner: Html5Qrcode | null,
  setScanner: Dispatch<SetStateAction<Html5Qrcode | null>>}) => {

  const isiOSDevice = () => {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) || (userAgent.includes('Mac') && 'ontouchend' in document);
  };

  useEffect(() => {
    props.setScanner(new Html5Qrcode('barcode-reader'));
  }, []);

  const disableZoom = isiOSDevice() || props.zoomNotSupportedAlert;
  
  return (
    <>
      {props.isLoading ? 
        <LoadingWheel/> 
        : <>
          <Box id="barcode-reader"
            sx={{ m: '0px' }} />
          {props.scanner && props.isScanning && !disableZoom && ( <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton onClick={props.handleZoomOutChange}>
              <ZoomOut />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, maxWidth: '200px', marginRight: '10px', marginLeft: '10px' }}>
              <Slider value={props.zoomFactor}
                onChange={props.handleZoomChange}
                aria-label="zoom level"
                min={1}
                max={7}
                step={2} // This step ensures only the desired values (1, 3, 5, 7) are selectable
                marks={[
                  { value: 1, label: '1x' },
                  { value: 3, label: '3x' },
                  { value: 5, label: '5x' },
                  { value: 7, label: '7x' },
                ]}
              />
            </Box>
            <IconButton onClick={props.handleZoomInChange}>
              <ZoomIn />
            </IconButton>
          </Box>)}
        </>}
    </>
  );
};

export default BarcodeScanner;
