import {  Button, Grid, Typography,  Dialog,  DialogContent, DialogActions, Box } from '@mui/material';
import {   X } from  '../../../assets';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';


export interface PurchasedOrders  {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}
const PurchasedOrdersModal=( props: PurchasedOrders) => {
  const navigate = useNavigate();
  const { t } = useTranslation('stockCount');

  const handleConfirm = () => {
    navigate('/store/po-receipt');
  };
  
  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth>
      <Grid 
        sx={{ m:'2px' }}>
        <Grid 
          sx={{ display:'flex',alignItems:'flex-start', justifyContent:'flex-start' }}>
          <X  onClick={props.onClose} />
        </Grid>
        <Grid 
          container
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <WarningIcon sx={{ color:theme.palette.info[200], marginRight: '4px' }} />
          <Typography variant='textLG'> {t('modalTitle')}</Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Box 
          sx={{ borderTop:'1px solid ', borderBottom:'1px solid ', borderColor:theme.palette.custom.gray[300], pt:'20px', pb:'20px' }}>
          <Typography variant='textSM'>{t('approvalMessage')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          onClick={props.onCancel}>{t('continue')}</Button>
        <Button variant="primary"
          onClick={handleConfirm}>
          {t('seeOrders')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchasedOrdersModal;