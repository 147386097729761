import { SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { CheckDone01, ShoppingCart01 } from '../../../assets';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TitleArea = (props: { storeName: string }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        pt: 6,
        backgroundColor:  theme.palette.primary[700],
      }}
    >
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.palette.common.white,
          pt: { xs: 6, sm: 9 },
          pb: 7,
          pl: { xs: 6, sm: 9 },
          pr: 6,
          borderRadius: { xs: '0px', sm: '40px 0px 0px 0px' },
        }}
      >
        <Box
          sx={{
            color: theme.palette.common.black,
            display: 'flex',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            gap: '10px 8px',
            alignSelf: 'stretch',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: '220px',
              p: 0,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 2,
              flex: '1 0 0',
            }}
          >
            <Typography
              sx={{
                color: theme.palette.custom.gray[900],
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '38px',
                textAlign: 'left',
              }}
            >
              {t('welcomeBack')}, {props.storeName} 👋
            </Typography>
            <Typography variant='textMD'>
              {t('welcomeBackSub')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              p: 0,
              alignItems: 'center',
              gap: 5,
              width: { xs: '100%', sm: '300px' },
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={theme.spacing(5)}
              sx={{ width: '100%' }}
            >
              <Button
                variant="secondary"
                size="lg"
                onClick={() => navigate('/store/stock-count')}
                sx={{
                  width: { xs: '100%', sm: '50%' },
                  borderColor: theme.palette.custom.gray[300],
                  color: theme.palette.custom.gray[700],
                }}
              >
                <SvgIcon sx={{ width: '20px' }}><CheckDone01 /></SvgIcon>
                <Typography
                  variant="textSM"
                  sx={{
                    px: '2px',
                    textTransform: 'none',
                  }}
                >
                  {t('stockCount')}
                </Typography>
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={() => navigate('/store/quick-order')}
                sx={{
                  width: { xs: '100%', sm: '50%' },
                  color: theme.palette.common.white,
                }}
              >
                <SvgIcon sx={{ width: '20px' }}><ShoppingCart01 /></SvgIcon>
                <Typography
                  variant="textSM"
                  sx={{
                    px: '2px',
                    textTransform: 'none',
                  }}
                >
                  {t('orderStock')}
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TitleArea;
