import { Box, Button, Grid, SvgIcon, TextField } from '@mui/material';
import ManageNotificationsTable from './ManageNotificationsTable';
import { Notification2 } from '../../../app/services/api/generated';
import { useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { Plus, SearchSm } from '../../../assets';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import CreateEditModal from './NotificationEditCreateModal';
import { theme } from '../../../theme';

const MainBox = (props: { notifications: Notification2[], loadData: () => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { saveNotification } = useNswagClient();
  const { t } = useTranslation('common');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [transformedData, setTransformedData] = useState<Notification2[]>(props.notifications ?? []);
  const [page, setPage] = useState(0);

  const handleCreateNotif = (body: Notification) => {
    saveNotification(body).then(props.loadData);
  };

  const handleSearch = () => {
    setPage(0);
    const filteredData = props.notifications.filter(x => 
      x.title?.toLowerCase().includes(searchTerm.toLowerCase()) || 
      x.message?.toLowerCase().includes(searchTerm.toLowerCase()));
    setTransformedData(filteredData);
  };

  const handleClear = () => {
    setPage(0);
    setSearchTerm('');
    props.loadData();
  };

  return <><Grid item
    xs={12}
    container
    justifyContent="flex-end">
    <Button variant="primary"
      size="lg"
      startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
      sx={{ ml: { sm: 2 } }}
      onClick={() => setShowCreateModal(true)}>
      {t('createNew')}
    </Button>
  </Grid>
  <Grid item
    xs={12}
    justifyContent="flex-end">
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
      mt: 5,
    }}>
      <Grid item
        container
        spacing={5}
        sx={{ px: '8px', py: '12px' }}>
        <Grid item
          xs={12}
          sm={12}
          md={4}>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            size='small'
            label={t('search')} />
        </Grid>
        <Grid item
          xs={12}
          sm={6}
          md={4}>
          <Button fullWidth
            variant="secondary"
            size="lg"
            onClick={() => handleClear()}
          >
            {t('clearFilters')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          sm={6}
          md={4}>
          <Button fullWidth
            variant="primary"
            size="lg"
            onClick={() => handleSearch()}
            startIcon={<SearchSm />}
          >
      Search
          </Button>
        </Grid>
      </Grid>

      <ManageNotificationsTable list={transformedData}
        setPage={setPage}
        page={page}
        loadData={props.loadData}></ManageNotificationsTable>

    </Box>
  </Grid>
  <CreateEditModal openModal={showCreateModal}
    onConfirm={(requestBody: Notification) => handleCreateNotif(requestBody)}
    notification={undefined}
    onCancel={() => setShowCreateModal(false)}></CreateEditModal></>;
};

const ManageApplicationRoles = (props: { notifications: Notification2[], loadData: () => void, isLoading: boolean }) => {
  return props.isLoading ? <LoadingWheel></LoadingWheel> : <MainBox notifications={props.notifications}
    loadData={props.loadData}></MainBox>;
};

export default ManageApplicationRoles;