export function isString(x: unknown): x is string {
  return typeof x === 'string' || x instanceof String;
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number' || x instanceof Number;
}
export const enum RtvReason {
    SHORTAGE = 1,
    DELIVERED_EXTRA = 2,
    DAMAGED = 3,
    EXPIRED = 4, 
    OTHER = 5
  }
  
export const returnToVendorReasons = [
  RtvReason.SHORTAGE,
  RtvReason.DELIVERED_EXTRA,
  RtvReason.DAMAGED,
  RtvReason.EXPIRED,
  RtvReason.OTHER,
];
  
export const returnToVendorReasonsTranslationMap = {
  [RtvReason.SHORTAGE]: 'returnToVendorReasons.shortage',
  [RtvReason.DELIVERED_EXTRA]: 'returnToVendorReasons.delivered_extra',
  [RtvReason.DAMAGED]: 'returnToVendorReasons.damaged',
  [RtvReason.EXPIRED]: 'returnToVendorReasons.expired',
  [RtvReason.OTHER]: 'returnToVendorReasons.other',
};
  
export function parseRtvReason(value: RtvReason | number | string): RtvReason {
  if (isString(value)) {
    value = parseInt(value) as RtvReason;
  }
  
  if (isNumber(value)) {
    value = value as RtvReason;
  }
  
  return value;
}