import React, { Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { useDrawer } from '../hooks/useDrawer';
import { Configuration } from '../../../../app/services/api/generated';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';

export interface DrawerValues {
  storeConfig: Configuration[],
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  isMobile: boolean,
  isHighLighted: (item: DrawerNavItem, pathname: string, search: string) => boolean,
  selectedNavItem: DrawerNavItem | undefined,
  setSelectedNavItem: Dispatch<SetStateAction<DrawerNavItem | undefined>>,
  appNavItemsToUse: DrawerNavItem[],
  handleNavItemClick: (item: DrawerNavItem) => void,
  systemNavItemsToUse: DrawerNavItem[],
  slidingSubMenuWidth: number,
}

export const DrawerContext = createContext<null | DrawerValues>(null);

interface ProviderProps {
    children: React.ReactNode;
}

export const DrawerProvider = ({ children }: ProviderProps) => {
  const {
    storeConfig,
    open,
    setOpen,
    isMobile,
    isHighLighted,
    selectedNavItem,
    setSelectedNavItem,
    appNavItemsToUse,
    handleNavItemClick,
    systemNavItemsToUse,
    slidingSubMenuWidth,
  } = useDrawer();

  const passedValue = {
    storeConfig,
    open,
    setOpen,
    isMobile,
    isHighLighted,
    selectedNavItem,
    setSelectedNavItem,
    appNavItemsToUse,
    handleNavItemClick,
    systemNavItemsToUse,
    slidingSubMenuWidth,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <DrawerContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </DrawerContext.Provider>
  );
};