export const purchaseOrderData = {
  title: 'Purchase Orders',
  overview: `
        <p>The Purchase Orders page is designed to let users keep track of the schedule and status of all Purchase Orders (PO's) for a given store. The PO data will be automatically imported from the existing Orderly system per store. By viewing an individual PO, its details can be checked, and every line can be confirmed or adjusted to allow for accurate receiving.</p>
        <p>When confirming a PO is received, the application will create material Transactions for each line on the PO, and automatically update the Stock on Hand. The PO details are then locked, so the same PO can't be received twice, but a log is still recorded and available to view.</p>
    `,
  keyFeatures: [
    'Receiving a PO will automatically create Transactions for each line selected.',
    'Receiving a PO will automatically update the Stock on Hand for each line selected.',
    'Copy Order Quantities button to quickly copy the expected values to all open lines.',
    'Editable \'selected\' field notes which lines to receive (or were processed if receipt complete).',
    'UoM can be edited when receiving, with drop-down restricted to existing options by item.',
    'Filters exist on the PO details page to find an item by number, description or line number.',
    'Receipt number and Delivery Date are automatically set, and non-editable.',
    'Receipt date is editable, but limited to days equal to or after delivery date.',
    'PO list can be searched by Receipt No., EWS No. or Oracle No., order type, date and packing slip.',
    'Sort in order or reverse order by column (e.g. Receipt No., or Delivery Date)',
  ],
  userGuide: [
    {
      guideTitle: '',
      guideContent: `
                <p>The Purchase Orders page is listed under the Receiving section in the main menu. By default it is sorted by Delivery Date, with the most recent entry at the top of the list. As with other pages the order can be reverse-sorted by clicking the column title again. Other column headings which have an arrow indicator alongside the name can also be sorted in the same way.</p>
                <p>Above the main list are a number of search options to find individual or certain types of Purchase Orders. These can be selected individually, or combined with each other to filter the list according to the criteria chosen, and any sorting by columns will maintain the search results. Any field which you enter data into will have a clear button and a little red cross to clear that field, while selecting the main Clear button will set all fields to empty.</p>
                <p>Other features of the main page are the paging control to limit the display to 10 records at once, the note field (right hand column) which is highlighted when a note exists and can be viewed by clicking or hovering over the ”i” icon. Next to the Note field is a Status field which will display a padlock icon if the PO has been Received already, and is therefore view only and no longer editable.</p>
                <p>To Receive a Purchase Order find the order you wish to open in the main list, and click on the Receipt Number to open the Purchase Order Details page. Here the top section allows you to add a Packing Slip reference, a Receiving Note, and confirm the Date of Receipt. Note that the Date of Receipt can’t be set before the Date of Delivery.</p>
                <p>The save button applies to fields in the header section, as well as any changes made to lines but will not confirm Receipt. Below this is a filter menu - hidden by default - to search for a specific line if required, and further down is the main list of items.</p>
                <p>Each line of the Purchase Order has 4 editable fields which you can alter before confirming receipt:
                Selected - Yes (tick) /No (un-tick) to confirm whether to create a Transaction for that line.
                Quantity - to confirm the quantity you have received (may or may not match the expected quantity).
                UoM - to confirm the UoM the quantity refers to (drop-down includes only valid options for that item).
                Note - to add a text note against that line for any reason (icon will change if a note exists on a line).
                </p>
                <p>Above the main list are options to Receive and Copy Order Quantities. The Copy Order Quantities button will populate the expected quantity and UoM values for any lines on the PO that have not already been selected. The Receive button is used to create Transactions for each line selected, and also locks the record so it can’t be received twice. Therefore it should only be used when you are happy all lines, as well as the header details, are correct.</p>
                <p>Note that the date and time set on the resulting Transactions is taken from the point at which the Receive button is clicked on that Purchase Order. It does not use the Receipt date set in the header details.</p>
            `,
    },
  ],
};
