import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { theme } from '../../../../theme';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../../app/services/api/generated';
import ItemRow from './ItemRow';
import useCurrencyCodeConfiguration from '../../../../hooks/configurations/useCurrencyCodeConfiguration';

type Props = {
    list: Item[];
    handleSort: (orderBy: keyof Item) => void;
    orderBy: string;
    order: 'asc' | 'desc';
}

const AllItemsList: FC<Props> = ({ list, handleSort, orderBy, order }) => {
  const { t } = useTranslation('products');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);
  
  return (
    <TableContainer>
      <Table
        size="medium"
        sx={{
          borderBottom: '1px solid',
          borderTop: '1px solid',
          borderColor: theme.palette.custom.gray[200],
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'itemNumber'}
                direction={order}
                onClick={() => handleSort('itemNumber')}
              >
                {t('itemNumber')}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'description'}
                direction={order}
                onClick={() => handleSort('description')}
              >
                {t('description')}
              </TableSortLabel>
            </TableCell>
            <TableCell>{t('uom')}</TableCell>
            <TableCell>{t('pickpackUOM')}</TableCell>
            <TableCell>{t('category')}</TableCell>
            <TableCell>{t('status')}</TableCell>
            <TableCell>{t('costPrice')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <ItemRow key={row.id}
              item={row}
              currencyCode={currencyCode}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllItemsList;