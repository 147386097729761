import { Uom, CreateTransactionRequest, CreateMultipleTransactionsRequest, Item, ProductTransactionSearch } from '../../../../../app/services/api/generated';
import { ReasonRecord } from '../../../types/ReasonRecord';
import useNswagClient from '../../../../../hooks/api/useNswagClient';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../../../components/shared/useUser';
import { TypesRecord } from '../../../types/TypesRecord';
import { SelectChangeEvent } from '@mui/material';
import { ItemCost, ItemQuantity, ItemReason, ItemUoms, ItemPossibleUOMs } from '../interfaces/itemValueTypes';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../../hooks/useLogError';
import useCurrencyCodeConfiguration from '../../../../../hooks/configurations/useCurrencyCodeConfiguration';
import axios, { CancelTokenSource } from 'axios';

export const useCreateTransaction = () => {
  const { getItems, getUoms, createMany, getTransactions, getTransactionTypes, getTransactionReasons, getConversions } = useNswagClient();
  const { hasPermissionTo, selectedStore } = useContext(UserContext);
  const { logError } = useLogError();
  //global options, get from api calls
  const [transactionTypes, setTransactionTypes] = useState<TypesRecord[]>([]);
  const [transactionReasons, setTransactionReasons] = useState<ReasonRecord[]>([]);
  //global vlues to pass to api
  const [transactionType, setTransactionType] = useState<TypesRecord | undefined>();
  const [createDate, setCreateDate] = useState(new Date());
  const [note, setNote] = useState('');
  //values to pass to api, different for each item
  const [itemsToCreate, setItemsToCreate] = useState<Item[]>([]);
  const [itemQuantities, setItemQuantities] = useState<ItemQuantity[]>([]);
  const [itemReasons, setItemReasons] = useState<ItemReason[]>([]);
  const [itemCosts, setItemCosts] = useState<ItemCost[]>([]);
  const [itemUoms, setItemUoms] = useState<ItemUoms[]>([]);
  const [totalReceiptValue, setTotalReceiptValue] = useState<number>(0);

  const [itemPossibleUOMs, setItemPossibleUOMs] = useState<ItemPossibleUOMs[]>([]);
  //result of API call, for alerts and disbling actions/inputs
  const [isProcessingSubmit, setIsProcessingSubmit] = useState<boolean>(false);
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ createErrorMessage, setCreateErrorMessage ]= useState('');
  //value to handle getting items from search and store selected ones
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState<string | null>(null);
  const [selectableItems, setSelectableItems] = useState<Item[]>([]);
  const [isItemsLoading, setIsItemsLoading] = useState<boolean>(false);
  //values used within use hook
  const storeNumber = selectedStore?.storeNumber ?? '-1';
  const franchise = selectedStore?.franchiseId ?? -1;
  //values used outside of hook
  const hasPermissionToChangePrice = hasPermissionTo(['ManualTransactionCostPriceWrite']);
  const dropDownItems = selectableItems.map(i => i.itemNumber + ' - ' + i.description);
  const isDisableActions = isSubmittedSuccess || isProcessingSubmit;

  const [duplicateTransactions, setDuplicateTransactions] = useState<ProductTransactionSearch[]>([]);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState<boolean>(false);
  const [isTypeChangeModalOpen, setIsTypeChangeModalOpen] = useState<boolean>(false);
  const [transactionTypeToChangeTo, setTransactionTypeToChangeTo] = useState<TypesRecord | undefined>();
  const [itemCostsAtSelect, setItemCostsAtSelect] = useState<ItemCost[]>([]);
  const [isCostChangeModalOpen, setIsCostChangeModalOpen] = useState<boolean>(false);
  const [hasRecentActivity, setHasRecentActivity] = useState<boolean | undefined>(true);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const [ hasNewChanges, setHasNewChanges ]= useState<boolean>(false);
  
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const { t } = useTranslation('createTransaction');

  useEffect(() => {
    loadTransactionTypes();
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    calculateTotalReceiptValue();
  }, [itemCosts, itemQuantities, itemUoms]);

  useEffect(() => {
    loadTransactionReasons();

  }, [transactionType]);
  const loadTransactionTypes = async (): Promise<void> => {
    getTransactionTypes(storeNumber).then(
      (element) => {
        let types: TypesRecord[] = [];
        element.data?.filter(x => x.enabled).forEach(x => {
          if (x.displayName) {
            const type = {
              id: '',
              type: x.displayName ?? '',
              franchiseID: x.franchiseId ?? -1,
              direction: x.direction ?? '',
              enabled: x.enabled ?? undefined,
              enabledDuplicateValidation: x.enabledDuplicateValidation ?? undefined,
            };
            types = types.concat(type);
          }
        });
        types.forEach((rag, index) => rag.id = (index + 1).toString());
        return types;
      })
      .then((reason) => setTransactionTypes(reason))
      .catch((error) => logError(error));
  };

  const loadTransactionReasons = (): void => {
    let actionType: string | undefined = undefined;

    if (transactionType?.direction === 'receive') {
      actionType = 'receive';
    }
    if (transactionType?.direction === 'issue') {
      actionType = 'issue';
    }

    getTransactionReasons(actionType, storeNumber).then(
      (element) => {
        let reasons: ReasonRecord[] = [];
        element.data?.reasons?.filter(x => x.actionType?.toLowerCase() === actionType?.toLowerCase()).forEach(x => {
          if (x.name) {
            const reason = {
              id: x.id ?? -1,
              reason: x.name ?? '',
              franchiseID: x.franchiseId ?? -1,
            };
            reasons = reasons.concat(reason);
          }
        });
        return reasons;
      },
    )
      .then((result) => setTransactionReasons(result))
      .catch((error) => logError(error));

  };

  function quantityIsValid(id: number | undefined) {
    if (id == undefined) return false;
    const quant = itemQuantities.find(x => x.id == id)?.quantity;
    if (quant == undefined) return false;
    return isFinite(quant) && Math.floor(quant) === quant || quant == 0;
  }

  function uomIsValid(id: number | undefined) {
    if (id == undefined) return false;
    const quant = itemUoms.find(x => x.id == id)?.uom;
    if (quant == undefined) return false;
    return quant != '';
  }

  const isFormInvalid = () => {
    if (itemsToCreate.length == 0) return true;
    if (itemCosts.some(x => x.cost < 0)) return true;
    if (itemsToCreate.some(x => !quantityIsValid(x.id))) return true;
    if (itemUoms.some(x => !uomIsValid(x.id))) return true;
    return false;
  };

  const transactionTypesToShow = () => {
    const reducedTypes = transactionTypes.filter(x => x.franchiseID == franchise || franchise == -1);

    const uniqueTransactionTypes = reducedTypes.filter((value: TypesRecord, index: number, array: TypesRecord[]) => {
      return array.indexOf(value) === index;
    });

    return uniqueTransactionTypes;
  };

  const closeTransactionTypeChangeModal = () => {
    setIsTypeChangeModalOpen(false);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    const selectedType = transactionTypes.find(x => x.id == event.target.value);
    if (selectedType?.id != transactionType?.id) {
      if (itemsToCreate.length > 0) {
        setTransactionTypeToChangeTo(selectedType);
        setIsTypeChangeModalOpen(true);
      }
      else {
        changeType(selectedType);
      }
    }
    setHasNewChanges(true);
  };

  const changeType = (passedTypeToChangeTo?: TypesRecord | undefined) => {
    const typeToUse = passedTypeToChangeTo;
    setItemsToCreate([]);
    setItemCosts([]);
    setItemPossibleUOMs([]);
    setItemQuantities([]);
    setItemReasons([]);
    setItemUoms([]);
    setTransactionType(typeToUse ?? transactionTypeToChangeTo);
  };

  const handleCreateDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    const today = new Date();
    const selectedDateTime = new Date(selectedDate);

    if (selectedDateTime > today) {
      // Prevent selecting future dates
      return;
    }
    if (selectedDate === '') {
      setCreateDate(new Date());
    }
    else {
      setCreateDate(new Date(event.target.value));
    }
    setHasNewChanges(true);
    
  };

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    if (value != '') {
      setIsItemsLoading(true);
      setSelectableItems([]);
      let isStockItem: boolean | undefined = undefined;
      if (transactionType?.direction === 'receive') {
        isStockItem = true;
      }
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();
    
      getItems(value, storeNumber, hasRecentActivity, undefined, isStockItem, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, cancelTokenSourceRef.current.token)
        .then(
          (result) => {
            setSelectableItems(result.data?.items ?? []);
            setIsItemsLoading(false);
          },
        )
        .catch((error) => {      
          if (!axios.isCancel(error)) {
            logError(error);
            setIsItemsLoading(false);
          } 
        });
    }
    else {
      setSelectableItems([]);
    }
    setHasNewChanges(true);
  };

  const handleItemSelect = async (newValue: string) => {
    setValue('');
    setSearchTerm('');
    const itemToAdd = selectableItems.find(i => i.itemNumber + ' - ' + i.description == newValue);

    if (itemToAdd) {
      const preExistingItem = itemsToCreate.find(x => x.id == itemToAdd.id);
      if (!preExistingItem) {
        setItemsToCreate(itemsToCreate.concat(itemToAdd));

        setItemQuantities(itemQuantities.concat({ id: itemToAdd.id ?? -1, quantity: 1 }));
        setItemReasons(itemReasons.concat({ id: itemToAdd.id ?? -1, reason: transactionReasons[0] }));
        setItemCosts(itemCosts.concat({ id: itemToAdd.id ?? -1, cost: itemToAdd.costPrice ?? 0 }));

        const possibleUoms = await possibleUOMs(storeNumber, itemToAdd.itemNumber ?? '-1');
        setItemPossibleUOMs(itemPossibleUOMs.concat({ id: itemToAdd.id ?? -1, uoms: possibleUoms }));

        const primaryUomCode = possibleUoms.find(uom => uom && uom.code === itemToAdd.uom)?.code;
        const newItemUom = itemUoms.concat({ id: itemToAdd.id ?? -1, uom: primaryUomCode ?? '' });
        setItemUoms(itemUoms.concat(newItemUom));
      }
    }
    setHasNewChanges(true);

  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
    setHasNewChanges(true);
  };

  const handleCostFeildSelected = () => {
    setItemCostsAtSelect(itemCosts);
  };

  const handleCostFeildUnselected = () => {
    if (itemCosts != itemCostsAtSelect) {
      setIsCostChangeModalOpen(true);
    }
  };

  const closeCostChangeModal = () => {
    setIsCostChangeModalOpen(false);
  };

  const handleCostChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCosts = itemCosts.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.cost = Number(event.target.value);
      }
      return returnValue;
    });
    setItemCosts(newCosts);
    setHasNewChanges(true);
  };

  const handleQuantityChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantities = itemQuantities.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.quantity = Number(event.target.value);
      }
      return returnValue;
    });
    setItemQuantities(newQuantities);
    setHasNewChanges(true);
  };

  const handleReasonChange = (id: number) => (event: SelectChangeEvent) => {
    const newReasons = itemReasons.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        const reasonToChangeTo = transactionReasons.find(x => x.id == Number(event.target.value));
        if (reasonToChangeTo) {
          returnValue.reason = reasonToChangeTo;
        }
      }
      return returnValue;
    });
    setItemReasons(newReasons);
    setHasNewChanges(true);
  };

  const handleUomChange = (id: number) => async (event: SelectChangeEvent) => {

    const item = itemsToCreate.find(x => x.id === id);
    const primaryUom = item?.uom ?? '';
    const originalCostPrice = item?.costPrice;
    let fromUom: string = itemUoms.find(x => x.id === id)?.uom ?? '';
    const toUom = event.target.value;

    let costPrice: number | undefined = itemCosts.find(x => x.id === id)?.cost ?? 0;

    const newUom = itemUoms.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        fromUom = returnValue.uom;
        returnValue.uom = event.target.value;
      }
      return returnValue;
    });

    let conversionNumber: number | undefined = 1;
    if (toUom === primaryUom) {
      costPrice = originalCostPrice;
    } else if (fromUom === primaryUom) {
      conversionNumber = await getUomConversion(item?.itemNumber?.toString() ?? '', primaryUom, toUom);
    }
    else {
      const primaryUomConversion = await getUomConversion(item?.itemNumber?.toString() ?? '', fromUom, primaryUom) ?? 0;
      costPrice = costPrice * primaryUomConversion;
      conversionNumber = await getUomConversion(item?.itemNumber?.toString() ?? '', primaryUom, fromUom);
    }
    if (costPrice && conversionNumber)
      costPrice = costPrice * conversionNumber;

    const newCosts = itemCosts.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.cost = Number(costPrice);
      }
      return returnValue;
    });
    setItemCosts(newCosts);
    setItemUoms(newUom);
    setHasNewChanges(true);
  };

  const getUomConversion = async (itemId: string, fromUom: string, toUom: string) => {
    try {
      const result = await getConversions(itemId, undefined, undefined, undefined, storeNumber);

      if (!result?.conversions) return 0;

      const conversions = result.conversions;
      const conversion = conversions.find(x => (x.fromUom === fromUom && x.toUom === toUom) || (x.fromUom === toUom && x.toUom === fromUom));

      if (!conversion) {
        return 0;
      }

      let conversionNumber;
      if (conversion.fromUom === toUom && conversion.toUom === fromUom) {
        if (!conversion.conversionNumber) {
          return 0;
        }
        conversionNumber = 1 / conversion.conversionNumber;
      } else {
        conversionNumber = conversion.conversionNumber;
      }
      return conversionNumber;
    } catch (error) {
      logError(error);
      return 0;
    }
  };


  const calculateTotalReceiptValue = () => {
    const selectedTransferItems = itemQuantities.map(itemQuantity => {
      const matchingCost = itemCosts.find(itemCost => itemCost.id === itemQuantity.id);
      if (matchingCost) {
        return {
          id: itemQuantity.id,
          quantity: itemQuantity.quantity,
          costPrice: matchingCost.cost,
        };
      }
    }).filter(item => item !== null);
    let total = 0;
    selectedTransferItems.forEach(item => {
      if (item?.costPrice && item?.quantity) {
        total += item.costPrice * item.quantity;
      }
    });
    setTotalReceiptValue(total);
  };


  const handleRecentActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasRecentActivity(event.target.checked ? true : undefined);
    setHasNewChanges(true);
  };

  const deleteButtonHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    setItemsToCreate(itemsToCreate.filter(x => x.id != id));
    setItemCosts(itemCosts.filter(x => x.id != id));
    setItemPossibleUOMs(itemPossibleUOMs.filter(x => x.id != id));
    setItemQuantities(itemQuantities.filter(x => x.id != id));
    setItemReasons(itemReasons.filter(x => x.id != id));
    setItemUoms(itemUoms.filter(x => x.id != id));
  };

  const errorDismissButtonHandler = () => {
    setIsError(false);
  };

  const successDismissButtonHandler = () => {
    setNote('');
    setItemsToCreate([]);
    setItemCosts([]);
    setItemPossibleUOMs([]);
    setItemQuantities([]);
    setItemReasons([]);
    setItemUoms([]);

    setIsSubmittedSuccess(false);
  };

  const reasonsToShow = () => {
    return transactionReasons;
  };

  const possibleUOMs = async (storeNumber: string, itemNumber: string): Promise<Uom[]> => {
    let uomReturn: Uom[] = [];
    const results = await getUoms(itemNumber, storeNumber, true, true);
    const uoms = results.data?.uoms;
    uomReturn = uoms ?? [];
    return uomReturn;
  };

  const handleDuplicateDialogClose = () => {
    setIsDuplicateModalOpen(false);
  };

  const recentManualTransactionsForStore = async () => {
    const dateNow = new Date(Date.now());
    dateNow.setDate(dateNow.getDate() + 1);

    const dateYesterday = new Date(Date.now());
    dateYesterday.setDate(dateYesterday.getDate() - 1);

    let recentTransactions: ProductTransactionSearch[] = [];

    await getTransactions(storeNumber, dateYesterday.toISOString(), dateNow.toISOString(), undefined, transactionType?.direction, transactionType?.type ?? '', undefined, undefined, undefined, undefined, 10000)
      .then((result) => {
        recentTransactions = result.data?.data ?? [];
      })
      .catch((error) => logError(error));

    const recentManTransactions = recentTransactions.filter(x => x.source == 'manual');
    return recentManTransactions;
  };

  const handleSubmit = () => {
    recentManualTransactionsForStore()
      .then((recentManTransactions) => {
        if (recentManTransactions.length == 0) {
          createTransactions();
        }
        else {
          if (transactionType?.enabledDuplicateValidation) {
            setDuplicateTransactions(recentManTransactions);
            setIsDuplicateModalOpen(true);
          }
          else {
            createTransactions();
          }
        }
      });

  };

  const createTransactions = () => {
    const transactions: CreateTransactionRequest[] = itemsToCreate.map(item => {
      const quantityToUse = itemQuantities.find(x => x.id == item.id)?.quantity ?? -1;
      const uomToUse = itemUoms.find(x => x.id == item.id)?.uom ?? '';
      const reasonToUse = (itemReasons.length > 0 && itemReasons?.find(x => x.id == item.id)?.reason?.reason) || '';

      const costToUse = itemCosts.find(x => x.id == item.id)?.cost ?? 0;

      return {
        store: storeNumber,
        itemNumber: item.itemNumber ?? '',
        description: item.description,
        type: transactionType?.type ?? '',
        direction: transactionType?.direction ?? '',
        quantity: quantityToUse,
        uom: uomToUse,
        note: note ?? '',
        costPrice: costToUse,
        reason: reasonToUse,
        date: createDate.toISOString(),
        timezoneOffset: new Date().getTimezoneOffset(),
      };
    });

    const body: CreateMultipleTransactionsRequest = {
      transactions: transactions,
      store: storeNumber,
    };

    setIsProcessingSubmit(true);
    setIsError(false);
    createMany(body)
      .then(() => {
        setIsSubmittedSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      )
      .catch((error) => {
        logError(error);
        let errorMessage = '';
        for (const key in error?.errors) {
          if (Object.prototype.hasOwnProperty.call(error?.errors, key)) {
            const value = error?.errors[key];
            if (Array.isArray(value) && value.length > 0) {
              errorMessage = value[0];  // Return the message part
            }
          }
        }

        setCreateErrorMessage(errorMessage);
        setIsError(true);
      })
      .finally(() => setIsProcessingSubmit(false));
  };

  return {
    hasPermissionToChangePrice,
    quantityIsValid,
    uomIsValid,
    isFormInvalid,
    transactionTypesToShow,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    handleNoteChange,
    handleCostChange,
    handleQuantityChange,
    handleReasonChange,
    handleUomChange,
    handleRecentActivity,
    deleteButtonHandler,
    dropDownItems,
    reasonsToShow,
    handleSubmit,
    transactionType,
    createDate,
    handleCreateDateChange,
    value,
    searchTerm,
    hasRecentActivity,
    itemsToCreate,
    itemUoms,
    itemPossibleUOMs,
    itemQuantities,
    itemCosts,
    itemReasons,
    totalReceiptValue,
    note,
    isItemsLoading,
    isProcessingSubmit,
    isSubmittedSuccess,
    isDisableActions,
    isError,
    errorDismissButtonHandler,
    successDismissButtonHandler,
    handleDuplicateDialogClose,
    isDuplicateModalOpen,
    createTransactions,
    duplicateTransactions,
    closeTransactionTypeChangeModal,
    changeType,
    isTypeChangeModalOpen,
    handleCostFeildSelected,
    handleCostFeildUnselected,
    closeCostChangeModal,
    isCostChangeModalOpen,
    currencyCode,
    hasNewChanges,
    t,
    createErrorMessage,
  };
};