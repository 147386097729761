import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import './i18n';
import './assets/fonts/SoDo-Sans/SoDoSans-Regular.ttf';
import './assets/fonts/SoDo-Sans/SoDoSans-SemiBold.ttf';
import './assets/fonts/SoDo-Sans/SoDoSans-Bold.ttf';
import { useSelectedTheme } from './hooks/useSelectedTheme';

const globalStyles = (
  <GlobalStyles
    styles={{
      'html,body,#root,.App': {
        width: '100%',
        height: '100%',
      },
      '#root': {
        display: 'flex',
      },
    }}
  />
);

const Index = () => {
  const { selectedTheme } = useSelectedTheme();

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssBaseline />
      {globalStyles}
      <App />
    </ThemeProvider>
  );
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceworker.js');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Index />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
