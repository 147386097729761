import { palette } from '../palette';

export const MuiTable = {
  styleOverrides: {
    root: {
      borderBottom: '1px solid',
      borderTop: '1px solid',
      borderColor: palette.custom.gray[200],
    },
  },
};

export const MuiTableRow =  {
  styleOverrides: {
    root: {
      '&.MuiTableRow-hover:hover': {
        cursor: 'pointer',
        backgroundColor: palette.custom.gray[100],
      },
    },
  },
};

export const MuiTableCell = {
  styleOverrides: {
    root: {
      color: palette.custom.gray[600],
      fontWeight: 500,
      fontSize: '12px',

      borderBottom: '1px solid',
      borderBottomColor: palette.custom.gray[200],
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    head: {
      color: palette.custom.gray[600],
      fontWeight: 600,
      fontSize: '12px',

      borderBottom: '1px solid',
      borderBottomColor: palette.custom.gray[200],
      backgroundColor: palette.custom.gray[50],
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};