import { Box, Button, Grid, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from '../../assets';
import RecipesTab from './components/Recipe/RecipesTab';
import MadeInStoreTab from './components/MadeInStore/MadeInStoreTab';
import { RecipeDialogProvider, useRecipeDialog } from './context/RecipeDialogContext';
import useCurrencyCodeConfiguration from '../../hooks/configurations/useCurrencyCodeConfiguration';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { UserContext } from '../../components/shared/useUser';

const enum TabEnum {
  Recipes = 1,
  MadeInStore = 2
}

const RecipeTabs = () => {
  const { t } = useTranslation('recipe');
  const { hasPermissionTo } = useContext(UserContext);
  const [ hasPermissionToReadMadeInStore, setHasPermissionToReadMadeInStore ] = useState<boolean>(false);
  const [ hasPermissionToCreateRecipe, setHasPermissionToCreateRecipe ] = useState<boolean>(false);
  const [ tabValue, setTabValue ] = useState<number>(TabEnum.Recipes);
  const { setShowRecipeDialog, updateFormField } = useRecipeDialog();
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  useEffect(() => {
    getCurrencyCodeConfiguration();
    setHasPermissionToReadMadeInStore(hasPermissionTo(['MadeInStoreRead']));
    setHasPermissionToCreateRecipe(hasPermissionTo(['CreateItemsAndRecipes']));
  }, []);
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onCreateNew = () => {
    setShowRecipeDialog(true);
    updateFormField('selectedEntityType', tabValue);
  };
  
  return (
    <PageArea>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
      >
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
          alignItems='start'
        >
          <PageTitle>{t('recipes')}</PageTitle>
          <Typography variant='textSM'>{t('subtitle')}</Typography>
        </Box>
        {hasPermissionToCreateRecipe && (
          <Grid item
            xs={12}
            sx={{ textAlign: 'right', mb: 3, display: tabValue === TabEnum.MadeInStore && !hasPermissionToReadMadeInStore ? 'none' : '' }}>
            <Button variant="primary"
              size="lg"
              startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
              onClick={onCreateNew}
            >
              {t('createNew')}
            </Button>
          </Grid>
        )}
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
        >
          <Grid container
            item
            xs={12}>
            <Grid item
              xs={12}
              md={8}>
              <Tabs value={tabValue}
                onChange={handleChange}
              >
                <Tab label={t('recipes')}
                  value={TabEnum.Recipes} />
                {hasPermissionToReadMadeInStore && <Tab label={t('madeInStore')}
                  value={TabEnum.MadeInStore} />}
              </Tabs>
            </Grid>
          </Grid>
          {tabValue === TabEnum.Recipes && <RecipesTab currencyCode={currencyCode} />}
          {tabValue === TabEnum.MadeInStore && <MadeInStoreTab />}
        </Box>
      </Box>
    </PageArea>
  );
};

const RecipePage = () => {
  return (
    <RecipeDialogProvider>
      <RecipeTabs />
    </RecipeDialogProvider>
  );
};

export default RecipePage;