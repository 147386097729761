import { Alert, Autocomplete, Box, Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { Trash01 ,Plus, Copy01 } from '../../../assets';
import { useContext, useEffect } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';
import { DayOffsets, StockCountScheduleType } from '../enums/StockScheduleEnums';
import { ScheduleRecurrenceFrequency, Store2 } from '../../../app/services/api/generated';
import { theme } from '../../../theme';
import InfoIcon from '@mui/icons-material/Info';

const ScheduleCreateSchedules =() => {
  const { t,
    today,
    isLoading,
    schedules,
    stores,
    deleteScheduleRow,
    setSchedules,
    hasRightToEdit } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const hasSchedules = schedules.length > 0;

  useEffect(() => {
    if (schedules.length === 0) {
      addNewSchedule();
    }
  }, [schedules]);

  const addNewSchedule = () => {
    const newSchedule = {
      ids: [],
      isActive: true,
      storeNumbers: [],
      generationDayOffset: DayOffsets.NONE,
      scheduleType: StockCountScheduleType.FREQUENCY_AND_INTERVAL,
      startDate: today,
      recurrenceInterval: 1,
      recurrenceFrequency: 1 as ScheduleRecurrenceFrequency,
      scheduledDayOfMonth: 1,
    };
    setSchedules(schedules.concat(newSchedule));
  };

  const copySchedule = (index: number) => {
    const newSchedule = structuredClone(schedules[index]);
    if (newSchedule) {
      const newMultiStoreGroupId = schedules.length > 0 ? Math.max(...schedules.map(x => x.multiStoresGroupId ?? -1)) + 1 : 0;
      newSchedule.ids = [];
      newSchedule.multiStoresGroupId = newMultiStoreGroupId;
      setSchedules(schedules.concat(newSchedule));
    }
  };

  const handleAcitveChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.isActive = event.target.checked;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleStartChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.startDate = event.target.value;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleStoreChange = (index: number) => (event: React.ChangeEvent<{}>, newValue: Store2[]) => {
    const convertedStores =  newValue.map(x => x.storeNumber ?? '');
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.storeNumbers = convertedStores;
        returnValue.ids = [];
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleDayOffsetSelect = (index: number, value: number) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.generationDayOffset = value;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleTypeSelect = (index: number) => (event: SelectChangeEvent<number>) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.scheduleType = Number(event.target.value);
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleFequencyChange = (index: number, value: number) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.recurrenceFrequency = value as ScheduleRecurrenceFrequency;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleIntervalChange = (index: number, value: number) => {
    if (value > 0){
      const newSchedules = schedules.map((a, i) => {
        const returnValue = { ...a };
        if (index == i) {
          returnValue.recurrenceInterval = value;
        }
        return returnValue;
      });
      setSchedules(newSchedules);
    }
  };

  const days = Array.from({ length: 28 }, (x, i) => i + 1);

  const handleDaySelect = (index: number, value: number) => {
    const newSchedules = schedules.map((a, i) => {
      const returnValue = { ...a };
      if (index == i) {
        returnValue.scheduledDayOfMonth = value;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  if(isLoading){
    return <></>;
  } 
  
  return (
    <Box sx={{ border:'2px solid', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        sx={{ pt: '12px', pb: '6px', px: '8px' }}>
        <Grid item 
          xs={3}>
          <Typography variant="textMD"
            sx={{ fontWeight:'bold' }}>{t('schedules')}</Typography>
        </Grid>
        <Grid item 
          xs={9}
          sx={{ display:'flex', justifyContent:'flex-end',alignItems:'center' }}>
          {hasSchedules && (
            <Button
              size='lg'
              sx={{ mr: 4 }}
              variant='bad'
              onClick={() => deleteScheduleRow()}
              startIcon={<Trash01 />}
              disabled ={!hasRightToEdit}>
              {t('deleteAll')}
            </Button>
          )}
          <Button
            size='lg'
            variant='primary'
            onClick={addNewSchedule}
            disabled ={!hasRightToEdit}>
            <Plus/>{t('add')}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ pt:'10px' }}>
        {schedules.length > 0 ? (
          <Stack>
            {schedules?.map((row, index) => (
              <Box key={row.ids?.toString()} 
                sx={{ borderTop:'4px solid', px:'8px', pb:'24px', pt:'8px', borderColor: theme.palette.primary[500] }}>
                <Grid container
                  sx={{ pb:'4px' }}>
                  <Grid item 
                    xs={8}>
                    <FormControlLabel
                      control={<Switch
                        checked={row.isActive}
                        disabled={!hasRightToEdit} 
                        onChange={handleAcitveChange(index ?? -1)}/>}
                      label={t('active')}
                      labelPlacement="start"
                      sx={{ ml:2 }}/>
                  </Grid>
                  <Grid item 
                    xs={4}
                    sx={{ display:'flex', justifyContent:'flex-end' }}>
                    <IconButton  disabled ={!hasRightToEdit}
                      onClick={() => copySchedule(index ?? 0)}><Copy01/></IconButton>
                    <IconButton  disabled ={!hasRightToEdit}
                      onClick={() => deleteScheduleRow(index ?? 0)}
                      sx={{ ml:'4px' }}><Trash01 style={{ color: hasRightToEdit? 'red':'action' }}/></IconButton>
                  </Grid>
                </Grid>
                <Grid container
                  spacing={5}>
                  <Grid item
                    xs={12}>
                    <Autocomplete multiple
                      disabled ={!hasRightToEdit}
                      options={stores}
                      getOptionKey={(option) => option.storeNumber ?? 0}
                      getOptionLabel={(option) => option.storeName ?? ''}
                      value={stores?.filter(option => row.storeNumbers?.includes(option.storeNumber ?? '')) ?? []}
                      onChange={handleStoreChange(index ?? -1)}
                      renderInput={(params) => (
                        <TextField {...params}
                          label={t('selectStores')}
                          placeholder={t('selectStores')}/>
                      )}/>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <TextField fullWidth
                      type='date'
                      disabled ={!hasRightToEdit}
                      value={row.startDate?.split('T')[0] ?? '01/01/1900'}
                      onChange={handleStartChange(index ?? -1)}
                      InputLabelProps={{ shrink: true }}
                      label={t('startDate')}/>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t('generationDayOffset')}<Tooltip title={t('generationDayOffsetInfo')}>
                        <InfoIcon fontSize='small'
                          sx={{ ml: 2, verticalAlign: 'sub' }}/>
                      </Tooltip></InputLabel>
                      <Select
                        value={row.generationDayOffset}
                        disabled ={!hasRightToEdit}
                        key={row.generationDayOffset}
                        label={'Generation Day Offset' + '--.'}
                        autoComplete="off"
                        onChange={(e) => handleDayOffsetSelect(index ?? -1, Number(e.target.value))}>
                        <MenuItem key={0} 
                          value={0}>
                          {t('none')}
                        </MenuItem>
                        <MenuItem key={-1} 
                          value={-1}>
                          {t('oneDay')}
                        </MenuItem>
                        <MenuItem key={-2} 
                          value={-2}>
                          {t('twoDays')}
                        </MenuItem>
                        <MenuItem key={-3} 
                          value={-3}>
                          {t('threeDays')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t('scheduleType')}</InputLabel>
                      <Select
                        value={row.scheduleType}
                        key={row.scheduleType}
                        disabled ={!hasRightToEdit}
                        label={t('scheduleType')}
                        onChange={handleTypeSelect(index ?? -1)}>
                        <MenuItem key={StockCountScheduleType.FREQUENCY_AND_INTERVAL} 
                          value={StockCountScheduleType.FREQUENCY_AND_INTERVAL}>
                          {t('frequencyAndInterval')}
                        </MenuItem>
                        <MenuItem key={StockCountScheduleType.MONTHLY_DATE} 
                          value={StockCountScheduleType.MONTHLY_DATE}>
                          {t('monthlyDate')}
                        </MenuItem>
                        <MenuItem key={StockCountScheduleType.LAST_DAY_OF_MONTH} 
                          value={StockCountScheduleType.LAST_DAY_OF_MONTH}>
                          {t('lastDayOfTheMonth')}
                        </MenuItem>
                        <MenuItem key={StockCountScheduleType.ONE_TIME} 
                          value={StockCountScheduleType.ONE_TIME}>
                          {t('oneDay')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {row.scheduleType === StockCountScheduleType.FREQUENCY_AND_INTERVAL && <><Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t('fequency')}</InputLabel>
                      <Select
                        value={row.recurrenceFrequency}
                        key={row.recurrenceFrequency}
                        disabled ={!hasRightToEdit}
                        label='Fequency'
                        onChange={(e) => handleFequencyChange(index ?? -1, Number(e.target.value))}>
                        <MenuItem key={1} 
                          value={1}>
                          {t('day')}
                        </MenuItem>
                        <MenuItem key={7} 
                          value={7}>
                          {t('week')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <TextField value={row.recurrenceInterval}
                      fullWidth
                      disabled ={!hasRightToEdit}
                      type='number'
                      onChange={(e) => handleIntervalChange(index ?? -1, Number(e.target.value))}
                      label={t('interval')}/>
                  </Grid></>}
                  {row.scheduleType === StockCountScheduleType.MONTHLY_DATE && <Grid item
                    lg={4}
                    md={6}
                    xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t('dayOfTheMonth')}</InputLabel>
                      <Select
                        value={row.scheduledDayOfMonth}
                        key={row.scheduledDayOfMonth}
                        disabled ={!hasRightToEdit}
                        label={t('dayOfTheMonth')}
                        onChange={(e) => handleDaySelect(index ?? -1, Number(e.target.value))}>
                        {days.map(day => <MenuItem key={day} 
                          value={day}>
                          {day}
                        </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>}
                </Grid>
              </Box>
            ))}
          </Stack>
        ) 
          :
          (
            <Alert severity='info'
              sx={{ mb: '12px', mx: '8px' }}>
              {t('noSchedulesAddedYet')}
            </Alert>
          )}
      </Box>
    </Box>
  );
};

export default ScheduleCreateSchedules;