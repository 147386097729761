import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { SvgIcon, DialogActions, Button, Alert, Stack, Grid } from '@mui/material';
import { Plus } from '../../../../../assets';
interface CreateTransactionsButtonsProps {
  onCancel: () => void;
}
export const CreateTransactionsButtons: React.FC<CreateTransactionsButtonsProps> = ({ onCancel }) => {
  const { isFormInvalid, handleSubmit, isDisableActions, isError,
    errorDismissButtonHandler, isSubmittedSuccess, successDismissButtonHandler,
    isProcessingSubmit, t, createErrorMessage } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <DialogActions sx={{ mb: 4, px: '24px' }}>
      <Grid container
        spacing={4}
        justifyContent="space-between">
        <Grid item
          xs={12}>
          {isError && <Alert severity="error"
            onClose={errorDismissButtonHandler}>
            {t('errorAlert')} - {createErrorMessage}
          </Alert>}
          {isSubmittedSuccess && <Alert severity="success"
            onClose={successDismissButtonHandler}>
            {t('successAlert')}
          </Alert>}
          {isProcessingSubmit && <Alert severity="info">
            {t('infoAlert')}
          </Alert>}
        </Grid>
        <Grid item
          xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}>
            <Button variant='secondary'
              size="lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button
              variant='primary'
              size="lg"
              disabled={isFormInvalid() || isDisableActions}
              onClick={handleSubmit}
              startIcon={<SvgIcon><Plus /></SvgIcon>}>
              {t('createTransactions')}
            </Button>
          </Stack>
        </Grid>
      </Grid>

    </DialogActions>
  );
};

