import React, { ChangeEvent, Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { TFunction } from 'i18next';
import { useScheduleCreate } from '../hooks/useScheduleCreate';
import { Item, PrimaryCategoryStockCount, Store2, StockCheckScheduleItem } from '../../../app/services/api/generated';
import { InternalStockCheckSchedule } from '../interfaces/StockInterfaces';
import { Blocker } from 'react-router-dom';

export interface ScheduleCreateValues {
    t: TFunction<'createStockCount', undefined>,
    isCategoriesModalOpen: boolean,
    isItemsModalOpen: boolean,
    isBulkModalOpen: boolean,
    isCategoryDeleteModalOpen: boolean,
    isDeleteModalOpen: boolean,
    allUsers: string[],
    handleActiveChange: (event: SelectChangeEvent) => void,
    handleQtyChange: (itemNumber: string, newValue: string) => void,
    handleValueChange: (itemNumber: string, newValue: string) => void,
    handleLeaveClick: () => void,
    handleUserSelect: (event: SelectChangeEvent<string>) => void,
    handleSelectChange: (event: SelectChangeEvent<number>) => void,
    generateTimeOptions: () => { value: number; label: string; }[],
    handleInputValue: (event: ChangeEvent<HTMLInputElement>) => void,
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    submitStock: () => void,
    handleAddBulkItemClick: () => void,
    handleAddItemsClick: () => void,
    handleAddCategoriesClick: () => void,
    handleClose: () => void,
    handleitems: (data: Item[]) => void,
    deleteItemRow: (itemNumberToDelete?: string) => void,
    deleteCategoryRow: (nameToDelete?: string) => void,
    deleteItems: () => void,
    deleteCategories: () => void,
    handleCategories: (data: PrimaryCategoryStockCount[]) => void,
    handleSliderChange: (event: Event, newValue: number | number[]) => void,
    handleIsTimeLockedChanged: (event: SelectChangeEvent) => void,
    name: string,
    today: string,
    selectedUser: string,
    approvalValue: number | undefined,
    selectedTime: number | undefined,
    isTimeLocked: boolean,
    sliderValue: number,
    categoryData: PrimaryCategoryStockCount[],
    itemsData: StockCheckScheduleItem[],
    closeSnackBar: () => void,
    isSnackBarOpen: boolean,
    snackBarSeverity: 'error' | 'warning' | 'info' | 'success',
    snackbarMessage: string,
    snackBarDuration: number | null,
    schedules: InternalStockCheckSchedule[],
    stores: Store2[],
    setSchedules: Dispatch<SetStateAction<InternalStockCheckSchedule[]>>,
    deleteScheduleRow: (index?: number) => void,
    isScheduleDeleteModalOpen: boolean,
    confirmDeleteSchedule: () => void,
    handleSmartCountChange: () => void,
    isSmartCount: boolean,
    handleCategoryChange: (index: number, newValue: string) => void,
    isActive: boolean,
    isLoading: boolean,
    generateStock: () => void,
    isCreating: boolean,
    blocker: Blocker,
    handleLeavePageModalClose: () => void,
    handleLeavePageModalConfirm: () => void,
    currencySymbol: string,
    hasRightToEdit: boolean
}

export const ScheduleCreateContext = createContext<null | ScheduleCreateValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const ScheduleCreateProvider = ({ children }: ProviderProps) => {
  const {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    allUsers,
    handleActiveChange,
    handleQtyChange,
    handleValueChange,
    handleLeaveClick,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleAddBulkItemClick,
    handleAddItemsClick,
    handleAddCategoriesClick,
    handleClose,
    handleitems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    schedules,
    stores,
    setSchedules,
    deleteScheduleRow,
    isScheduleDeleteModalOpen,
    confirmDeleteSchedule,
    handleSmartCountChange,
    isSmartCount,
    handleCategoryChange,
    isActive,
    isLoading,
    generateStock,
    isCreating,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    currencySymbol,
    hasRightToEdit,
  } = useScheduleCreate();

  const passedValue = {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    allUsers,
    handleActiveChange,
    handleQtyChange,
    handleValueChange,
    handleLeaveClick,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleAddBulkItemClick,
    handleAddItemsClick,
    handleAddCategoriesClick,
    handleClose,
    handleitems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    schedules,
    stores,
    setSchedules,
    deleteScheduleRow,
    isScheduleDeleteModalOpen,
    confirmDeleteSchedule,
    handleSmartCountChange,
    isSmartCount,
    handleCategoryChange,
    isActive,
    isLoading,
    generateStock,
    isCreating,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    currencySymbol,
    hasRightToEdit,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <ScheduleCreateContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </ScheduleCreateContext.Provider>
  );
};