import { Box, Paper, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import PendingChangesTable from './components/AllStoresChangesTable';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import { AllStoresContext, AllStoresValues } from '../../contexts/AllStoresChangesContext';

export default function AllStoresChanges() {
  const { t, isLoading, loadData, page } = useContext(AllStoresContext) as AllStoresValues;

  useEffect(() => loadData(), [page]);

  return (
    <Paper>
      <Box sx={{ p: '8px' }}>
        <Typography variant='displayXS'>{t('changesForAllStores')}</Typography>
      </Box>
      {isLoading ? <LoadingWheel/> : 
        <PendingChangesTable></PendingChangesTable>}
    </Paper>
  );
}