import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { FC } from 'react';
import RecipeItemRow from './RecipeItemRow';
import { Recipe } from '../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';

type Props = {
    list: Recipe[];
    handleSort: (orderBy: keyof Recipe) => void;
    orderBy: string;
    order: 'asc' | 'desc';
    currencyCode?: string;
}

const RecipeList: FC<Props> = ({ list, handleSort, orderBy, order, currencyCode }) => {
  const { t } = useTranslation('recipe');
  
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'itemNumber'}
                direction={order}
                onClick={() => handleSort('itemNumber')}
              >
                {t('productSKU')}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'description'}
                direction={order}
                onClick={() => handleSort('description')}
              >
                {t('productName')}
              </TableSortLabel>
            </TableCell>
            <TableCell align='center'>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'status'}
                direction={order}
                onClick={() => handleSort('status')}
              >
                {t('status')}
              </TableSortLabel>
            </TableCell>
            <TableCell align='center'>
              <TableSortLabel
                sx={{ '.MuiTableSortLabel-icon': { m: 0 } }}
                active={orderBy === 'costPriceOnHand'}
                direction={order}
                onClick={() => handleSort('costPriceOnHand')}
              >
                {t('costPrice')}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <RecipeItemRow key={row.id}
              item={row}
              currencyCode={currencyCode}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecipeList;