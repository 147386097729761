import { Box } from '@mui/material';
import React from 'react';

interface PageAreaProps {
  children: React.ReactNode
}


const PageArea: React.FC<PageAreaProps> = props => {
  return (
    <Box sx={{ p:10 }}>
      {props.children}
    </Box>
  );
};

export default PageArea;