import { Alert, Box, Button, Grid, IconButton, Paper, Popover, Snackbar, SvgIcon, TextField, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import RecipeList from './RecipeList';
import { Search } from '@mui/icons-material';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import CustomPagination from '../../../../components/forms/Pagination';
import { CreateMadeInStoreItemRequestDto, ItemComponent, MadeInStoreType, Recipe, UpsertItemComponent } from '../../../../app/services/api/generated';
import { theme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../components/shared/useUser';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import useLogError from '../../../../hooks/useLogError';
import { DotsVertical } from '../../../../assets';
import { Loader } from '../../../../components/ui/Loader';
import { exportFile } from '../../../../utils';
import CreateRecipeDialog from '../CreateRecipeDialog';
import { useRecipeDialog } from '../../../../pages/Recipe/context/RecipeDialogContext';

type Props = {
  currencyCode?: string;
}

const RecipesTab: FC<Props> = ({ currencyCode }) => {
  const { t } = useTranslation('recipe');
  const { selectedStore } = useContext(UserContext);
  const { getRecipes, exportRecipes, createMadeInStore, addComponent, deleteComponent } = useNswagClient();
  const { logError } = useLogError();
  const { setIsEditMode, setShowRecipeDialog } = useRecipeDialog();
  const [ recipes, setRecipes ] = useState<Recipe[]>([]);
  const [ totalItems, setTotalItems ] = useState<number>(0);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ page, setPage ] = useState<number>(0);
  const [ searchTerm, setSearchTerm ] = useState<string>('');
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ isExporting, setIsExporting ] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);
  const [ isSubmittedSuccessfully, setIsSubmittedSuccessfully ] = useState(1);
  const [ showSubmitStatusMessage, setShowSubmitStatusMessage ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const rowsPerPage = 10;

  const maxPages = () => {
    return Math.ceil(totalItems / rowsPerPage);
  };

  useEffect(() => {
    loadData(page, searchTerm);
  }, [page, isSubmittedSuccessfully]);

  const onSearch = () => {
    setPage(0);
    loadData(page, searchTerm);
  };

  const comparator = (property: keyof Recipe, isAsc: boolean) => (a: Recipe, b: Recipe): number => {
    if (typeof a[property] === 'string' || a[property] instanceof Date) {
      return isAsc
        ? String(a[property]).localeCompare(String(b[property]))
        : String(b[property]).localeCompare(String(a[property]));
    } else {
      return isAsc ? Number(a[property]) - Number(b[property]) : Number(b[property]) - Number(a[property]);
    }
  };

  const toSort = (arr: Recipe[], compareFn: (a: Recipe, b: Recipe) => number): Recipe[] => {
    return arr.slice().sort(compareFn);
  };

  const handleSort = (orderByProp: keyof Recipe) => {
    const isAsc = orderBy === orderByProp && order === 'asc';
    const sortedData = toSort(recipes, comparator(orderByProp, isAsc));
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(orderByProp);
    setRecipes(sortedData);
  };

  const loadData = (page: number, searchBy: string) => {
    setIsLoading(true);
    const totalSkip = page * rowsPerPage;
    if (selectedStore) {
      getRecipes(searchBy, selectedStore.storeNumber, totalSkip, rowsPerPage).then((result) => {
        if (result?.data) {
          setRecipes(result.data);
          setTotalItems(result.totalCount ?? 0);
          setShowRecipeDialog(false);
        }
      })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setPage(0);
  };

  const onClear = () => {
    setPage(0);
    setSearchTerm('');
    loadData(0, '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleExportRecipes = (): void => {
    if (selectedStore?.storeNumber) {
      setIsExporting(true);
      exportRecipes(selectedStore?.storeNumber).then((response) => {
        if (response) {
          exportFile(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Recipes-${selectedStore?.storeNumber}.xls`);
        }
      }).catch((error) => {
        logError(error);
      }).finally(() => {
        setIsExporting(false);
        handleClose();
      });
    }
  };

  const onSubmit = (recipe: CreateMadeInStoreItemRequestDto) => {
    setIsUpdating(true);

    createMadeInStore(recipe)
      .then(() => {
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .finally(() => {
        setIsUpdating(false);
        setShowSubmitStatusMessage(true);
        loadData(page, searchTerm);
      });
  };

  const componentToUpsertComponent = (component: ItemComponent, itemId: number): UpsertItemComponent => {
    return {
      itemId: itemId,
      componentItemId: component.componentId,
      quantity: component.quantity,
      weight: component.weight,
      volume: component.volume,
    };
  };

  const onUpdate = (entityId: number, entityType: MadeInStoreType, added: ItemComponent[], updated: ItemComponent[], deleted: ItemComponent[]) => {
    const addComponentPromises = added.map((component) => addComponent(componentToUpsertComponent(component, entityId)));
    const updateComponentPromises = updated.map((component) => addComponent(componentToUpsertComponent(component, entityId)));
    const deleteComponentPromises = deleted.map((component) => component.componentId && deleteComponent(entityId, component.componentId));

    const allPromises = [...addComponentPromises, ...updateComponentPromises, ...deleteComponentPromises];

    Promise.all(allPromises)
      .then(() => {
        setIsSubmittedSuccessfully((prev) => prev + 1);
        setIsEditMode(false);
      })
      .catch(error => {
        logError(error);
        setErrorMessage(error);
      })
      .finally(() => {
        setIsUpdating(false);
        setShowSubmitStatusMessage(true);
        loadData(page, searchTerm);
      });
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignContent: 'center', my: '24px', py: '5px' }}>
      <Box sx={{ px:'12px' }}>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box mb={6}
            textAlign='left'
            flexDirection='row'
            alignItems='center'>
            <Typography variant='textLG'>{t('recipes')}</Typography>
            <Typography variant='textXS'
              sx={{ border: `1px solid ${theme.palette.primary[400]}`, py: 2, px: 3, mx: 3, borderRadius: '6px', color: theme.palette.common.white, background: theme.palette.primary[500] }}>
              {totalItems} {t('items')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, alignSelf: 'start', justifyContent: 'end' }}>
            <>
              <IconButton
                onClick={handleOpen}
              >
                <DotsVertical />
              </IconButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Typography
                  variant="textMD"
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  sx={{ p: 5, cursor: 'pointer', pointerEvents: isExporting ? 'none' : '' }}
                  onClick={handleExportRecipes}
                >
                Export Recipes
                  {isExporting && <Loader />}
                </Typography>
              </Popover>
            </>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          my={5}
        >
          <Grid item
            xs={12}
            sm={6}>
            <TextField
              label={t('search')}
              value={searchTerm}
              onChange={(event) => handleSearchChange(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item
            container
            xs={12}
            sm={6}
            spacing={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <Button
                onClick={onClear}
                variant='secondary'
                size='lg'
                sx={{ mr: 4 }}
              >
                <Typography>{t('clear')}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onSearch}
                size='lg'
                startIcon={<SvgIcon><Search /></SvgIcon>}>
                <Typography>{t('search')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {
        isLoading ? (
          <LoadingWheel />
        ) : (
          <>
            <RecipeList
              list={recipes}
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
              currencyCode={currencyCode}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={maxPages()}
              breakpointForChangeDisplay={120000}
            ></CustomPagination>
          </>
        )
      }
      <CreateRecipeDialog
        isUpdating={isUpdating}
        onCreateConfirm={onSubmit}
        onUpdateConfirm={onUpdate}
        isComplete={isSubmittedSuccessfully}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSubmitStatusMessage}
        onClose={() => setShowSubmitStatusMessage(false)}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowSubmitStatusMessage(false)}
          severity={isSubmittedSuccessfully ? 'success' : 'error'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {isSubmittedSuccessfully ? t('successMessage') : t('errorMessage') + ' ' + errorMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default RecipesTab;