import { Grid, Typography } from '@mui/material';
import NotificationsBox from './components/ManageNotificationsBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useEffect, useState } from 'react';
import { SuccessResponse_1OfSuccessResponse_1OfIEnumerable_1OfNotification, Notification2 } from '../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../hooks/useLocalStorage';
import useLogError from '../../hooks/useLogError';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const ManageNotifications = () => {
  const { getAllNotifications } = useNswagClient();
  const { t } = useTranslation('manageNotifications');
  const [notifications, setNotifications] = useState<Notification2[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStore, defaultStoreValue] =  useLocalStorage('selectedStore', null);
  const { logError } = useLogError();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (selectedStore != null){
      setIsLoading(true);
      getAllNotifications(selectedStore.storeNumber ?? defaultStoreValue).then((result: SuccessResponse_1OfSuccessResponse_1OfIEnumerable_1OfNotification) => {
        if (result?.data) {
          setNotifications(result.data as Notification2[] ?? []);
        }
      })
        .catch((error) => {
          logError(error);
        })
        .finally(() => (setIsLoading(false)));
    }
  };
  
  return <PageArea>
    <Grid container>
      <Grid item
        xs={12}
        sx={{ textAlign: 'left' }}>
        <PageTitle>{t('heading')}</PageTitle>
      </Grid>
      <Grid container
        item
        xs={12}>
        <Typography variant="textMD"
          sx={{ my: 4 }}>
          {t('subHeading')}
        </Typography>
      </Grid>
      <NotificationsBox notifications={notifications}
        loadData={loadData}
        isLoading={isLoading}></NotificationsBox>
    </Grid>
  </PageArea>;
};

export default ManageNotifications;