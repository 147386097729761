import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StockCheckItemValue } from '../../../app/services/api/generated';

type Props = {
  itemValues?: StockCheckItemValue[];
}

const UomDetailsTable: FC<Props> = ({ itemValues }) => {
  const { t } = useTranslation('stockCountReview');
  return (
    <Box display="flex"
      flexDirection="column"
      p={3}
      width="25%"
    >
      <Typography variant="textLG">{t('expandedDetail')}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('uoM')}</TableCell>
            <TableCell>{t('countQty')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemValues?.map((itemValue) => (
            <TableRow key={itemValue.id}>
              <TableCell>
                {itemValue.uom}
                {itemValue.uom !== 'EA' && <Typography variant="textSM">({itemValue.conversionToEach} EA)</Typography>}
              </TableCell>
              <TableCell>{itemValue.countQuantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UomDetailsTable;