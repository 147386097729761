import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { client,OutOfStockUrgentAction } from '../../../app/services/api/orderManagementClient';
import { Box, Typography } from '@mui/material';
import { TabEnum } from '../enums/NotificationEnums';
import useLogError from '../../../hooks/useLogError';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';

export interface NotificationsProps {
    storeNumber:  string | undefined; 
    tabEnum: number; 
    setReadToTrue: Dispatch<SetStateAction<boolean>>;
  }
const NotificationsUrgentActionRequired = (props: Readonly<NotificationsProps>) =>  {
  const [dataContext, setDataContext] = useState<OutOfStockUrgentAction>();
  const { logError } = useLogError();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');
  
  useEffect(() => {   
    if(props.tabEnum === TabEnum.UrgnetAlerts)
    {
      setLoading(true);
      client.GetUrgentAlerts(props.storeNumber).then((orderUrgentActionResult) => {
        setDataContext(orderUrgentActionResult.data);
      }).catch((error) => {
        logError('Error fetching notifications:' + error);
      }).finally(() => {
        setLoading(false);
        props.setReadToTrue(false);
      }); 
    }
  }, [props.tabEnum]);
  
  return (
    <Box>
      {loading ? <LoadingWheel/> : 
        <Typography>
          <div dangerouslySetInnerHTML={{ __html: dataContext?.context ?? t('notificationNoDataAvailable') }} /> 
        </Typography>} 
    </Box>
  );
};

export default NotificationsUrgentActionRequired;