import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Franchise, Persona, User, Store, District } from '../../../app/services/api/generated';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useLogError from '../../../hooks/useLogError';
import { UserPutPostRequest } from '../types/UserPutPostRequest';
import { isEmailValid } from '../../../utils';

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: Function;
  user: User | undefined;
  onCancel: () => void;
}

const CreateEditModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, user, onCancel }) => {
  const { t } = useTranslation('manageUsers');
  const { getFranchises, getPersonas, getDistrictsByFranchise, getFranchiseStores } = useNswagClient();
  const [request, setRequest] = useState<UserPutPostRequest | undefined>(undefined);
  const [franchiseList, setFranchiseList] = useState<Franchise[] | undefined>(undefined);
  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stores, setStores] = useState<Store[] | undefined>([]);
  const [districts, setDistricts] = useState<District[] | undefined>([]);
  const [personas, setPersonas] = useState<Persona[]>([]);
  const { logError } = useLogError();
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const isCreate = !user;

  useEffect(() => {
    getData();
  }, [user, openModal]);

  const getData = () => {
    const newRequest = {
      id: user?.id,
      districtId: user?.district?.id,
      emailAddress: user?.emailAddress,
      allStores: user?.allStores,
      personaId: user?.persona?.id,
      franchiseName: user?.franchiseName,
      stores: user?.stores?.map(x => x.storeNumber) as string[],
    };

    if (user?.allStores){
      setCheckedAllStores(true);
    }

    if (user?.district?.id && user?.franchiseName){
      getDistrictsByFranchiseName(user.franchiseName);
    }

    setRequest(newRequest);

    setIsLoading(true);
    if (user?.franchiseName != undefined){
      getPersonasByFranchiseName(user?.franchiseName);
      getStoresByFranchiseName(user?.franchiseName);
    }
    else {
      getFranchiseData();
      getPersonaData();
    }
    setIsLoading(false);

    setSelectedStores(user?.stores?.map(x => x.storeNumber) as string[] ?? []);
  };
 
  const getPersonaData = () => {
    getPersonas().then((result) => {
      if (result?.data) {
        setPersonas(result.data);
      }
    })
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  const getFranchiseData = () => {
    getFranchises().then((result) => {
      if (result?.data) {
        setFranchiseList(result.data);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (event.target.checked) setSelectedStores(stores?.map(option => option.storeNumber) as string[] || []);
    else setSelectedStores([]);

    setCheckedAllStores(checked);
  };

  const getPersonasByFranchiseName = (franchiseName: string) => {
    getPersonas(franchiseName).then((result) => {
      if (result?.data) {
        setPersonas(result.data);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  const getDistrictsByFranchiseName = (franchiseName: string) => {
    getDistrictsByFranchise(franchiseName).then((result) => {
      if (result?.data) {
        let validDistricts = result?.data as District[];
        validDistricts = validDistricts.filter(x => x.franchiseName == franchiseName);
        setDistricts(validDistricts);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  const getStoresByFranchiseName = (franchiseName: string) => {
    getFranchiseStores(franchiseName).then((result) => {
      if (result?.data) {
        setStores(result.data?.stores);
      }})
      .catch((error) => {
        logError(error);
      }).finally(() => (setIsLoading(false)));
  };

  const handleCancel = () => {
    onCancel();
    setRequest(undefined);
  };

  const handleConfirm = async() => {
    const emailValid = await validateEmail(request?.emailAddress ?? '');

    if (emailValid){
      const newRequest = {
        ...request,
        stores: request?.districtId ? null : selectedStores,
        allStores: request?.districtId ? false : checkedAllStores,
      };
      onConfirm(newRequest);
      setRequest(undefined);
      onCancel();
    }
  };

  const handleFranchiseChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setRequest({ ...request, franchiseName: value, personaId: undefined, districtId: undefined, stores: undefined });
    getPersonasByFranchiseName(value);
    getDistrictsByFranchiseName(value);
    getStoresByFranchiseName(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const handleSelectChange = (event: SelectChangeEvent<number | string>) => {
    const { name, value } = event.target;

    if (name == 'districtId'){
      if (value != undefined){
        setSelectedStores([]);
      }
    }
    setRequest({ ...request, [name]: value });
  };

  const handleStoreChange = (event: React.ChangeEvent<{}>, newValue: Store[]) => {
    const selectedIds = newValue.map(option => option.storeNumber);
    setSelectedStores(selectedIds as string[]);
    setRequest({ ...request, districtId: selectedStores.length == 0 ? user?.district?.id : undefined });
  };

  const validateEmail = (email: string) => {
    const emailValid =  isEmailValid(email);
    return emailValid;
  };

  const formItemStyling = { display: 'flex', flexDirection: 'column', alignItems: 'left'  };
  const isValid = request?.emailAddress != undefined && request?.emailAddress.length > 0 && 
  request?.personaId != undefined && request?.franchiseName != undefined && (request?.districtId != undefined ? request?.districtId > 0 : selectedStores.length > 0 || checkedAllStores);
  return isLoading ? <LoadingWheel></LoadingWheel> : (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column', maxWidth: '450px' }}>
        <Grid
          container
          spacing={6}
          alignItems='self-end'
          p={6}
        >
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('email')}</Typography>
            <TextField
              fullWidth
              required
              name='emailAddress'
              placeholder='Email'
              id="input-with-icon-textfield"
              value={request?.emailAddress}
              disabled={!isCreate}
              onChange={handleInputChange}
            />
          </Grid>
          {isCreate &&
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('franchise')}</Typography>
            <FormControl>
              <Select
                name='franchiseName'
                value={request?.franchiseName}
                onChange={handleFranchiseChange}
                fullWidth
                required
              >
                {franchiseList && franchiseList.length > 0 && franchiseList.map((f) => (
                  <MenuItem key={f.franchiseId}
                    value={f.franchiseName}>{f.franchiseName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          }
          {districts && districts.length > 0 &&
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('district')}</Typography>
            <FormControl>
              <Select
                name='districtId'
                value={request?.districtId}
                onChange={handleSelectChange}
                fullWidth
                required
                defaultValue={undefined}
              >
                <MenuItem
                  value={undefined}>{t('none')}</MenuItem>
                {districts.map((f) => (
                  <MenuItem key={f.id}
                    value={f.id}>{f.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          }
          {request?.franchiseName != undefined && request?.franchiseName.length > 0 &&
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('role')}</Typography>
            <FormControl>
              <Select
                name='personaId'
                value={request?.personaId}
                onChange={handleSelectChange}
                fullWidth
                required
              >
                {personas && personas.length > 0 && personas.map((f) => (
                  <MenuItem key={f.id}
                    value={f.id}>{f.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          }
          {!checkedAllStores &&
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('stores')}</Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                disabled={(request?.districtId != undefined && request?.districtId > 0) || request?.franchiseName == undefined}
                multiple
                options={stores || []}
                value={stores?.filter(option => selectedStores?.includes(option.storeNumber ?? '')) ?? []}
                onChange={handleStoreChange}
                getOptionKey={(option) => option.storeNumber ?? 0}
                getOptionLabel={(option) => option.storeName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    required={selectedStores.length === 0 && !checkedAllStores}/>
                )}
              />
            </FormControl>
          </Grid>
          }
          {!request?.districtId &&
          <Grid item
            sm={6}
            xs={12}>
            <FormGroup style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={checkedAllStores}
                  onChange={handleSelectAllChange} />}
                label={t('allStores')}
              />
            </FormGroup>
          </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          disabled={!isValid}
          onClick={handleConfirm}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateEditModal;