export const enum DayOffsets {
    NONE = 0,
    ONEDAY = -1,
    TWODAYs = -2,
    THREEDAYs = -3,
}

export const enum StockCountScheduleType {
    FREQUENCY_AND_INTERVAL,
    MONTHLY_DATE,
    LAST_DAY_OF_MONTH,
    ONE_TIME
}