import { Grid, Typography } from '@mui/material';
import CategoriesBox from './components/ManageCategoriesBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useEffect, useState } from 'react';
import { SuccessResponse_1OfList_1OfPrimaryCategoryStockCount, PrimaryCategoryStockCount } from '../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../hooks/useLocalStorage';
import useLogError from '../../hooks/useLogError';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { ConfigurationName } from '../../enums/ConfigurationName';

const ManageCategories = () => {
  const { getCategories, getConfiguration } = useNswagClient();
  const { t } = useTranslation('manageCategories');
  const [categories, setCategories] = useState<PrimaryCategoryStockCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStore, defaultStoreValue] =  useLocalStorage('selectedStore', null);
  const [isDailyWasteConfigEnabled, setIsDailyWasteConfigEnabled] = useState<boolean>(false);
  const [isSalesConfigEnabled, setIsSalesConfigEnabled] = useState<boolean>(false);
  const { logError } = useLogError();

  useEffect(() => {
    loadData();
    checkConfig();
  }, []);

  const loadData = () => {
    if (selectedStore != null){
      setIsLoading(true);
      getCategories(selectedStore.storeNumber ?? defaultStoreValue).then((result: SuccessResponse_1OfList_1OfPrimaryCategoryStockCount) => {
        if (result?.data) {
          setCategories(result.data);
        }
      })
        .catch((error) => {
          logError(error);
        })
        .finally(() => (setIsLoading(false)));
    }
  };

  const checkConfig = () => {
    getConfiguration(ConfigurationName.DailyWasteCheckSheetEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((config: any) => {
        if (config.data.value === 'true') {
          setIsDailyWasteConfigEnabled(true);
        }
      })
      .catch((e) => logError(e));

    getConfiguration(ConfigurationName.SalesForecastEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((config: any) => {
        if (config.data.value === 'true') {
          setIsSalesConfigEnabled(true);
        }
      })
      .catch((e) => logError(e));
  };

  return <PageArea>
    <Grid container>
      <Grid item
        xs={12}
        sx={{ textAlign: 'left' }}>
        <PageTitle>{t('heading')}</PageTitle>
      </Grid>
      <Grid container
        item
        xs={12}>
        <Typography variant="textMD"
          sx={{ my: 4 }}>
          {t('subHeading')}
        </Typography>
      </Grid>
      <CategoriesBox categories={categories}
        isDailyWasteConfigEnabled={isDailyWasteConfigEnabled}
        isSalesConfigEnabled={isSalesConfigEnabled}
        loadData={loadData}
        isLoading={isLoading}></CategoriesBox>
    </Grid>
  </PageArea>;
};

export default ManageCategories;