import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { InfoCircle, X } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import { ReportNavItem } from './ReportTypesData';
import { cloneElement } from 'react';

export interface ReportDialogProps {
  open: boolean;
  reportItem: ReportNavItem;
  onClose: () => void;
}

const ReportDialog = (props: ReportDialogProps) => {
  const { t } = useTranslation('common');
  const { reportItem } = { ...props };

  const formContent = reportItem.form ? (
    cloneElement(reportItem.form, {
      onClose: props.onClose,
    })
  ) : null;

  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ width: '20px', mr: '5px' }}>{reportItem.icon}</SvgIcon>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>{t(reportItem.title)}
          <Tooltip title={t(reportItem.info)}>
            <SvgIcon sx={{ width: '20px', ml: '5px' }}><InfoCircle color={theme.palette.custom.blue[500]} /></SvgIcon>
          </Tooltip>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            p: 0,
            color: (theme) => theme.palette.custom.gray[500],
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {formContent}
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialog;