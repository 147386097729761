import { useContext } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { CreateStockTransfersHeader } from './CreateStockTransfersHeader';
import { CreateStockTransfersButtons } from './CreateStockTransfersButtons';
import { CreateStockTransfersTopForms } from './CreateStockTransfersTopForms';
import { CreateStockTransfersItemsTable } from './CreateStockTransfersItemTable';
import { CreateStockTransfersNote } from './CreateStockTransfersNote';

export const MainDialog = () => {
  const { onCancel, openModal } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      onClose={onCancel}
      open={openModal}
    >
      <CreateStockTransfersHeader />
      <DialogContent sx={{ pb: 0 }}>
        <Grid container
          spacing={5}>
          <CreateStockTransfersTopForms />
          <CreateStockTransfersItemsTable />
          <CreateStockTransfersNote />
        </Grid>
      </DialogContent>
      <CreateStockTransfersButtons />
    </Dialog>
  );
};

