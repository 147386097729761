import { createContext, useContext, useState, useMemo, Dispatch, SetStateAction } from 'react';

interface ProductDetailsContextProps {
  resetMinimumQuantity: () => void,
  minimumQuantity: string,
  setMinimumQuantity: Dispatch<SetStateAction<string>>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const initialContextState: ProductDetailsContextProps = {
  resetMinimumQuantity: () => {},
  minimumQuantity: '',
  setMinimumQuantity: () => {},
  isEditMode: false,
  setIsEditMode: () => {},
  isValid: true,
  setIsValid: () => {},
};

const ProductDetailsContext = createContext<ProductDetailsContextProps>(initialContextState);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProductDetailsProvider = ({ children }: any) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [minimumQuantity, setMinimumQuantity] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const contextValue = useMemo(() => {
    const resetMinimumQuantity = () => {
      setMinimumQuantity('');
    };

    return {
      minimumQuantity,
      setMinimumQuantity,
      resetMinimumQuantity,
      isEditMode,
      setIsEditMode,
      isValid,
      setIsValid,
    };

  }, [isEditMode, minimumQuantity, isValid]);

  return (
    <ProductDetailsContext.Provider value={contextValue}>{children}</ProductDetailsContext.Provider>
  );
};

const useProductDetailsContext = () => {
  const context = useContext(ProductDetailsContext);

  return context;
};

export { ProductDetailsProvider, useProductDetailsContext };
