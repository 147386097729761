import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Trash01 } from '../../../assets';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
import { PrimaryCategoryStockCount } from '../../../app/services/api/generated';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: (substituteId: number) => void;
  onCancel: () => void;
  list: PrimaryCategoryStockCount[]
}

const DeleteModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, onCancel, list }) => {
  const { t } = useTranslation('manageCategories');
  const [substituteId, setSubstituteId] = React.useState<number>(0);

  const handleConfirm = () => {
    onConfirm(substituteId);
    onCancel();
  };

  return (
    <Dialog
      onClose={() => onCancel()}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('deleteCategory')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column', maxWidth: '450px' }}>
        <Grid
          container
          spacing={6}
          alignItems='self-end'
          p={6}
        >
          <Grid item
            xs={12}>
            <Typography variant='textSM'
            >
              {t('substitute')}
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                value={list.find(x => x.id == substituteId)}
                options={list}
                onChange={(event, newValue) => {
                  setSubstituteId(newValue?.id ?? 0);
                }}
                getOptionKey={(option) => option.id ?? 0}
                getOptionLabel={(option) => option.primaryCategoryName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    placeholder={t('primaryCategory')}
                    required/>
                )}
              />
            </FormControl>
          </Grid>
          <Typography variant="textMD"
            sx={{ my: 4, mx: 6 }}>
            {t('deleteConfirm')}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
        <Button
          variant='bad'
          size="lg"
          disabled={substituteId <= 0}
          onClick={handleConfirm}
          startIcon={<SvgIcon><Trash01 /></SvgIcon>}>
          {t('confirmDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteModal;