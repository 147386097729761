/* eslint-disable @typescript-eslint/no-explicit-any */
const useLogError = () => {

  const logError = (error: any): void => {
    try {
      console.log(error);
    } catch (e: any) {
      console.log(e);        
    }
  };

  return { logError };
};

export default useLogError;