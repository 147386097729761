import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useContext } from 'react';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';

export function ConfirmModal() {
  const { t, closesAcceptModal,  isOpenModal, handleAcceptClick, acceptId } = useContext(InvoicesContext) as CreateInvoicesValues;

 

  return (
    <Dialog onClose={closesAcceptModal}
      open={isOpenModal}>
      <DialogTitle> {t('modalHeader')}</DialogTitle>
      <DialogContent sx={{ pt:'4px' }}>
        {t('modalTitle')}
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={closesAcceptModal}>
          {t('close')}
        </Button>
        <Button variant='primary'
          size="lg"
          onClick={() => handleAcceptClick(acceptId ?? -1)}>
          {t('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
