import useLocalStorage from './useLocalStorage';

type LanguageSwitcher = {
	switchLanguage: (isoCode?: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLanguageSwitcher = (i18n: any, callback: () => void): LanguageSwitcher => {
  const [ storedValue, setValue ] = useLocalStorage('language', '');

  const switchLanguage = (isoCode?: string) => {
    if (isoCode) {
      setValue(isoCode);
      i18n.changeLanguage(isoCode);
      callback();
    }
  };

  return {
    switchLanguage,
  };
};
