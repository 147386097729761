import { MuiButton } from './MuiButton';
import { MuiDrawer } from './MuiDrawer';
import { MuiTooltip } from './MuiTooltip';
import { MuiTab, MuiTabs } from './MuiTabs';
import { MuiTable, MuiTableCell, MuiTableRow } from './MuiTable';
import { MuiDateCalendar } from './MuiDateCalendar';

const MuiAlert = {
  defaultProps: {
    variant: 'outlined',
  },
};

const MuiFormField = {
  defaultProps: {
    size: 'small',
  },
};

const MuiTextField = {
  defaultProps: {
    size: 'small',
  },
};

const MuiSelect = {
  defaultProps: {
    size: 'small',
  },
};

export const components = {
  MuiTooltip: MuiTooltip,
  MuiButton: MuiButton,
  MuiTabs: MuiTabs,
  MuiTab: MuiTab,
  MuiDrawer: MuiDrawer,
  MuiTable: MuiTable,
  MuiTableRow: MuiTableRow,
  MuiTableCell: MuiTableCell,
  MuiAlert: MuiAlert,
  MuiFormField: MuiFormField,
  MuiTextField: MuiTextField,
  MuiSelect: MuiSelect,
  MuiDateCalendar: MuiDateCalendar,
};