import { Autocomplete, Checkbox, Grid, PaletteColor, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import DenseTable from './CountTable';
import { RAGRecord } from '../types/RAGRecord';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { formatDateString } from '../../../utils';

import { useMemo, useState } from 'react';
interface OptionType {
  id: string | undefined;
  name: string| undefined;
}

const TitleArea = (text: string | JSX.Element) => {
  return (
    <Box
      sx={{
        display: 'flex',
        py: '20px',
        px: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
      }}
    >
      <Typography
        variant="textLG"
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      >
        {text}
      </Typography>
    </Box>);
};



const CountDropDownBox = (props: {
  text: string;
  palette: PaletteColor;
  list: RAGRecord[] | undefined;
}) => {

  const { t } = useTranslation('common');
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [previousSelectedDates, setPreviousSelectedDates] = useState<string[]>([]);
  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [previousSelectedStores, setPreviousSelectedStores] = useState<string[]>([]);

  const stockStatusBoxes = ['Pending', 'Counting', 'Completed'];

  const uniqueDates = useMemo(() => {
    // Get unique dates from the list
    const dates = Array.from(new Set(props.list?.map(item => item.itemDate).filter(date => date !== undefined)));
  
    // Format each date using formatDateString
    return dates.map(date => formatDateString(date ?? ''));
  }, [props.list]);

  const uniqueStores = useMemo(() => {
    return Array.from(new Set(props.list?.map(item => item.storeName).filter(store => store !== undefined)));
  }, [props.list]);

  const autocompleteDateOptions = useMemo(() => [
    { id: '-1', name: 'All' },
    ...uniqueDates.map(date => ({ id: date, name: date })),
  ], [uniqueDates]);

  const autocompleteStoreOptions = useMemo(() => [
    { id: '-1', name: 'All' },
    ...uniqueStores.map(store => ({ id: store, name: store })),
  ], [uniqueStores]);

  const filteredList = useMemo(() => {
    if (!props.list || !stockStatusBoxes.includes(props.text)) return props.list || [];

    return props.list.filter(item => {
      const itemDate = formatDateString(item.itemDate ?? '');
      const itemStore = item.storeName ?? '';

      const matchesDate = selectedDates.length === 0 || selectedDates.includes('-1') || selectedDates.includes(itemDate);
      const matchesStore = selectedStores.length === 0 || selectedStores.includes('-1') || selectedStores.includes(itemStore);

      return matchesDate && matchesStore;
    });
  }, [props.list, selectedDates, selectedStores, props.text]);

  const handleSelectionChange = (
    event: React.ChangeEvent<{}>,
    newValue: OptionType[],
    autocompleteOptions: OptionType[],
    setSelectedItems: (items: string[]) => void,
    previousSelectedItems: string[],
    setPreviousSelectedItems: (items: string[]) => void,
  ) => {
    let selectedIds = newValue.map(option => option.id!);
    const isAllSelected = selectedIds.includes('-1');
  
    if (isAllSelected && selectedIds.length < autocompleteOptions.length - 1) {
      // If "All" is selected, select everything 
      setSelectedItems(autocompleteOptions.map(option => option.id!));
    } 
    else if (isAllSelected && selectedIds.length === autocompleteOptions.length - 1) {
      // If previously "All" was selected and one individual option is unselected, unselect "All"
      selectedIds = selectedIds.filter(id => id !== '-1');
      setSelectedItems(selectedIds);
    } 
    else if (!isAllSelected && selectedIds.length === autocompleteOptions.length - 1 && !previousSelectedItems.includes('-1')) {
      // If all the data are selected, select "All"
      selectedIds = [...selectedIds, '-1'];
      setSelectedItems(selectedIds);
    } 
    else if (!isAllSelected && previousSelectedItems.includes('-1')) {
      // If previously "All" was selected and now is unselected, unselect select all options
      setSelectedItems([]);
    } 
    else {
      // Normal selection or deselection
      setSelectedItems(selectedIds);
    }
  
    setPreviousSelectedItems(selectedIds);
  };

  const handleStoreChange = (event: React.ChangeEvent<{}>, newValue: OptionType[]) => {
    handleSelectionChange(
      event,
      newValue,
      autocompleteStoreOptions,
      setSelectedStores,
      previousSelectedStores,
      setPreviousSelectedStores,
    );
  };
  
  const handleDateChange = (event: React.ChangeEvent<{}>, newValue: OptionType[]) => {
    handleSelectionChange(
      event,
      newValue,
      autocompleteDateOptions,
      setSelectedDates,
      previousSelectedDates,
      setPreviousSelectedDates,
    );
  };
 
  let titleAreaContent;
  if (props.list === undefined) {
    titleAreaContent = TitleArea(t('Loading') + '...');
  } else if (props.list?.length === 0) {
    titleAreaContent = TitleArea(t('noData'));
  } else {
    titleAreaContent = (
      <>
        <Box
          sx={{
            display: 'flex',
            py: '20px',
            px: '16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
          }}
        >
          <Typography
            variant="textLG"
            sx={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
            }}
          >
            {props.text}
            <Box
              sx={{
                display: 'flex',
                p: '2px 8px',
                alignItems: 'center',
                borderRadius: '16px',
                border: '1px solid ',
                borderColor: props.palette[200],
                background: props.palette[50],
              }}
            >
              <Typography variant="textXS"
                sx={{ color: props.palette[700] }}>
                {props.list?.length} {t('items')}
              </Typography>
            </Box>
          </Typography>
          {stockStatusBoxes.includes(props.text) && (
            <Grid container
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}>
              <Grid item
                md={6}
                sm={2}
                xs={6}
                sx={{ mt: { xs: 3, sm:3 } }}
              >
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  disableCloseOnSelect
                  options={autocompleteDateOptions}
                  getOptionLabel={(option) => option.name}
                  value={autocompleteDateOptions.filter(option => selectedDates.includes(option.id))}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={t('Date')}
                      placeholder={t('choose')} />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected}
                        sx={{ mr: 1 }} />
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
              <Grid item
                md={6}
                sm={2}
                xs={6}
                sx={{  width: '100%', overflowX:'hidden', overflowY:'auto', maxHeight:'10rem', mt: { xs: 3, sm:3 } }}>

                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  disableCloseOnSelect
                  options={autocompleteStoreOptions}
                  getOptionLabel={(option) => option.name!}
                  value={autocompleteStoreOptions.filter(option => selectedStores.includes(option.id!))}
                  onChange={handleStoreChange}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={t('Store')}
                      placeholder={t('choose')} />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected}
                        sx={{ mr: 1 }} />
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <DenseTable list={filteredList} />
      </>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        borderTopColor: props.palette[400],
        borderTopWidth: '4px',
        borderTopStyle: 'solid',
        borderRadius: '0px 0px 40px 40px',
      }}
    >
      {titleAreaContent}
    </Box>
  );
};

export default CountDropDownBox;