import {  Button, Grid } from '@mui/material';
import { Save01 } from '../../../assets';
import { useContext } from 'react';
import { StockCountCreateContext, StockCountCreateValues } from '../contexts/StockCountCreateContext';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';

const StockCountCreateTop=() => {
  const { t,
    hasCategories,
    hasItems,
    isSmartCount,
    handleLinkClick,
    submitStock,
    isSubmitting,
    name } = useContext(StockCountCreateContext) as StockCountCreateValues;

  return (
    <Grid container>
      <Grid item 
        xs={12}>
        <BackButton 
          handleClick={handleLinkClick} 
          title={t('exit')}
          isLink={false}
        />
      </Grid>
      <Grid item
        xs={12}
        sx={{ display:'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Grid item
          sx={{ marginRight: '20px' }}>
          <PageTitle>{t('title')}</PageTitle>
        </Grid>
        <Button size='lg'
          disabled={!name || (!hasItems && !hasCategories && !isSmartCount) || isSubmitting}
          onClick={submitStock}>
          <Save01/>{t('save')} 
        </Button>
      </Grid>
    </Grid>
  );
};

export default StockCountCreateTop;