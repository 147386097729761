import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Save01, Lock01, LockUnlocked01, Edit05, Plus } from '../../../assets';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';

const PurchaseOrdersDetailsTop = () => {
  const { saveClicked,
    addItemClicked,
    isReceiving,
    isLoading,
    showAdjustButton,
    isProcessing,
    adjustClicked,
    showSave,
    purchaseOrders,
    canStoreAddItemsToPO } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  const navigate = useNavigate();
  const { t } = useTranslation('purchaseOrderDetails');

  return <>
    <BackButton
      handleClick={() => navigate('/store/po-receipt')}
      title={t('goBack')}
      isLink={false}
      styles={{ marginBottom: '20px' }}
    />
    <PageTitle>
      {t('title')}: {purchaseOrders?.orderNumber}
    </PageTitle>
    {isLoading ? <LoadingWheel /> :
      <Grid container
        sx={{ pb: '8px' }}>
        <Grid item
          xs={6}>
          <Stack spacing={3}>
            <Box>
              <Typography variant='textLG'>
                {t('status')}: {isReceiving ?
                  <>{t('received')} <SvgIcon sx={{ verticalAlign: '-10%', fontSize: 'inherit' }}><Lock01 /></SvgIcon></>
                  :
                  <>{t('notReceived')} <SvgIcon sx={{ verticalAlign: '-10%', fontSize: 'inherit' }}><LockUnlocked01 /></SvgIcon></>}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item
          sx={{ textAlign: 'right' }}
          xs={6}>
          {showSave && canStoreAddItemsToPO &&
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom', mr: '4px' }}
              onClick={addItemClicked}
              disabled={isProcessing}
              startIcon={<SvgIcon> <Plus /> </SvgIcon>}>
              {t('addItem')}
            </Button>
          }
          {showSave &&
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom' }}
              onClick={saveClicked}
              disabled={isProcessing}
              startIcon={<SvgIcon> <Save01 /> </SvgIcon>}>
              {t('save')}
            </Button>
          }
          {showAdjustButton &&
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom' }}
              onClick={adjustClicked}
              disabled={isProcessing}
              startIcon={<SvgIcon> <Edit05 /> </SvgIcon>}>
              {t('adjustBtn')}
            </Button>
          }
        </Grid>
      </Grid>}
  </>;
};

export default PurchaseOrdersDetailsTop;