
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { theme } from '../../../../theme';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';

export const NavItem = (props: {item: DrawerNavItem, onClick: (() => void) | undefined}) => {
  const { t } = useTranslation('drawer');
  const { pathname, search } = useLocation();
  const { isHighLighted } = useContext(DrawerContext) as DrawerValues;
  const showAsHighlighted = isHighLighted(props.item, pathname, search);

  if (!props.item.canRender) {
    return <></>;
  }

  return (
    <ListItem
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        borderRadius: '4px',
        p: 0,
        pr: 6,
        backgroundColor: showAsHighlighted ? theme.palette.primary[400] : 'transparent',
      }}
    >
      <ListItemButton onClick={props.onClick}>
        <Box display='flex'
          flexDirection='row'>
          <ListItemIcon
            sx={{
              minWidth: 0,
              fontSize: 8,
              color: `${showAsHighlighted ? 'common.white' : 'primary.light'}`,
            }}
          >
            {props.item.variant == 'customIcon' ? props.item.icon : <SvgIcon>{props.item.icon}</SvgIcon>}
          </ListItemIcon>
          <ListItemText
            primary={t(props.item.translationKey ?? '')}
            primaryTypographyProps={{
              color: `${showAsHighlighted ? theme.palette.common.white : theme.palette.primary.contrastText}`,
              fontSize: 12,
              pl: 8,
            }}
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};