import { Box, Grid, IconButton, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import useLogError from '../../../hooks/useLogError';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { exportFile, formatDateString } from '../../../utils';
import { UserContext } from '../../../components/shared/useUser';
import { DotsVertical } from '../../../assets';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../../components/ui/Loader';
import { theme } from '../../../theme';
import { RequestItemHistoryReportRequest } from '../../../app/services/api/generated';

interface ItemDailyHistory {
  date: string;
  dayPrice?: number;
  opening?: number;
  receipts?: number;
  madeInStore?: number;
  transferIn?: number;
  transferOut?: number;
  sales?: number;
  waste?: number;
  theoUsage?: number;
  theoClosing?: number;
  inventory?: number;
  closingVar?: number;
  returnToVendor?: number;
}

type Props = {
  currencyCode?: string;
}

const History: FC<Props> = ({ currencyCode }) => {
  const { t } = useTranslation('productDetails');
  const [searchParams] = useSearchParams();
  const sku = searchParams.get('sku');
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const [hasPermissionToReadMadeInStore, setHasPermissionToReadMadeInStore] = useState<boolean>(false);
  const { logError } = useLogError();
  const { getItemDailyHistory, getItemHistoryReportById } = useNswagClient();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [data, setData] = useState<ItemDailyHistory[]>([]);
  const [fromDate, setFromDate] = useState<string>(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  useEffect(() => {
    if (selectedStore) {
      loadData(fromDate, toDate);
    }
    setHasPermissionToReadMadeInStore(hasPermissionTo(['MadeInStoreRead']));
  }, []);

  const loadData = (from: string, to: string) => {
    if (!from || !to) {
      return;
    }
    if (sku && selectedStore?.storeNumber) {
      setLoading(true);

      getItemDailyHistory(sku, selectedStore?.storeNumber, from, to)
        .then((result) => {
          setData(result.data ?? []);
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setLoading(false));
    }
  };
  
  const getMaxDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
  };

  const getMinDate = () => {
    const minDate = new Date('1900-01-01').toISOString().split('T')[0];
    return minDate;
  };

  const handleFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (new Date(value) < new Date(toDate)) {
      setFromDate(event.target.value);
      loadData(event.target.value, toDate);
    }
  };

  const handleToDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (new Date(value) > new Date(fromDate)) {
      setToDate(event.target.value);
      loadData(fromDate, event.target.value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onDownload = (): void => {
    if (sku) {
      setIsDownloading(true);
      const requestBody: RequestItemHistoryReportRequest = {
        itemId: sku,
        storeNumber: selectedStore?.storeNumber,
        from: fromDate,
        to: toDate,
      };
    
      getItemHistoryReportById(requestBody).then((response) => {
        if (response) {
          exportFile(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Item History Report - ${selectedStore?.storeNumber}.xlsx`);
        }
      }).catch((error) => {
        logError(error);
      }).finally(() => {
        setIsDownloading(false);
        handleClose();
      });
    }
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', borderRadius: '5px', width: '100%', height: '100%', my: 5, px: 5, pb: 5 }}>
      <Grid container
        py={5}>
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={handleClick}
          >
            <DotsVertical />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box p={4}
              sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="textSM"
                display='flex'
                flexDirection='row'
                alignItems='center'
                sx={{ p: 3, cursor: 'pointer', pointerEvents: isDownloading ? 'none' : '' }}
                onClick={onDownload}
              >
                {t('historyTab.downloadFullReport')}
                {isDownloading && <Loader />}
              </Typography>
            </Box>
          </Popover>
        </Grid>
        <Grid item
          md={3}
          xs={12}>
          <TextField
            label={t('historyTab.startDate')}
            type="date"
            required
            value={fromDate}
            onChange={handleFromDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: getMinDate(), max: toDate != '' ? toDate : getMaxDate() } }}
            fullWidth
          />
        </Grid>
        <Grid item
          sx={{ ml: isMobile ? '' : 3, mt: isMobile ? 5 : '' }}
          md={3}
          xs={12}>
          <TextField
            label={t('historyTab.endDate')}
            type="date"
            required
            value={toDate}
            onChange={handleToDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: fromDate != '' ? fromDate : getMinDate(), max: getMaxDate() } }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item
        mt={5}
        xs={12}>
        {
          loading ? <LoadingWheel /> : (
            <TableContainer>
              <Table>
                <TableHead
                  sx={{ paddingTop: '10px' }}>
                  <TableRow>
                    <Tooltip title={t('historyTab.dateTooltip')}>
                      <TableCell>
                        {t('historyTab.date')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.dayPriceTooltip')}>
                      <TableCell>
                        {t('historyTab.dayPrice')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.openingTooltip')}>
                      <TableCell>
                        {t('historyTab.opening')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.receiptsTooltip')}>
                      <TableCell>
                        {t('historyTab.receipts')}
                      </TableCell>
                    </Tooltip>
                    {hasPermissionToReadMadeInStore && <Tooltip title={t('historyTab.madeInStoreTooltip')}>
                      <TableCell>
                        {t('historyTab.madeInStore')}
                      </TableCell>
                    </Tooltip>}
                    <Tooltip title={t('historyTab.transferInTooltip')}>
                      <TableCell>
                        {t('historyTab.transferIn')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.transferOutTooltip')}>
                      <TableCell>
                        {t('historyTab.transferOut')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.wasteTooltip')}>
                      <TableCell>
                        {t('historyTab.waste')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.returnToStockTooltip')}>
                      <TableCell>
                        {t('historyTab.returnToStock')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.usageTooltip')}>
                      <TableCell>
                        {t('historyTab.usage')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.theoClosingTooltip')}>
                      <TableCell>
                        {t('historyTab.theoClosing')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.inventoryTooltip')}>
                      <TableCell>
                        {t('historyTab.inventory')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={t('historyTab.closingVarTooltip')}>
                      <TableCell>
                        {t('historyTab.closingVar')}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data?.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={11}>
                          <Typography variant="textSM">{t('noData')}</Typography>
                        </TableCell>
                      </TableRow> : 
                      data?.map((item: ItemDailyHistory) => (
                        <TableRow key={item.date}>
                          <TableCell>
                            {formatDateString(item.date)}
                          </TableCell>
                          <TableCell>
                            <RoundAndFormatValue
                              value={item?.dayPrice ?? 0}
                              currencyCode={currencyCode}
                            />
                          </TableCell>
                          <TableCell>
                            {item?.opening?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.receipts?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          {hasPermissionToReadMadeInStore && <TableCell>
                            {item?.madeInStore?.toFixed(2) ?? 'N/A'}
                          </TableCell>}
                          <TableCell>
                            {item?.transferIn?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.transferOut?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.waste?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.returnToVendor?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.theoUsage?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.theoClosing?.toFixed(2) ?? 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item?.inventory?.toFixed(2) ?? ''}
                          </TableCell>
                          <TableCell>
                            {item?.closingVar?.toFixed(2) ?? ''}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </Grid>
    </Paper>
  );
};

export default History;
