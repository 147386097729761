export function isString(x: unknown): x is string {
  return typeof x === 'string' || x instanceof String;
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number' || x instanceof Number;
}
export const enum Reason {
    SHORTAGE = 1,
    DAMAGE = 2,
    EXPIRED_STALE_PRODUCT = 3,
    OUT_OF_TEMPERATURE = 4, // no longer valid but old data may still have this value
    OVERAGE = 5
}

export const creditRequestReasons = [
  Reason.SHORTAGE,
  Reason.DAMAGE,
  Reason.EXPIRED_STALE_PRODUCT,
  Reason.OUT_OF_TEMPERATURE,
  Reason.OVERAGE,
];

export const creditRequestReasonsTranslationMap = {
  [Reason.SHORTAGE]: 'creditRequestReasons.shortage',
  [Reason.DAMAGE]: 'creditRequestReasons.damage',
  [Reason.EXPIRED_STALE_PRODUCT]: 'creditRequestReasons.expired_stale_product',
  [Reason.OUT_OF_TEMPERATURE]: 'creditRequestReasons.out_of_temperature',
  [Reason.OVERAGE]: 'creditRequestReasons.overage',
};

export function parseReason(value: Reason | number | string): Reason {
  if (isString(value)) {
    value = parseInt(value) as Reason;
  }

  if (isNumber(value)) {
    value = value as Reason;
  }

  return value;
}