import { Box, CircularProgress } from '@mui/material';

export const Loader = () => {
  return (
    <Box sx={{ ml: 3 }}>
      <CircularProgress
        size={15}
        thickness={5}
        color='info'
        sx={{
          '& .${circularProgressClasses.circle}': {
            strokeLinecap: 'round',
          },
        }}
      ></CircularProgress>
    </Box>
  );
};