import { Box, Button, FormControl, FormControlLabel, Grid, Stack, SvgIcon, Switch, TextField } from '@mui/material';
import { SearchSm } from '../../.././../../assets';
import React, { useEffect } from 'react';
import { HistoryContext, HistoryValues } from '../../../contexts/HistoryContext';

export default function PendingChangesFilters() {
  const { t, LoadData, page, searchTerm, handleSearchChange, handleClearButtonClick, 
    handleSearchButtonClick, handleAppliedSwitchChange, handleDisregardedSwitchChange, 
    appliedChecked, disregardedChecked } = React.useContext(HistoryContext) as HistoryValues;
  
  useEffect(() => LoadData(), [page]);

  return (
    <Box sx={{ px: '8px', pb: '8px', width: '100%' }}>
      <Grid container
        spacing='8px'
        direction="row"
        justifyContent="start"
        alignItems="center">
        <Grid item
          xs={12}>
          <FormControl fullWidth>
            <TextField label={t('search')}
              size='small'
              id="input-with-icon-textfield"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={6}
          xs={12}>
          <FormControl size='small'>
            <FormControlLabel control={<Switch
              checked={appliedChecked}
              onChange={handleAppliedSwitchChange} />}
            labelPlacement='start'
            label={t('applied')} />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={6}
          xs={12}>
          <FormControl size='small'>
            <FormControlLabel control={<Switch
              checked={disregardedChecked}
              onChange={handleDisregardedSwitchChange} />}
            labelPlacement='start'
            label={t('disregarded')} />
          </FormControl>
        </Grid>
        <Grid item
          xs={12}>
          <Stack direction={'row'}
            justifyContent='space-around'
            spacing={4}>
            <Button size='lg'
              variant='secondary'
              onClick={handleClearButtonClick}
              fullWidth>{t('clearAll')}</Button>
            <Button size='lg'
              variant='primary'
              onClick={handleSearchButtonClick}
              startIcon={<SvgIcon><SearchSm></SearchSm></SvgIcon>}
              fullWidth>{t('search')}</Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}