import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { AutomatedTask, AutomatedTaskPreviewDate, AutomatedTaskSchedule, Item, KeyValuePair_2OfInt32AndString, Store, SuccessResponse_1OfList_1OfKeyValuePair_2OfInt32AndString } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';
import { TaskType, gintWaves } from '../enums/IAutomatedTask';
import { IMaintenanceTaskData } from '../interfaces/IAutomated';
import axios, { CancelTokenSource } from 'axios';

export const useScheduleReport = () => {
  //use hooks
  const { t } = useTranslation('scheduledReports');
  const { selectedStore } = useContext(UserContext);
  const { getStores, getCurrentUser, getReminderRoles, getPrimaryCategoriesForStore, getDistrictsByFranchise, getItemsByStoreNumbers } = useNswagClient();
  const navigate = useNavigate();
  const { logError } = useLogError();
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  //Modals open state 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //consts
  const today = new Date().toISOString().split('T')[0];
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<AutomatedTaskPreviewDate[]>();

  //snackbar modal state
  const [snackBarDuration, setSnackBarDuration] = useState<number | null>(6000);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');

  //Task Types show states 
  const [showStores, setShowStores] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [showFranchises, setShowFranchises] = useState<boolean>(false);
  const [showLegalEntity, setShowLegalEntity] = useState<boolean>(false);
  const [showIntegration, setShowIntegration] = useState<boolean>(false);
  const [showRecursionType, setShowRecursionType] = useState<boolean>(false);
  const [showGintWaves, setShowGintWaves] = useState<boolean>(false);
  const [showEndOffset, setShowEndOffset] = useState<boolean>(false);
  const [showMaximumRecordCountPerFile, setShowMaximumRecordCountPerFile] = useState<boolean>(false);
  const [showDateOffset, setShowDateOffset] = useState<boolean>(false);
  const [showDistricts, setShowDistricts] = useState<boolean>(false);
  const [showAllDistricts, setShowAllDistricts] = useState<boolean>(false);
  const [showStartOffset, setShowStartOffset] = useState<boolean>(false);
  const [showOnlyMadeInStore, setShowOnlyMadeInStore] = useState<boolean>(false);
  const [showAllStores, setShowAllStores] = useState<boolean>(false);
  const [showRole, setShowRole] = useState<boolean>(false);
  const [showPercentageThreshold, setShowPercentageThreshold] = useState<boolean>(false);
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [showSchedules, setShowSchedules] = useState<boolean>();
  const [showHasRecentActivity, setShowHasRecentActivity] = useState<boolean>();
  const [showSelectItems, setShowSelectItems] = useState<boolean>(false);
  const [formatedCategories, setFormatedCategories] = useState<string[]>([]);
  //Task types values save states
  const [legalEntity, setLegalEntity] = useState<string>();
  const [maximumRecordCountPerFile, setMaximumRecordCountPerFile] = useState<number>();
  const [categoryNames, setCategoryNames] = useState<{ id: number; name: string; }[]>([]);
  const [madeInStore, setMadeInStore] = useState<boolean>();
  const [integration, setIntegration] = useState<string | undefined>();
  const [recursionType, setRecursionType] = useState<number | undefined>();
  const [percentageThreshold, setPercentageThreshold] = useState<number | undefined>();
  const [stores, setStores] = useState<Store[] | undefined>(undefined);
  const [waves, setWaves] = useState<string[]>();
  const [startOffset, setStartOffset] = useState<number | undefined>();
  const [dateOffset, setDateOffset] = useState<number | undefined>();
  const [endOffset, setEndOffset] = useState<number | undefined>();
  const [role, setRole] = useState<number | undefined>();
  const [formatedDistincs, setFormatedDistincs] = useState<number | undefined>();
  const [franchises, setFranchises] = useState<string[] | undefined>([]);
  const [franchisesType, setFranchisesType] = useState<string[]>([]);
  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [categoriesType, setCategoriesType] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectCategories, setSelectCategories] = useState(false);
  const [selectedWaves, setSelectedWaves] = useState(false);
  const [gintWavesType, setGintWavesType] = useState<string[]>([]);
  const [formatedWaves, setFormatedWaves] = useState<string[]>();
  const [hasRecentActivity, setHasRecentActivity] = useState<boolean>();

  // Maintainance Report data type
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [maintainanceData, setMaintainanceData] = useState<IMaintenanceTaskData[]>([]);
  //modal open state 
  const { getAutomatedTasksById, saveAutomatedTasks, getPreviewDates } = useNswagClient();
  const [name, setName] = useState<string>();
  const [taskType, setTaskType] = useState<string>();
  const [emails, setEmails] = useState<string>();
  const [active, setActive] = useState(false);
  const [allStores, setAllStores] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [emailUser, setEmailUser] = useState(false);
  const [schedules, setSchedules] = useState<AutomatedTaskSchedule[]>();
  const [saveUserReport, setSaveUserReport] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const taskId = id ? parseInt(id) : undefined;
  const [selectedTask, setSelectedTask] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<number>();
  const [distrinct, setDistrinct] = useState<boolean>(false);
  const [interval, setInterval] = useState<number | undefined>();
  const [scheduleToDeleteIndex, setScheduleToDeleteIndex] = useState<number | undefined>();
  const [taskDeleteId, setTaskDeleteId] = useState<number>();
  const [roles, setRoles] = useState<KeyValuePair_2OfInt32AndString[]>();
  const [distincs, setDistincs] = useState<{ id: number; name: string; }[]>([]);
  const [userId, setUserId] = useState<number | undefined>();
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [isLoadingItemData, setIsLoadingItemData] = useState<boolean>(false);
  const[showWarning, setShowWarning] = useState<boolean>(false);

  const [endDateErrors, setEndDateErrors] = useState<{ [key: number]: string | null }>({});
  const selectedItemsFilteredByCategory = selectedItems.filter(x => categoriesType.some(cat => cat == x.primaryCategory) || categoriesType.length == 0);
  const itemsFilteredByCategory = items.filter(x => (Array.isArray(categoriesType) && categoriesType.length > 0 ? categoriesType.some(cat => cat === (x.primaryCategory ?? '')) : true));

  useEffect(() => {
    getAllCategories();
    if (taskId != 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    getData();
    getRoles();
    getDistricts();
    getAllStores();
    retrieveUser();
  }, []);

  useEffect(()=>{
    if(schedules){
      for(let i=0;i<schedules.length;i++){
        if(schedules[i].startDate != '' && schedules[i].endDate != ''){
          const tempStartDate = new Date(schedules[i].startDate ?? '');
          const tempEndDate = new Date(schedules[i].endDate ?? '');
          const content = tempEndDate<tempStartDate ? 'End date must be after the start date' : null;
          setEndDateErrors((prevErrors)=>({ ...prevErrors, [i]:content }));
        }else{
          setEndDateErrors((prevErrors)=>({ ...prevErrors, [i]:null }));
        }
      }
    }
  },[schedules]);

  //if passed item numbers from api init load try to load full item data from stores and return matching item numbers
  //if not passed item numbers from api init load, use existing state as willl be call from selected store change
  const getItemsFromItemNumberArray = async (passedSelectedItems: string[] | undefined, passedStores: string[] | undefined): Promise<Item[]> => {
    if (!passedSelectedItems || !passedStores) {
      return selectedItems;
    }

    const apiResponse = await getItemsByStoreNumbers({ stores: passedStores });
    const apiItems = apiResponse.data;

    if (!apiItems) {
      return selectedItems;
    }

    const selectedItemsFromAPI = apiItems.filter(item =>
      passedSelectedItems.includes(item.itemNumber ?? ''),
    );

    return selectedItemsFromAPI;
  };

  const getItems = async (passedStores: string[] | undefined, passedSelectedItems: string[] | undefined) => {
    const storesToUse = passedStores ?? stores?.map(s => s.storeNumber ?? '') ?? [];

    const selectedItemsToUse = await getItemsFromItemNumberArray(passedSelectedItems, passedStores);

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();

    setIsLoadingItemData(true);
    setItems([]);
    setSelectedItems([]);

    try {
      const response = await getItemsByStoreNumbers({ stores: storesToUse }, cancelTokenSourceRef.current.token);
      const fetchedItems = response.data ?? [];
      setItems(fetchedItems);

      const selectedItemsFromAPI = fetchedItems.filter(item =>
        selectedItemsToUse.some(prevItem => prevItem.id === item.id),
      );
      setSelectedItems(selectedItemsFromAPI ?? []);
    }
    catch (error) {
      if (!axios.isCancel(error)) {
        setSnackBarProps('error', `Unable to load item numbers for stores: ${error}`);
        logError(error);
      }
    }
    finally {
      setIsLoadingItemData(false);
    }
  };

  const getRoles = () => {
    getReminderRoles().then((result: SuccessResponse_1OfList_1OfKeyValuePair_2OfInt32AndString) => {
      if (result?.data) {
        setRoles(result.data);
      }
    })
      .catch((error) => {
        logError(error);
      });
  };

  const retrieveUser = () => {
    getCurrentUser().then((result) => {
      setUserId(result.data?.id);
    })
      .catch((error) => {
        localStorage.clear();
        logError(error);
      });
  };

  const getAllCategories = () => {
    const storeNumber = selectedStore?.storeNumber ?? '';
    getPrimaryCategoriesForStore(storeNumber).then((result) => {
      if (result) {
        const finaldata = result.data;
        const categoryNames: SetStateAction<{ id: number; name: string; }[]> = [];
        const filteredCategories = finaldata?.filter(c => c.includeInReports);
        filteredCategories!.forEach(category => {
          categoryNames.push({
            id: category.id ?? 0,
            name: category.primaryCategoryName ?? '',
          });
        });
        setCategoryNames(categoryNames);
      } else {
        logError(result);
      }
    })
      .catch((error) => {
        logError(error);
      });
  };

  const getAllStores = () => {
    getStores().then((res) => {
      if (res?.data?.stores) {
        setStores(res?.data?.stores);
      }
    }).catch((error) => {
      logError(error);
    });
  };

  const getDistricts = () => {
    const storeFranchise = selectedStore?.franchiseName ?? '';
    getDistrictsByFranchise(storeFranchise).then((result) => {
      if (result) {
        const districts = result.data;
        if (districts && Array.isArray(districts)) {
          const newDistrictNames = districts.map(data => ({
            id: data.id || 0,
            name: data.description || '',
          }));
          setDistincs(prevDistrictNames => [
            ...prevDistrictNames,
            ...newDistrictNames,
          ]);
        }
        getAllCategories();
      } else {
        logError(result);
      }
    })
      .catch((error) => {
        logError(error);
      });
  };

  const handleSelectedStoresChange = (stores: string[]) => {
    setSelectedStores(stores);
    if (stores.length > 0) {
      getItems(stores, undefined);
    }
  };

  const getData = () => {
    if (!selectedStore?.storeNumber) return;

    getAutomatedTasksById(taskId ?? 0, selectedStore.storeNumber).then((result) => {
      setName(result.data?.automatedTask?.name);
      setUserId(result.data?.automatedTask?.userId);
      setEmails(result.data?.automatedTask?.additionalEmails);
      setTaskType(result?.data?.automatedTask?.taskType);
      if (taskId === 0) {
        setActive(true);
        setEmailUser(true);
        setSaveUserReport(true);
      } else {
        setActive(result?.data?.automatedTask?.isActive ?? false);
        setEmailUser(result.data?.automatedTask?.emailUser!);
        setSaveUserReport(result.data?.automatedTask?.saveToUserReports!);
      }


      if (!isEdit) {
        const defaultMaintenanceTaskData: IMaintenanceTaskData = {
          schema: '',
          storedProcedure: '',
          timeOut: 0,
        };
        const maintenanceTaskDataArray: IMaintenanceTaskData[] = [defaultMaintenanceTaskData];
        setMaintainanceData(maintenanceTaskDataArray);
      }
      if (result?.data?.automatedTask?.taskType !== 'Maintenance') {
        const allSchedules = result.data?.automatedTask?.schedules?.length;
        const allTasks = result.data?.automatedTask?.taskData;
        setSchedules(result.data?.automatedTask?.schedules);
        setFranchises(result.data?.franchiseNames);
        const data = JSON.parse(result.data?.automatedTask?.taskData!);
        setAllStores(data.allStores);
        setFranchisesType(data.franchises);
        setRecursionType(data.recursionType);
        setDateOffset(data.dateOffset);
        setIntegration(data.integration);
        setSelectedStores(data.stores);
        getItems(data.stores, data.itemNumbers);

        setCategoriesType(data.categories);
        if (taskId === 0 && result?.data?.automatedTask?.taskType === 'SbuxGintWaves') {
          const filteredWaves = gintWaves.filter(wave => data.gintWaves.includes(wave.waveNumber)).map(wave => wave.name);
          setGintWavesType(filteredWaves);
        }
        else {
          setGintWavesType(data.gintWaves ?? []);
        }
        setStartOffset(data.startOffset);
        setRole(data.role);
        setEndOffset(data.endOffset);
        setLegalEntity(data.legalEntity);
        setPercentageThreshold(data.percentageThreshold);
        setMaximumRecordCountPerFile(data.maximumRecordCountPerFile);
        setDistrinct(data.showAllDistricts);
        setHasRecentActivity(data.hasRecentActivity);

        if (allSchedules! > 0) {
          setShowSchedules(true);
        }
        if (allTasks != '') {
          setShowTasks(true);
        }
      }
      if (result?.data?.automatedTask?.taskType === 'Maintenance') {
        setShowSchedules(true);
        setShowTasks(true);
        const taskData = JSON.parse(result.data?.automatedTask?.taskData!);
        const scheduleData = result.data?.automatedTask?.schedules!;
        setMaintainanceData(taskData);
        setSchedules(scheduleData);
      }
      getReportType(result?.data?.automatedTask?.taskType ?? '');
    }).catch((error) => {
      logError(error);
    });
  };

  const getReportType = (tasktype: string) => {
    if (gintWavesType === undefined) {
      setGintWavesType([]);
    }
    setShowAllStores(false);
    setShowCategories(false);
    setShowStores(false);
    setShowFranchises(false);
    setShowRecursionType(false);
    setShowDateOffset(false);
    setShowEndOffset(false);
    setShowStartOffset(false);
    setShowAllDistricts(false);
    setShowMaximumRecordCountPerFile(false);
    setShowOnlyMadeInStore(false);
    setShowGintWaves(false);
    setShowDistricts(false);
    setShowIntegration(false);
    setShowLegalEntity(false);
    setShowPercentageThreshold(false);
    setShowHasRecentActivity(false);
    setShowSelectItems(false);

    switch (tasktype) {
    case TaskType.TransferReport:
    case TaskType.ItemActivityReport:
    case TaskType.ItemTimeOutReport:
    case TaskType.ItemStockOutReport:
    case TaskType.WasteReport:
    case TaskType.CreditPurchaseReport:
    case TaskType.MadeInStoreReport:
      setShowAllStores(true);
      setShowCategories(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowEndOffset(true);
      setShowStartOffset(true);
      break;
    case TaskType.ProductMarginReport:
    case TaskType.InvoiceRecReport:
    case TaskType.ConstrainedItemReport:
    case TaskType.ReceiptsReport:
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowEndOffset(true);
      setShowStartOffset(true);
      setShowAllStores(true);
      break;
    case TaskType.StockOnHandReport:
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowDateOffset(true);
      setShowCategories(true);
      setShowAllStores(true);
      setShowOnlyMadeInStore(true);
      setShowHasRecentActivity(true);
      setShowSelectItems(true);
      break;
    case TaskType.StockCountSummaryReport:
    case TaskType.DcShortageReport:
    case TaskType.ExtendedStockCountsReport:
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowDateOffset(true);
      setShowAllStores(true);
      break;
    case TaskType.CostOfSalesReport:
    case TaskType.StockDaysReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowCategories(true);
      break;
    case TaskType.StockCountsVsUsageReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowDistricts(true);
      setShowAllDistricts(true);
      setShowStartOffset(true);
      setShowEndOffset(true);
      break;
    case TaskType.TheoryActualReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowCategories(true);
      setShowRecursionType(true);
      setShowDistricts(true);
      setShowAllDistricts(true);
      setShowStartOffset(true);
      setShowEndOffset(true);
      break;
    case TaskType.EzoraTheoryActualReport:
    case TaskType.EzoraWasteReport:
    case TaskType.EuroGaragesTheoryActualReport:
    case TaskType.EuroGaragesWasteReport:
    case TaskType.EuroGaragesItemActivityReport:
    case TaskType.EuroGaragesItemTimeOutReport:
    case TaskType.EuroGaragesTransferReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowStartOffset(true);
      setShowEndOffset(true);
      setShowIntegration(true);
      break;
    case TaskType.EuroGaragesCostOfSalesReport:
    case TaskType.ShellStockSummaryReport:
    case TaskType.EuroGaragesStockDaysReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowIntegration(true);
      break;
    case TaskType.EuroGaragesStockOnHandReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowIntegration(true);
      setShowRecursionType(true);
      setShowDateOffset(true);
      break;
    case TaskType.RoleReminder:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowRole(true);
      break;
    case TaskType.ScoreCardsIntegrationTransactions:
    case TaskType.ScoreCardsIntegrationPurchaseOrders:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      break;
    case TaskType.ErrorItemReport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowRecursionType(true);
      setShowDateOffset(true);
      break;
    case TaskType.IntegrationExportStores:
    case TaskType.IntegrationExportItems:
    case TaskType.IntegrationExportPurchaseOrders:
    case TaskType.IntegrationExportTransactions:
    case TaskType.IntegrationExportTransactionFacts:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowIntegration(true);
      break;
    case TaskType.MacPercentageChange:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowPercentageThreshold(true);
      break;
    case TaskType.DsdExport:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowLegalEntity(true);
      setShowIntegration(true);
      setShowRecursionType(true);
      setShowStartOffset(true);
      setShowEndOffset(true);
      break;
    case TaskType.SbuxGintWaves:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowLegalEntity(true);
      setShowIntegration(true);
      setShowRecursionType(true);
      setShowStartOffset(true);
      setShowGintWaves(true);
      setShowEndOffset(true);
      setShowMaximumRecordCountPerFile(true);
      break;
    case TaskType.IntegrationExportMaterialTransactionFacts:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      setShowIntegration(true);
      setShowRecursionType(true);
      setShowDateOffset(true);
      break;
    default:
      setShowAllStores(true);
      setShowStores(true);
      setShowFranchises(true);
      break;
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const tasktype = event.target.value as TaskType;
    setTaskType(tasktype);
    setShowTasks(true);
    getReportType(tasktype);
    setShowWarning(false);
  };

  const handleTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedTask(event.target.value as number);
  };

  const handleDayChange = (event: SelectChangeEvent<number>) => {
    setSelectedDate(event.target.value as number);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setFranchisesType(selectAll ? [] : (franchises || []));
  };
  const handleSelectAllCategories = () => {
    setSelectCategories(!selectCategories);
    setCategoriesType(selectCategories ? [] : categoryNames.map(option => option.name));
  };

  const handleSelectAllWaves = () => {
    setSelectedWaves(!selectedWaves);
  };

  const validateSave = () => {
    if (taskType === ''
      || taskType === undefined || taskType === null) {
      setSnackBarProps('warning', 'Please select a task type');
      return false;
    }
    if (name === ''
      || name === null
      || name === undefined) {
      setSnackBarProps('warning', 'Please add a name for the Automated Report');
      return false;
    }
    if (emailUser && !saveUserReport) {
      setSnackBarProps('warning', 'If "Email User" is selected, then "Save To User Reports" must be selected');
      return false;
    }
    return true;
  };
  const handleInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setInterval(newQuantity);
    } else {
      setInterval(undefined);
    }

  };
  const addNewSchedule = () => {
    const newSchedule = {
      id: 0,
      automatedTaskId: taskId ?? 0,
      isActive: true,
      startDate: '',
      endDate: '',
      recurrenceFrequency: 1,
      recurrenceInterval: 1,
      scheduleType: 0,
      scheduledDayOfMonth: 0,
      nextRunDate: '',
      lastGenerated: '',
    };
    setSchedules([...(schedules ?? []), newSchedule]);
    setShowSchedules(true);
  };

  const deleteScheduleRow = (index?: number) => {
    setScheduleToDeleteIndex(index);
    setIsDeleteModalOpen(true);
  };

  const deleteTaskType = (id?: number) => {
    setTaskDeleteId(id);
    setOpenDeleteModal(true);
  };

  const onSave = () => {
    const task = prepareTaskToSave();
    saveTask(task);
  };

  const prepareTaskToSave = () => {
    if (categoriesType && categoriesType.length === categoryNames.length) {
      const allCategories: string[] = [];
      setFormatedCategories(allCategories);
    }
    const task: AutomatedTask = {
      id: taskId ?? 0,
      userId: userId,
      isActive: active,
      name: name ?? '',
      saveToUserReports: saveUserReport,
      additionalEmails: emails ?? '',
      emailUser: emailUser,
      schedules: schedules ?? [],
      taskData: '',
      taskType: taskType,
    };
    if (taskType !== 'Maintenance') {
      const allData = populateReportData(taskType ?? '');
      const formatedData = JSON.stringify(allData);
      task.taskData = formatedData;
    }
    else {
      const formatedData = JSON.stringify(maintainanceData);
      task.taskData = formatedData;
    }

    if (schedules?.length! > 0) {
      for (const schedule of task.schedules!) {
        schedule.scheduleType = Number(schedule.scheduleType);
        schedule.recurrenceFrequency = Number(schedule.recurrenceFrequency);
        if (taskId! <= 0) {
          schedule.lastGenerated = schedule.startDate;
          schedule.nextRunDate = schedule.endDate;
        }
      }
    }
    return task;
  };

  const goBack = () => {
    navigate('/scheduled-reports');
  };

  const saveTask = (task: AutomatedTask) => {
    if (!validateSave()) {
      return;
    }
    saveAutomatedTasks(task)
      .then(() => {
        if (taskId! <= 0) {
          goBack();
        } else {
          setSnackBarProps('success', 'Report updated succesfully!');
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  const populateReportData = (taskType: string) => {
    switch (taskType) {
    case TaskType.TransferReport:
    case TaskType.ItemActivityReport:
    case TaskType.ItemTimeOutReport:
    case TaskType.ItemStockOutReport:
    case TaskType.WasteReport:
    case TaskType.CreditPurchaseReport:
    case TaskType.MadeInStoreReport: {
      return {
        allStores: allStores,
        endOffset: endOffset,
        franchises: franchisesType,
        categories: formatedCategories,
        recursionType: recursionType,
        startOffset: startOffset,
        stores: selectedStores,
      };
    }
    case TaskType.ConstrainedItemReport:
    case TaskType.InvoiceRecReport:
    case TaskType.ReceiptsReport:
    case TaskType.ProductMarginReport: {
      return {
        allStores: allStores,
        endOffset: endOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        startOffset: startOffset,
        stores: selectedStores,
      };
    }
    case TaskType.StockOnHandReport: {
      return {
        allStores: allStores,
        dateOffset: dateOffset,
        franchises: franchisesType,
        categories: formatedCategories,
        recursionType: recursionType,
        stores: selectedStores,
        hasRecentActivity: hasRecentActivity,
        onlyMadeInStore: madeInStore,
        itemNumbers: selectedItemsFilteredByCategory.map(x => x.itemNumber),
      };
    }
    case TaskType.DcShortageReport: {
      return {
        allStores: allStores,
        dateOffset: dateOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        stores: selectedStores,
        hasRecentActivity: hasRecentActivity,
      };
    }
    case TaskType.StockCountSummaryReport: {
      return {
        allStores: allStores,
        dateOffset: dateOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        stores: selectedStores,
      };
    }
    case TaskType.CostOfSalesReport:
    case TaskType.ErrorItemReport:
    case TaskType.StockDaysReport: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        categories: formatedCategories,
        recursionType: recursionType,
        stores: selectedStores,
      };
    }
    case TaskType.StockCountsVsUsageReport: {
      return {
        allStores: allStores,
        endOffset: endOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        startOffset: startOffset,
        stores: selectedStores,
        showDistrict: showAllDistricts,
        districts: formatedDistincs,
      };
    }
    case TaskType.TheoryActualReport: {
      return {
        allStores: allStores,
        endOffset: endOffset,
        franchises: franchisesType,
        categories: formatedCategories,
        recursionType: recursionType,
        startOffset: startOffset,
        stores: selectedStores,
        showDistrict: showAllDistricts,
        districts: formatedDistincs,
      };
    }
    case TaskType.EzoraTheoryActualReport:
    case TaskType.EzoraWasteReport:
    case TaskType.EuroGaragesTheoryActualReport:
    case TaskType.EuroGaragesWasteReport:
    case TaskType.EuroGaragesItemActivityReport:
    case TaskType.EuroGaragesItemTimeOutReport:
    case TaskType.EuroGaragesTransferReport: {
      return {
        allStores: allStores,
        endOffset: endOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        startOffset: startOffset,
        stores: selectedStores,
        integration: integration,
      };
    }
    case TaskType.EuroGaragesCostOfSalesReport:
    case TaskType.EuroGaragesStockDaysReport: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        stores: selectedStores,
        integration: integration,
      };
    }
    case TaskType.EuroGaragesStockOnHandReport: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        recursionType: recursionType,
        stores: selectedStores,
        dateOffset: dateOffset,
        integration: integration,
      };
    }
    case TaskType.RoleReminder: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        stores: selectedStores,
        role: role,
      };
    }
    case TaskType.ShellStockSummaryReport:
    case TaskType.ScoreCardsIntegrationTransactions:
    case TaskType.ScoreCardsIntegrationPurchaseOrders: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        stores: selectedStores,
      };
    }
    case TaskType.IntegrationExportStores:
    case TaskType.IntegrationExportItems:
    case TaskType.IntegrationExportPurchaseOrders:
    case TaskType.IntegrationExportTransactions:
    case TaskType.IntegrationExportTransactionFacts: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        recursionType: recursionType,
        stores: selectedStores,
        integration: integration,
      };
    }
    case TaskType.IntegrationExportMaterialTransactionFacts: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        dateOffset: dateOffset,
        recursionType: recursionType,
        stores: selectedStores,
        integration: integration,
      };
    }
    case TaskType.ExtendedStockCountsReport: {
      return {
        allStores: allStores,
        dateOffset: dateOffset,
        franchises: franchisesType,
        recursionType: recursionType,
        stores: selectedStores,
      };
    }
    case TaskType.MacPercentageChange: {
      return {
        allStores: allStores,
        stores: selectedStores,
        franchises: franchisesType,
        percentageThreshold: percentageThreshold,
      };
    }
    case TaskType.DsdExport: {
      return {
        allStores: allStores,
        stores: selectedStores,
        franchises: franchisesType,
        legalEntity: legalEntity,
        integration: integration,
        recursionType: recursionType,
        startOffset: startOffset,
        endOffset: endOffset,
      };
    }
    case TaskType.SbuxGintWaves: {
      return {
        allStores: allStores,
        franchises: franchisesType,
        legalEntity: legalEntity,
        integration: integration,
        recursionType: recursionType,
        startOffset: startOffset,
        endOffset: endOffset,
        gintWaves: formatedWaves,
        maximumRecordCountPerFile: maximumRecordCountPerFile,
      };
    }
    default: {
      return null;
    }
    }
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
    setOpenDeleteModal(false);
    setPreviewModal(false);
  };

  const confirmDeleteSchedule = () => {
    let data: AutomatedTaskSchedule[] = [];
    if (scheduleToDeleteIndex != undefined ){
      data = schedules?.filter((item,index)  => index !== scheduleToDeleteIndex) ?? [];
      setEndDateErrors((prevErrors)=>({ ...prevErrors, [scheduleToDeleteIndex ?? -1]:null }));
    }else{
      setEndDateErrors({});
    }
    setSchedules(data);
    handleClose();
  };

  const confirmDeleteTask = () => {
    if (taskDeleteId === undefined) {
      setMaintainanceData([]);
    }
    else {
      setMaintainanceData(prevData => {
        const newData = prevData.filter((_, index) => index !== taskDeleteId);
        return newData;
      });
    }
    handleClose();
  };

  const getAllPreviewDates = () => {
    const previewData = prepareTaskToSave();
    getPreviewDates(previewData).then((result) => {
      if (result) {
        setPreviewData(result.data);

      } else {
        logError(result);
      }
    })
      .catch((error) => {
        logError(error);
      });
    setPreviewModal(true);
  };

  const closeSnackBar = () => {
    setIsSnackBarOpen(false);
  };

  const setSnackBarProps = (severity: 'error' | 'warning' | 'info' | 'success', message: string) => {
    severity === 'success' ? setSnackBarDuration(6000) : setSnackBarDuration(null);
    setIsSnackBarOpen(true);
    setSnackBarSeverity(severity);
    setSnackbarMessage(message);
  };

  return {
    t,
    schedules,
    name,
    emailUser,
    active,
    showSchedules,
    showTasks,
    showAllStores,
    showCategories,
    showDateOffset,
    showEndOffset,
    showFranchises,
    showGintWaves,
    showLegalEntity,
    showIntegration,
    showMaximumRecordCountPerFile,
    showPercentageThreshold,
    showStores,
    franchises,
    showRecursionType,
    showStartOffset,
    showDistricts,
    taskType,
    distrinct,
    allStores,
    franchisesType,
    selectAll,
    today,
    selectedTask,
    selectedDate,
    interval,
    saveUserReport,
    emails,
    roles,
    showRole,
    categoryNames,
    distincs,
    showAllDistricts,
    showHasRecentActivity,
    hasRecentActivity,
    legalEntity,
    showOnlyMadeInStore,
    madeInStore,
    maximumRecordCountPerFile,
    stores,
    integration,
    endOffset,
    startOffset,
    waves,
    recursionType,
    role,
    dateOffset,
    percentageThreshold,
    isDeleteModalOpen,
    categoriesType,
    selectCategories,
    selectedWaves,
    gintWavesType,
    selectedStores,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    isEdit,
    maintainanceData,
    previewData,
    previewModal,
    openDeleteModal,
    formatedDistincs,
    formatedWaves,
    showSelectItems,
    isLoadingItemData,
    endDateErrors,
    setEndDateErrors,
    handleSelectedStoresChange,
    setShowStores,
    setShowFranchises,
    setCategoriesType,
    handleClose,
    confirmDeleteSchedule,
    setPercentageThreshold,
    setDateOffset,
    setRole,
    setMaximumRecordCountPerFile,
    setIntegration,
    setMadeInStore,
    setLegalEntity,
    setSchedules,
    setShowSchedules,
    handleChange,
    handleSelectAllChange,
    handleTypeChange,
    handleDayChange,
    handleInputValue,
    addNewSchedule,
    setAllStores,
    setName,
    setEmails,
    setActive,
    setEmailUser,
    setSaveUserReport,
    setShowHasRecentActivity,
    setEndOffset,
    setStartOffset,
    setWaves,
    setRecursionType,
    deleteScheduleRow,
    setFranchises,
    setFranchisesType,
    onSave,
    getAllPreviewDates,
    handleSelectAllCategories,
    handleSelectAllWaves,
    setGintWavesType,
    closeSnackBar,
    goBack,
    deleteTaskType,
    setMaintainanceData,
    confirmDeleteTask,
    setFormatedCategories,
    setFormatedWaves,
    setFormatedDistincs,
    setHasRecentActivity,
    items,
    selectedItems,
    setSelectedItems,
    selectedItemsFilteredByCategory,
    itemsFilteredByCategory,
    setShowWarning,
    showWarning,
  };
};



