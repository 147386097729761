import useNswagClient from '../../../hooks/api/useNswagClient';
import { PrimaryCategory2 } from '../../../app/services/api/generated';
import { useState } from 'react';
import useLogError from '../../../hooks/useLogError';

const useCategoriesData = () => {
  const { getPrimaryCategoriesForStore } = useNswagClient();
  const { logError } = useLogError();
  const [allCategories, setAllCategories] = useState<PrimaryCategory2[]>([]);

  const loadCategories = async (storeNumber: string) => {
    try {
      const res = await getPrimaryCategoriesForStore(storeNumber);
      if (res.data) {
        setAllCategories(res.data);
      }
    } catch (error) {
      logError(error);
    }
  };

  return {
    allCategories,
    loadCategories,
  };
};

export default useCategoriesData;