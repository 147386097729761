import { useContext, useEffect } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { Grid, FormControl, TextField } from '@mui/material';

export const CreateStockTransfersNote = () => {
  const { handleNoteChange, note, setNote, isNoteDisabled, t } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;
  
  useEffect(() => {
    setNote('');
  }, []);

  return (
    <Grid item
      xs={12}>
      <FormControl fullWidth
        size='small'>
        <TextField id="textfeild-note"
          label={t('create_modal.notes')}
          multiline
          rows={4}
          size='small'
          disabled={isNoteDisabled}
          value={note}
          onChange={handleNoteChange}
        />
      </FormControl>
    </Grid>
  );
};

