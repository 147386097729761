import { Grid, FormControl, TextField, Button, SvgIcon } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from '../../../theme';
import { SearchSm } from '../../../assets';
import { useContext } from 'react';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';
import { InvoiceTable } from './InvoiceTable';

export const InvoiceBox = () => {
  
  const { t, invoiceNumber, handleInvoiceNumberChange, handleEndDateChange, orderNumber, handleClearAllClick, handleOrderNumberChange, startDate, endDate, loadData, handleStartDateChange } = useContext(InvoicesContext) as CreateInvoicesValues;

  return <Box sx={{
    border: '1px solid',
    borderColor: theme.palette.custom.gray[300],
    py: '0px',
    textAlign: 'left',
  }} >
    <Grid container
      spacing={5}
      sx={{
        py: '12px',
        px: '8px',
      }} >
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField label={t('invoiceNumber')}
            value={invoiceNumber}
            onChange={handleInvoiceNumberChange}/>
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField label={t('orderNumber')}
            value={orderNumber}
            onChange={handleOrderNumberChange}/>
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField type='date'
            InputLabelProps={{ shrink: true }}
            label={t('startDate')}
            value={startDate}
            inputProps={{ max: endDate }}
            onChange={handleStartDateChange}/>
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField type='date'
            InputLabelProps={{ shrink: true }}
            label={t('endDate')}
            value={endDate}
            inputProps={{ min: startDate }}
            onChange={handleEndDateChange}/>
        </FormControl>
      </Grid>
      <Grid item
        xs={6}>
        <Button
          fullWidth
          variant="secondary"
          size="lg"
          onClick={handleClearAllClick}
        >
          {t('clearAll')}
        </Button>
      </Grid>
      <Grid item
        xs={6}>
        <Button
          fullWidth
          variant="primary"
          size="lg"
          startIcon={<SvgIcon> <SearchSm/> </SvgIcon>}
          onClick={loadData}
        >
          {t('search')}
        </Button>
      </Grid>
    </Grid>
    <InvoiceTable></InvoiceTable>
  </Box>;
};