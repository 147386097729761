import { createContext, useEffect, useMemo, useState } from 'react';
import { Store, User } from '../../app/services/api/generated';
import { jwtDecode } from 'jwt-decode';
import { Role } from '../../enums/Role';

export interface UserType extends User {
  username?: string;
  roles?: string[];
}

const initialUserState: UserType = {
  emailAddress: '',
};

interface UserContextType {
  user: UserType | null;
  selectedStore?: Store | null;
  setSelectedStore: (store: Store) => void;
  setUserContext: (user: User, token: string) => void;
  hasPermissionTo: (action: Role[]) => boolean;
  isAdmin: () => boolean;
  setUserStores: (stores: Store[]) => void;
}

const initialContextState: UserContextType = {
  user: null,
  selectedStore: null,
  setSelectedStore: (store: Store) => { },
  setUserContext: () => { },
  hasPermissionTo: () => false,
  isAdmin: () => false,
  setUserStores: (stores: Store[]) => { },
};

export const UserContext = createContext<UserContextType>(initialContextState);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserType | null>(initialUserState);
  const [selectedStore, setSelectedStore] = useState<Store | null>(() => {
    const storedStore = localStorage.getItem('selectedStore');
    return storedStore ? JSON.parse(storedStore) : null;
  });

  useEffect(() => {
    localStorage.setItem('selectedStore', JSON.stringify(selectedStore));
  }, [selectedStore]);

  // Retrieve user from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const decodeJwtToken = (token: string) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (err) {
      console.error('Failed to decode JWT', err);
      return null;
    }
  };

  const contextValue = useMemo(() => {
    const setUserContext = (userData: User | null, token: string) => {
      let username = '';
      let roles = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedJwtToken = (decodeJwtToken(token) || {}) as { [key: string]: any };
      if (decodedJwtToken) {
        username = decodedJwtToken['https://starbucks-inventory-api.orderly.eu/UserName'];
        roles = decodedJwtToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      }

      const user: UserType = {
        ...userData,
        username: username,
        roles: roles,
        stores: userData?.stores ?? [],
      };

      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
    };

    const hasPermissionTo = (actions: Role[]): boolean => {
      if (!user?.roles) return false;

      if (user.roles.includes('admin')) return true;

      if (actions.some(a => a === 'User') && (user?.roles.length > 0)){
        return true;
      } 

      return actions.some(a => user.roles?.includes(a.toLowerCase()));
    };

    const isAdmin = () => {
      if (!user?.roles) return false;
      return user.roles.includes('admin');
    };

    const setUserStores = (stores: Store[]) => {
      setUser({ ...user, stores });
      localStorage.setItem('user', JSON.stringify({ ...user, stores }));
    };

    return {
      user,
      selectedStore,
      setSelectedStore,
      setUserContext,
      hasPermissionTo,
      isAdmin,
      setUserStores,
    };
  }, [user, selectedStore]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};