import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, SvgIcon, TableSortLabel, Tooltip } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import DeleteModal from './NotificationDeleteModal';
import CreateEditModal from './NotificationEditCreateModal';
import { Notification2 } from '../../../app/services/api/generated';
import { Trash01, Edit05 } from '../../../assets';
import { formatDateTime } from '../../../utils';

export default function DenseTable(props: {
  readonly list: Notification2[], readonly loadData: () => void, readonly page: number, readonly setPage: React.Dispatch<React.SetStateAction<number>>
}) {
  const { t } = useTranslation('manageNotifications');
  const { saveNotification, deleteNotification } = useNswagClient();
  const [data, setData] = React.useState(props.list);
  const [selectedNotification, setSelectedNotification] = React.useState<Notification2 | undefined>(undefined);
  const [showNotifEditModal, setShowNotifEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const rowsPerPage = 10;
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Notification2>('title' as keyof Notification2);
  const maxPages = props.list
    ? Math.max(1, Math.ceil(props.list.length / rowsPerPage))
    : 1;

  React.useEffect(() => {
    setData(props.list);
  }, [props.list]);

  const handleSort = (property: keyof Notification2) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSearchChange();
    }
  };

  const sortedData = () => {
    const isAsc = order === 'asc';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const comparator = (a: any, b: any) => {
      
      if (typeof a[orderBy] === 'string') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fistString = a[orderBy].replace(/<\/?[^>]+(>|$)/g, '');
        const secondString = b[orderBy].replace(/<\/?[^>]+(>|$)/g, '');
        return (isAsc ? fistString.localeCompare(secondString) : secondString.localeCompare(fistString));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]) as any;
      }
    };
    return props.list.sort(comparator);
  };

  const editNotification = (notif: Notification2 | undefined) => {
    setSelectedNotification(notif);
    setShowNotifEditModal(true);
  };

  const handleCloseModal = () => {
    setShowNotifEditModal(false);
    setShowDeleteModal(false);
    setSelectedNotification(undefined);
  };

  const deleteNotif = (notif: Notification2) => {
    setSelectedNotification(notif);
    setShowDeleteModal(true);
  };

  const handleEditNotification = (body: Notification) => {
    saveNotification(body).then(props.loadData);
  };

  const handleDeleteNotification = () => {
    deleteNotification(selectedNotification?.notificationId ?? 0).then(props.loadData);
  };

  const handleSearchChange = () => {
    setData(sortedData());
  };

  const visibleRows = () => {
    return data.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage);
  };

  const addMaxSizeToImages = (htmlString: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const images = Array.from(tempDiv.getElementsByTagName('img'));
    images.forEach(img => {
      img.style.maxWidth = '400px';
    });
    return tempDiv.innerHTML;
  };

  return (
    <Grid item
      xs={12}>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table">
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell
                align="left"
                width={'25%'}>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={order}
                  onClick={() => handleSort('title')}>
                  {t('title')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                width={'40%'}>
                <TableSortLabel
                  active={orderBy === 'message'}
                  direction={order}
                  onClick={() => handleSort('message')}>
                  {t('message')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'startDate'}
                  direction={order}
                  onClick={() => handleSort('startDate')}>
                  {t('startDate')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left">
                <TableSortLabel
                  active={orderBy === 'endDate'}
                  direction={order}
                  onClick={() => handleSort('endDate')}>
                  {t('endDate')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                {t('edit')}
              </TableCell>
              <TableCell align="left">
                {t('remove')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <React.Fragment key={row.notificationId}>
                <TableRow hover>
                  <TableCell
                    onClick={() => editNotification(row)}>
                    {row.title}
                  </TableCell>
                  <TableCell
                    onClick={() => editNotification(row)}>
                    <div dangerouslySetInnerHTML={{ __html: addMaxSizeToImages(row.message as string) }}></div>
                  </TableCell>
                  <TableCell            
                    onClick={() => editNotification(row)}>
                    {formatDateTime(row.startDate ?? '')}
                  </TableCell>
                  <TableCell
                    onClick={() => editNotification(row)}>
                    {formatDateTime(row.endDate ?? '')}
                  </TableCell>
                  <TableCell
                    onClick={() => editNotification(row)}>
                    <Tooltip title={t('editNotification')}
                      color='dark'
                      placement='top'>
                      <IconButton
                        sx={{ zIndex: 999 }}
                        size='small'>
                        <SvgIcon><Edit05></Edit05></SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Delete notification'
                      color='dark'
                      placement='top'>
                      <IconButton
                        sx={{ zIndex: 999 }}
                        size='small'
                        onClick={() => deleteNotif(row)}>
                        <SvgIcon color='error'><Trash01></Trash01></SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={props.page}
        setPage={props.setPage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>

      <CreateEditModal openModal={showNotifEditModal}
        onConfirm={(requestBody: Notification) => handleEditNotification(requestBody)}
        notification={selectedNotification}
        onCancel={handleCloseModal}></CreateEditModal>
        
      <DeleteModal openModal={showDeleteModal}
        onConfirm={handleDeleteNotification}
        onCancel={handleCloseModal}></DeleteModal>              
    </Grid>
  );
}
