import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../../../components/forms/Pagination';
import { theme } from '../../../../../theme';
import { Button, Paper } from '@mui/material';
import { ItemChangeTypeMap } from '../../../data/ItemChangeTypeMap';
import { HistoryContext, HistoryValues } from '../../../contexts/HistoryContext';
import { ChangesDialog } from '../../ChangesDialog';

export default function HistoryPageTable() {
  const { t, data, page, setPage, maxPages, handleViewButtonClick, viewId, isChangesDialogOpen, closeDialog, LoadData } = React.useContext(HistoryContext) as HistoryValues;

  React.useEffect(() => {}, [data, page]);

  return (
    <Paper>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table"
          sx={{
            borderTop: '1px solid',
            borderColor: theme.palette.custom.gray[200],
          }}
        >
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align="left"
              >
                {t('type')}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align="left"
              >
                {t('description')}
              </TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.itemFeedChangeId}
                sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  {ItemChangeTypeMap[row.type ?? 999]}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  {String(row.description)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.custom.gray[200],
                  }}
                >
                  <Button size='lg'
                    onClick={handleViewButtonClick(row.itemFeedChangeId ?? -1)}>{t('view')}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>
      <ChangesDialog isOpen={isChangesDialogOpen}
        closeDialog={closeDialog}
        changeId={[viewId]}
        loadData={LoadData}
        showActionButtons={false}/>
    </Paper>
  );
}
