import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { FormEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import useNswagClient from './../../../../hooks/api/useNswagClient';
import { PrimaryCategory2, StockDaysRequest, Store } from './../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { UserContext } from './../../../../components/shared/useUser';

type IProps = {
  onClose?: () => void;
}

const StockDaysReportForm = (props: IProps) => {
  const { selectedStore, user } = useContext(UserContext);
  const [allCategories, setAllCategories] = useState<PrimaryCategory2[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<PrimaryCategory2[]>([]);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const { getPrimaryCategoriesForStore, runStockDaysReport } = useNswagClient();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (selectedStore?.storeNumber) {
      loadCategories(selectedStore.storeNumber);
    }
  }, [selectedStore]);

  const loadCategories = (storeNumber: string) => {
    getPrimaryCategoriesForStore(storeNumber).then((res) => {
      if (res.data) {
        setAllCategories(res.data);
        setSelectedCategories(res.data);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSelectedStores([]);

    setCheckedAllStores(checked);
  };

  const handleSelectAllCategoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedCategories(allCategories);
    } else {
      setSelectedCategories([]);
    }
  };

  const handleCategorySelectChange = (event: SyntheticEvent, value: PrimaryCategory2[]) => {
    setSelectedCategories(value);
  };

  const handleSelectChange = (event: SyntheticEvent, value: Store[]) => {
    setSelectedStores(value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsGenerating(true);
    setErrorMessage('');
    setSuccessMessage('');

    const selectedAllStores = checkedAllStores || (selectedStores.length > 0 && selectedStores.length == user?.stores?.length);

    const request: StockDaysRequest = {
      reportOnAllAvailableStores: selectedAllStores,
      primaryCategoryIds: selectedCategories.length === allCategories.length ? [] : selectedCategories.map(x => x.id as number),
      stores: selectedAllStores ? undefined : selectedStores.map(x => x.storeNumber as string),
    };

    runStockDaysReport(request).then(() => {
      setSuccessMessage(t('reporting.generic.post-generation-message'));
      setIsRequestSubmitted(true);
    }).catch((error) => {
      if (typeof(error) === 'string') {
        setErrorMessage(error);
      }
      console.log(error);
    }).finally(() => {
      setIsGenerating(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container
        spacing={10}
        sx={{ pt: '10px' }}>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={user?.stores ?? []}
              value={selectedStores}
              onChange={handleSelectChange}
              disabled={checkedAllStores}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedStores.length === 0 && !checkedAllStores}
                  label={t('reporting.select-stores')}
                  placeholder={t('reporting.select-stores')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox
                checked={checkedAllStores}
                onChange={handleSelectAllChange} />}
              label={t('reporting.all-stores')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={allCategories}
              value={selectedCategories}
              onChange={handleCategorySelectChange}
              getOptionKey={(option) => option.id ?? ''}
              getOptionLabel={(option) => option.primaryCategoryName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedCategories.length === 0}
                  label={t('reporting.select-categories')}
                  placeholder={t('reporting.select-categories')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox checked={allCategories.length === selectedCategories.length}
                onChange={handleSelectAllCategoriesChange} />}
              label={t('reporting.all-categories')}
            />
          </FormGroup>
        </Grid>
        {
          errorMessage && 
          <Grid item
            xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        }
        {
          successMessage && 
          <Grid item
            xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        }
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', columnGap: '10px' }}>
          <Button size='lg'
            variant='secondary' 
            onClick={props.onClose}>{t('cancel')}</Button>
          <Button size='lg'
            disabled={isGenerating || isRequestSubmitted}
            type='submit'>{t('reporting.generate')}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StockDaysReportForm;