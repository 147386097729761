import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/shared/useUser';
import { ConfigurationName } from '../../enums/ConfigurationName';

const useCurrencyCodeConfiguration = () => {
  const { selectedStore } = useContext(UserContext);
  const { getConfiguration } = useNswagClient();
  const [currencyCode, setCurrencyCode] = useState<string>();

  const getCurrencyCodeConfiguration = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.CurrencyCode, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      if (res.data.value) {
        setCurrencyCode(res.data.value);
      }
    });
  };

  return {
    currencyCode,
    getCurrencyCodeConfiguration,
  };
};

export default useCurrencyCodeConfiguration;