import {  Box, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../components/shared/useUser';
import { GetTransactionTypeResponse, PatchTransactionTypeRequest } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import useLogError from '../../../hooks/useLogError';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';


const TransactionManagementPage=() => {
  const { selectedStore } = useContext(UserContext);
  const [data, setData] = useState<GetTransactionTypeResponse[] >([]);
  const { getTransactionTypes ,patchTransactionTypes } = useNswagClient();
  const [loading, setLoading] = useState<boolean>(true);
  const { logError } = useLogError();
  const { t } = useTranslation('manageTransactions');

  useEffect(() => {
    setLoading(true);
    getData();
  },[]);
 
  const getData = () => {
    const storeNumber = selectedStore?.storeNumber;
    if (storeNumber) {
      getTransactionTypes(storeNumber).then((result) => {
        if (result) {  
          setLoading(false);
          const transactions  = result.data;
          if(transactions){
            setData(transactions);
            setLoading(false);
          }
        } else {
          logError(result);
        }})
        .catch((error) => {
          logError(error);
        });
    }
  };

  const patchType = (rowId: number, enabled:boolean, duplicateEnabled:boolean, type:string) => {
    const body : PatchTransactionTypeRequest = {
      enabled : enabled,
      type: type,
      enabledDuplicateValidation : duplicateEnabled,
    };
    patchTransactionTypes(rowId, body).then(() => {
    })
      .catch((error) => {
        logError(error);
      });
  };

  const handleEnableChange = (rowId: number, enabledDuplicate: boolean, type: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    patchType(rowId, isChecked,enabledDuplicate, type);
  };
  const handleEnableDuplicateChange = (rowId: number, enabled: boolean, type: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    patchType(rowId, enabled, isChecked, type);
  };
  
  return (
    <Box>
      {loading ? <LoadingWheel />: (
        <TableContainer 
        >
          <Table
            size="small"
          >
            <TableHead>
              <TableRow >
                <TableCell>
                  {t('name')}
                </TableCell>
                <TableCell>
                  {t('visible')}
                </TableCell>
                <TableCell>
                  {t('doubleEnable')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row:GetTransactionTypeResponse) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell >
                    {row.displayName}
                  </TableCell>
                  <TableCell>
                    {row.name === 'Waste' ? (
                      <Tooltip title={t('wasteVisibilityTooltip')}
                        arrow
                        placement="bottom">
                        <span>
                          <Switch
                            disabled
                            defaultChecked={row.enabled}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={handleEnableChange(row.id ?? 0, row.enabledDuplicateValidation ?? false, row.type ?? '')}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Switch
                        defaultChecked={row.enabled}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={handleEnableChange(row.id ?? 0, row.enabledDuplicateValidation ?? false, row.type ?? '')}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Switch
                      defaultChecked={row.enabledDuplicateValidation}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={handleEnableDuplicateChange(row.id ?? 0, row.enabled ?? false, row.type ?? '')}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TransactionManagementPage;