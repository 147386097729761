import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SvgIcon, TextField } from '@mui/material';
import ScheduleTable from './TransactionsTable';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import { TransactionRecord } from '../types/TransactionRecord';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { StoreRecord } from '../types/StoreRecord';
import { ReasonRecord } from '../types/ReasonRecord';
import { TypesRecord } from '../types/TypesRecord';
import { SearchSm } from '../../../assets';
import { adjustDateByDays } from '../../../utils';

const TransactionsBox = (props: {
  schedules: TransactionRecord[],
  loadData: (storeNum?: string) => void,
  reloadDropDowns: (storeNum?: string) => void,
  isLoading: boolean,
  isReloading: boolean,
  stores: StoreRecord[],
  transactionTypes: TypesRecord[],
  reasons: ReasonRecord[],
  startDate: Date,
  endDate: Date,
  setStartDate: Dispatch<SetStateAction<Date>>,
  setEndDate: Dispatch<SetStateAction<Date>>,
  dropdownStore: string,
  setDropdownStore: Dispatch<SetStateAction<string>>,
  page: number,
  setPage: (newPage: number) => void,
  totalCount: number,
  searchTerm: string,
  setSearchTerm: Dispatch<SetStateAction<string>>,
  type: string,
  setType: Dispatch<SetStateAction<string>>,
  reason: string,
  setReason: Dispatch<SetStateAction<string>>,
  sourceID: string,
  setSourceID: Dispatch<SetStateAction<string>>,
  source: string,
  setSource: Dispatch<SetStateAction<string>>,
  fieldToSort: string,
  setFieldToSort: Dispatch<SetStateAction<string>>, 
  sortDirection: 'asc' | 'desc' | undefined,
  setSortDirection: Dispatch<SetStateAction<'asc' | 'desc'>>
}) => {

  const [filtersCleared, setFiltersCleared] = useState(false);
  const { t } = useTranslation('common');
  const sources = ['manual', 'pos', 'system'];

  useEffect(() => {
    if (filtersCleared) {
      props.loadData();
    }
  }, [filtersCleared]);

  useEffect(() => {
    props.loadData();
  }, [props.fieldToSort, props.sortDirection]);

  
  const getDateOnly = (date: Date) => {
    return date.toISOString().split('T')[0];
  };


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchTerm(event.target.value);
  };

  const handleStoreChange = (event: SelectChangeEvent) => {
    props.setDropdownStore(event.target.value);
    props.reloadDropDowns(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    props.setType(event.target.value);
  };

  const handleReasonChange = (event: SelectChangeEvent) => {
    props.setReason(event.target.value);
  };

  const handleSourceIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSourceID(event.target.value);
  };

  const handleSourceChange = (event: SelectChangeEvent) => {
    props.setSource(event.target.value);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    if (selectedDate === '') {
      props.setStartDate(adjustDateByDays(new Date(),-7)); 
    } else {
      props.setStartDate(new Date(selectedDate));
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    if (selectedDate === '') {
      props.setEndDate(adjustDateByDays(new Date(), 1)); 
    } else {
      props.setEndDate(new Date(event.target.value));
    }
  };

  const handleClearButtonClick = () => { 
    props.setSearchTerm('');
    props.setType('');
    props.setReason('');
    props.setSourceID('');
    props.setSource('');
    props.setEndDate(adjustDateByDays(new Date(), 1)); 
    setFiltersCleared(true);
  };

  const handleSearchButtonClick = () => {
    props.loadData();
    setFiltersCleared(false);
  };

  return (props.isLoading ? <LoadingWheel></LoadingWheel> :
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
      py: '0px',
      textAlign: 'left',
    }} >
      <Grid container
        spacing={5}
        sx={{
          borderColor: theme.palette.custom.gray[300],
          py: '12px',
          px: '8px',
        }} >
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('transactions.searchSkuDescription')}
              size='small'
              variant="outlined"
              value={props.searchTerm}
              onChange={handleSearchChange} />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="store-label">{t('transactions.store')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t('transactions.store')}
              value={props.dropdownStore} // Set the value to the currently selected store
              onChange={handleStoreChange}
            >
              {props.stores.map((store) => {
                return (
                  <MenuItem key={store.id}
                    value={store.id}>
                    {store.displayName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="transaction-types-label">{t('transactions.transactionTypes')}</InputLabel>
            <Select
              labelId="transaction-types-label"
              id="transaction-types-select"
              value={props.type}
              label={t('transactions.transactionTypes')}
              onChange={handleTypeChange}
            >
              <MenuItem value="All">
                All
              </MenuItem>
              {props.transactionTypes.map((type) => {
                return <MenuItem key={type.type}
                  value={type.type}>{type.type}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="transaction-reasons-label">{t('transactions.transactionReasons')}</InputLabel>
            <Select
              labelId="transaction-reasons-label"
              id="transaction-reasons-select"
              value={props.reason}
              label={t('transactions.transactionReasons')}
              onChange={handleReasonChange}
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              {props.reasons.map((reason) => {
                return <MenuItem key={reason.reason}
                  value={reason.reason}>{reason.reason}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('transactions.sourceID')}
              size='small'
              variant="outlined"
              value={props.sourceID}
              onChange={handleSourceIDChange} />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={4}         
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <InputLabel id="source-label">Source</InputLabel>
            <Select
              labelId="source-label"
              id="source-select"
              value={props.source}
              label="Source"
              onChange={handleSourceChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {sources.map((type) => {
                return <MenuItem key={type}
                  value={type}>{type}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={3}
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('transactions.startDate')}
              type='date'
              size='small'
              variant="outlined"
              value={ getDateOnly(props.startDate) }
              inputProps={{
                max: getDateOnly(props.endDate),
                required: true,
              }}
              onChange={handleStartDateChange} 
              InputLabelProps={{ shrink: true }} // Ensures label stays above the input field
            />
          </FormControl>
        </Grid>
        <Grid item
          md={3}
          sm={3}
          xs={6}>
          <FormControl fullWidth
            size='small'>
            <TextField id="outlined-basic"
              label={t('transactions.endDate')}
              type='date'
              size='small'
              variant="outlined"
              value={getDateOnly(props.endDate)}
              InputProps={{ inputProps: { min: getDateOnly(props.startDate) } }}
              onChange={handleEndDateChange}
              InputLabelProps={{ shrink: true }} // Ensures label stays above the input field
            />
          </FormControl>
        </Grid>
        <Grid item
          md={6}
          sm={3}
          xs={6}>
          <Button size='lg'
            variant='secondary'
            onClick={handleClearButtonClick}
            fullWidth>{t('clearAll')}</Button>
        </Grid>
        <Grid item
          md={6}
          sm={3}
          xs={6}>
          <Button size='lg'
            variant='primary'
            onClick={handleSearchButtonClick}
            startIcon={<SvgIcon><SearchSm></SearchSm></SvgIcon>}
            fullWidth>{t('search')}</Button>
        </Grid>
      </Grid>
      {props.isReloading ? <LoadingWheel></LoadingWheel> :
        <ScheduleTable list={props.schedules}
          reloadData={props.loadData}
          page={props.page}
          setPage={props.setPage}
          totalCount={props.totalCount}
          fieldToSort={props.fieldToSort}
          setFieldToSort={props.setFieldToSort}
          sortDirection={props.sortDirection}
          setSortDirection={props.setSortDirection}></ScheduleTable>}

    </Box>);
};

export default TransactionsBox;
