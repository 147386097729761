import { Stack } from '@mui/material';
import ScheduleCreateTop from './components/ReportSchedulesTop';
import ScheduleReportHeader from './components/ReportSchedulesHeader';
import { ScheduleCreateProvider } from './context/ScheduledReportsContext';
import Schedules from './components/Schedules';
import ScheduleReportTasks from './components/TaskTypes';
import ReportSchedulesPopUps from './components/ReportSchedulesPopUps';
import PageArea from '../../components/shared/PageArea';

const StockCountScheduleCreate=() => {
  return (
    <ScheduleCreateProvider>
      <PageArea>
        <Stack spacing={5}>
          <ScheduleReportHeader/>
          <ScheduleCreateTop/>
          <ScheduleReportTasks/>
          <Schedules/>
        </Stack>
      </PageArea>
      <ReportSchedulesPopUps/>
    </ScheduleCreateProvider>
  );
};

export default StockCountScheduleCreate;