import { Box, Tab, Tabs } from '@mui/material';
import { SupportTab } from './enums/SupportTab';
import { useState } from 'react';
import { purchaseOrderData, recipeData, stockCountData, stockOnHandData, transactionData, uomConversionsData, viewItemsData } from './data';
import SupportDeatils from './components/SupportDetails';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const Support = () => {
  const [activeTab, setActiveTab] = useState<SupportTab>(SupportTab.STOCK_CHECK);

  const handleTabChange = (event: React.SyntheticEvent, newValue: SupportTab) => {
    setActiveTab(newValue);
  };

  return (
    <PageArea>
      <PageTitle>Support</PageTitle>
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="stock-transfers-tabs">
          <Tab
            label='Stock check'
            value={SupportTab.STOCK_CHECK} />
          <Tab
            label='Stock on hand'
            value={SupportTab.STOCK_ON_HAND} />
          <Tab
            label='Transactions'
            value={SupportTab.TRANSACTIONS} />
          <Tab
            label='Purchase order'
            value={SupportTab.PURCHASE_ORDER} />
          <Tab
            label='View items'
            value={SupportTab.VIEW_ITEMS} />
          <Tab
            label='Recipes'
            value={SupportTab.RECIPIES} />
          <Tab
            label='UOM conversions'
            value={SupportTab.UOM_CONVERSIONS} />
        </Tabs>
        <Box sx={{ pt:3 }}>
          {activeTab === SupportTab.STOCK_CHECK && <SupportDeatils data={stockCountData}/>}
          {activeTab === SupportTab.STOCK_ON_HAND && <SupportDeatils data={stockOnHandData}/>}
          {activeTab === SupportTab.TRANSACTIONS && <SupportDeatils data={transactionData}/>}
          {activeTab === SupportTab.PURCHASE_ORDER && <SupportDeatils data={purchaseOrderData}/>}
          {activeTab === SupportTab.VIEW_ITEMS && <SupportDeatils data={viewItemsData}/>}
          {activeTab === SupportTab.RECIPIES && <SupportDeatils data={recipeData}/>}
          {activeTab === SupportTab.UOM_CONVERSIONS && <SupportDeatils data={uomConversionsData}/>}
        </Box>
      </Box>
    </PageArea>
  );
};

export default Support;