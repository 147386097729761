import {  Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { StockCountCreateContext, StockCountCreateValues } from '../contexts/StockCountCreateContext';
import InfoIcon from '@mui/icons-material/Info';

const StockCountCreateMain=() => {
  const { t,
    allUsers,
    handleSmartCountChange,
    handleDateChange,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    selectedDate,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    isSmartCount,
    currencySymbol,
    sliderValue } = useContext(StockCountCreateContext) as StockCountCreateValues;

  return (
    <Box sx={{ border:'1px solid', py: '12px', px: '8px' }}>
      <Grid container
        spacing={5}>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <TextField 
              label={t('name')}
              variant="outlined"
              value={name}
              onChange={handleInputChange}/>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <TextField 
              label={<>{t('scheduledDate')}<Tooltip title={t('scheduleDateInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip></>}
              type='date'
              variant="outlined"
              value={selectedDate}
              defaultValue={today}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: today }}
              onChange={handleDateChange}/>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink >
              {t('approver')}
              <Tooltip title={t('approverInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip>
            </InputLabel>
            <Select notched
              value={selectedUser}
              label={t('approver') + '--.'}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
              onChange={handleUserSelect}>
              {allUsers.map((option) => (
                <MenuItem key={option.emailAddress}
                  value={option.emailAddress}>
                  {option.emailAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <TextField 
              label={<>{t('approvalValueToleranceLabel') + ' ' + currencySymbol} <Tooltip title={t('approvalValueToleranceInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip></>}
              variant="outlined"
              value ={approvalValue}
              onChange={handleInputValue}
            />
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <Box sx={{ display: 'flex', alignItems: 'left' }}>
            <FormControlLabel
              control={<Switch checked={isSmartCount}
                onChange={handleSmartCountChange} />}
              label={<>{t('smartCount')}
                <Tooltip title={t('smartCountInfo')}>
                  <InfoIcon fontSize='small'
                    sx={{ ml: 2, verticalAlign: 'sub' }}/>
                </Tooltip>
              </>}
              labelPlacement="start"
              sx={{ ml:2 }}/>
          </Box>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={<Switch onChange={handleIsTimeLockedChanged}
                checked={isTimeLocked}/>}
              label={<>{t('lockHour')}
                <Tooltip title={t('lockHourInfo')}>
                  <InfoIcon fontSize='small'
                    sx={{ ml: 2, verticalAlign: 'sub' }}/>
                </Tooltip>
              </>}
              labelPlacement="start"
              sx={{ ml:2 }}/>
          </Box>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <FormControl fullWidth>
            <InputLabel shrink >
              {t('lockTime')}
              <Tooltip title={t('lockTimeInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip>
            </InputLabel>
            <Select
              label={'Lock Count Till Time' + '--.'}
              defaultValue={0}
              value={ selectedTime}
              disabled={!isTimeLocked}
              onChange={handleSelectChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {generateTimeOptions().map((option) => (
                <MenuItem key={option.value}
                  value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <Box sx={{ px: 6, mt: -4 }}>
            <Typography variant='textXS'>
              {t('approvalQtyValue')}: {sliderValue}%
              <Tooltip title={t('approvalQtyValueInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub', height: '16px', width: '16px' }}/>
              </Tooltip>
            </Typography>
            <Slider style={{ paddingTop: 0, paddingBottom: 0 }}
              value={sliderValue}
              min={0}
              step={1}
              max={100}
              onChange={handleSliderChange}
              valueLabelDisplay="off"
              aria-labelledby="non-linear-slider"/>
          </Box>
        </Grid> 
      </Grid>
    </Box>
  );
};

export default StockCountCreateMain;