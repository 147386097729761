import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDownloadDetail } from '../interfaces/ReportDownloadDetail';
import useNswagClient from './../../../hooks/api/useNswagClient';
import { prepareReports } from '../helpers/ReportingHelper';
import LoadingWheel from './../../../components/ui/LoadingWheel';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { BookClosed, DownloadCloud01 } from './../../../assets';
import CustomPagination from './../../../components/forms/Pagination';
import { theme } from '../../../theme';

const ReportHistoryTab = () => {
  const { t } = useTranslation('common');
  const [reports, setReports] = useState<ReportDownloadDetail[]>([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [downloadReportId, setDownloadReportId] = useState(0);
  const { getReportsAvailableForDownload, getReport } = useNswagClient();

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    setIsLoading(true);
    getReportsAvailableForDownload().then((response) => {
      if (response?.data?.reports) {
        const reportDetails = prepareReports(response.data.reports);
    
        setReports(reportDetails);
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  };
    
  const maxPages = () => {
    return Math.ceil(reports.length / rowsPerPage);
  };
    
  const visibleRows = () => {
    return reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
    
  const downloadReport = (name: string, id: number) => {
    setDownloadReportId(id);
    getReport(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
    
      const fileName = `${name}.xlsx`;
    
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setDownloadReportId(0);
    });
  };

  return (
    <>
      {
        isLoading ?
          <LoadingWheel /> :
          <>
            {
              visibleRows().map((item) => {
                return (
                  <Box key={item.id}
                    sx={{ 
                      display: 'flex', 
                      width: '100%', 
                      minHeight: '60px', 
                      p: '12px',
                      alignItems: 'center',
                      justifyContent: 'left', 
                      mb: '12px', 
                      border: `solid 1px ${theme.palette.custom.gray[200]}`, 
                      borderRadius: '4px' }}>
                    <SvgIcon sx={{ width: '20px', mr: '24px' }}><BookClosed /></SvgIcon>
                    <Typography variant="textMD"
                      sx={{ display: 'flex', flex: 1, justifyContent: 'left', textAlign: 'left', overflow: 'auto' }}>
                      {t(item.displayName ?? '')} {t('reporting.created-at')} {item.createdAt} 
                      { (item.dateFrom && item.dateTo) && ` for ${item.dateFrom} - ${item.dateTo}` }
                      <br />
                      {item.storeName}
                    </Typography>
                    <Button size='lg'
                      onClick={() => downloadReport(item.displayName ?? '', item.id ?? 0)}
                      disabled={downloadReportId === item.id}
                      startIcon={<SvgIcon><DownloadCloud01 /></SvgIcon>}>
                      <Typography sx={{ display: { xs: 'none', md: 'block' } }}>{t('reporting.download')}</Typography>
                    </Button>
                  </Box>
                );
              })
            } {
              visibleRows().length > 0 ?
                <CustomPagination
                  page={page}
                  setPage={setPage}
                  maxPages={maxPages()}
                  breakpointForChangeDisplay={120000}
                ></CustomPagination>
                :
                t('noData')
            }
          </>
      }
    </>
  );
};

export default ReportHistoryTab;