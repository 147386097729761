import { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { RecipeComponent } from '../../../../app/services/api/generated';
import RoundAndFormatValue from '../../../../components/shared/RoundAndFormatValue';

type Props = {
  item: RecipeComponent;
  currencyCode?: string;
}

const IngredientRow: FC<Props> = ({ item, currencyCode }) => {
  return (
    <TableRow
      key={`{${item.componentId}}` }
    >
      <TableCell>
        {item.componentId}
      </TableCell>
      <TableCell>
        {item.componentItemNumber}
      </TableCell>
      <TableCell>
        {item.componentDescription}
      </TableCell>
      <TableCell>
        {Number(item.weight).toFixed(2)}
      </TableCell>
      <TableCell>
        {Number(item.quantity).toFixed(2)}
      </TableCell>
      <TableCell>
        {<RoundAndFormatValue
          value={item.componentCostPriceOnHand ?? 0}
          currencyCode={currencyCode} />}
      </TableCell>
    </TableRow>
  );
};

export default IngredientRow;