import {  Alert, Autocomplete, Box, Button,  FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { AutomatedTask, Item, Store } from '../../../app/services/api/generated';
import { gintWaves } from '../enums/IAutomatedTask';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import { Trash01, Plus } from '../../../assets';

export interface AutomatedTasks  {
automatedTasks : AutomatedTask | undefined
}

interface ActionTypes {
  index : number,
  description: string,
}

const recursionTypes: ActionTypes[] = [
  { index: 0,description: 'Offset' },
  { index: 1,description: 'Day of month' },
];

const CreateScheduledReports =() => {
  const { 
    t,
    isEdit,
    showTasks,
    showAllStores,
    showCategories,
    showDateOffset,
    showEndOffset,
    showGintWaves,
    showIntegration,
    showLegalEntity,
    showMaximumRecordCountPerFile,
    showPercentageThreshold,
    franchises,
    allStores,
    showRecursionType,
    showStartOffset,
    franchisesType,
    roles,
    showRole,
    categoryNames,
    distincs,
    showAllDistricts,
    showHasRecentActivity,
    hasRecentActivity,
    showOnlyMadeInStore,
    madeInStore, 
    maximumRecordCountPerFile, 
    legalEntity,
    stores,
    integration,
    endOffset,
    startOffset,
    recursionType,
    dateOffset, 
    percentageThreshold,
    showDistricts,
    role,
    taskType,
    gintWavesType,
    selectedStores, 
    maintainanceData,
    categoriesType,
    showSelectItems,
    isLoadingItemData,
    handleSelectedStoresChange,
    setShowStores,
    setShowFranchises,
    setGintWavesType,
    setPercentageThreshold,
    setDateOffset,
    setMadeInStore,
    setIntegration,
    setMaximumRecordCountPerFile,
    setLegalEntity,
    setAllStores,
    setHasRecentActivity,
    setEndOffset,
    setStartOffset,
    setFormatedDistincs,
    setRecursionType,
    setRole,
    setFranchisesType,
    setFormatedWaves,
    setMaintainanceData,
    setCategoriesType,
    setFormatedCategories,
    deleteTaskType,
    selectedItemsFilteredByCategory,
    itemsFilteredByCategory,
    setSelectedItems,
    setShowWarning,  
    showWarning,
  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;
  
  useEffect(() => {
    if(allStores){
      setShowFranchises(true);
      setShowStores(false);
    }
    else{
      setShowStores(true);
      setShowFranchises(false);
    }    
  }, []);

  const [allSelected, setAllSelected] = useState(false);
  const [allCategoriesSelected, setAllCategoriesSelected] = useState(false);
  const [allFranchisesSelected, setAllFranchisesSelected] = useState(false);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const SELECT_ALL = 'Select All';
  const UNSELECT_ALL = 'Unselect All';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleStoreChange = (event: SelectChangeEvent) => {
    if(allStores){
      setShowFranchises(true);
      setShowStores(false);
    }
    else {
      setShowFranchises(false);
      setShowStores(true);
    }
    setAllStores(!allStores);
  }; 

  const handleMadeinStoreChange = (event: SelectChangeEvent) => {
    setMadeInStore(!allStores);
  };

  const handleLegalEntity = (event: ChangeEvent<HTMLInputElement>) => {
    setLegalEntity(event.target.value);
  };

  const handleEndOffset = (event: SelectChangeEvent<number>, child: ReactNode) => {
    const id = event.target.value as number; 
    if(id < startOffset!){
      setShowWarning(true);
    }
    else
    {
      setShowWarning(false);
    }
    setEndOffset(event.target.value as unknown as number);
  };

  const handleStartOffset = (event: SelectChangeEvent<number>, child: ReactNode) => {  
    const id = event.target.value as number;
    if(endOffset !== undefined && id > endOffset){
      setShowWarning(true);
    }
    else{
      setShowWarning(false);
    }
    setStartOffset(event.target.value as unknown as number);
  };

  const handleDateOffset = (event: SelectChangeEvent<number>, child: ReactNode) => {
    setDateOffset(event.target.value as unknown as number);
  };

  const handleRecordsPerFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10); 
    setMaximumRecordCountPerFile(newValue);
  };

 
  const handlePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newValue = parseInt(value, 10);

    if (value === '') {
      setPercentageThreshold(newValue);  
    } else if (newValue >= 0 && newValue <= 100) {
      setPercentageThreshold(newValue);
    } else if (newValue < 0) {
      setPercentageThreshold(0);
    } else if (newValue > 100) {
      setPercentageThreshold(100);
    }
  };


  const handleRoleChange = (event: SelectChangeEvent<number>, child: ReactNode) => {
    setRole(event.target.value as unknown as number);
  };

  const handleRecursionType = (event: SelectChangeEvent<number>, child: ReactNode) => {
    setRecursionType(event.target.value as unknown as number);
  };

  const handleDistinctChange = (event: SelectChangeEvent<number>, child: ReactNode) => {
    setFormatedDistincs(event.target.value as unknown as number);
  };

  const handleIntegration = (event: ChangeEvent<HTMLInputElement>) => {
    setIntegration(event.target.value);
  };
  
  const handleFranchisesChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    if (newValue.some(option => option === SELECT_ALL)) {
      setAllFranchisesSelected(true);
      setFranchisesType(franchises?.map(option => option) as string[] || []);
    }
    else if((newValue.some(option => option === UNSELECT_ALL))) {
      setAllFranchisesSelected(false);
      setFranchisesType([]);
    } 
    else {
      const selectedIds = newValue.map(option => option);
      setFranchisesType(selectedIds);
    }
    
  };
  
  const handleStoresChange = (event: React.ChangeEvent<{}>, newValue: Store[]) => {
   
    if (newValue.some(store => store.storeNumber === SELECT_ALL)) {
      setAllSelected(true);
      handleSelectedStoresChange(stores?.map(option => option.storeNumber) as string[] || []);
    } 
    else if (newValue.some(store => store.storeName === UNSELECT_ALL)) {
      setAllSelected(false);
      handleSelectedStoresChange([]);
    }
    else {
      const selectedIds = newValue.map(option => option.storeNumber);
      handleSelectedStoresChange(selectedIds as string[]);
    }
  };

  const handleItemChange = (event: React.ChangeEvent<{}>, newValue: Item[]) => {
 
    if (newValue.some(item => item.id === 0)) {
      setAllItemsSelected(true);
      setSelectedItems(itemsFilteredByCategory);
    } 
    else if (newValue.some(item => item.id === 1)) {
      setAllItemsSelected(false);
      setSelectedItems([]);
    }
    else {
      setSelectedItems(newValue);
    }
    
  };
 

  const handleCategoryChange = (event: React.ChangeEvent<{}>, newValue: { id: number | string ; name: string; }[]) => {
    if (newValue.some(option => option.name === UNSELECT_ALL)) {
      setAllCategoriesSelected(false);
      setCategoriesType([]);
    } 
    else if (newValue.some(option => option.name === SELECT_ALL)) {
      setAllCategoriesSelected(true);
      setCategoriesType(categoryNames?.map(option => option.name) || []);
    } 
    else {
      const selectedIds = newValue.map(option => option.id.toString());
      const selectedCategories = newValue.map(option => option.name);
      setFormatedCategories(selectedIds);
      setCategoriesType(selectedCategories);
    }
  };
  
  const handleSelectWaves = (event: React.ChangeEvent<{}>, newValue: { name: string; waveNumber: string; }[]) => {
    const selectedIds = newValue.map(option => option.name );
    const formatedData = newValue.map(option => option.waveNumber);
    setFormatedWaves(formatedData);
    setGintWavesType(selectedIds);
  };
  
  const generateStoreOptions = () => {
    return [
      { storeNumber: allSelected ? UNSELECT_ALL : SELECT_ALL, storeName: allSelected ? UNSELECT_ALL : SELECT_ALL },
      ...(stores || []),
    ];
  };

  const generateItemsOptions = () => {
    return [
      { id: allItemsSelected ? 1 : 0, itemNumber: allItemsSelected ? UNSELECT_ALL : SELECT_ALL },
      ...itemsFilteredByCategory,
    ];
  };
  
  const generateFranchiseOptions = (franchises :string[] ) => {
    return allFranchisesSelected ? [UNSELECT_ALL, ...franchises] : [SELECT_ALL, ...franchises];
  };

  const generateCategoriesOptions = () => {
    return [
      { id: allCategoriesSelected ? UNSELECT_ALL : SELECT_ALL, name: allCategoriesSelected ? UNSELECT_ALL : SELECT_ALL },
      ...(categoryNames || []),
    ];
  };

  const generateCategoriesValue = () => {
    if (categoriesType === undefined) {
      return [];
    }
    if (!isEdit) {
      return categoryNames?.filter(option => categoriesType.includes(option.name ?? '')) ?? [];
    } else {
      const convertedArray = categoriesType.map(str => parseInt(str, 10));
      const containsNaN = convertedArray.some(num => isNaN(num));
      if (containsNaN) {
        return categoryNames?.filter(option => categoriesType.includes(option.name ?? '')) ?? [];
      } else {
        const filteredCategories = categoryNames.filter(category => convertedArray.includes(category.id));
        return filteredCategories.map(category => ({ id: category.id, name: category.name })) ?? [];
      }
    }
  };
  
  const offsets = Array.from({ length: 31 }, (_, i) => -30 + i);
  const startOffsets = Array.from({ length: 31 }, (_, i) => -30 + i);

  const handleStoredProcedureChange = (index: number, newValue : string) => {
    const updatedData = [...maintainanceData!];
    updatedData[index].storedProcedure = newValue;
    setMaintainanceData(updatedData);
  };

  const handleSchemaChange = (index: number, newValue : string) => {
    const updatedData = [...maintainanceData!];
    updatedData[index].schema = newValue;
    setMaintainanceData(updatedData);
  };

  const handleTimeOutChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    const updatedData = [...maintainanceData!];
    updatedData[index].timeOut = value;
    setMaintainanceData(updatedData);
  };

  const addNewMaintainanceData = () => {
    const newData = {
      storedProcedure: '',
      schema: '',
      timeOut:0,
    };
    setMaintainanceData([...(maintainanceData ?? []), newData]);
  };

  

  return (
    <Box sx={{ border:'2px solid', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        sx={{ p:'10px' }}
      >
        <Grid item 
          xs ={7}
        >
          <Typography variant="textXL"
            sx={{ textAlign:'left',fontWeight:'bold' }}>{t('typesTitle')}</Typography>
        </Grid>
      </Grid>
      { showTasks  && taskType !=='Maintenance' && (
        <Grid container
          spacing={5}
          flexWrap='wrap'
          sx={{ p:'10px' , pb:'10px' }}
        >
          { showAllStores && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('allStores')}</Typography>
              <Switch
                defaultChecked={allStores}
                onChange={handleStoreChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          )}
          { !allStores && taskType !== 'SbuxGintWaves' && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Autocomplete
                fullWidth
                multiple
                options={generateStoreOptions()}
                value={stores?.filter(option => selectedStores?.includes(option.storeNumber ?? '')) ?? []}
                onChange={handleStoresChange}
                getOptionKey={(option) => option.storeNumber ?? 0}
                getOptionLabel={(option) => option.storeName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('stores')}
                  />
                )}
              />
            </Grid> 
          )}
          { allStores === true && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Autocomplete multiple
                options={generateFranchiseOptions(franchises!)}
                getOptionKey={(option) => option ?? 0}
                getOptionLabel={(option) => option ?? ''}
                value={franchisesType?.filter(option => option.includes(option ?? '')) ?? []}
                onChange={handleFranchisesChange}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('franchises')}
                  />
                )}/>
            </Grid> 
          )}
          {showSelectItems &&
          <Grid item
            xs={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                disableCloseOnSelect 
                options={generateItemsOptions()}
                value={selectedItemsFilteredByCategory}
                onChange={handleItemChange}
                loading={isLoadingItemData}
                getOptionKey={(option) => option.id ?? ''}
                getOptionLabel={(option) => (option && (option.id === 1 || option.id === 0) ?`${option.itemNumber}`: `${option.itemNumber} - ${option.description}`) ?? ''}
                disabled={itemsFilteredByCategory.length === 0 && !isLoadingItemData}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('searchForItem')}
                  />
                )}
              />
            </FormControl>
          </Grid>}
          { showCategories && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Autocomplete multiple
                options={generateCategoriesOptions()}
                getOptionKey={(option) => option.id ?? 0}
                getOptionLabel={(option) => option.name ?? ''}
                value={generateCategoriesValue()}
                onChange={handleCategoryChange}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('categories')}
                  />
                )}/>
            </Grid> 
          )}
          { showHasRecentActivity && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('activity')}</Typography>
              <Switch
                defaultChecked={hasRecentActivity}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => setHasRecentActivity(!hasRecentActivity)}
              />
            </Grid>
          )}
          { showDistricts && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('showDistinct')}</Typography>
              <Switch
                defaultChecked={allStores}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          )}
          { showAllDistricts && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('district')}</InputLabel>
                <Select
                  label={t('district')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  onChange={handleDistinctChange}
                >
                  {distincs.map((option) => (
                    <MenuItem key={option.id}
                      value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> 
          )}
          { showRecursionType && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('recursion')}</InputLabel>
                <Select
                  label={t('recursion')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  value={recursionType}
                  onChange={handleRecursionType}
                >
                  {recursionTypes.map((option) => (
                    <MenuItem key={option.index}
                      value={option.index}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> 
          )}
          { showStartOffset && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('startOffset')}</InputLabel>
                <Select
                  label={t('startOffset')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  value={startOffset}
                  onChange={handleStartOffset}
                >
                  {startOffsets.map((option) => (
                    <MenuItem key={option}
                      value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          { showEndOffset && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('endOffset')}</InputLabel>
                <Select
                  label={t('endOffset')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  value={endOffset}
                  onChange={handleEndOffset}
                >
                  {offsets.map((option) => (
                    <MenuItem key={option}
                      value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          { showDateOffset && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('dateOffset')}</InputLabel>
                <Select
                  label={t('dateOffset')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  value={dateOffset}
                  onChange={handleDateOffset}
                >
                  {offsets.map((option) => (
                    <MenuItem key={option}
                      value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          { showLegalEntity && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <TextField
                  label={t('legalEntity')}
                  size='small'
                  variant="outlined"
                  value={legalEntity}
                  onChange={handleLegalEntity}
                >
                </TextField>
              </FormControl>
            </Grid>
          )}
          { showIntegration && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <TextField
                  label={t('integration')}
                  size='small'
                  variant="outlined"
                  value={integration}
                  onChange={handleIntegration}
                >
                </TextField>
              </FormControl>
            </Grid>
          )}
          { showGintWaves && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Autocomplete multiple
                options={gintWaves}
                getOptionKey={(option) => option.waveNumber ?? 0}
                getOptionLabel={(option) => option.name ?? ''}
                value={gintWaves?.filter(option => (gintWavesType.includes(option.name ?? '') ?? [] ))}
                onChange={handleSelectWaves}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('gintWaves')}
                  />
                )}/>
            </Grid> 
          )}
          { showMaximumRecordCountPerFile && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <TextField
                  type='number'
                  label={t('countPerFile')}
                  size='small'
                  variant="outlined"
                  value={maximumRecordCountPerFile}
                  onChange={handleRecordsPerFileChange}
                >
                </TextField>
              </FormControl>
            </Grid>
          )}
          { showRole && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <InputLabel id="type-label">{t('roles')}</InputLabel>
                <Select
                  label={t('roles')}
                  size='small'
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  value={role}
                  onChange={handleRoleChange}
                >
                  {roles?.map((option) => (
                    <MenuItem key={option.key}
                      value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> 
          )}
          { showPercentageThreshold && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <FormControl fullWidth
                size='small'>
                <TextField
                  type='number'
                  label={t('percentage')}
                  size='small'
                  placeholder='%'
                  variant="outlined"
                  value={percentageThreshold}
                  inputProps={{ min: 0, max: 100 }}
                  onChange={handlePercentageChange}
                >
                </TextField>
              </FormControl>
            </Grid>
          )}
          { showOnlyMadeInStore && (
            <Grid item
              xs={12}
              md={4}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('madeInStore')} </Typography>
              <Switch
                defaultChecked={madeInStore}
                inputProps={{ 'aria-label': 'controlled' }}
                value={madeInStore}
                onChange={handleMadeinStoreChange}
              />
            </Grid>
          )}
        </Grid>
      )}
      {! showTasks   && (
        <Alert severity='info'
          variant= { 'outlined'}
          sx={{ mb: '12px', mx: '8px' }}>
          {t('noTasks')}</Alert> 
      )}
      {showTasks && taskType ==='Maintenance' && (
        <Box>
          <Grid container
            spacing={5}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            sx={{ p:'10px' , pb:'10px' }}
          >
            <Grid item 
              xs ={12} 
              sx ={{ display:'flex',justifyContent:'flex-end', alignItems:'flex-end' }}>
              {maintainanceData?.length! > 0 && ( 
                <Button
                  size='lg'
                  sx={{ mr: 3 }}
                  variant='bad'
                  onClick={() => deleteTaskType()}
                ><Trash01/>{t('remove')}
                </Button>
              )}
              <Button
                size='lg'
                sx={{ mr: 3 }}
                variant='primary'
                onClick={addNewMaintainanceData} 
              ><Plus/>{t('addTask')}
              </Button>
            </Grid>
          </Grid>
          {maintainanceData?.map((row, index) => (
            <Grid container
              spacing={5}
              key={index}
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              sx={{ p:'10px' , pb:'10px' }}
            >
              <Grid item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
                <FormControl fullWidth
                  size='small'>
                  <TextField
                    label={t('schema')}
                    size='small'
                    variant="outlined"
                    value={row.schema}
                    onChange={(e) => handleSchemaChange(index, e.target.value)}
                  >
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
                <FormControl fullWidth
                  size='small'>
                  <TextField
                    label={t('storedProcedure')}
                    size='small'
                    variant="outlined"
                    value={row.storedProcedure}
                    onChange={(e) => handleStoredProcedureChange(index, e.target.value)}
                  >
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
                <FormControl fullWidth
                  size='small'>
                  <TextField
                    label={t('timeOut')}
                    size='small'
                    variant="outlined"
                    value={row.timeOut}
                    onChange={handleTimeOutChange(index)}
                  >
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'  }}>
                <Trash01  color={theme.palette.error[500]}
                  onClick={() => deleteTaskType(index)}
                />
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
      {showWarning && (
        <Alert severity='error'
          variant= { 'outlined'}
          sx={{ mb: '12px', mx: '8px' }}>
          {t('offsetWarning')} </Alert>
      )}
    </Box>
      
  );
};

export default CreateScheduledReports;