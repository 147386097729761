import { createContext, useMemo } from 'react';
import { usePurchaseOrdersDetails } from '../hooks/usePurchaseOrderDetails';
import { SelectChangeEvent } from '@mui/material';
import { Item, PurchaseOrderHeader } from '../../../app/services/api/generated';
import { LineRecord } from '../interfaces/LineRecord';
import { SelectableItem } from '../interfaces/SelectableItem';

export interface PurchaseOrderDetailsValues {
  poLineChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  searchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleNoteButtonClick: (id: number, note: string) => void,
  closeModal: () => void,
  closeStockCountModal: () => void,
  closeCreditRequestModal: () => void,
  closeCreditRequestCutOffModal: () => void,
  handleQuantityChange: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleUomsChange: (id: number) => (event: SelectChangeEvent) => void,
  handleNotesChange: (id: number, note: string) => void,
  handleReasonChange: (id: number, reason: string) => void,
  sortedPoLines: LineRecord[] | undefined,
  isShowingTrafficCode: boolean | undefined,
  handlePurchaseOrderNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  copyOrderQuantities: () => void,
  closeSnackBar: () => void,
  saveClicked: () => void,
  receiveClick: () => void,
  oneClickReceiveClick: () => void,
  isReceiving: boolean,
  id: string | undefined,
  purchaseOrders: PurchaseOrderHeader | undefined,
  isStockCheckModalOpen: boolean,
  isCreditRequestModalOpen: boolean,
  isCreditRequestCutOffModalOpen: boolean,
  snackBarDuration: number | null,
  isSnackBarOpen: boolean,
  snackbarMessage: string,
  snackBarSeverity: 'error' | 'warning' | 'info' | 'success',
  note: string,
  noteId: number,
  purchaseOrderNote: string,
  search: string,
  poLineSearch: string,
  cutOffTime: string,
  isEdited: (line: LineRecord | undefined) => boolean,
  lines: LineRecord[],
  isNoteModalOpen: boolean,
  order: 'asc' | 'desc',
  orderBy: keyof LineRecord,
  handleSort: (orderBy: keyof LineRecord) => void,
  isConfirmOpen: boolean,
  closeConfirmModal: () => void,
  acceptStockCountModal: () => void,
  isLoading: boolean,
  receivePurchaseOrder: () => void,
  isProcessing: boolean,
  searchClick: () => void,
  clearAll: () => void,
  canAdjust: boolean,
  showCopyModal: boolean,
  showCopyQuantityModal: () => void,
  showAdjustButton: boolean,
  showReceive: boolean | undefined,
  adjustClicked: () => void,
  isEditable: boolean | undefined,
  confirmModal: () => void,
  isClickable: boolean,
  isAdjusting: boolean,
  cancelAdjust: () => void,
  showOneClickReceive: boolean | undefined,
  showSave: boolean,
  addItemClicked: () => void,
  showAddItemModal: boolean,
  handleSearchTermChange: (value: string) => void,
  items: Item[],
  isLoadingItemData: boolean,
  closeAddItemModal: () => void,
  itemSelected: (items: Item[]) => void,
  selectedItems: SelectableItem[],
  searchTerm: string,
  handleUomChange: (id: number, value: string) => void,
  handleModalQuantityChange: (id: number, value: string) => void,
  handleCostPriceChange: (id: number, value: string) => void,
  confirmAddItems: () => void,
  canStoreAddItemsToPO: boolean,
}

export const PurchaseOrderDetailsContext = createContext<null | PurchaseOrderDetailsValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const PurchaseOrderDetailsProvider = ({ children }: ProviderProps) => {
  const {
    poLineChange,
    searchChange,
    handleNoteButtonClick,
    closeModal,
    closeStockCountModal,
    closeCreditRequestModal,
    closeCreditRequestCutOffModal,
    handleQuantityChange,
    handleUomsChange,
    handleNotesChange,
    handleReasonChange,
    sortedPoLines,
    isShowingTrafficCode,
    handlePurchaseOrderNoteChange,
    copyOrderQuantities,
    closeSnackBar,
    saveClicked,
    receiveClick,
    oneClickReceiveClick,
    isReceiving,
    id,
    purchaseOrders,
    isStockCheckModalOpen,
    isCreditRequestModalOpen,
    isCreditRequestCutOffModalOpen,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    note,
    noteId,
    purchaseOrderNote,
    search,
    poLineSearch,
    cutOffTime,
    isEdited,
    lines,
    isNoteModalOpen,
    order,
    orderBy,
    handleSort,
    isConfirmOpen,
    closeConfirmModal,
    acceptStockCountModal,
    isLoading,
    receivePurchaseOrder,
    isProcessing,
    searchClick,
    clearAll,
    canAdjust,
    showCopyModal,
    showCopyQuantityModal,
    showAdjustButton,
    showReceive,
    adjustClicked,
    isEditable,
    confirmModal,
    isClickable,
    isAdjusting,
    cancelAdjust,
    showOneClickReceive,
    showSave,
    addItemClicked,
    showAddItemModal,
    handleSearchTermChange,
    items,
    isLoadingItemData,
    closeAddItemModal,
    itemSelected,
    selectedItems,
    searchTerm,
    handleUomChange,
    handleModalQuantityChange,
    handleCostPriceChange,
    confirmAddItems,
    canStoreAddItemsToPO,
  } = usePurchaseOrdersDetails();

  const passedValue = {
    poLineChange,
    searchChange,
    handleNoteButtonClick,
    closeModal,
    closeStockCountModal,
    closeCreditRequestModal,
    closeCreditRequestCutOffModal,
    handleQuantityChange,
    handleUomsChange,
    handleNotesChange,
    handleReasonChange,
    sortedPoLines,
    isShowingTrafficCode,
    handlePurchaseOrderNoteChange,
    copyOrderQuantities,
    closeSnackBar,
    saveClicked,
    receiveClick,
    oneClickReceiveClick,
    isReceiving,
    id,
    purchaseOrders,
    isStockCheckModalOpen,
    isCreditRequestModalOpen,
    isCreditRequestCutOffModalOpen,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    note,
    noteId,
    purchaseOrderNote,
    search,
    poLineSearch,
    cutOffTime,
    isEdited,
    lines,
    isNoteModalOpen,
    order,
    orderBy,
    handleSort,
    isConfirmOpen,
    closeConfirmModal,
    acceptStockCountModal,
    isLoading,
    receivePurchaseOrder,
    isProcessing,
    searchClick,
    clearAll,
    canAdjust,
    showCopyModal,
    showCopyQuantityModal,
    showAdjustButton,
    showReceive,
    adjustClicked,
    isEditable,
    confirmModal,
    isClickable,
    isAdjusting,
    cancelAdjust,
    showOneClickReceive,
    showSave,
    addItemClicked,
    showAddItemModal,
    handleSearchTermChange,
    items,
    isLoadingItemData,
    closeAddItemModal,
    itemSelected,
    selectedItems,
    searchTerm,
    handleUomChange,
    handleModalQuantityChange,
    handleCostPriceChange,
    confirmAddItems,
    canStoreAddItemsToPO,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);

  return (
    <PurchaseOrderDetailsContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </PurchaseOrderDetailsContext.Provider>
  );
};