import { Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  TextField } from '@mui/material';
import { GetTransfersResponse } from '../../../app/services/api/generated';
import { useState } from 'react';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { SearchSm } from '../../../assets';
import { StockTransfersFilterOptions } from '../interfaces/StockTransfersFilterOption';
import { StockTransfersTab } from '../enums/StockTransfersTab';
import StockTransfersTable from './StockTransfersTable';
import { StockTransferType } from '../interfaces/StockTransferType';

export const stockTransferTypes: StockTransferType[] = [
  { value: 'All', description: 'type.all' },
  { value: 'Outgoing', description: 'type.outgoing' },
  { value: 'Incoming', description: 'type.incoming' },
];
  
const StockTransfersBox = (props: {stockTransfers: GetTransfersResponse, activeTab: StockTransfersTab
    loadData: (options: StockTransfersFilterOptions) => void;}) => {
  
  const defaultSort='transferDate';
  const defaultTake= 10;
  const defaultSkip= 0;
  const defaultDirection='desc';
  
  
  
  const [transferNumberSearch, setTransferNumberSearch] = useState('');
  const [stockTransferType, setStockTransferType] = useState('All');
  const [date, setDate] = useState('');
  const [page, setPage] = useState(0);
  const { t } = useTranslation('stockTransfers');
  
  const callLoadDataFromAPI = (options: StockTransfersFilterOptions) => {
    props.loadData(options);
  };
  
  const loadDataFromStockTransfersTable = (sort: string, direction: string, skip: number, take: number) => {
    callLoadDataFromAPI({
      transferNumber: transferNumberSearch !== '' ? parseInt(transferNumberSearch): undefined,
      transferType: stockTransferType,
      requestedDate: date,
      sort: sort,
      direction: direction,
      skip: skip,
      take: take,
    });
  };
  
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };
  
  const handleTransferNumberSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransferNumberSearch(event.target.value);
  };
  
  const handleStockTransferTypeChange = (event: SelectChangeEvent<string>) => {
    setStockTransferType(event.target.value);
    
  };
  
  const clearAll = () => {
    setTransferNumberSearch('');
    setDate('');
    setStockTransferType('All');
    callLoadDataFromAPI({
      transferNumber: undefined,
      transferType: '',
      requestedDate: '',
      sort: defaultSort,
      direction: defaultDirection,
      skip: 0,
      take: 10,
    });
  
    setPage(1);
  };
  
  
  const searchClick = () => {
    const transfertype = stockTransferType === 'All' ? '' : stockTransferType;
    callLoadDataFromAPI({
      transferNumber: transferNumberSearch !== '' ? parseInt(transferNumberSearch): undefined,
      transferType: transfertype,
      requestedDate: date,
      sort: defaultSort,
      direction: defaultDirection,
      skip: defaultSkip,
      take: defaultTake,
    });
  
    setPage(1);
  };
  
  
  return <Grid container
    sx={{ border: '1px solid', borderColor: theme.palette.custom.gray[300], py: '12px', px: '8px' }}
    spacing={5}>
      
    <Grid item
      md={3}
      xs={12}>
      <FormControl fullWidth
        size='small'>
        <TextField id="transfer-number-filter"
          label={t('filter.search_placeholder')}
          size='small'
          variant="outlined"
          value={transferNumberSearch}
          onChange={handleTransferNumberSearchChange} />
      </FormControl>
    </Grid>
  
    <Grid item
      md={3}
      xs={12}>
      <FormControl fullWidth
        size='small'>
        <InputLabel id="type-label">{t('filter.type_placeholder')}</InputLabel>
        <Select
          labelId="label"
          id="transfer-type-filter"
          value={stockTransferType}
          label={t('filter.type_placeholder')}
          onChange={handleStockTransferTypeChange}
        >
         
          {stockTransferTypes.map((type) => (
            <MenuItem key={type.value}
              value={type.value}>{t(type.description)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  
    <Grid item
      md={2}
      xs={12}>
      <FormControl fullWidth
        size='small'>
        <TextField id="transfer-date-filter"
          label={t('filter.date_placeholder')}
          type='date'
          size='small'
          variant="outlined"
          value={date}
          InputLabelProps={{ shrink: true }}
          onChange={handleDateChange}/>
      </FormControl>
    </Grid>

    <Grid item
      xs={12}
      md={2}
      sx={{  display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <Button
        variant="secondary"
        size="lg"
        onClick={clearAll}
        fullWidth
      >
        {t('filter.clear')}
      </Button>
    </Grid>
  
    <Grid item
      xs={12}
      md={2}
      sx={{  display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <Button
        variant="primary"
        size="lg"
        startIcon={<SvgIcon> <SearchSm color='white' /> </SvgIcon>}
        onClick={searchClick}
        fullWidth
      >
        {t('filter.search')}
      </Button>
    </Grid>
  
    <Grid item
      xs={12}
      sx={{ mt: '20px' }} >
      <div style={{ overflowX: 'auto' }}>
        { props.stockTransfers &&
          <StockTransfersTable list={props.stockTransfers}
            setPageZero={page}
            loadData={loadDataFromStockTransfersTable}
            activeTab={props.activeTab}
          ></StockTransfersTable>
        }
      </div>
    </Grid>
  </Grid>;
};
  
export default StockTransfersBox;