import { Box, Button, Grid, SvgIcon, TextField } from '@mui/material';
import ApplicationRolesTable from './ApplicationRolesTable';
import { Persona, PostPersonaRequest, KeyValuePair_2OfStringAndString } from '../../../app/services/api/generated';
import { useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { Plus, SearchSm } from '../../../assets';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import CreateEditModal from './RoleEditCreateModal';
import { PersonaPutPostRequest } from '../types/PersonaPutPostRequest';
import { theme } from '../../../theme';
import useLogError from '../../../hooks/useLogError';
import PageTitle from '../../../components/shared/PageTitle';

const MainBox = (props: { personas: Persona[], loadData: () => void, roles: KeyValuePair_2OfStringAndString[] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { createPersona } = useNswagClient();
  const { t } = useTranslation('manageRoles');
  const [showCreateRoleModal, setShowCreateRoleModal] = useState<boolean>(false);
  const [transformedData, setTransformedData] = useState<Persona[]>(props.personas ?? []);
  const [page, setPage] = useState(0);
  const { logError } = useLogError();

  const handleCreateRole = (body: PostPersonaRequest) => {
    createPersona(body).then(() => props.loadData()).catch((error) => {
      logError(error);
    });
  };

  const handleSearch = () => {
    setPage(0);
    const filteredData = props.personas.filter(x => 
      x.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      x.franchiseName?.toLowerCase().includes(searchTerm.toLowerCase()));
    setTransformedData(filteredData);
  };

  const handleClear = () => {
    setPage(0);
    setSearchTerm('');
    props.loadData();
  };

  return <>
    <PageTitle>{t('manageApplicationRoles')}</PageTitle>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Button variant="primary"
        size="lg"
        startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
        onClick={() => setShowCreateRoleModal(true)}>
        {t('createNew')}
      </Button>
    </Box>
    <Box
      sx={{
        border: '1px solid',
        borderColor: theme.palette.custom.gray[300],
        mt: 5,
      }}>
      <Grid item
        container
        p={5}
        xs={12}
        spacing={5}>
        <Grid item
          sm={12}
          md={4}>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            size='small'
            label={t('search')} />
        </Grid>
        <Grid item
          xs={12}
          sm={6}
          md={4}>
          <Button fullWidth
            disabled={searchTerm === ''}
            variant="secondary"
            size="lg"
            onClick={() => handleClear()}
          >
            {t('clearFilters')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          sm={6}
          md={4}>
          <Button fullWidth
            disabled={searchTerm === ''}
            variant="primary"
            size="lg"
            onClick={() => handleSearch()}
            startIcon={<SearchSm />}
          >
        Search
          </Button>
        </Grid>
      </Grid>
      <ApplicationRolesTable list={transformedData}
        setPage={setPage}
        page={page}
        roles={props.roles}
        loadData={props.loadData}></ApplicationRolesTable>
    </Box>

    <CreateEditModal openModal={showCreateRoleModal}
      onConfirm={(requestBody: PersonaPutPostRequest) => handleCreateRole(requestBody)}
      persona={undefined}
      roles={props.roles}
      onCancel={() => setShowCreateRoleModal(false)}></CreateEditModal></>;
};

const ManageApplicationRoles = (props: { personas: Persona[], loadData: () => void, isLoading: boolean, roles: KeyValuePair_2OfStringAndString[] }) => {
  return props.isLoading ? <LoadingWheel></LoadingWheel> : <MainBox personas={props.personas}
    loadData={props.loadData} 
    roles={props.roles}></MainBox>;
};

export default ManageApplicationRoles;