import { Alert, Box, Button, Grid, SelectChangeEvent, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useBlocker } from 'react-router-dom';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import { LocationResponse, StockCheckDetails, StockCheckItem, StockCheckStatus } from '../../app/services/api/generated';
import StockCountDetails from './components/StockCountDetails';
import { Save01, Play, Camera01 } from '../../assets';
import { StockCheckStatus as StockCheckStatusEnum } from '../../enums/StockCheckStatus';
import LoadingWheel from '../../components/ui/LoadingWheel';
import SubmitModal from './components/SubmitModal';
import { theme } from '../../theme';
import { UserContext } from '../../components/shared/useUser';
import PurchasedOrdersModal from './modals/PurchasedOrdersModal';
import LeavePageModal from '../../components/shared/dialogs/LeavePageModal';
import MessagesSnackbar from '../../components/shared/MessagesSnackbar';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import BackButton from '../../components/shared/actions/BackButton';
import { ConfigurationName } from '../../enums/ConfigurationName';

const StockCountDetailsPage = () => {
  const navigate = useNavigate();
  const { getStockCheckDetails, getPurchaseOrders, saveStockCheck, submitStockCheck,startStockCheck, getStoreLocations, getStockCheckByIdAndZoneId, saveStockCheckAtLocation, submitStockCheckAtLocation, getConfiguration } = useNswagClient();
  const [stockCheckData, setStockCheckData] = useState<StockCheckDetails>({});
  const { logError } = useLogError();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const stockCheckId = id ? parseInt(id) : undefined;
  const [showSavedAlert, setShowSavedAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [countComplete, setCountComplete] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [countDate, setCountDate] = useState<string | undefined>('');
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [disableCount, setDisableCount] = useState(false);
  const [isScannerModalOpen, setIsScannerModalOpen] = useState<boolean>(false);
  const [zonesData,  setZonesData] = useState<LocationResponse[]>([]);
  const [selectedZoneId, setSelectedZoneId] = useState<string>('-1');
  const [allZonesEnabled, setAllZonesEnabled] = useState<boolean>(true);
  const childRef = useRef<{ getUpdatedValues: () => { scheduledDate: string, stockCheckItems: StockCheckItem[] } | null }>(null);
  const { t } = useTranslation('common');
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isOnline, setIsOnline] = useState(true);
  const [nextPathname, setNextPathname] = useState('');
  const [showLocationWarning, setShowLocationWarning] = useState(false);
  const [hasBarcodeScanPermission, setHasBarcodeScanPermission] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      const newPathname = nextLocation.pathname; 
      setNextPathname(newPathname); 
      return !isOnline && currentLocation.pathname !== newPathname;
    },
  );

  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (!isOnline) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [navigator.onLine]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [navigator.onLine]);

  useEffect(() => {
    setLoading(true);
    GetStockCheckDetails(stockCheckId);

    if (selectedStore?.storeNumber) {
      loadZones(selectedStore?.storeNumber);
    }
  }, [countComplete]);


  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.BarcodeScanning, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      if (res.data.value === 'true') {
        setHasBarcodeScanPermission(true);
      }
    });
  }, []);

  
  const GetStockCheckDetails = (stockCheckId: number | undefined) => {
    setLoading(true);
    getStockCheckDetails(stockCheckId).then((result) => {
      if (result?.data) {
        if( !result?.data.stockCheck?.canStartCount && result?.data.stockCheck?.status === StockCheckStatusEnum.Pending){
          setShowErrorAlert(true);
          setCountDate(result.data.stockCheck.unlockTimeUtc);
          setDisableCount(true);
        }
        setStockCheckData(result.data);
        setAllZonesEnabled(result.data.allCountZonesEnabled ?? true);
        setLoading(false);
      }      
    })
      .catch((error) => {
        logError(error);
      });
  };

  const loadZones = (storeNumber: string) => {
    getStoreLocations(storeNumber).then((result) => {
      if (result?.data) {
        setZonesData(result.data);
      }      
    })
      .catch((error) => {
        logError(`Error fetching zones data:', ${error}`);
      });
  };
  
  const isStockPending = stockCheckData?.stockCheck?.status === StockCheckStatusEnum.Pending;
  const isStockCounting = stockCheckData?.stockCheck?.status === StockCheckStatusEnum.Counting;
  
  const handleCount = () => {
    setOpenPurchaseModal(false);
    const stockId = stockCheckId ?? 0;
    setLoading(true);
    startStockCheck(stockId).then(() => {
      setLoading(false);
      setCountComplete(true);
    })
      .catch((error) => {
        logError(error);
      });
  };
  const handleClose = () => {
    setOpenPurchaseModal(false);
  };
  const startCount = () => {
    const storeNumber = selectedStore?.storeNumber;
    getPurchaseOrders(storeNumber, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, undefined, undefined, undefined, 10).then((result) => {
      if (result?.data?.purchaseOrderHeadersCount! > 0 ){  
        setOpenPurchaseModal(true);
      } else {
        setLoading(true);
        handleCount();
      }})
      .catch((error) => {
        logError(error);
      });  
  };
 
  const getStatusLabel = (status: StockCheckStatus | undefined): string => {

    switch (status) {
    case StockCheckStatusEnum.Counting:
      return t('stockCountStatus.counting');
    case StockCheckStatusEnum.Completed:
      return t('stockCountStatus.completed');
    case StockCheckStatusEnum.Pending:
      return t('stockCountStatus.pending');
    case StockCheckStatusEnum.PendingApproval:
      return t('stockCountStatus.pendingApproval');
    default:
      return t('stockCountStatus.unknownStatus');
    }
  };

  const closeModal = () => {
    setIsScannerModalOpen(false);
  };

  const handleScannerClick = () => {
    setIsScannerModalOpen(true);
  };
  const formatCountDate = (inputString:string) => {
    const inputDate = new Date(inputString);
  
    const countDate = `${('0' + inputDate.getDate()).slice(-2)}-${(
      '0' +
    (inputDate.getMonth() + 1)
    ).slice(-2)}-${inputDate.getFullYear()} ${('0' + inputDate.getHours()).slice(
      -2,
    )}:${('0' + inputDate.getMinutes()).slice(-2)}`;

    return countDate;
  };
  const handleSave = () => {
    const { scheduledDate, stockCheckItems } = childRef.current?.getUpdatedValues() ?? {};

    // Update the stockCheckData with the new values
    const updatedStockCheckData: StockCheckDetails = {
      ...stockCheckData,
      stockCheck: {
        ...stockCheckData.stockCheck,
        plannedCountDate: (scheduledDate),
        items: stockCheckItems,
      },
    };

    if(selectedZoneId == '-1'){
      saveStockCheck(stockCheckId ?? 0, stockCheckItems)
        .then(() => {
          setStockCheckData(updatedStockCheckData);
          setShowSavedAlert(true);
        })
        .catch(err => {
          logError(err);
        });
    }
    else {
      saveStockCheckAtLocation(stockCheckId ?? 0, parseInt(selectedZoneId), stockCheckItems)
        .then(() => {
          setStockCheckData(updatedStockCheckData);
          setShowSavedAlert(true);
        })
        .catch(err => {
          logError(err);
        });
    }
  };

  const handleExitAndSave = () => {
    handleSave();
    navigate('/store/stock-count');
  };

  const handleConfirmModal = () => {
    setLoading(true);

    const { stockCheckItems } = childRef.current?.getUpdatedValues() ?? {};

    if(selectedZoneId === '-1') {
      submitStockCheck(stockCheckId ?? 0, stockCheckItems)
        .then(() => {
          setOpenModal(false);
          setLoading(false);
          navigate('/store/stock-count'); 
        })
        .catch((error) => {
          logError(error);
        });
    }
    else {
      submitStockCheckAtLocation(stockCheckId ?? 0, parseInt(selectedZoneId),  stockCheckItems)
        .then(() => {
          setOpenModal(false);
          setLoading(false);
          navigate('/store/stock-count'); 
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const submitButtonHandler = () => {
    setOpenModal(true);
  };

  const GetStockCheckByIdAndZoneId = (stockCheckId: number, zoneId: number) => {
    setLoading(true);
    getStockCheckByIdAndZoneId(stockCheckId, zoneId).then((result) => {
      if (result?.data) {
        if( !result?.data.stockCheck?.canStartCount && result?.data.stockCheck?.status === StockCheckStatusEnum.Pending){
          setShowErrorAlert(true);
          setCountDate(result.data.stockCheck.unlockTimeUtc);
          setDisableCount(true);
        }
        setStockCheckData(result.data);
        setAllZonesEnabled(result.data.allCountZonesEnabled ?? true);
        setLoading(false);
      }      
    })
      .catch((error) => {
        logError(error);
      });
  };

  const handleSelectedZoneChange = (event: SelectChangeEvent<string>) => {
    if(!isOnline){
      setShowLocationWarning(true);
      return;
    }

    setShowSavedAlert(false);
    const newValue = event.target.value;
    setSelectedZoneId(newValue);
    if(newValue != '-1')
    {
      GetStockCheckByIdAndZoneId(stockCheckId ?? 0, parseInt(newValue));
    }
    else {
      GetStockCheckDetails(stockCheckId);
    }
  };

  const handleSavingDataUserBackOnline =() => {
    handleSave();
  };
  const handleCloseSaveAlert = () => {
    setShowSavedAlert(false);
  };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };
  const onCancelLocationWarning = () => {
    setShowLocationWarning(false);
  };

  
  return (
    <Box display="flex"
      width="100%"
      height="100%"
      flexDirection="column" >
      <PageArea>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          {isStockPending && (
            <BackButton 
              url="/store/stock-count"
              title={t('back')}
              isLink
            />
          )}
          {isStockCounting && (
            <BackButton
              handleClick={handleExitAndSave}
              title={t('exitAndSave')}
              isLink={false}
              styles={{
                color: isOnline ? theme.palette.primary[500] : theme.palette.custom.gray[500],
                cursor: isOnline ? 'auto' : 'none',
              }}
            />
          )}
        </Grid>
        <Grid>
          <PageTitle>
            {t('stockCount')}: {stockCheckData?.stockCheck?.name}
          </PageTitle>
        </Grid>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <Typography variant="textSM">Status: {getStatusLabel(stockCheckData?.stockCheck?.status)}</Typography>
        </Grid>
        {isStockPending &&
          (<Grid item
            xs={12}
            sx={{ textAlign: 'right' }}>
            <Button variant="primary"
              size="lg"
              sx={{ mb: 4 }}
              disabled ={disableCount || !hasPermissionTo(['StockCountWrite'])}
              onClick={startCount}
              startIcon={<SvgIcon> <Play fill='white' /> </SvgIcon>}
            >
              {t('startCounting')}
            </Button>
          </Grid>)
        }
        {isStockCounting && (
          <> <Grid container
            sx={{ mt: 2 }}
            justifyContent="flex-end">
            <Grid item
              xs={12}
              sx={{ textAlign: 'right' }}>
              
              {isMobile && hasBarcodeScanPermission && <Button
                variant="primary"
                size="lg"
                sx={{ mb: 4 }}
                startIcon={<SvgIcon> <Camera01 /> </SvgIcon>}
                onClick={handleScannerClick}
              >
                {t('stockCountPage.barcodeScanning')}
              </Button>}

              <Button
                variant="primary"
                size="lg"
                startIcon={<SvgIcon fill='white'> <Save01 fill='white' /> </SvgIcon>}
                sx={{ ml: 4, mb: 4 }}
                disabled={!isOnline}
                onClick={handleSave}
              >
                {t('save')}
              </Button>

              <Button
                variant="primary"
                size="lg"
                type='submit'
                sx={{ ml: 4, mb: 4 }}
                disabled={!isOnline}

                onClick={submitButtonHandler}
              >
                {t('submit')}
              </Button>
            </Grid>
          </Grid>

          {showSavedAlert && <Grid item
            xs={12}
            sx={{ pt: '20px' }}>
            <Alert severity="success"
              onClose={handleCloseSaveAlert}>Success, the stock count values have been saved</Alert>
          </Grid>
          }
          <SubmitModal openModal={openModal}
            onConfirm={handleConfirmModal}
            onCancel={handleCancelModal}></SubmitModal>
          </>
        )}
        {showErrorAlert && (
          <Grid container
          >
            <Grid item
              xs={12}
              sx={{ display:'flex', justifyContent:'center',alignContent:'center' }}>
              <Alert 
                severity="error">You can start counting on {formatCountDate(countDate!)} </Alert>
            </Grid>
          </Grid>
        )}
        <Grid item
          xs={12}
          sx={{ pt: '20px' }}>
          {loading ? <LoadingWheel />
            : (isStockCounting || isStockPending) && 
            <StockCountDetails stockCheckDetails={stockCheckData}
              isScannerModalOpen={isScannerModalOpen}
              closeModal={closeModal}
              zonesData={zonesData}
              selectedZoneId={selectedZoneId}
              handleSelectedZoneChange={handleSelectedZoneChange}
              allZonesEnabled={allZonesEnabled}
              handleSavingDataUserBackOnline={handleSavingDataUserBackOnline}
              ref={childRef}></StockCountDetails>
          }
        </Grid>
      </PageArea>
      <PurchasedOrdersModal
        open={openPurchaseModal}
        onClose={handleClose}
        onCancel={handleCount}
      />
      <LeavePageModal
        open={blocker.state === 'blocked' && !isOnline}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onConfirmUrl={nextPathname}
        leaveWarningContent={t('stockcounts.warningOfflineMessage')}
      />
      <MessagesSnackbar
        open={showLocationWarning && !isOnline}
        onClose={onCancelLocationWarning}
        message={t('stockcounts.warningOfflineLocationSwitch')}
        severity="warning"
        verticalPosition="top"
        duration={null}
      />
    </Box>
  );
};

export default StockCountDetailsPage;