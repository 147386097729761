export const MuiDateCalendar = {
  styleOverrides: {
    root: { 
      padding: '8px',
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
    },
    day: {
      padding: '6px',
      fontSize: '0.75rem',
    },
  },
};