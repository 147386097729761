import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { WifiOff } from '../../../assets';

interface OnlineOfflineSnackbarProps {
  onOnline?: () => void; 
}

const OnlineOfflineSnackbar: React.FC<OnlineOfflineSnackbarProps> = ({ onOnline }) => {
  const { t } = useTranslation('common');
  const [online, setOnline] = useState(navigator.onLine);
  const [showOfflineMessage, setShowOfflineMessage] = useState(false);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);


  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      setShowOnlineMessage(true);
      setShowOfflineMessage(false);

      // Invoke the callback when online
      onOnline && onOnline();
    };

    const handleOffline = () => {
      setOnline(false);
      setShowOfflineMessage(true);
      setShowOnlineMessage(false);
      
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      
    };
  }, [onOnline]);

  const handleCloseOfflineMessage = () => {
    setShowOfflineMessage(false);

  };

  const handleCloseOnlineMessage = () => {
    setShowOnlineMessage(false);
  };
 
  return (
    <>
      {!online && <><Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showOfflineMessage}
        onClose={handleCloseOfflineMessage}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseOfflineMessage}
          severity="error"
          sx={{ width: '100%' }}>
          {t('stockcounts.offlineMessage')}
        </Alert>
      </Snackbar>
      <Tooltip title={t('stockcounts.warningOfflineMessage')}
        enterTouchDelay={0}>
        <IconButton
          title={t('stockcounts.warningOfflineMessage')}
          size='large'>
          <SvgIcon color='error'
            fontSize="large"><WifiOff></WifiOff></SvgIcon>
        </IconButton>
      </Tooltip></>
      }
      {online && <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showOnlineMessage}
        onClose={handleCloseOnlineMessage}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseOnlineMessage}
          severity="success"
          sx={{ width: '100%' }}>
          {t('stockcounts.onlineMessage')}
        </Alert>
      </Snackbar>
      }
    </>
  );
};

export default OnlineOfflineSnackbar;
