import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { useEffect, useState } from 'react';
import { ItemCompare } from '../../../../app/services/api/generated';
import { ChangesTable } from './components/ChangeTable';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../hooks/useLogError';
import LoadingWheel from '../../../../components/ui/LoadingWheel';

export const ChangesDialog = (props: {isOpen: boolean, closeDialog: () => void, changeId: number[], loadData: ()=>void, showActionButtons: boolean}) => {
  const { getChangeDetails, applyChanges, disregardChanges } = useNswagClient();
  const { logError } = useLogError();
  
  const [data, setData] = useState<ItemCompare | undefined>();
  const [isLoading, setIsLoading]= useState(false);
  const [isProcessing, setIsProcessing]= useState(false);
  const [isFinishedProcessing, setIsFinishedProcessing]= useState(false);
  const [isErrorProcessing, setIsErrorProcessing]= useState(false);
  const [errorMessage, setErrorMessage ] = useState('');
  const [selectedID, setSelectedID] = useState(-1);
  
  const { t } = useTranslation('recipeUpdates');

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const loadDataWithDelay = async () => {
    await delay(1000);
    reloadData();
  };

  const handleSelectIdChange = (event: SelectChangeEvent<string>) => {
    setSelectedID(Number(event.target.value));
    loadData(Number(event.target.value));
  };

  const reloadData = async () => {
    props.closeDialog();
    props.loadData();
  };

  const handleDisregardSelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await disregardChanges(props.changeId)
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        loadDataWithDelay();
      });
  };

  const handleApplySelected = async () => {
    setIsProcessing(true);
    setIsFinishedProcessing(false);
    setIsErrorProcessing(false);

    await applyChanges(props.changeId)
      .then(() => {
        setIsFinishedProcessing(true);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsErrorProcessing(true);
      })
      .finally(() => {
        setIsProcessing(false);
        loadDataWithDelay();
      });
  };

  useEffect(() => {
    let idToUSe = -1;
    if (props.changeId.length > 0){
      idToUSe = props.changeId[0];
      setSelectedID(props.changeId[0]);
    }
    loadData(idToUSe);
  }, [props.changeId]);

  const loadData = (id: number) => {
    if (id != -1){
      setIsLoading(true);
      getChangeDetails(id).then(
        (result) => {
          setData(result);
        },
      )      
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Dialog open={props.isOpen}
      onClose={props.closeDialog}
      maxWidth='lg'>
      {isLoading ? <LoadingWheel/> : 
        <>
          <DialogTitle>
            <Typography variant='displayXS'>
              {t('changesModal.title')}
            </Typography>
            <IconButton
              onClick={props.closeDialog}
              sx={{
                position: 'absolute',
                right: 16,
                top: 8,
                color: (theme) => theme.palette.custom.gray[800],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {props.changeId.length > 1 &&
        <FormControl fullWidth
          size='small'
          sx={{ mt: '8px' }}>
          <InputLabel id="type-label">{t('changesModal.store')}</InputLabel>
          <Select
            labelId="label"
            id="transfer-type-filter"
            value={props.changeId.some(x => x == selectedID) ? String(selectedID) : ''}
            label='Store'
            onChange={handleSelectIdChange}
          >
            {props.changeId.map((type) => (
              <MenuItem key={type}
                value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>}
            {data && <>
              {data?.changeData?.description}
              <ChangesTable data={data}></ChangesTable>
            </>}
          </DialogContent>
          {data && 
      <DialogActions sx={{ px: '24px' }}>
        <Grid container
          spacing={4}
          justifyContent="space-between">
          <Grid item
            xs={12}>
            {isErrorProcessing && <Alert severity='error'>{t('changesModal.errorMessage') + ' ' + errorMessage}</Alert>}
            {isFinishedProcessing && <Alert severity='success'>{t('changesModal.sucessfulMessage')}</Alert>}
            {isProcessing && <Alert severity='info'>{t('changesModal.processingMessage')}</Alert>}
          </Grid>
          <Grid item
            xs={12}>
            <Stack direction='row'
              justifyContent="flex-end"
              spacing={4}>
              <Button size='lg'
                variant='secondary'
                onClick={props.closeDialog}>
                {t('close')}
              </Button>
              {props.showActionButtons && <Button size='lg'
                onClick={handleDisregardSelected}
                disabled={isFinishedProcessing || isProcessing || isLoading}
                variant='badSecondary'>
                {t('disregard')}
              </Button>}
              {props.showActionButtons && <Button size='lg'
                onClick={handleApplySelected}
                disabled={isFinishedProcessing || isProcessing || isLoading}
                variant='primary'>
                {t('apply')}
              </Button>}
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>}
        </>}

    </Dialog>
  );
};
