import { useContext, useEffect } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { IconButton,
  Grid, 
  FormControl, 
  Select, 
  MenuItem, 
  TextField, 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../../../theme';

export const CreateStockTransfersItemsTable = () => {
  const { itemsToCreate,
    itemPossibleUOMs,
    handleUomChange,
    handleQuantityChange,
    isValidatingItemQuantity,
    deleteButtonHandler,
    setItemsToCreate,
    t,
  } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;

  const bodyStyle = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
    px: '12px',
  };

  useEffect(() => {
    setItemsToCreate([]);
  }, []);

  return (
    <>
      {itemsToCreate.length > 0 && <Grid item
        xs={12}>
        <TableContainer component={'div'}>
          <Table
            size="small"
            aria-label="a table"
            sx={{
              borderBottom: '1px solid',
              borderTop: '1px solid',
              borderColor: theme.palette.custom.gray[200],
              width: '100%',
            }}
          >
            <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('create_modal.item_number_header')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('create_modal.item_description_header')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('create_modal.item_uom_header')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('create_modal.item_quantity_header')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsToCreate.map((row) => (
                <TableRow
                  key={row.id ?? -1}
                  sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    {row.itemNumber}
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    {row.itemDescription}
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl fullWidth>
                      <Select
                        labelId="transaction-reasons-label"
                        id="transaction-reasons-select"
                        value={row.uom ?? ''}
                        sx={{ backgroundColor: theme.palette.common.white }}
                        onChange={handleUomChange(row.id ?? -1)}
                      >
                        <MenuItem key='None'
                          disabled
                          value=''>
                          <em>None</em>
                        </MenuItem>
                        {itemPossibleUOMs.find(x => x.id == row.id)?.uoms.map((uom) => {
                          return <MenuItem key={uom.code}
                            value={uom.code}>{uom.code}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl
                      size='small'
                      sx={{ width: '80px' }}>
                      <TextField id="outlined-basic"
                        type='number'
                        size='small'
                        sx={{ backgroundColor: theme.palette.common.white }}
                        disabled={!row.uom || row.uom.length === 0 || isValidatingItemQuantity || row.uom === ''}
                        onChange={handleQuantityChange(row.id ?? -1)}
                        error={row.quantityError}
                        helperText={row.errorDescription}
                        value={row.quantity}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <IconButton
                      size='small'
                      onClick={(event) => deleteButtonHandler(event, row.id ?? -1)}
                    >
                      <SvgIcon color='error'><CloseIcon></CloseIcon></SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>}
    </>
  );
};