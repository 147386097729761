
import {  Button, Typography,  Dialog,  DialogContent, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StockCountCreateContext, StockCountCreateValues } from '../../contexts/StockCountCreateContext';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const StockCountCreateDeleteCategoryModal=() => {
  const { isCategoryDeleteModalOpen,
    handleClose,
    deleteCategories,
    categoryData,
    categoryId } = useContext(StockCountCreateContext) as StockCountCreateValues;

  const { t } = useTranslation('createStockCount');

  const handleConfirm = () => {
    if( categoryId !== undefined){
      const data = categoryData?.filter(item  => item.id !== categoryId );
      deleteCategories(data);
    }
    else{
      deleteCategories([]);
    }
  };
  
  return (
    <Dialog open={isCategoryDeleteModalOpen}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {t('deleteCategory')}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('approvalDialogMessage')}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}> {t('cancel')}</Button>
        <Button variant="bad"
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCountCreateDeleteCategoryModal;