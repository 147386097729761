import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useContext } from 'react';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';

export const EditNoteModal = () => {
  const { closeEditNoteModal, note, openEditNoteModal, updateItemNote, itemId } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  const handleClose = () => {
    closeEditNoteModal();
  };

  return (
    <Dialog onClose={handleClose}
      open={openEditNoteModal}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>Edit Note</DialogTitle>
      <DialogContent sx={{ pt:'4px' }}>
        <TextField fullWidth
          multiline
          minRows={4}
          onChange={updateItemNote(itemId)}
          value={note}>
        </TextField>
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleClose}>
          {('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
