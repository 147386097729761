export const stockCountData = {
  title: 'Stock Count',
  overview: `
        <p>The Stock Count feature allows stores to record the actual amount of stock held. This information can then be used rectify any differences between the actual stock as counted, and the existing stock level in the system application. A Stock Count can be scheduled, so it will create a new identical Stock Count for the same items at a regular interval (e.g. weekly), with one-off Stock Counts created separately.</p>
        <p>All items on any count can be assigned a tolerance - either value or percentage. When the count of an item is within the tolerance range, the system will automatically create a transaction to adjust the quantity held to match that of the count. For items outside of the tolerance, an email is sent to the designated approver, who can decide whether to reject or approve each of these individual item counts.</p>
    `,
  keyFeatures: [
    'Schedule stock counts to automatically repeat the same count on a given interval and frequency.',
    'Manually create a stock count so additional, non-scheduled, counts can be made on ad hoc basis.',
    'Set tolerances allowing items within the range to be automatically approved.',
    'Set tolerances on the stock count header, or on an item-by-item level; using £, % or both.',
    'Item counts outside the approval tolerance(s) are sent via email to Admin, so they can be manually approved/rejected.',
    'Approved item counts create a Transaction, to update the quantity in the application to the amount counted.',
    'Create locations to list all the items held in a specific place.',
    'Create bespoke lists of items, so that counts can be made for a set group of items.',
    'Import item numbers from a spreadsheet to quickly create new lists and locations.',
    'Edit and delete lists or locations that already exist.',
    'Add all items from one or more lists or locations to a stock count to save adding items individually.',
    'Add / Remove individual or multiple items from an existing count or schedule to apply going forward.',
    'List of available Stock counts are listed with current status for user to select.',
    'List of completed Stock counts included in separate archive page.',
    'Stock Counts lists can be sorted by name, date, location and status.',
    'Stock Count lists can be filtered by name, date and location.',
    'When viewing a stock count, Print option allow full list to be printed.',
    'Items can be counted by their Primary UoM and Eaches, Cases and Boxes if these exist for the item.',
    'Approval and completion screens breaks the down total count by location and UoM.',
    'Approval screen highlights all items outside tolerance with details of system quantity compared to count quantity.',
  ],
  userGuide: [
    {
      guideTitle: 'Stock Count page',
      guideContent: `
                <p>The Stock Count page is listed under the main Stock option is the left-hand menu. When you open the page it will list all the available Stock Counts to the User in order of the Scheduled Date, with the newest first.</p>
                <p>There is a filter control above the list itself to filter by name (there may be more than one with the same name if created from a schedule), Date and Location. Filtering by Location means that one or more items from the selected location is included in a Count, and so the Counts returned after filtering may include other Locations, as well as the one selected.</p>
                <p>The main list includes the option above to toggle between the Archive, which lists completed Stock Counts, and the default view of Available Stock Counts, which is any that have been created, but not completed. The Created by column indicate whether a Count has been created by the User or generated from a Scheduled Stock Count.</p>
                <p>Also above the main list to the top right is the Create New Stock Count button (visibility will depend on the User’s authorisation level), and this is use to create a new ‘Ad-Hoc’ Stock Count, although it also includes an option to turn the count into a Schedule if required.</p>
            `,
    },
    {
      guideTitle: 'Creating New Stock Counts and Schedules',
      guideContent: `
                <p>When opening the Create Stock Count page the header lists standard Name and Description fields, and then a Planned Count Date. This is when the count is expected to take place, but doesn’t have any other function other than as a guide and for visibility in the main list. If the count is made on a different date the system will not do anything, and the Count will remain in the list until it is completed.</p>
                <p>The Approver field allows you to select who will receive email notification should any items on the count fall outside of the tolerance. This means that they will have to manually approve or reject each item. If no item fall outside of the tolerance, an email is still sent to confirm that the count has been completed.</p>
                <p>There are also 2 tolerance fields - one for % and one for £ - and both can be used if required. They do not get combined or applied in total, and whatever is entered here will apply to each line being counted, and saves the user having to input the same value on multiple lines.</p>
                <p>The tolerance fields are optional, however if left blank it means that the count made will be accepted for ALL items, so they will be automatically approved, and system values will update to match the count. A setting of 0 means the count will have to match the exact quantity / value held in the application prior to the count in order to be automatically approved, and any variances will be flagged to the approver.</p>
                <p>Setting up a Schedule means creating a template from which new counts are generated. The setup of Schedules is largely the same as setting up a normal Stock Count, although the planned date is not used; instead the Start Date lets you decide when the first Stock Count will be created, and the Frequency and Interval fields determine when and how often subsequent Stock Counts will be created. The Active field is used to allow or block new counts being created from the schedule.</p>
                <p>Below the Schedule section are 3 ‘Add Items’ options: Add Items From Location lets you select a Location (or use select all), to populate the Stock Count with all items for that Location, Add Items From List work in the same way. The Add Item button lets you search by item number or description to find particular items (see Stock Management section below for more on Locations and Lists).</p>
                <p>After adding items they will be listed below, and can be deleted individually (remove column), or by ticking multiple lines, then selecting remove all. The 2 tolerance column allow you set the individual tolerance for that item - if no value or percentage is entered, it means that item will use the tolerances set in the header section of the stock count.</p>
            `,
    },
    {
      guideTitle: 'Stock Management',
      guideContent: `
                <p>The Admin menu includes an option to view the Stock Management page, which includes Stock Locations and Stock Lists. Below these is the Scheduled Stock Counts, which lists all the different Schedules, along with the Store they apply to, and whether they are currently active or disabled. This section allows users to create new Schedules directly (rather than via the New Stock Count screen mentioned previously), or edit any of the existing Schedules. Note that any edits will apply to the next Stock Count created from that Schedule, and don’t affect Stock Counts already generated.</p>
                <p>The Manage Stock Locations section lists any Locations that have previously been created, alongside the Store they apply to. They can be selected to edit items included in that location, or deleted entirely - by using the “X” on the right-hand side. The Add New Stock Location button lets you select a Store from available options, and then assign a Code and Description to the location - note that the same code can’t be used twice for the same store.</p>
                <p>The Add New Item button allows you to set which items are stored at that Location, and once Saved the items will be listed underneath. These can then be added to or removed, and the filter above applies to the items you’ve already added, to find an item or show only items that are in a given category. When you add a Location to a Stock Count or Schedule, all the items listed against the Location will be added.</p>
                <p>The Import Stock Location button above the main list allows you to select a spreadsheet from which to create a new (or update an existing Location) with specified items. To ensure this works as expected, the first column on the sheet should include the items numbers you want to include, and the sheet name should be that of the Location you want to create/update. You can use different sheets - with different names - on the same Import to create / update multiple Locations at once.</p>
                <p>In addition, you can create / update Lists at the same time by using the 2nd, 3rd, 4th etc. columns to name any List(s) you would like to add that item to - if a list doesn’t already with that name, one will be created, if it does that item will be added to that, unless it already exists. After a successful Import, a summary is displayed, to state what action was taken for each Item, Location and List in the spreadsheet.</p>
                <p>The Import Stock List feature works the same way, although the sheet title will be the name of the List, and the 2nd, 3rd, 4th etc. columns are used to state the name of any Locations you would like the items added to.</p>
                <p>The rest of the Manage Stock List section works the same as for the Manage Stock Location section described above. The difference is in how they get used, as Lists are only designed to group certain types of items (such as Category e.g. Dairy, to quickly add to Stock Counts/Schedules), but don’t get used elsewhere. Locations do this as well, but also get used on the Stock Count itself, so users know where a given count need to take place, and can state how many items exist in a certain place. This breakdown also applies to displays, and the Count and Printouts are grouped by Location.</p>
            `,
    },
    {
      guideTitle: 'Making and Completing a Stock Count',
      guideContent: `
                <p>Note that there are several steps required to completing an actual Stock Count, after it has been created in the system. The details below cover all possible features, although the visibility and availability of these will depend on the Roles (authorisation) given to the User who has logged in.</p>
                <p>On the Stock list page, the right-hand column lists the current Status of each Stock Count. When an Ad-Hoc stock count is Saved, it is given a Status of NEW, which means it can still be edited. The next step is to Submit it for Counting, after which it is given a Status of Pending. This is also the Status given to any Stock Counts automatically generated from a Schedule, and shows all the items to be counted, ordered by Location - so an item may appear more than once. This screen gives the option to Print the Count, and click the Start Counting option when ready to input the Count numbers themselves.</p>
                <p>After clicking the Start Counting option, the Status progresses to ‘Counting’, and the fields become editable to enter the actual count. There are often several options in the Quantity fields of each item to include the primary unit of measure, as well as Eaches, Cases and Boxes where they exist for an item. You only need to input the number for UoM that you have counted, and if you have counted different UoMs for an item, enter the relevant data against the correct option, and this will be handled by the system.</p>
                <p>The Counting screen also allows the Count to be printed or Saved - for example if the User has counted one location and wants to do others later - so it will remain in that state. Once all fields have been completed, or left blank if required (this will act as if 0 were entered, i.e. no stock of that UoM at that Location for that item), click the Submit for Approval button in the top right.</p>
                <p>After the Submit for Approval button is clicked an email is sent to the Approver assigned to that count (this may or may not be the person doing the count). The email will say that a Count has been made, and state how many (if any) items fell outside of the tolerance (which was set when the Count was created), it also contains a link to view the Count.</p>
                <p>When clicking Submit for Approval, if all items were within the tolerance(s) set, then the Count will be given a Status of Completed with any required Transactions created (see later for more) and listed in the Archived section (also see later step). If any items were outside of the tolerance(s) set, then the Stock Count will be given a status of Pending Approval. This means they will require manual intervention from the Approver - to accept or reject the count made for these items - before the Stock Count can be Completed.</p>
                <p>The Pending Approval screen highlights all the items that fell outside of the tolerance(s) - above or below - and lists the System Quantity, Count Quantity and variance from the expected amount. The little + icon next to the SKU number in the left column allows you to see a breakdown of the Count made by Location and UoM.</p>
                <p>The right hand column give the option to Reject or Accept the count for each item, and below the list are options to Reject All or Accept All, which can them be changed on the individual lines if required. The lines are also given a colour accent on the left according to the selection made. Underneath the list of items needing approval is a View Stock Count option, which can be expanded to view all the items on the original count (i.e. items within the tolerance, as well as those highlighted above).</p>
                <p>Once all items have been set as Accepted or Rejected, the Stock Count can be completed, by clicking the Approve Stock Count button in the top right (this also creates any Transactions required - see below). This will set the status of the Stock Count to Completed, and if viewing from the main page, it will now be under the Archived section, along with all other completed Stock Counts.</p>
                <p>Whether or not a Stock Count needed approving, all item counts that are within the tolerance(s) set, but not exactly the same as the quantity expected, will have a Transaction automatically created. These Transactions will adjust (up or down) the amount held in the application, so it will match the quantity counted, and will be visible on the Transaction page, with a Transaction Type of Stock Adjustment Issue/Receipt.</p>
                <p>Any items that are manually accepted for Approval will have Transactions made in the same way, but items that get rejected for Approval will not have any Transaction made, and so the application quantity will remain unchanged. A new count can be created, or manual Transactions made separately for these items, if required.</p>
                <p>As mentioned the final Status for a Stock Count is Completed, and records of these are available from the Archive section. Viewing this screen shows the Count made, along with a breakdown for each item according to Location and UoM by clicking the + icon alongside the SKU number.</p>
            `,
    },
  ],
};
