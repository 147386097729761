import StockCountCreateCategoriesModal from './modals/StockCountCreateCategoriesModal';
import StockCountCreateItemsModal from './modals/StockCountCreateItemsModal';
import StockCountCreateBulkModal from './modals/StockCountCreateBulkModal';
import StockCountCreateDeleteCategoryModal from './modals/StockCountCreateDeleteCategoryModal';
import StockCountCreateDeleteItemsModal from './modals/StockCountCreateDeleteItemsModal';
import MessagesSnackbar from '../../../components/shared/MessagesSnackbar';
import { useContext } from 'react';
import { StockCountCreateContext, StockCountCreateValues } from '../contexts/StockCountCreateContext';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';

const StockCountCreatePopUps=() => {
  const { closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm } = useContext(StockCountCreateContext) as StockCountCreateValues;
    
  return (
    <>
      <StockCountCreateCategoriesModal/>
      <StockCountCreateItemsModal/>
      <StockCountCreateBulkModal/>
      <StockCountCreateDeleteCategoryModal/>
      <StockCountCreateDeleteItemsModal/>
      <LeavePageModal open={blocker.state === 'blocked'}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onConfirmUrl='/store/orders-history'/>
      <MessagesSnackbar onClose={closeSnackBar}
        open={isSnackBarOpen}
        severity={snackBarSeverity}
        message={snackbarMessage}
        duration={snackBarDuration}/>
    </>
  );
};

export default StockCountCreatePopUps;