import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material';
import { StockCheckItem } from '../../../app/services/api/generated';
import { Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';

interface ItemQuant{
  uom?: string,
  amount?: number,
  conversionToEach?: number,
}

export const BarcodeStockItemDetails = (props: {isOpen: boolean, closeModal: () => void, stockCheck: StockCheckItem | undefined, uom: string, setStockCheckItems: React.Dispatch<React.SetStateAction<StockCheckItem[]>>}) => {
  const { getByItemNumber } = useNswagClient();
  const { t } = useTranslation('common');
  const { selectedStore } = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState('');
  const [quantities, setQuantities] = useState<ItemQuant[]>([]);
  const { logError } = useLogError();

  useEffect(() => {
    const quantities = props.stockCheck?.itemValues?.map(x => {
      return { uom: x.uom, amount: x.countQuantity, conversionToEach: x.conversionToEach };
    });
    setQuantities(quantities ?? []);

    getByItemNumber(props.stockCheck?.itemNumber ?? '', selectedStore?.storeNumber)
      .then((result) => {
        setImageUrl(result.data?.imageUrl ?? '');
      })
      .catch((error) => logError(error));
  }, [props.stockCheck]);

  const handleQuantityChange = (uom?: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(event.target.value);
    const itemIndex = quantities.findIndex(x => x.uom === uom);

    if (itemIndex !== -1) {
      setQuantities(prevItems => {
        const updatedQuantities = [...prevItems];
        updatedQuantities[itemIndex] = { ...updatedQuantities[itemIndex], amount };
        return updatedQuantities;
      });
    } else {
      setQuantities(prevItems => [...prevItems, { uom: uom, amount }]);
    }
  };
  
  const handleSave = () => {
    if (props.stockCheck) {
      const updatedItemValues = props.stockCheck.itemValues?.map((value, index) => ({
        ...value,
        countQuantity: quantities[index]?.amount ?? value.countQuantity, // Use the stored value in quantities if available, otherwise use the current value
      }));

      const updatedStockCheck = {
        ...props.stockCheck,
        itemValues: updatedItemValues,
      };

      // Update the stockCheckItem with the updated values
      props.setStockCheckItems(prevItems => {
        const updatedItems = prevItems.map(item => item.itemNumber === updatedStockCheck.itemNumber ? updatedStockCheck : item);
        return updatedItems;
      });
    }
    props.closeModal();
  };

  return (
    <Dialog open={props.isOpen}
      maxWidth={'xs'}
      fullWidth>
      <DialogTitle> 
        <Grid container>
          <Grid item
            xs={4}>
            {imageUrl ? <Box 
              component='img'
              sx={{ width: '100px', height: '100px' }}
              src={imageUrl}/> : 
              <Box 
                component='img'
                sx={{ width: '100px', height: '100px', backgroundColor: theme.palette.custom.gray[200] }}/> }
          </Grid>
          <Grid item
            xs={8}>
            <Stack>
              <Typography variant='textXS'>{props.stockCheck?.itemNumber}</Typography>
              <Typography variant='textMD'>{props.stockCheck?.description}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}
          sx={{ pt: 4 }}>
          {quantities.map(
            (x) => (
              x.uom == props.uom ?
                <FormControl key={x.uom}>
                  <TextField type='number'
                    value={x.amount ?? ''}
                    autoFocus
                    label={<b>{x.uom + ' (' + x.conversionToEach + ' EA)'}</b>}
                    onChange={handleQuantityChange(x.uom)}/>
                </FormControl> :
                <FormControl key={x.uom}>
                  <TextField type='number'
                    value={x.amount ?? ''}
                    label={x.uom + ' (' + x.conversionToEach + ' EA)'}
                    onChange={handleQuantityChange(x.uom)}/>
                </FormControl>
            ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: '24px', pt: 0 }}>
        <Stack spacing={4}
          sx={{ width: '100%' }}>
          <Button variant='primary'
            fullWidth
            onClick={handleSave}>{t('save')}</Button>
          <Button variant='secondary'
            fullWidth
            onClick={props.closeModal}>{t('back')}</Button>
        </Stack>
      </DialogActions>
    </Dialog>

  );
};