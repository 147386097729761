import * as React from 'react';
import { Alert, Box, Button, Stack } from '@mui/material';
import { PendingChangesContext, PendingChangesValues } from '../../../contexts/PendingChangesContext';
import { theme } from '../../../../../theme';

export const PendingChangesButtons = () => {
  const { 
    t,
    data, 
    page, 
    handleDisregardSelected,
    handleApplySelected,
    isProcessing,
    isFinishedProcessing,
    isErrorProcessing,
    selectedRows,
    dismissAlerts,
    errorMessage,
  } = React.useContext(PendingChangesContext) as PendingChangesValues;

  React.useEffect(() => {}, [data, page]);

  return (
    <Box sx={{ borderTop: '1px solid', borderTopColor: theme.palette.custom.gray[200] }}>
      <Box sx={{ px: '8px', pt: '8px' }}>
        {isProcessing && <Alert severity='info'>{t('processingMessage')}</Alert>}
        {isFinishedProcessing && <Alert severity='success'
          onClose={dismissAlerts}>{t('sucessfulMessage')}</Alert>}
        {isErrorProcessing && <Alert severity='error'
          onClose={dismissAlerts}>{t('errorMessage') + ' ' + errorMessage}</Alert>}
      </Box>
      <Stack direction={'row'}
        justifyContent='space-around'
        sx={{ p:'8px' }}
        spacing={4}>
        <Button size='lg'
          variant='badSecondary'
          disabled={selectedRows.length == 0 || isProcessing}
          onClick={handleDisregardSelected}
          fullWidth>{t('disregardSelected')}</Button>
        <Button size='lg'
          variant='primary'
          disabled={selectedRows.length == 0 || isProcessing}
          onClick={handleApplySelected}
          fullWidth>{t('applySelected')}</Button>
      </Stack>
    </Box>
  );
};
