import { Button, Grid, SvgIcon, Tab, Tabs } from '@mui/material';
import StockCountPage from './components/StockCountPage';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from '../../assets';
import { ActiveTab } from '../../enums/ActiveTab';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './../../components/shared/useUser';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const StockCount=() => {
  const [active, setActive] = useState<ActiveTab>(ActiveTab.Active);
  const { hasPermissionTo } = useContext(UserContext);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: ActiveTab) => {
    setActive(newValue);
  };
  const handleClick = () => {
    navigate('/store/create-stock-count');
  };

  return (
    <PageArea>
      <Grid container>
        <Grid item 
          xs={12} >
          <PageTitle>
            {t('stockcounts.title')}
          </PageTitle>
        </Grid>
        <Grid container
          item
          xs={12}>
          <Grid item
            xs={8}>
            <Tabs value={active}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example">
              <Tab label={t('active')}
                value={ActiveTab.Active} /> 
              <Tab label={t('archived')}
                value={ActiveTab.Inactive} /> 
            </Tabs>
          </Grid>
          {
            hasPermissionTo(['StockCountWrite']) &&
            <Grid item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size='lg'
                sx={{ mt: 4 }}
                startIcon={<SvgIcon><Plus /></SvgIcon>}
                onClick={() => handleClick()} >{t('stockcounts.newitem')}</Button>
            </Grid>
          }
        </Grid>
        <Grid item
          xs={12}
          sx={{  pt:'10px' }}>
          <StockCountPage activeTab={active} />
        </Grid>
      </Grid>
    </PageArea>
  );
};

export default StockCount;