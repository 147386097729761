import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import { useContext } from 'react';
import { InvoiceDetailsContext, InvoiceDetailsValues } from '../contexts/InvoicesContext';

export function NoteModal() {
  const { t, closesNoteModal, note, isOpenNoteModal } = useContext(InvoiceDetailsContext) as InvoiceDetailsValues;

  const handleClose = () => {
    closesNoteModal();
  };

  return (
    <Dialog onClose={handleClose}
      open={isOpenNoteModal}>
      <DialogContent>{note}</DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
