import React, { ChangeEvent, Dispatch, SetStateAction, createContext, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { TFunction } from 'i18next';
import { useScheduleReport } from '../hooks/useScheduleReport';
import { AutomatedTaskSchedule, KeyValuePair_2OfInt32AndString, Store, AutomatedTaskPreviewDate, Item } from '../../../app/services/api/generated';
import { IMaintenanceTaskData } from '../interfaces/IAutomated';

export interface ScheduleCreateValues {
    t:  TFunction<'scheduledReports', undefined>,
    schedules: AutomatedTaskSchedule[] | undefined,
    name: string | undefined,
    emailUser: boolean,
    active: boolean,
    showSchedules: boolean | undefined,
    showTasks: boolean,
    showAllStores: boolean,
    showCategories: boolean,
    showDateOffset: boolean,
    showEndOffset: boolean,
    showFranchises: boolean,
    showGintWaves: boolean,
    showLegalEntity: boolean,
    showIntegration: boolean,
    showAllDistricts: boolean,
    showMaximumRecordCountPerFile: boolean,
    showPercentageThreshold: boolean,
    showStores: boolean,
    franchises: string[] | undefined,
    showRecursionType: boolean,
    showStartOffset: boolean,
    distrinct: boolean,
    allStores: boolean,
    selectAll: boolean,
    showHasRecentActivity: boolean | undefined,
    hasRecentActivity: boolean | undefined,
    showOnlyMadeInStore: boolean,
    saveUserReport: boolean,
    taskType: string | undefined,
    franchisesType: string[],
    today: string,
    selectedTask :number | undefined,
    selectedDate: number | undefined,
    interval: number | undefined,
    emails: string | undefined,
    showRole: boolean,
    roles: KeyValuePair_2OfInt32AndString[] | undefined,
    categoryNames: {
    id: number;
    name: string;
    }[],
    distincs: {
    id: number;
    name: string;
    }[],
    legalEntity: string | undefined,
    madeInStore: boolean | undefined, 
    maximumRecordCountPerFile: number | undefined, 
    stores: Store[] | undefined,
    integration: string | undefined,
    endOffset: number | undefined,
    startOffset: number | undefined,
    waves: string[] | undefined,
    recursionType: number | undefined,
    role: number | undefined,
    dateOffset: number | undefined, 
    percentageThreshold: number | undefined,
    showDistricts: boolean,
    isDeleteModalOpen: boolean,
    categoriesType: string[], 
    selectCategories: boolean,
    selectedWaves: boolean,
    gintWavesType: string[], 
    selectedStores: string[], 
    isSnackBarOpen: boolean,
    snackbarMessage: string,
    snackBarDuration: number | null,
    snackBarSeverity: 'error' | 'warning' | 'info' | 'success',
    isEdit: boolean,
    maintainanceData: IMaintenanceTaskData[] | undefined,
    previewData: AutomatedTaskPreviewDate[] | undefined,
    openDeleteModal: boolean,
    previewModal: boolean,
    formatedDistincs: number | undefined,
    showSelectItems: boolean,
    isLoadingItemData: boolean,
    endDateErrors: { [key: number]: string | null },
    setEndDateErrors: Dispatch<SetStateAction<{ [key: number]: string | null }>>,
    handleSelectedStoresChange: (passedStores: string[]) => void,
    setShowStores: Dispatch<SetStateAction<boolean>>,   
    setShowFranchises: Dispatch<SetStateAction<boolean>>,
    setCategoriesType: Dispatch<SetStateAction<string[]>>,
    setPercentageThreshold: Dispatch<SetStateAction<number | undefined>>,
    setDateOffset: Dispatch<SetStateAction<number | undefined>>,
    setRole: Dispatch<SetStateAction<number | undefined>>,
    setIntegration: Dispatch<SetStateAction<string | undefined>>,
    setMadeInStore: Dispatch<SetStateAction<boolean | undefined>>,
    setMaximumRecordCountPerFile: Dispatch<SetStateAction<number | undefined>>,
    setLegalEntity: Dispatch<SetStateAction<string | undefined>>,
    handleChange: (event: SelectChangeEvent<string>) => void,
    handleSelectAllChange: () => void,
    handleTypeChange: (event: SelectChangeEvent<number>) => void,
    handleDayChange: (event: SelectChangeEvent<number>) => void,
    handleInputValue: (event: ChangeEvent<HTMLInputElement>) => void,
    setSchedules: Dispatch<SetStateAction<AutomatedTaskSchedule[] | undefined>>
    setShowSchedules: Dispatch<SetStateAction<boolean | undefined>>
    addNewSchedule: () => void,
    setAllStores: Dispatch<SetStateAction<boolean>>,
    setName: Dispatch<SetStateAction<string | undefined>>,
    setEmails: Dispatch<SetStateAction<string | undefined>>,
    setActive: Dispatch<SetStateAction<boolean>>,
    setEmailUser: Dispatch<SetStateAction<boolean>>,
    setSaveUserReport: Dispatch<SetStateAction<boolean>>,
    setShowHasRecentActivity: Dispatch<SetStateAction<boolean | undefined>>,
    setEndOffset: Dispatch<SetStateAction<number | undefined>>,
    setStartOffset: Dispatch<SetStateAction<number | undefined>>,
    setWaves: (value: SetStateAction<string[] | undefined>) => void,
    setRecursionType: Dispatch<SetStateAction<number | undefined>>,
    deleteScheduleRow: (id?: number, index?: number) => void,
    setFranchises: Dispatch<SetStateAction<string[] | undefined>>,
    setFranchisesType: Dispatch<SetStateAction<string[]>>,
    onSave: () => void,
    handleClose: () => void,
    confirmDeleteSchedule: () => void,
    getAllPreviewDates: () => void,
    handleSelectAllCategories: () => void,
    handleSelectAllWaves: () => void,
    setGintWavesType: Dispatch<SetStateAction<string[]>>,
    closeSnackBar: () => void,
    goBack: () => void,
    setMaintainanceData: React.Dispatch<React.SetStateAction<IMaintenanceTaskData[]>>,
    deleteTaskType: (id?: number | undefined) => void,
    confirmDeleteTask: () => void,
    setFormatedCategories: Dispatch<SetStateAction<string[]>>,
    setFormatedWaves: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    setFormatedDistincs: React.Dispatch<React.SetStateAction<number | undefined>>,
    formatedWaves: string[] | undefined,
    setHasRecentActivity: Dispatch<SetStateAction<boolean | undefined>>,
    selectedItemsFilteredByCategory: Item[],
    itemsFilteredByCategory: Item[],
    setSelectedItems: Dispatch<SetStateAction<Item[]>>,
    setShowWarning: Dispatch<SetStateAction<boolean>>,
    showWarning: boolean,

}

export const ScheduleCreateContext = createContext<null | ScheduleCreateValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const ScheduleCreateProvider = ({ children }: ProviderProps) => {
  const {
    t,
    schedules,
    name,
    emailUser,
    active,
    showSchedules,
    showTasks,
    showAllStores,
    showCategories,
    showDateOffset,
    showEndOffset,
    showFranchises,
    showGintWaves,
    showLegalEntity,
    showIntegration,
    showMaximumRecordCountPerFile,
    showPercentageThreshold,
    showStores,
    franchises,
    showRecursionType,
    showStartOffset,
    showDistricts,
    taskType,
    distrinct,
    allStores,
    franchisesType,
    selectAll,
    today,
    selectedTask,
    selectedDate,
    interval,
    saveUserReport,
    emails,
    roles,
    showRole,
    categoryNames,
    distincs,
    showAllDistricts,
    showHasRecentActivity,
    hasRecentActivity,
    legalEntity,
    showOnlyMadeInStore,
    madeInStore, 
    maximumRecordCountPerFile, 
    stores,
    integration,
    endOffset,
    startOffset,
    waves,
    recursionType,
    role,
    dateOffset, 
    percentageThreshold,
    isDeleteModalOpen,
    categoriesType, 
    selectCategories,
    selectedWaves,
    gintWavesType,
    selectedStores, 
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    isEdit,
    maintainanceData,
    previewData,
    previewModal,
    openDeleteModal,
    formatedDistincs,
    formatedWaves,
    showSelectItems,
    isLoadingItemData,
    endDateErrors,
    setEndDateErrors,
    handleSelectedStoresChange,
    setShowStores,
    setShowFranchises,
    setCategoriesType,
    handleClose,
    confirmDeleteSchedule,
    setPercentageThreshold,
    setDateOffset,
    setRole,
    setMaximumRecordCountPerFile,
    setIntegration,
    setMadeInStore,
    setLegalEntity,
    setSchedules,
    setShowSchedules,
    handleChange,
    handleSelectAllChange,
    handleTypeChange,
    handleDayChange,
    handleInputValue,
    addNewSchedule,
    setAllStores,
    setName,
    setEmails,
    setActive,
    setEmailUser,
    setSaveUserReport,
    setShowHasRecentActivity,
    setEndOffset,
    setStartOffset,
    setWaves,
    setRecursionType,
    deleteScheduleRow,
    setFranchises,
    setFranchisesType,
    onSave,
    getAllPreviewDates,
    handleSelectAllCategories,
    handleSelectAllWaves,
    setGintWavesType,
    closeSnackBar,
    goBack,
    deleteTaskType,
    setMaintainanceData,
    confirmDeleteTask,
    setFormatedCategories,
    setFormatedWaves,
    setFormatedDistincs,
    setHasRecentActivity,
    selectedItemsFilteredByCategory,
    itemsFilteredByCategory,
    setSelectedItems,
    setShowWarning,
    showWarning,
  } = useScheduleReport();

  const passedValue = {
    t,
    schedules,
    name,
    emailUser,
    active,
    showSchedules,
    showTasks,
    showAllStores,
    showCategories,
    showDateOffset,
    showEndOffset,
    showFranchises,
    showGintWaves,
    showLegalEntity,
    showIntegration,
    showMaximumRecordCountPerFile,
    showPercentageThreshold,
    showStores,
    franchises,
    showRecursionType,
    showStartOffset,
    showDistricts,
    taskType,
    distrinct,
    allStores,
    franchisesType,
    selectAll,
    today,
    selectedTask,
    selectedDate,
    interval,
    saveUserReport,
    emails,
    roles,
    showRole,
    categoryNames,
    distincs,
    showAllDistricts,
    showHasRecentActivity,
    hasRecentActivity,
    legalEntity,
    showOnlyMadeInStore,
    madeInStore, 
    maximumRecordCountPerFile, 
    stores,
    integration,
    endOffset,
    startOffset,
    waves,
    recursionType,
    role,
    dateOffset, 
    percentageThreshold,
    isDeleteModalOpen,
    categoriesType, 
    selectCategories,
    selectedWaves,
    gintWavesType,
    selectedStores, 
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    isEdit,
    maintainanceData,
    previewData,
    previewModal,
    openDeleteModal,
    formatedDistincs,
    formatedWaves,
    showSelectItems,
    isLoadingItemData,
    endDateErrors,
    setEndDateErrors,
    handleSelectedStoresChange,
    setShowStores,
    setShowFranchises,
    setCategoriesType,
    handleClose,
    confirmDeleteSchedule,
    setPercentageThreshold,
    setDateOffset,
    setRole,
    setMaximumRecordCountPerFile,
    setIntegration,
    setMadeInStore,
    setLegalEntity,
    setSchedules,
    setShowSchedules,
    handleChange,
    handleSelectAllChange,
    handleTypeChange,
    handleDayChange,
    handleInputValue,
    addNewSchedule,
    setAllStores,
    setName,
    setEmails,
    setActive,
    setEmailUser,
    setSaveUserReport,
    setShowHasRecentActivity,
    setEndOffset,
    setStartOffset,
    setWaves,
    setRecursionType,
    deleteScheduleRow,
    setFranchises,
    setFranchisesType,
    onSave,
    getAllPreviewDates,
    handleSelectAllCategories,
    handleSelectAllWaves,
    setGintWavesType,
    closeSnackBar,
    goBack,
    deleteTaskType,
    setMaintainanceData,
    confirmDeleteTask,
    setFormatedCategories,
    setFormatedWaves,
    setFormatedDistincs,
    setHasRecentActivity,
    selectedItemsFilteredByCategory,
    itemsFilteredByCategory,
    setSelectedItems,
    setShowWarning,
    showWarning,
  };
  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  return (
    <ScheduleCreateContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </ScheduleCreateContext.Provider>
  );
};