import { Button, Dialog, DialogTitle, DialogContent,DialogContentText, DialogActions,  Typography, Link, IconButton } from '@mui/material';
import { News, Files } from '../../../app/services/api/orderManagementClient';
import DescriptionIcon from '@mui/icons-material/Description';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface NewsProps {
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
  selectedNews: News | null;
}

const NewsDetails = ( props: Readonly<NewsProps>) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation('common');
  
  const openImagePreview = (url: string) => {
    setSelectedImage(url);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedImage(null);
    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog open={props.isDialogOpen}
        onClose={props.handleCloseDialog}
        PaperProps={{ sx: { maxWidth: 'none' } }}>
        <DialogTitle>
          {props.selectedNews?.title}</DialogTitle>
        <DialogContent sx={{ width: 'auto' }}>
          <DialogContentText>
            <Typography
              sx={{ marginBottom: 8 }}>
              {<div dangerouslySetInnerHTML={{ __html: props.selectedNews?.teaser ?? '' }} />}
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: props.selectedNews?.body ?? '' }} />
          </DialogContentText>
          <DialogContentText>
            {props.selectedNews?.files?.map((file: Files, id: number) => (
              <Link
                key={`${id}-${file.fileUrl}`}
                href={`${file.fileUrl}`}
                underline="always"
                sx={{ display: 'inline-flex', alignItems: 'center', marginBottom: '10px',  cursor: 'pointer' }}
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  variant="primary"
                  color="primary"
                  sx={{ marginRight: '10px' }}>
                  <DescriptionIcon sx={{ marginRight: '4px' }} />
                  <Typography variant="textSM">{file.fileDescription}</Typography>
                </Button>
              </Link>
            ))}</DialogContentText>
          {
            props.selectedNews?.thumbnailUrl &&   
              <IconButton key={props.selectedNews?.thumbnailUrl}
                onClick={() => openImagePreview(props.selectedNews?.thumbnailUrl!)}>
                <img  
                  width={150}
                  height={150}
                  style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }}
                  src={props.selectedNews?.thumbnailUrl }
                  alt={'Could not open imape'}
                />
              </IconButton> 
          }
          {props.selectedNews?.imagesUrl?.map((url: string) => (
            
            <IconButton key={url}
              onClick={() => openImagePreview(url)}
              sx={{ cursor: 'pointer' }}>
              <img
                width={150}
                height={150}
                style={{ display: 'block', marginBottom: '5px' }}
                src={url}
                alt={'Could not open image'}
              />
            </IconButton>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseDialog}
            color="primary">{t('notificationClose')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDialogOpen}
        onClose={closeDialog}> 
        <DialogContent>
          <img
            src={selectedImage!}
            alt={`Image ${selectedImage} was not found`}
            style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}
            color="primary">{t('notificationClose')}</Button>
        </DialogActions> 
      </Dialog></>
  );
};

export default NewsDetails;