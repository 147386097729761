import { Box, Button, Grid, SvgIcon } from '@mui/material';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { GetPurchaseOrderHeadersResponse, OrderTypesResponse } from '../../app/services/api/generated';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import PurchaseOrdersBox from './components/PurchaseOrdersBox';
import { Plus } from './../../assets';
import { PurchaseOrdersFilterOptions } from './interfaces/PurchaseOrdersFilterOptions';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';


const PurchaseOrdersPage = () => {
  const { selectedStore } = useContext(UserContext);

  const { getPurchaseOrders, getOrderTypes } = useNswagClient();
  const [purchaseOrders, setPurchaseOrders] = useState<GetPurchaseOrderHeadersResponse>({});
  const [ordersType, setOrdersType] = useState<OrderTypesResponse>({});

  const { t } = useTranslation('common');

  useEffect(() => {
    loadData();
  }, [selectedStore]);

  const loadData = () => {
    const franchiseName = selectedStore?.franchiseName ?? '';
    getOrderTypes(franchiseName).then((result) => {
      if (result?.data) {
        setOrdersType(result.data);
      }
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadFilterData = (options: PurchaseOrdersFilterOptions) => {
    const {
      searchTerm,
      orderType,
      from,
      to,
      received,
      sort,
      direction,
      skip,
      take,
    } = options;

    const storeNumber = selectedStore?.storeNumber;
    const typetest = orderType === 'All' ? '' : orderType;
    getPurchaseOrders(storeNumber, undefined, searchTerm, undefined, typetest, from, to, undefined, received, sort, direction, skip, take)
      .then((result) => {
        if (result?.data) {
          setPurchaseOrders(result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageArea>
      <Box display="flex"
        width="100%"
        height="100%"
        flexDirection="column" >
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>{t('purchaseOrderReceipt.title')}</PageTitle>
        </Grid>
        {false && <Grid item //to be changed later based on configuration related to Create Purchase Order
          xs={12}
          sx={{ textAlign: 'right' }}>
          <Button variant="primary"
            size="lg"
            startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
          >
            {t('createNew')}
          </Button>
        </Grid>}

        <Grid item
          xs={12}
          sx={{ pt: '8px' }}>
          {purchaseOrders &&
            <PurchaseOrdersBox purchaseOrders={purchaseOrders}
              ordersType={ordersType}
              loadData={loadFilterData}
            />
          }
        </Grid>
      </Box>
    </PageArea>
  );
};

export default PurchaseOrdersPage;
