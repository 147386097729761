import {  Button, Typography, Dialog, DialogContent,  DialogActions, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { RecipeComponent } from '../../../app/services/api/generated';

type Props = {
  index: number;
  item: RecipeComponent;
  onRemoveComponent: (index: number) => void;
  open: boolean;
  onClose: () => void;
}

const DeleteRecipeComponentModal: FC<Props> = ({ open, onClose, index, item, onRemoveComponent }) => {

  const { t } = useTranslation('recipe');

  const handleConfirm = () => {
    onRemoveComponent(index);
  };

  return (
    <Dialog open={open}
      onClose={onClose}
      maxWidth='sm'>
      <DialogTitle>
        {t('deleteIngredientTitle')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('deleteIngredientConfirmation')} {item.componentDescription}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={onClose}> {t('cancel')}</Button>
        <Button variant="bad"
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecipeComponentModal;