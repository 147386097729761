import { Box,
  Button, 
  Grid, 
  IconButton, 
  SvgIcon, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Tooltip } from '@mui/material';
import { Edit05, Plus, Trash01 } from '../../assets';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../components/shared/useUser';
import { LocationRequest, LocationResponse } from '../../app/services/api/generated';
import LoadingWheel from '../../components/ui/LoadingWheel';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import MessagesSnackbar from './components/MessagesSnackbar';
import StockCountZonesDeleteDialog from './components/StockCountZonesDeleteDialog';
import StockCountZonesCreateEditDialog from './components/StockCountZonesCreateEditDialog';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { extractErrorMessage } from '../../utils';

const StockCountZonesPage = () => {

  const { t } = useTranslation('stockCountZones');
  const { getStoreLocations, createLocation, deleteLocation, updateLocation } = useNswagClient();
  const { logError } = useLogError();
  const { hasPermissionTo, selectedStore } = useContext(UserContext);
  const [hasPermissionToReadZones, setHasPermissionToReadZones] = useState<boolean>(false);
  const [hasPermissionToWriteZones, setHasPermissionToWriteZones] = useState<boolean>(false);
  const [data,  setData] = useState<LocationResponse[]>([]);
  const [isLoading, setIsLoading]= useState<boolean>(false);
  const [hasMoreThanNineZones, setHasMoreThanNineZones] = useState<boolean>(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isSubmitZoneProcessing, setIsSubmitZoneProcessing] = useState<boolean>(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false);
  const [isErrorNameTxtF, setIsErrorNameTxtF] = useState<boolean>(false);
  const [errorNameTxtFDescr, setErrorNameTxtFDescr] = useState<string>('');
  const [nameTxtFValue, setNameTxtFValue] = useState<string>('');
  const [zoneCreationErrorResponse, setZoneCreationErrorResponse] = useState<string>('');
  const [showCreationSuccessAlert, setShowCreationSuccessAlert] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleteZoneProcessing, setIsDeleteZoneProcessing] = useState<boolean>(false);
  const [zoneDeletionErrorResponse, setZoneDeletionErrorResponse] = useState<string>('');
  const [showDeletionSuccessAlert, setShowDeletionSuccessAlert] = useState<boolean>(false);
  const [candidateZoneToDelete, setCandidateZoneToDelete] = useState<number | undefined>();
  const [candidateZoneToEdit, setCandidateZoneToEdit] = useState<number | undefined>();
  
  useEffect(() => {
    if (selectedStore?.storeNumber) {
      setHasPermissionToReadZones(hasPermissionTo(['StockLocationRead']));
      setHasPermissionToWriteZones(hasPermissionTo(['StockLocationWrite']));
      if(hasPermissionTo(['StockLocationRead'])) {
        loadZones();
      }
    }
  }, [selectedStore]);

  const loadZones = async () => {
    setIsLoading(true);
    await fetchZones(selectedStore?.storeNumber ?? '');
    setIsLoading(false);
  };

  const fetchZones = async (storeNumber: string) => {
    try {
      const zonesResponse = await getStoreLocations(storeNumber);
      if(zonesResponse?.data)
      {
        setData(zonesResponse.data);
        setHasMoreThanNineZones(zonesResponse.data.length > 9);
      }
    } catch (error) {
      logError(`Error loading data:', ${error}`);
    }
  };

  const handleNewZoneBtnClick = async () => {
    setIsSubmitBtnDisabled(true);
    setIsErrorNameTxtF(false);
    setErrorNameTxtFDescr('');
    setNameTxtFValue('');
    setZoneCreationErrorResponse('');
    setIsSubmitZoneProcessing(false);
    setIsCreateDialogOpen(true);
  };

  const handleDeleteZoneBtnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    setCandidateZoneToDelete(id);
    setZoneDeletionErrorResponse('');
    setIsDeleteZoneProcessing(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditZoneBtnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    setCandidateZoneToEdit(id);
    setIsSubmitBtnDisabled(true);
    setIsErrorNameTxtF(false);
    setErrorNameTxtFDescr('');
    setNameTxtFValue(data.find(location => location.id === id)?.name ?? '');
    setZoneCreationErrorResponse('');
    setIsSubmitZoneProcessing(false);
    setIsCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = async () => {
    setCandidateZoneToEdit(undefined);
    setIsCreateDialogOpen(false);
    setZoneCreationErrorResponse('');
  };

  const handleCloseDeleteDialog = async () => {
    setIsDeleteDialogOpen(false);
    setZoneDeletionErrorResponse('');
  };

  const handleNameTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setNameTxtFValue(newValue);

    if(data.some(location => location.name === newValue)){
      setIsErrorNameTxtF(true);
      setErrorNameTxtFDescr(t('createDialog.txtFNameError'));
      setIsSubmitBtnDisabled(true);
    }
    else {
      setIsErrorNameTxtF(false);
      setErrorNameTxtFDescr('');
      setIsSubmitBtnDisabled(false);
    }

    if(newValue === '')
      setIsSubmitBtnDisabled(true);
  };

  const handleAddNewZoneConfirm = async () => {
    setIsSubmitZoneProcessing(true);
    if(nameTxtFValue !== '' && selectedStore?.storeNumber) {
      const body: LocationRequest = {
        name: nameTxtFValue.trim(),
        storeNumber: selectedStore.storeNumber,
      };
      createLocation(body).then((result) => {
        if (result.data?.isSuccess) {
          setIsSubmitZoneProcessing(false);
          setIsCreateDialogOpen(false);
          setShowCreationSuccessAlert(true);
          loadZones();
        }
      }).catch((error) => {
        logError(error);
        const errorMessage = extractErrorMessage(error);
        setZoneCreationErrorResponse(errorMessage ?? t('createDialog.errorResponseConfiguration', { storeName: selectedStore?.storeName }));
        setIsSubmitZoneProcessing(false);
      });
    }
  };

  const handleEditZoneConfirm = async () => {
    setIsSubmitZoneProcessing(true);

    if(nameTxtFValue !== '' && candidateZoneToEdit && selectedStore?.storeNumber) {
      updateLocation({
        id: candidateZoneToEdit,
        name: nameTxtFValue.trim(),
        storeNumber: selectedStore.storeNumber,
      }).then((result) => {
        if(result?.success) {
          setIsSubmitZoneProcessing(false);
          setIsCreateDialogOpen(false);
          setShowCreationSuccessAlert(true);
          setCandidateZoneToEdit(undefined);
          loadZones();
        }
      }).catch((error) => {       
        logError(error);
        const errorMessage = extractErrorMessage(error);
        setZoneCreationErrorResponse(errorMessage ?? t('editDialog.errorResponseConfiguration', { storeName: selectedStore?.storeName }));
        setIsSubmitZoneProcessing(false);
      });
    }
  };

  const handleDeleteZoneConfirm = async () => {
    setIsDeleteZoneProcessing(true);

    if(candidateZoneToDelete) {
      deleteLocation(candidateZoneToDelete).then((result) => {
        if(result.success) {
          setIsDeleteZoneProcessing(false);
          setIsDeleteDialogOpen(false);
          setShowDeletionSuccessAlert(true);
          loadZones();
        }
      }).catch((error) => {       
        logError(error);
        const errorMessage = extractErrorMessage(error);
        setZoneDeletionErrorResponse(errorMessage ?? t('deleteDialog.errorResponse'));
        setIsDeleteZoneProcessing(false);
      });
    }
  };

  return (
    <PageArea>
      <Box display="flex"
        width="100%"
        height="100%"
        flexDirection="column" >
        <Grid container>
          <Grid item
            xs={12}
            sx={{ textAlign: 'left' }}>
            <PageTitle>{t('title')}</PageTitle>        
          </Grid>
          <Grid
            item
            xs={8} >     
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {hasPermissionToWriteZones && (hasMoreThanNineZones ? (<Tooltip title={t('table.zones10Tooltip')}>
              <Box>
                <Button
                  variant='primary'
                  size='lg'
                  sx={{ ml: 4 }}   
                  disabled={hasMoreThanNineZones} 
                  startIcon={<SvgIcon><Plus fill='white' /></SvgIcon>}>{t('createButton')}
                </Button>
              </Box>
            </Tooltip>) : (
              <Button
                variant='primary'
                size='lg'
                sx={{ ml: 4 }}   
                disabled={hasMoreThanNineZones} 
                onClick={handleNewZoneBtnClick}       
                startIcon={<SvgIcon><Plus fill='white' /></SvgIcon>}>{t('createButton')}
              </Button>
            ))}
          </Grid>
        </Grid> 
      </Box>
      {isLoading ? <LoadingWheel/> : 
        (
          <TableContainer 
            sx={{ pt:5 }}>
            <Table aria-label="stock-count-zones-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('table.number')}
                  </TableCell>
                  <TableCell>
                    {t('table.name')}
                  </TableCell>
                  {hasPermissionToWriteZones && <TableCell align="right">
                    {t('table.actions')}
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? <TableRow><TableCell align='center'
                  colSpan={hasPermissionToWriteZones ? 3 : 2}>{t('table.noZone')}</TableCell></TableRow> : (data.map((zone, index) => (
                  <TableRow key={zone.id}>
                    <TableCell>
                      {index+1}
                    </TableCell>
                    <TableCell>
                      {zone.name}
                    </TableCell>       
                    {hasPermissionToWriteZones && <TableCell align="right">
                      <Tooltip title={t('table.editZoneTooltip')}>
                        <IconButton
                          sx={{ mr: '10px' }}
                          size='small'
                          onClick={(event) => handleEditZoneBtnClick(event, zone.id ?? -1)}>
                          <SvgIcon color='primary'><Edit05 /></SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('table.deleteZoneTooltip')}>
                        <IconButton
                          size='small'
                          onClick={(event) => handleDeleteZoneBtnClick(event, zone.id ?? -1)}>
                          <SvgIcon color='error'><Trash01 /></SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>}     
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      } 
      <StockCountZonesCreateEditDialog
        open={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        isSubmitZoneProcessing={isSubmitZoneProcessing}
        isErrorNameTxtF={isErrorNameTxtF}
        errorNameTxtFDescr={errorNameTxtFDescr}
        nameTxtFValue={nameTxtFValue}
        handleNameTextFieldChange={handleNameTextFieldChange}
        handleAddNewZoneConfirm={handleAddNewZoneConfirm}
        handleEditZoneConfirm={handleEditZoneConfirm}
        error={zoneCreationErrorResponse}
        isSubmitBtnDisabled={isSubmitBtnDisabled}
        candidateZoneToEdit={candidateZoneToEdit}
      />
      <StockCountZonesDeleteDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteZoneConfirm}
        isLoading={isDeleteZoneProcessing}
        error={zoneDeletionErrorResponse}
        zoneName={data.find((zone) => zone.id === candidateZoneToDelete)?.name}
      />
      <MessagesSnackbar
        open={showCreationSuccessAlert}
        onClose={() => setShowCreationSuccessAlert(false)}
        message={t('createDialog.successCreation')}
        severity="success"
      />
      <MessagesSnackbar
        open={showDeletionSuccessAlert}
        onClose={() => setShowDeletionSuccessAlert(false)}
        message={t('deleteDialog.successDeletion')}
        severity="success"
      />
    </PageArea>
  );
};

export default StockCountZonesPage;