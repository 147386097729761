import { Reason } from './../../../pages/CreditDetails/enums/reason';

export const purchaseOrderLineReasons = {
  [Reason.SHORTAGE]: 'Shortage',
  [Reason.DAMAGE]: 'Damage',
  [Reason.EXPIRED_STALE_PRODUCT]: 'Expired / Stale Product',
};

export const purchaseOrderLineReasonsTranslationMap = {
  [Reason.SHORTAGE]: 'reasons.shortage',
  [Reason.DAMAGE]: 'reasons.damage',
  [Reason.EXPIRED_STALE_PRODUCT]: 'reasons.expired_stale_product',
};

export const availablePurchaseOrderLineReasons = Object.entries(purchaseOrderLineReasons).map(([key, value]) => ({ key, value }));

export const getReason = (reason: string) => {
  return purchaseOrderLineReasonsTranslationMap[Number(reason) as keyof typeof purchaseOrderLineReasons];
};