import { InvoicesProvider } from './contexts/InvoicesContext';
import { InvoiceTitle } from './components/InvoiceTitle';
import { InvoicePopups } from './components/InvoicePopups';
import { InvoiceBox } from './components/InvoiceSearchBox';
import PageArea from '../../components/shared/PageArea';

const Invoices = () => {

  return (
    <InvoicesProvider>
      <PageArea>
        <InvoiceTitle></InvoiceTitle>
        <InvoiceBox></InvoiceBox>
        <InvoicePopups></InvoicePopups>
      </PageArea>
    </InvoicesProvider>

  );
};

export default Invoices;
