import React, { useEffect, useRef, useState } from 'react';
import {  Grid, Dialog, Button, DialogContent, styled, DialogTitle, IconButton } from '@mui/material';
import { useSelectedTheme } from '../../../hooks/useSelectedTheme';
import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import '../customStyle/ImageUpload.css';
import { PurchaseOrderLine, PurchaseOrderPhoto } from '../../../app/services/api/generated';
import { ImagePreview,ImagePreviewModal, ImageUploadComponentProps } from './../interfaces/CreditRequestInterfaces';
import InformationModal from '../modals/InformationModal';
import { useTranslation } from 'react-i18next';
 
const ImageUpload =(props: ImageUploadComponentProps) => {
  const CustomButton = styled(Button)({
    width: '100px', 
    height: '100px',  
    borderRadius: '0',
    border: '1px dotted',  
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
  const { selectedTheme: theme } = useSelectedTheme();
   
  const [imageFiles, setImageFiles] = useState<ImagePreview[]>([]);
  const [imagePreviewModal, setImagePreviewModal] = useState<ImagePreviewModal>({ show: false, fileData: null, fileSize: 0 });
  const [openFileTypeDialog, setOpenFileTypeDialog] = useState(false);  
  const [openFileSizeDialog, setOpenFileSizeDialog] = useState(false);   
  const [imageSize, setImageSize] = useState<string>();   
  const [purchaseOrderLine, setPurchaseOrderLine] = useState<PurchaseOrderLine>(props.selectedRow!);   
  const allowedFileTypes: string[] = [ 'gif', 'bmp', 'jpg', 'jpeg', 'png' ];
  const maxFileSize: number = 10*1024*1024;
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onCloseImageDialog = () => {
    setImagePreviewModal({ show: false, fileData: null, fileSize: 0 });  
  };  
  const { t } = useTranslation('createCreditRequest');
  useEffect(() => { 
    if (props.selectedRow?.photos) {
      const previews: ImagePreview[] = props.selectedRow.photos.map(photo => ({
        fileData: photo,
        fileSize: 0, 
        width: 110,  
        height: 110,  
      }));
      setImageFiles(previews);
    }
  }, [props.selectedRow]);

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const filenameParts = file.name.split('.');
      const fileExt = filenameParts[filenameParts.length - 1].toLowerCase();
      if (!allowedFileTypes.includes(fileExt)) {
        setOpenFileTypeDialog(true);
        return;
      }
      if (file.size > maxFileSize) {
        setOpenFileSizeDialog(true);
        const megabytes = (file.size / (1024 * 1024)).toFixed(2);
        setImageSize(megabytes);
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const fileData: PurchaseOrderPhoto = {
          fileName: file.name,
          fileData: fileReader.result as string,
        };
        addImage(fileData, file.size);
      };
      fileReader.readAsDataURL(file);
    }  
  };
 
  const onDeleteImage = ( image: ImagePreview,event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    removeImage(image.fileData);
  };

  const onPreviewImage = ( image: ImagePreview) => {
    setImagePreviewModal({
      show: true,
      fileData: image.fileData,
      fileSize: image.fileSize,
    });
  }; 

  const handleCloseFileSizeDialog = () => {
    setOpenFileSizeDialog(false);
  };

  const handleCloseFileTypeDialog = () => {
    setOpenFileTypeDialog(false);
  };

  const formatAllowedFileTypes = (fileTypes: string[]) => {
    return fileTypes.map(x => `.${x}`).join(',');
  };

  const removeImage = (fileData: PurchaseOrderPhoto) => {
    const newImageFiles = imageFiles.filter(image => image.fileData !== fileData);
    setImagePreviewModal({
      show: false,
      fileData: null,
      fileSize: 0,
    });
    setImageFiles(newImageFiles);
    const updatedPurchaseOrderLine = { ...purchaseOrderLine };
    updatedPurchaseOrderLine.photos = purchaseOrderLine?.photos!.filter(photo => { 
      return photo !== fileData;
    });
    setPurchaseOrderLine(updatedPurchaseOrderLine);
    props.updateSelectedRow(updatedPurchaseOrderLine);
  };
  
  const addImage = (fileData: PurchaseOrderPhoto, fileSize: number) => { 
    const image = { fileData, fileSize, width: 100, height: 100 };
    const newImageFiles = [...imageFiles, image]; 
    setImageFiles(newImageFiles);

    const updatedPurchaseOrderLine = { ...purchaseOrderLine };
    if (!updatedPurchaseOrderLine.photos) {
      updatedPurchaseOrderLine.photos = [];
    }
    const imgToAddInQueue:PurchaseOrderPhoto = {
      fileData: fileData.fileData,
      fileName: fileData.fileName,

    };
    updatedPurchaseOrderLine.photos.push(imgToAddInQueue);
    setPurchaseOrderLine(updatedPurchaseOrderLine);
    props.updateSelectedRow(updatedPurchaseOrderLine);
  }; 

  return (
    <>{ props.isButtonVisible && <Grid
      container
      spacing={2}
      display='flex'
      justifyContent="center"
      alignItems="center"
      p={6}>
      <CustomButton
        variant="secondaryIconOnly"
        onClick={() => {
          if (!fileInputRef.current) {
            return;
          }
          fileInputRef.current.click();
        }}
      >
        <AddPhotoAlternateOutlinedIcon fontSize="large" />
        <input
          type="file"
          id="uploader"
          ref={fileInputRef}
          hidden
          accept={formatAllowedFileTypes(allowedFileTypes)}
          onChange={onFileSelected} />
      </CustomButton >  
      <InformationModal open={openFileSizeDialog} 
        onClose={handleCloseFileSizeDialog} 
        message={t('modals.fileTooLargeWarning', {
          fileSize: imageSize, 
        })}
        title={t('modals.fileTooLargeTitle')}/> 
      <InformationModal open={openFileTypeDialog}
        onClose={handleCloseFileTypeDialog} 
        message={t('modals.badFileFormatWarning')}
        title={t('modals.badFileFormat')}/> 
    </Grid>}
    {purchaseOrderLine?.photos && purchaseOrderLine.photos.length > 0 && <><Grid
      container
      spacing={2}
      border='1px solid'
      borderColor={theme.palette.custom.gray[300]}
      display='flex'
      alignItems='self-end'
      p={6}>
      {imageFiles.map((imageFile) => (
        <Grid key={imageFile.fileData.fileName}
          className="uploader-item image-preview-container" 
          onClick={() => onPreviewImage(imageFile)}>
          <img
            alt={imageFile.fileData.fileName}
            className="image-preview"
            src={imageFile.fileData.fileData}
            width={imageFile.width}
            height={imageFile.height}
          />
          <Grid className="uploader-item image-preview-buttons-container">
            { props.isButtonVisible &&  <IconButton className="image-preview-delete-button"
              aria-label="delete" 
              onClick={(event) => {  
                onDeleteImage(imageFile, event); 
              }}>
              <CloseIcon />
            </IconButton>}
          </Grid>
        </Grid>
      ))}
    </Grid>
    <Dialog open={!!imagePreviewModal.show}
      onClose={onCloseImageDialog}>
      <DialogTitle>
        <IconButton onClick={onCloseImageDialog}
          style={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {imagePreviewModal.fileData && (
          <img
            src={imagePreviewModal.fileData.fileData}
            alt={imagePreviewModal.fileData.fileName}
            style={{ maxWidth: '100%', maxHeight: '100%' }} />
        )}
      </DialogContent>
    </Dialog>  
    </>}
    </> 
  );
};

export default ImageUpload;
