import { useContext } from 'react';
import { CreateStockTransfersContext, CreateStockTransfersValues } from '../contexts/CreateStockTransfersContext';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CreateStockTransfersHeader = () => {
  const { onCancel, t } = useContext(CreateStockTransfersContext) as CreateStockTransfersValues;

  return (
    <>
      <DialogTitle sx={{ pr: '48px' }}>
        {t('create_modal.title')}
      </DialogTitle>
      <IconButton
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

