import {  Alert, Box, Button, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Trash01 ,Plus } from '../../../assets';
import { useContext } from 'react';
import { StockCountCreateContext, StockCountCreateValues } from '../contexts/StockCountCreateContext';

const StockCountCreateItems=() => {
  const { t,
    hasItems,
    handleQtyChange,
    handleValueChange,
    handleClickBulkClick,
    handleClick,
    deleteItem,
    deleteItemRow,
    currencySymbol,
    convertedItems } = useContext(StockCountCreateContext) as StockCountCreateValues;

  return (
    <Box  sx={{ border:'1px solid' }}>
      <Grid container
        sx={{ pt: '12px', pb: '6px', px: '8px' }}>
        <Grid item 
          xs={6}>
          <Typography variant="textMD"
            sx={{ textAlign:'left',fontWeight:'bold' }}>{t('items')}</Typography>
        </Grid>
        <Grid item 
          xs={6} 
          sx={{ display:'flex',justifyContent:'flex-end',alignItems:'center' }}>
          {hasItems && (
            <Button size='lg'
              sx={{ mr: 3 }}
              onClick={deleteItem}
              variant='bad'
              startIcon={<Trash01/>}>{t('deleteAll')}
            </Button>
          )}
          <Button size='lg'
            sx={{ mr: 3 }}
            variant='primary'
            onClick={handleClickBulkClick}
            startIcon={<Plus/>}>
            {t('addBulks')}
          </Button>
          <Button size='lg'
            variant='primary'
            onClick={handleClick}
            startIcon={<Plus/>}>
            {t('addItem')}
          </Button>
        </Grid>
      </Grid>
      <Box sx= {{ pt:'10px' }}>
        {hasItems ? (
          <TableContainer sx={{ overflowy:'auto' }}>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell >{t('skuNumber')}</TableCell>
                  <TableCell >{t('description')}</TableCell>
                  <TableCell >{t('category')}</TableCell>
                  <TableCell >{t('approvalQtyValue')}</TableCell>
                  <TableCell >{t('qtyTolerance') + ' ' + currencySymbol}</TableCell>
                  <TableCell >{t('Remove')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {convertedItems?.map((row) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell >
                      {row.itemNumber}
                    </TableCell>
                    <TableCell >
                      {row.description}
                    </TableCell>  
                    <TableCell >
                      <TextField disabled
                        placeholder={row.category}> </TextField>
                    </TableCell>  
                    <TableCell>
                      <TextField InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      type='number'
                      value={row.tolerancePercentage?.toString() ?? '0'}
                      onChange={(e) => handleQtyChange(row.id ?? 0, e.target.value)}></TextField>  
                    </TableCell>  
                    <TableCell>
                      <TextField InputProps={{
                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      }}
                      type='number'
                      value={row.toleranceValue?.toString() ?? '0'}
                      onChange={(e) => handleValueChange(row.id ?? 0, e.target.value)}></TextField>  
                    </TableCell>
                    <TableCell >
                      <Trash01 style={{ color:'red' }}
                        onClick={() => deleteItemRow(row.id ?? 0)}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) :(
          <Alert severity='info'
            sx={{ mb: '12px', mx: '8px' }}>
            {t('noItems')}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default StockCountCreateItems;