import {  Button, Typography, Dialog, DialogContent,  DialogActions, DialogTitle, IconButton } from '@mui/material';
import { ScheduleCreateContext, ScheduleCreateValues } from '../../contexts/ScheduleCreateContext';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ScheduleCreateDeleteItemsModal=() => {
  const { t,
    isDeleteModalOpen,
    handleClose,
    deleteItems } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  return (
    <Dialog open={isDeleteModalOpen}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {t('deleteItem')}
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('approvalDialogItemsMessage')}</Typography>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button variant="bad"
          size='lg'
          onClick={deleteItems}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleCreateDeleteItemsModal;