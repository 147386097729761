import { palette } from '../palette';

export const MuiDrawer = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        backgroundColor: palette.primary[700],
        color: palette.primary.contrastText,
        height: '100vh',
      },
      '& .AppSlidingSubMenu-root': {
        backgroundColor: palette.primary[800],
        color: palette.primary.contrastText,
      },
      '& .MuiListItemIcon-root': {
        alignItems: 'center',
      },
    },
  },
};