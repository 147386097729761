import {  Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { ScheduleCreateContext, ScheduleCreateValues } from '../contexts/ScheduleCreateContext';
import { theme } from '../../../theme';
import InfoIcon from '@mui/icons-material/Info';

const ScheduleCreateMain=() => {
  const { t,
    isLoading,
    allUsers,
    handleActiveChange,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    handleSliderChange,
    handleIsTimeLockedChanged,
    handleSmartCountChange, 
    name,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    isSmartCount,
    isActive,
    currencySymbol,
    hasRightToEdit } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  if(isLoading){
    return <></>;
  } 

  return (
    <Box sx={{ border:'2px solid', py: '12px', px: '8px', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        spacing={5}>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <TextField 
              disabled = {!hasRightToEdit}
              label={t('name')}
              variant="outlined"
              value={name}
              onChange={handleInputChange}/>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink >
              {t('approver')}
              <Tooltip title={t('approverInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip>
            </InputLabel>
            <Select value={selectedUser}
              notched
              disabled = {!hasRightToEdit}
              label={t('approver') + '--.'}
              onChange={handleUserSelect}>
              <MenuItem key=""
                value="">
                {t('all')}
              </MenuItem>
              {allUsers.map((emailAddress) => (
                <MenuItem key={emailAddress}
                  value={emailAddress}>
                  {emailAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <TextField label={<>{t('approvalValueToleranceLabel') + ' ' + currencySymbol} <Tooltip title={t('approvalValueToleranceInfo')}>
              <InfoIcon fontSize='small'
                sx={{ ml: 2, verticalAlign: 'sub' }}/>
            </Tooltip></>}
            variant="outlined"
            disabled = {!hasRightToEdit}
            value={approvalValue ?? 0}
            onChange={handleInputValue}/>
          </FormControl>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <Box sx={{ px: 6, mt: -4 }}>
            <Typography variant='textXS'>
              {t('approvalQtyValue')}: {sliderValue}%
              <Tooltip title={t('approvalQtyValueInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub', height: '16px', width: '16px' }}/>
              </Tooltip>
            </Typography>
            <Slider
              style={{ paddingTop: 0, paddingBottom: 0 }}
              value={sliderValue}
              min={0}
              step={1}
              max={100}
              disabled = {!hasRightToEdit}
              onChange={handleSliderChange}
              valueLabelDisplay="off"
              aria-labelledby="non-linear-slider"/>
          </Box>
        </Grid> 
        <Grid item 
          lg={3}
          sm={6}
          xs={12}>
          <FormControlLabel
            disabled = {!hasRightToEdit}
            control={<Switch onChange={handleActiveChange}
              checked={isActive}/>}
            label={<>
              {t('active')}
              <Tooltip title={t('activeInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip>
            </>}
            labelPlacement="start"
            sx={{ ml:2 }}
          />
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <Box sx={{ display: 'flex', alignItems: 'left' }}>
            <FormControlLabel
              disabled = {!hasRightToEdit}
              control={<Switch checked={isSmartCount}
                onChange={handleSmartCountChange} />}
              label={<>{t('smartCount')}
                <Tooltip title={t('smartCountInfo')}>
                  <InfoIcon fontSize='small'
                    sx={{ ml: 2, verticalAlign: 'sub' }}/>
                </Tooltip>
              </>}
              labelPlacement="start"
              sx={{ ml:2 }}/>
          </Box>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              disabled = {!hasRightToEdit}
              control={<Switch onChange={handleIsTimeLockedChanged}
                checked={isTimeLocked}/>}
              label={<>{t('lockHour')}
                <Tooltip title={t('lockHourInfo')}>
                  <InfoIcon fontSize='small'
                    sx={{ ml: 2, verticalAlign: 'sub' }}/>
                </Tooltip>
              </>}
              labelPlacement="start"
              sx={{ ml:2 }}/>
          </Box>
        </Grid>
        <Grid item
          lg={3}
          sm={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
          <FormControl fullWidth>
            <InputLabel shrink >
              {t('lockTime')}
              <Tooltip title={t('lockTimeInfo')}>
                <InfoIcon fontSize='small'
                  sx={{ ml: 2, verticalAlign: 'sub' }}/>
              </Tooltip>
            </InputLabel>
            <Select
              label={t('lockTime') + '--.'}
              defaultValue={0}
              value={selectedTime ?? 0}
              disabled={!isTimeLocked || !hasRightToEdit}
              onChange={handleSelectChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {generateTimeOptions().map((option) => (
                <MenuItem key={option.value}
                  value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScheduleCreateMain;