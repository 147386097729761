import React, { createContext, useMemo } from 'react';
import { TFunction } from 'i18next';
import { Invoice, InvoiceItem } from '../../../app/services/api/generated';
import { useInvoiceDetails } from '../hooks/useInvoiceDetails';
import { NavigateFunction } from 'react-router-dom';

export interface InvoiceDetailsValues {
    t: TFunction<'invoices', undefined>, 
    data: Invoice | undefined,
    navigate: NavigateFunction,
    note: string,
    itemId: number,
    isCreditNote: boolean,
    handleEditNoteButtonClick: (note: string, passedId: number) => () => void,
    handleNoteButtonClick: (note: string, passedId: number) => () => void,
    handleAcceptButtonClick: (itemId: number) => () => void,
    closeEditNoteModal: () => void,
    closesNoteModal: () => void,
    updateItemNote: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    acceptItem: (itemId: number) => void,
    openEditNoteModal: boolean,
    rowSxPropToUse: (status: string) => { backgroundColor: string } | { backgroundColor: undefined;},
    saveInvoice: () => void,
    invoiceNote: string,
    changeInvoiceNote: (event: React.ChangeEvent<HTMLInputElement>) => void,
    canEdit: boolean,
    isOpenNoteModal: boolean,
    isLoading: boolean,
    sorteditems: InvoiceItem[] | undefined,
    order: 'asc' | 'desc',
    orderBy: keyof InvoiceItem,
    handleSort: (orderBy: keyof InvoiceItem) => void
    isShowSuccessAlert: boolean,
    isShowProcessingAlert: boolean,
    isShowErrorAlert: boolean,
    errorMessage: string,
    processingMessage: string,
    successMessage: string,
    closeSuccessAlert: () => void,
    closeProcessingAlert: () => void,
    closeErrorAlert: () => void,
    isOpenAcceptModal: boolean,
    closesAcceptModal: () => void,
    acceptId: number | undefined,
}

export const InvoiceDetailsContext = createContext<null | InvoiceDetailsValues>(null);

interface ProviderProps {
    children: React.ReactNode;
}

export const InvoiceDetailsProvider = ({ children }: ProviderProps) => {
  const {
    t,
    data,
    navigate,
    note,
    acceptId,
    itemId,
    isCreditNote,
    handleEditNoteButtonClick,
    handleNoteButtonClick,
    closeEditNoteModal,
    closesNoteModal,
    updateItemNote,
    acceptItem,
    openEditNoteModal,
    rowSxPropToUse,
    saveInvoice,
    invoiceNote,
    changeInvoiceNote,
    canEdit,
    isOpenNoteModal,
    isLoading,
    sorteditems,
    order,
    orderBy,
    handleSort,
    isShowSuccessAlert,
    isShowProcessingAlert,
    isShowErrorAlert,
    errorMessage,
    processingMessage,
    successMessage,
    isOpenAcceptModal,
    closeSuccessAlert,
    closeProcessingAlert,
    closeErrorAlert,
    handleAcceptButtonClick,
    closesAcceptModal,
  } = useInvoiceDetails();

  const passedValue = {
    t,
    data,
    navigate,
    note,
    acceptId,
    itemId,
    isCreditNote,
    handleEditNoteButtonClick,
    handleNoteButtonClick,
    closeEditNoteModal,
    closesNoteModal,
    updateItemNote,
    acceptItem,
    openEditNoteModal,
    rowSxPropToUse,
    saveInvoice,
    invoiceNote,
    changeInvoiceNote,
    canEdit,
    isOpenNoteModal,
    isLoading,
    sorteditems,
    order,
    orderBy,
    handleSort,
    isShowSuccessAlert,
    isShowProcessingAlert,
    isShowErrorAlert,
    errorMessage,
    processingMessage,
    successMessage,
    isOpenAcceptModal,
    closeSuccessAlert,
    closeProcessingAlert,
    closeErrorAlert,
    handleAcceptButtonClick,
    closesAcceptModal,
  };

  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);
  
  return (
    <InvoiceDetailsContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </InvoiceDetailsContext.Provider>
  );
};